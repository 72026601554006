import React from "react";
import {inject, observer} from "mobx-react";

const Pagination = inject("rootStore")(observer(class Pagination extends React.Component {

    lastPage;
    currentPage;

    componentDidUpdate(prevProps) {
        if (
            this.injected.lastPage !== prevProps.lastPage ||
            this.injected.currentPage !== prevProps.currentPage
        ) {
            let pagi = this.createPageNumber(this.injected.currentPage, this.injected.lastPage)
            this.setState({
                lastPage: this.injected.lastPage,
                currentPage: this.injected.currentPage,
                pagi: this.createPageNumber(this.injected.currentPage, this.injected.lastPage)
            })


        }


    }

    constructor(props, context) {
        super(props, context);
        let pagi = this.createPageNumber(this.injected.currentPage, this.injected.lastPage)

        this.state = {
            lastPage: this.injected.lastPage,
            currentPage: this.injected.currentPage,
            pagi: pagi

        }
    }


    get injected() {
        return this.props;
    }

    getPage(e, pageNumber) {
        e.preventDefault();
        this.injected.parentCallback(pageNumber);
    }

    createPageNumber(currentPage, lastPage) {
        let pagination = [];
        let last = lastPage

            if (currentPage - 3 > 1) {
                for (let i = currentPage - 3; i < currentPage; i++) {
                    pagination.push(i)
                }
            } else if (currentPage - 1 > 1) {
                for (let i = currentPage - 1; i < currentPage; i++) {
                    pagination.push(i)
                }
            }

            if (currentPage !== lastPage && currentPage !== 1) {
                pagination.push(currentPage)
            }

            if (currentPage + 3 < lastPage) {
                for (let i = currentPage + 1; i <= currentPage + 3; i++) {
                    pagination.push(i)
                }
            } else if (currentPage + 1 < lastPage) {
                for (let i = currentPage + 1; i <= currentPage + 1; i++) {
                    pagination.push(i)
                }
            }


        return pagination

    };

    render() {


        return (
            <nav aria-label="Page navigation example" className="ml-auto mr-auto">
                <ul className="pagination justify-content-end">

                    {this.state.lastPage  !== 1 &&
                    (
                        <span style={{display: "flex"}}>
                        <li key={1} className={this.state.currentPage === 1 ? "page-item active" : "page-item"}><a href="/#"
                                                                                                                   className="page-link"
                                                                                                                   key={1}
                                                                                                                   onClick={e => this.getPage(e, 1)}>1ère
                            ... </a></li>

                    {this.state.pagi?.map((i) => (
                        <li key={i} className={this.state.currentPage === i ? "page-item active" : "page-item"}><a
                        href="/#" className="page-link" key={i} onClick={e => this.getPage(e, i)}>{i}</a></li>
                        ))}


                        <li key={this.state.lastPage}
                        className={this.state.currentPage === this.state.lastPage ? "page-item active" : "page-item"}>
                        <a href="/#" className="page-link" key={this.state.lastPage}
                        onClick={e => this.getPage(e, this.state.lastPage)}>... Dernière</a></li>

                        </span>
                    )}

                </ul>
            </nav>
        )
    }
}));

export default Pagination;
