import React from "react";
import {inject, observer} from "mobx-react";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Link} from "react-router-dom";
import getRoutes from "../../../components/Akitatek/getRoutes";
import FormGroup from "reactstrap/lib/FormGroup";
import CardBody from "reactstrap/lib/CardBody";


const TableLotRow = inject("rootStore")(observer(class TableLotRow extends React.Component {

    item;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            fournisseur: this.injected.item.fournisseur ?? {nom: this.injected.rootStore.authStore.nameCompany},
            prix_unitaire: this.injected.item.commande_produit?.prix_unitaire ?? this.injected.item.product?.prix_unitaire,
            tva: this.injected.item.commande_produit?.tva ?? 0,
            remise: this.injected.item.commande_produit?.remise ?? 0,
            quantite: this.injected.item.stock ?? 0,
            created_at: this.injected.item.created_at ?? "",
            updated_at: this.injected.item.updated_at ?? "",
            pid: this.injected.item.pid ?? "",
            id: this.injected.item.id ?? "",
            qtePrint: "1",
            addStockage: false,
            product: this.injected.item.product ?? "",
        };
    }


    componentDidUpdate(prevProps) {
        if (this.injected.item !== prevProps.item) {
            this.setState({
                fournisseur: this.injected.item.fournisseur ?? {nom: this.injected.rootStore.authStore.nameCompany},
                prix_unitaire: this.injected.item.commande_produit?.prix_unitaire ?? this.injected.item.product?.prix_unitaire,
                tva: this.injected.item.commande_produit?.tva ?? 0,
                remise: this.injected.item.commande_produit?.remise ?? 0,
                quantite: this.injected.item.stock ?? 0,
                created_at: this.injected.item.created_at ?? "",
                updated_at: this.injected.item.updated_at ?? "",
                pid: this.injected.item.pid ?? "",
                id: this.injected.item.id ?? "",
                product: this.injected.item.product ?? "",
            })
        }
    }

    onUpdate(e) {
        e.preventDefault();

        let data = {
            qtePrint: this.state.qtePrint,
            lot_id: this.state.id
        }
        this.injected.rootStore.stockStore.productStore.updateMyLot(data)
    }


    render() {
        return (
            <>
                <tr style={{textAlign: "center"}}>
                    <th>
                        <span className="mb-0 text-sm">
                                <Link style={{color: "#20a8d8"}} to={{
                                    pathname: getRoutes("Modifier stock", this.state.product?.id ),
                                    aboutProps: {
                                        stock: this.state.product
                                    }
                                }}>
                                    <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
                                    {this.state.pid}
                                </Link>
                        </span>
                    </th>

                    <td>
                        <span className="mb-0 text-sm">
                            {this.state.fournisseur?.nom}
                        </span>
                    </td>
                    <td>
                        <span className="mb-0 text-sm">
                            {this.state.prix_unitaire} €
                        </span>
                    </td>

                    <td>
                        <span className="mb-0 text-sm">
                            {this.state.tva}
                        </span>
                    </td>

                    <td>
                        <span className="mb-0 text-sm">
                            {this.state.remise}
                        </span>
                    </td>

                    <td>
                        <span className="mb-0 text-sm">
                            {this.state.quantite}
                        </span>
                    </td>

                    <td className="th-price">
                        <span className="mb-0 text-sm">
                            {moment(this.state.created_at).format("DD/MM/YYYY")}
                        </span>
                    </td>

                    <td>
                        <div>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Impression par :</FormLabel>
                                <RadioGroup row aria-label="position" name="position" defaultValue={this.state.qtePrint}
                                            onChange={e => {
                                                this.setState({qtePrint: e.target.value})
                                            }}>
                                    <FormControlLabel value="1" control={<Radio/>} label="Lot(1)"/>
                                    <FormControlLabel value={this.state.quantite.toString()} control={<Radio/>}
                                                      label={"Quantite(" + this.state.quantite + ")"}/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </td>

                    <td>
                    {this.injected.rootStore.authStore.modules['print'] === 1 && (
                        <Button variant="primary"
                                size="sm" type="button" className="mr-1"
                                onClick={e =>  this.injected.rootStore.stockStore.downloadPdf(e, this.state.product?.id, this.state.id,"")}
                        >
                            <i className="fa fa-print"/>
                        </Button>)}

                    <span className="mb-0 text-sm">
                       <Button
                           onClick={(e) => {
                               this.onUpdate(e);
                           }} variant="success" size="sm" type="button" className="mr-1">
                                                    <i className="fa fa-check"/>
                            </Button>
                        </span>
                    </td>
                </tr>
            </>
        );
    }

}));

export default TableLotRow;
