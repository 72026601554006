/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ObsoleteLogin from "./views/auth/Login";
import Customers from "./views/admin/Customers/Customers";
import Invoices from "./views/admin/Invoices/Invoices";
import AddCustomer from "./views/admin/Customers/AddCustomer";
import AddInvoice from "./views/admin/Invoices/AddInvoice";
import Blogs from "./views/admin/Blogs/Blogs";
import CreateBlog from "./views/admin/Blogs/CreateBlog";
import GiveMeMoney from "./views/admin/Invoices/GiveMeMoney";
import Stats from "./views/admin/Stats/Stats";
import Annuaire from "./views/admin/Annuaire/Annuaire";
import CreateMarque from "./views/admin/Annuaire/CreateMarque";
import CreateProduct from "./views/admin/Annuaire/CreateProduct";
import Products from "./views/admin/Annuaire/Product/Produtcs";
import Prestation from "./views/admin/Annuaire/Prestation/Prestation";
import AddPrestation from "./views/admin/Annuaire/Prestation/AddPrestation";
import Outils from "./views/admin/Outils/Outils.tsx";
import Devis from "./views/admin/Devis/Devis";
import AddDevis from "./views/admin/Devis/AddDevis";
import InfosOnCustomers from "./views/admin/Customers/InfosOnCustomers";
import Marques from "./views/admin/Annuaire/Marques";
import Stock from "./views/admin/Stock/Stock";
import Commande from "./views/admin/Commande/Commande";
import Accueil from "./views/admin/Accueil/Accueil";
import AddCommande from "./views/admin/Commande/AddCommande";
import PriseEnCharge from "./views/admin/PriseEnCharge/PriseEnCharge";
import AddPriseEnCharge from "./views/admin/PriseEnCharge/UpdatePriseEnCharge";
import AddPriseEnChargeStepper from "./views/admin/PriseEnCharge/Stepper/AddPriseEnChargeStepper";
import GlobalSearchPage from "./views/admin/Search/GlobalSearchPage";
import Carrousel from "./views/admin/Magasin/Carrousel/Carrousel";
import AddStock from "./views/admin/Stock/AddStock";
import EditStock from "./views/admin/Stock/EditStock";
import Tickets from "./views/admin/Tickets/Tickets";
import EditTicket from "./views/admin/Tickets/EditTicket";
import SeeAll from "./views/admin/Notifications/SeeAll";
import Settings from "./views/admin/Settings/Settings";
import StatsDetails from "./views/admin/Stats/Excel";
import UpdatePage from "./views/admin/Magasin/Pages/UpdatePage";
import Asset from "./views/admin/Asset/Asset";
import AddAsset from "./views/admin/Asset/AddAsset";
import UpdateAsset from "./views/admin/Asset/UpdateAsset";
import Casier from "./views/admin/Casier/Casier";
import Service from "./views/admin/Magasin/Services/Service";
import Presentation from "./views/admin/Magasin/Presentations/Presentation";
import AddPresentation from "./views/admin/Magasin/Presentations/AddPresentation";
import AddService from "./views/admin/Magasin/Services/AddService";
import CommandeClient from "./views/admin/CommandeClient/CommandeClient";
import SeeCommandeClient from "./views/admin/CommandeClient/SeeCommandeClient";
import IndexPage from "./views/admin/Magasin/Pages/IndexPage";
import PriseEnChargeInternet from "./views/admin/PriseEnCharge/PriseEnChargeInternet";
import Printer from "./views/admin/Printer/Printer";
import Cat from "./views/admin/Annuaire/Cat/Cat";
import Category from "./views/admin/Casier/Casier";


let routes = [
    {
        path: '/',
        exact: true,
        name: "Home",
        layout: "/admin"
    },
    {
        path: '/notifications',
        name: "Notifications",
        component: SeeAll,
        layout: "/admin"
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "ni ni-tv-2 text-primary",
        component: Accueil,
        layout: "/admin"
    },
    {path: '/tickets', exact: true, name: 'Tickets', component: Tickets, module: 'document'},
    {
        path: "/tickets/index",
        name: "Tickets",
        icon: "ni ni-key-25 text-info",
        component: Tickets,
        module: 'document',
        layout: "/admin"
    },
    {
        path: "/tickets/update/:id",
        name: "Gérer un ticket",
        icon: "ni ni-key-25 text-info",
        component: EditTicket,
        module: 'document',
        layout: "/admin"
    },
    {path: '/factures', exact: true, name: 'Factures', component: Invoices, module: 'document'},
    {
        path: "/factures/index",
        name: "Factures",
        module: 'document',
        icon: "ni ni-key-25 text-info",
        component: Invoices,
        layout: "/admin"
    },
    {
        path: "/factures/modifier/:id/:type",
        name: "Gérer une Facture",
        icon: "ni ni-tv-2 text-primary",
        component: AddInvoice,
        module: 'document',
        layout: "/admin"
    },

    {
        path: "/factures/payer/:id",
        name: "Payer une Facture",
        icon: "ni ni-tv-2 text-primary",
        component: GiveMeMoney,
        module: 'document',
        layout: "/admin"
    },
    {path: '/clients', exact: true, name: 'Clients', component: Customers},
    {
        path: "/clients/index",
        name: "Clients",
        icon: "ni ni-single-02 text-yellow",
        component: Customers,
        layout: "/admin",
    },
    {
        path: "/clients/ajouter",
        name: "Ajout Client",
        icon: "ni ni-tv-2 text-primary",
        component: AddCustomer,
        layout: "/admin"
    },
    {
        path: "/clients/modifier/:id",
        name: "Modifier Client",
        icon: "ni ni-tv-2 text-primary",
        component: InfosOnCustomers,
        layout: "/admin"
    },

    {
        path: "/login",
        name: "Login",
        icon: "ni ni-key-25 text-info",
        component: ObsoleteLogin,
        layout: "/auth"
    },
    {path: '/blogs', exact: true, name: 'Blogs', component: Blogs, module: 'blog'},
    {
        path: "/blogs/index",
        name: "Blogs",
        module: "blog",
        icon: "ni ni-key-25 text-info",
        component: Blogs,
        layout: "/admin"
    },
    {
        path: "/blogs/add",
        name: "Ajout Blog",
        icon: "ni ni-tv-2 text-primary",
        component: CreateBlog,
        module: "blog",
        layout: "/admin"
    },
    {
        path: "/blogs/update/:id",
        name: "Modifier Blog",
        icon: "ni ni-tv-2 text-primary",
        component: CreateBlog,
        module: "blog",
        layout: "/admin"
    },

    {
        path: "/excel",
        name: "Details CA",
        icon: "ni ni-tv-2 text-primary",
        component: StatsDetails,
        layout: "/admin"
    },

    {
        path: "/stats",
        name: "Statistiques",
        icon: "ni ni-tv-2 text-primary",
        component: Stats,
        layout: "/admin"
    },

    {path: '/magasin', exact: true, name: 'Magasin', component: Carrousel},
    {
        path: "/magasin/carroussel/index",
        name: "Gestion Carousel",
        identifiant: "akitatek",
        module: "web_client",
        icon: "ni ni-tv-2 text-primary",
        component: Carrousel,
        layout: "/admin"
    },

    {path: '/marques', exact: true, name: 'Annuaire', component: Annuaire},

    {
        path: "/marques/index",
        name: "Annuaire",
        icon: "ni ni-tv-2 text-primary",
        component: Annuaire,
        layout: "/admin"
    },
    {
        path: "/categories/index",
        name: "Catégories",
        icon: "ni ni-tv-2 text-primary",
        component: Cat,
        layout: "/admin"
    },
    {
        path: "/marques/create/",
        name: "Ajouter une marque",
        icon: "ni ni-tv-2 text-primary",
        component: CreateMarque,
        layout: "/admin"
    },

    {
        path: "/marques/update/:id",
        name: "Modifier une marque",
        icon: "ni ni-tv-2 text-primary",
        component: Marques,
        layout: "/admin"
    },
    {
        path: "/marques/products/create",
        name: "Ajouter un produit",
        icon: "ni ni-tv-2 text-primary",
        component: CreateProduct,
        layout: "/admin"
    },
    {
        path: "/marques/products/show/:idMarque/create",
        name: "Créer un produit",
        icon: "ni ni-tv-2 text-primary",
        component: CreateProduct,
        layout: "/admin"
    },

    {
        path: "/marques/products/montrer/:id/",
        name: "Afficher un produit",
        icon: "ni ni-tv-2 text-primary",
        component: CreateProduct,
        layout: "/admin"
    },
    {path: '/prestations', exact: true, name: 'Prestations', component: Prestation},

    {
        path: "/prestations/index",
        name: "Liste des prestations",
        icon: "ni ni-tv-2 text-primary",
        component: Prestation,
        layout: "/admin"
    },
    {
        path: "/prestations/edit/add",
        name: "Créer une prestation",
        icon: "ni ni-tv-2 text-primary",
        component: AddPrestation,
        layout: "/admin"
    },
    {
        path: "/prestations/edit/:id",
        name: "Modifier une prestation",
        icon: "ni ni-tv-2 text-primary",
        component: AddPrestation,
        layout: "/admin"
    },
    {
        path: "/prestations/marques",
        name: "Créer une marque",
        icon: "ni ni-tv-2 text-primary",
        component: CreateMarque,
        layout: "/admin"
    },
    {
        path: "/prestations/products",
        name: "Créer un produit",
        icon: "ni ni-tv-2 text-primary",
        component: CreateProduct,
        layout: "/admin"
    },

    {path: '/products', exact: true, name: 'Produits', component: Products},
    {path: '/outils', exact: true, name: 'Outils', component: Outils},
    {
        path: "/outils/index",
        name: "Outils",
        icon: "ni ni-tv-2 text-primary",
        component: Outils,
        layout: "/admin"
    },

    {path: '/devis', exact: true, name: 'Devis', component: Devis, module: 'document'},
    {
        path: "/devis/index",
        name: "Devis",
        module: 'document',
        icon: "ni ni-key-25 text-info",
        component: Devis,
        layout: "/admin"
    },
    {
        path: "/devis/edit/:id",
        name: "Gérer un devis",
        module: 'document',
        icon: "ni ni-key-25 text-info",
        component: AddDevis,
        layout: "/admin"
    },
    {path: '/stock', exact: true, name: 'Stock', component: Stock, module: "stock"},
    {
        path: "/stock/index",
        name: "Mon stock",
        icon: "ni ni-key-25 text-info",
        component: Stock,
        module: "stock",
        layout: "/admin"
    },
    {
        path: "/stock/add",
        name: "Ajout stock",
        icon: "ni ni-key-25 text-info",
        component: AddStock,
        module: "stock",
        layout: "/admin"
    },
    {
        path: "/stock/edit/:id",
        name: "Modifier stock",
        icon: "ni ni-key-25 text-info",
        component: EditStock,
        module: "stock",
        layout: "/admin"
    },

    {path: '/commandes', exact: true, name: 'Commande', component: CommandeClient, module: 'boutique'},
    {
        path: "/commandes/index",
        name: "Commande Client",
        icon: "ni ni-key-25 text-info",
        component: CommandeClient,
        module: "boutique",
        layout: "/admin"
    },
    {
        path: "/commandes/modifier/:id",
        name: "Voir une commande",
        icon: "ni ni-key-25 text-info",
        component: SeeCommandeClient,
        module: "boutique",
        layout: "/admin"
    },
    {path: '/command', exact: true, name: 'Commande', component: Commande, module: 'stock'},
    {
        path: "/command/index",
        name: "Commande",
        icon: "ni ni-key-25 text-info",
        component: Commande,
        module: "stock",
        layout: "/admin"
    },
    {
        path: "/command/update/:id",
        name: "Gérer une commande",
        icon: "ni ni-key-25 text-info",
        component: AddCommande,
        module: "stock",
        layout: "/admin"
    },
    {path: '/casier', exact: true, name: 'Casier', component: Casier, module: 'stock'},
    {
        path: "/casier/index",
        name: "Casier",
        icon: "ni ni-key-25 text-info",
        component: Casier,
        module: "stock",
        layout: "/admin"
    },
    {path: '/category', exact: true, name: 'Catégorie', component: Category},
    {
        path: "/category/index",
        name: "Categorie",
        icon: "ni ni-key-25 text-info",
        component: Category,
        layout: "/admin"
    },
    {path: '/priseencharge', exact: true, name: 'Prise En Charge', component: PriseEnCharge},
    {
        path: "/priseenchargeinternet/index",
        name: "Prise En Charge Internet",
        icon: "ni ni-key-25 text-info",
        component: PriseEnChargeInternet,
        layout: "/admin"
    },
    {
        path: "/priseencharge/index",
        name: "Prise En Charge",
        icon: "ni ni-key-25 text-info",
        component: PriseEnCharge,
        layout: "/admin"
    },
    {
        path: "/priseencharge/add",
        name: "Créer une prise En Charge",
        icon: "ni ni-key-25 text-info",
        component: AddPriseEnChargeStepper,
        layout: "/admin"
    },
    {
        path: "/priseencharge/update/:id",
        name: "Gérer une Prise En Charge",
        icon: "ni ni-key-25 text-info",
        component: AddPriseEnCharge,
        layout: "/admin"
    },
    {
        path: "/search",
        name: "Rechercher",
        icon: "ni ni-key-25 text-info",
        component: GlobalSearchPage,
        layout: "/admin"
    },
    {
        path: "/profile",
        name: "Profil",
        icon: "ni ni-key-25 text-info",
        component: GlobalSearchPage,
        layout: "/admin"
    },
    {
        path: "/settings",
        name: "Paramètres",
        icon: "ni ni-key-25 text-info",
        component: Settings,
        layout: "/admin"
    },
    {
        path: "/pages/",
        name: "Gérer les pages",
        icon: "ni ni-key-25 text-info",
        component: IndexPage,
        module: "web_client",
        layout: "/admin"
    },
    {
        path: "/page/:page",
        name: "Modifier une page",
        icon: "ni ni-key-25 text-info",
        component: UpdatePage,
        module: "web_client",
        layout: "/admin"
    },
    {
        path: "/accueil/services",
        name: "Présenter vos services",
        icon: "ni ni-key-25 text-info",
        component: Service,
        module: "web_client",
        layout: "/admin"
    },

    {
        path: "/accueil/service/ajouter/nouveau",
        name: "Editer un service",
        icon: "ni ni-key-25 text-info",
        component: AddService,
        module: "web_client",
        layout: "/admin"
    },
    {
        path: "/accueil/service/ajouter/:id",
        name: "Ajouter un service",
        icon: "ni ni-key-25 text-info",
        component: AddService,
        module: "web_client",
        layout: "/admin"
    },
    {
        path: "/accueil/presentations/ajouter/nouveau",
        name: "Ajouter un atout",
        icon: "ni ni-key-25 text-info",
        component: AddPresentation,
        module: "web_client",
        layout: "/admin"
    },
    {
        path: "/accueil/presentations",
        name: "Présenter vos atouts",
        icon: "ni ni-key-25 text-info",
        component: Presentation,
        module: "web_client",
        layout: "/admin"
    },

    {
        path: "/accueil/presentation/ajouter/:id",
        name: "Editer un service",
        icon: "ni ni-key-25 text-info",
        component: AddPresentation,
        module: "web_client",
        layout: "/admin"
    },

    {path: '/images', exact: true, name: 'Gestion des images', component: Asset},
    {
        path: "/images/index",
        name: "Gestion des images",
        icon: "ni ni-key-25 text-info",
        component: Asset,
        layout: "/admin"
    },
    {
        path: "/images/modifier/:id",
        name: "Modifier une image",
        icon: "ni ni-key-25 text-info",
        component: UpdateAsset,
        layout: "/admin"
    },
    {
        path: "/images/ajouter",
        name: "Ajouter des images",
        icon: "ni ni-key-25 text-info",
        component: AddAsset,
        layout: "/admin"
    },
    {
        path: "/impression/index",
        name: "Paramètres d'impression",
        icon: "ni ni-key-25 text-info",
        component: Printer,
        layout: "/admin"
    },
];
export default routes;
