import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardFooter, CardHeader, Container, Form, Input, Row,} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import Tabs from "../../../components/Akitatek/Tabs/Tabs";
import {Col} from "react-bootstrap";
import Label from "reactstrap/lib/Label";
import FormGroup from "reactstrap/lib/FormGroup";
import getRoutes from "../../../components/Akitatek/getRoutes";
import Dropdown from "react-bootstrap/Dropdown";
import UserInformation from "../../../components/Akitatek/AdressCustomer";
import ActionsPDF from "../Invoices/ActionsPDF";
import ActionsCmdClient from "./ActionsCmdClient";


const SeeCommandeClient = inject("rootStore")(observer(class SeeCommandeClient extends React.Component {


    commande;

    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);

        this.state = {
            activeTab: "0",
            pid: "",
            commande: this.injected.location.aboutProps?.commande ?? {
                title: "",
                lignes: [],
                mouv: [],
                user: {},
                status: "élaboration",
            }
        };

        this.injected.rootStore.savedDatasStore.setHeader("Commandes Client", "Gestion des commandes client", "commande.jpg", this.injected.rootStore.commandeClientStore)
    }

    //update component if the id change, or if there is a refresh
    async componentDidUpdate(prevProps) {
        if (this.injected.location !== prevProps.location) {
            await this.onRouteChanged()
        }
        if (this.state.commande === "" && this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }

    //check if the values are up to date
    async componentDidMount() {
        if (this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }


    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (id) {
            await this.onReload(id)
        }
    }

    async onReload(id) {
        let myCmd = await this.injected.rootStore.commandeClientStore.getCommandeForId(id);

        if (myCmd) {
            this.setState({
                commande: myCmd
            })
        }
    }
     reload = (data) => {
        if (data) {
            this.setState({
                commande: data
            })
        }
    }

    createButtonSave(title, color, onclickFunc) {
        return (
            <Button color={color}
                    type="submit"
                    onClick={onclickFunc}>{title}</Button>)
    }


    async enter(e) {
        if (e.key === 'Tab' || e.key === 'Enter') {
            e.preventDefault()
            let datas = {
                commande_id: this.state.commande.id,
                pid: this.state.pid

            }
            let authResponse = await this.injected.rootStore.commandeClientStore.commande(datas)

            if (authResponse?.datas) {
                this.setState({
                    commande: authResponse?.datas,
                    pid: ""
                })
            }

        }
    }

    setForm() {
        return (
            <div>
                <Form
                    className="mb-4"
                    id="form-add-priseencharge"
                    name="form-add-priseencharge"
                    style={{textAlign: "center"}}
                >
                    <CardBody>
                        <Col>
                            <Row className="ml-2 mr-2" style={{justifyContent: "space-between"}}>
                                <Col form>
                                    <Row form>
                                        <Col md={2}>
                                            <Label>

                                                Facture associée :
                                            </Label>
                                        </Col>
                                        <Col md={4}>
                                            {this.state.commande.facture ? (
                                                <Link to={{
                                                    pathname: getRoutes("Gérer une facture", this.state.commande.facture?.id),
                                                    aboutProps: {
                                                        isCommandeValid: true,
                                                        invoice: this.state.commande.facture
                                                    }
                                                }}>
                                                    Facture n° {this.state.commande.facture?.id}
                                                </Link>
                                            ) : "Aucune"}

                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={2}>
                                            <Label for="exampleEmail">
                                                Prix :
                                            </Label>
                                        </Col>
                                        <Col md={4}>
                                            {this.state.commande.total_facture} €
                                        </Col>
                                    </Row>

                                    <Row form>
                                        <Col md={2}>
                                            <Label for="exampleEmail">
                                                Mode :
                                            </Label>
                                        </Col>
                                        <Col md={4}>
                                            {this.state.commande.choix}

                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={2}>
                                            <Label for="exampleEmail">Suivi</Label>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Input
                                                    className={"mb-3"}
                                                    placeholder="Suivi"
                                                    id="input-suivi"
                                                    type="text"
                                                    value={this.state.commande?.suivi}
                                                    onChange={(e) => {
                                                        let commande = this.state.commande
                                                        commande.suivi = e.target.value
                                                        this.setState({commande: commande})
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                {this.state.commande.facture && (
                                    <UserInformation user={this.state.commande?.user}
                                                     adresse={this.state.commande.facture.adresse}
                                                     adresseLivraison={this.state.commande.facture.adresse_livraison}/>
                                )}

                            </Row>
                        </Col>
                    </CardBody>
                </Form></div>);
    }


    setPrepa() {
        return (<div>
            <Form
                className="mb-4"
                id="form-add-priseencharge"
                name="form-add-priseencharge"
                style={{textAlign: "center"}}
            >
                <CardBody>
                    <Col>
                        <Row className="ml-2 mr-2" style={{justifyContent: "space-between"}}>
                            <Card style={{width: "50%"}}>
                                <CardBody>
                                    <Input placeholder={"Scannez votre produit"}
                                           value={this.state.pid}
                                           onChange={e => this.setState({pid: e.target.value})}
                                           onKeyDown={e => this.enter(e)}
                                           onKeyPress={e => this.enter(e)}
                                    />
                                </CardBody>
                                <CardFooter>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Produit</th>
                                            <th scope="col">Ref</th>
                                            <th scope="col">Lot ID</th>
                                            <th scope="col">Quantité</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.commande.mouv?.map(ligne => {
                                            return (<tr key={ligne.id}>
                                                <td> {ligne?.id} </td>
                                                <Link style={{color: "#20a8d8"}} to={{
                                                    pathname: getRoutes("Modifier stock", ligne.product?.id),
                                                    aboutProps: {
                                                        stock: ligne.product
                                                    }
                                                }}>
                                                    <td> {ligne?.product.nom}  </td>
                                                </Link>
                                                <td> {ligne?.lot.pid} </td>
                                                <td> {ligne?.lot.id} </td>
                                                <td scope="row">{ligne?.quantite} </td>
                                            </tr>)
                                        })}
                                        </tbody>
                                    </table>
                                </CardFooter>

                            </Card>
                            <Card style={{width: "50%"}}>
                                <CardBody>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="exampleEmail">
                                                Nombre d'articles :
                                            </Label>
                                        </Col>
                                        <Col md={4}>
                                            {this.state.commande.total_quantity}
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="exampleEmail">
                                                Articles :
                                            </Label>
                                        </Col>
                                        <Col>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Ref</th>
                                                    <th scope="col">Prix unitaire</th>
                                                    <th scope="col">Quantité</th>
                                                    <th scope="col">Casier</th>
                                                    <th scope="col">A scanner</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.commande?.lignes?.map(ligne => {
                                                    return (<tr>
                                                        <td> # {ligne?.product.id} </td>
                                                        <Link style={{color: "#20a8d8"}} to={{
                                                            pathname: getRoutes("Modifier stock", ligne.product?.id),
                                                            aboutProps: {
                                                                stock: ligne.product
                                                            }
                                                        }}>
                                                            <td> {ligne?.nom} </td>
                                                        </Link>
                                                        <td>{ligne?.price} €</td>
                                                        <td>{ligne?.quantity} </td>
                                                        <td>{ligne?.product?.casier?.stockage.name} - {ligne?.product?.casier?.name} </td>
                                                        <td> {ligne?.stock} </td>
                                                    </tr>)
                                                })}
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>


                        </Row>
                    </Col>
                </CardBody>
            </Form></div>)
    }

    onSendBackTab = (nb) => {
        this.setState({
            activeTab: nb
        })
    }



    async onUpdate(e) {
        e.preventDefault();

        let data = this.state.commande
        let authResponse = await this.injected.rootStore.commandeClientStore.update(data)

        if (authResponse?.status === "success") {
            this.setState({
                commande: authResponse.datas
            })
        }
    }

    render() {
        return (
            <>
                {this.state.commande?.id ? (
                        <Container className="mt-2" fluid>
                            <Row className="mb-3">
                                <div className="col">
                                    <Card className="shadow">
                                        <CardHeader
                                            className={this.injected.rootStore.savedDatasStore.setColor(this.state.commande?.status)}
                                            style={{display: "flex", justifyContent: "space-between"}}>
                                            <h4 className="mb-0">

                                                {this.state.commande?.id && (
                                                    "Commande #" + this.state.commande?.id + " " + this.state.commande?.statusname
                                                )}
                                            </h4>

                                            <div style={{display: "flex"}}>
                                                {this.state.commande.status === "ORDERED" | this.state.commande.status === "PREPARATION" ? (
                                                <ActionsCmdClient data={this.state.commande} onCallback={this.reload}/>
                                                ) : ''}

                                                {(this.state.commande.facture && (
                                                    <ActionsPDF data={this.state.commande.facture}
                                                                api={this.injected.rootStore.invoiceStore} type={"Facture"}
                                                                filename={"facture" + this.state.commande.facture?.id + "_" + "nom" + "_" + this.state.commande.facture?.created_at + ".pdf"}/>
                                                ))}

                                                <div className={"ml-2"}>
                                                    {(this.state.commande.facture && (
                                                        <ActionsPDF data={this.state.commande.facture}
                                                                    api={this.injected.rootStore.invoiceStore}
                                                                    type={"Enveloppe"} name={"pdf.enveloppe"}
                                                                    filename={"enveloppe" + this.state.commande.id + ".pdf"}/>
                                                    ))}
                                                </div>

                                                <div className={"ml-2"}>

                                                    {
                                                        this.createButtonSave("Modifier", "success", e => this.onUpdate(e))
                                                    }
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Tabs
                                            onCallback={this.onSendBackTab}
                                            activeTab={this.state.activeTab}
                                            children={[this.setForm(), this.setPrepa()]}
                                            titles={["Infos", "Préparation de commande"]}/>
                                    </Card>
                                </div>
                            </Row>
                        </Container>) :
                    (<Container className={"mt-2"} fluid> <Card><CardBody>Commande Client introuvable. Merci de
                        réessayer</CardBody></Card> </Container>)}
            </>
        );
    }
}));

export default withRouter(SeeCommandeClient);

