/* Custom import */
import Api from '../../util/api'
import {action, decorate, observable} from "mobx";

export default class DevisCreateStore {


    status = observable.box("");
    allStatus = {
        'WAIT':'attente',
        'VALID': 'valider',
        'ARCHIVED': 'archive'
    };

    constructor(rootStore, devisStore) {
        this.rootStore = rootStore
        this.devisStore = devisStore;
        this.api = new Api();
    }

    async getDevis(id) {
         this.rootStore.savedDatasStore.setLoading(true)
        let devis =  await this.api.devis.findDevisById(id);
        this.rootStore.savedDatasStore.setLoading(false)
        return devis
    }
}

decorate(DevisCreateStore, {
    status: observable,
    getDevis: action
});

