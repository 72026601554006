import React from "react";
import RowTD from "./RowTD";
import RowThLink from "./RowThLink";
import DateRow from "./Date";
import UserRow from "./UserRow";
import StatusRow from "./StatusRow";
import PhoneRow from "./PhoneRow";
import ActionPencilLink from "./ActionsPencilLink";
import MapRow from "./MapRow";
import MagasinOuInternet from "./MagasinOuInternet";

function TypeRow({element, data, rootStore, children}) {
    if((element?.key && !data[element.key]) && (element?.sub && !data[element.sub]?.[element.key] )) {
        return <td>  </td>
    }
    if (element?.action === "tr") {
        if (element.last) {
            return <RowTD title={data[element.sub]?.[element.last]?.[element.key]} action={element.title}  />
        }
        if (element.sub) {
            return <RowTD title={data[element.sub]?.[element.key]} action={element.title}  />
        }
        return <RowTD title={data[element.key]} action={element.title} alerte={data[element.alerte]}/>
    }
    if (element?.action === "phone") {
        if (element.sub) {
            return <td className="th-actions text-center"><PhoneRow title={data[element.sub]?.[element.key]} action={element.key}/> </td>
        }
        return <td className="th-actions text-center"><PhoneRow title={data[element.key]} action={element.key}/> </td>
    }
    if (element?.action === "email") {
        if (element.sub) {
            return <th className="text-center"><PhoneRow title={data[element.sub]?.[element.key]} action={element.key} /></th>
        }
        return <th className="text-center"><PhoneRow title={data[element.key]} action={element.key}/></th>
    }

    if (element?.action === "map") {
        return <MapRow element={data[element.key]} href={element.href} type={element.type}/>
    }

    if (element?.action === "thLink") {

        return <RowThLink title={data[element.key]} data={data} href={element.href}/>
    }

    if (element?.action === "date") {
        return <DateRow title={data[element.key]}/>
    }

    if (element?.action === "user") {
        return <UserRow data={data[element.key]}/>
    }

    if (element?.action === "status") {
        return <StatusRow title={data[element.key]} enumStatus={data.statusEnum ?? data.status} rootStore={rootStore}/>
    }

    if (element?.action === "actionpencillink") {
        return <ActionPencilLink title={data[element.key]} data={data} href={element.href}/>
    }

    if (element?.action === "magasinouinternet") {
        return <MagasinOuInternet data={data[element.key]} />
    }

    return <React.Fragment> {children}</React.Fragment>
}

export default TypeRow;
