import React from "react";
import {inject, observer} from "mobx-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Input from "reactstrap/lib/Input";
import Button from "react-bootstrap/Button";
import {Card, CardHeader} from "reactstrap";
import ModalAkitatek from "../../../components/Akitatek/Modal/Modal";
import AddMachineUser from "../Customers/AddMachineUser";
import CreateMarque from "../Annuaire/CreateMarque";
import CreateProduct from "../Annuaire/CreateProduct";
import Casier from "../Casier/Casier";

const SearchMachineUser = inject("rootStore") (observer( class SearchMachineUser extends React.Component {


    id
    machinesClient
    user

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            id: this.injected.id,
            machineClient: "",
            user: this.injected.user,
            accessoires: "",
            machinesClient: this.injected.machinesClient ?? [],
            addMachineClient: false,
            addProduit: false,
            addStockage: false,
            addMarque: false,
            casier: "",
            activeTab: "2"

        }

        this.injected.rootStore.stockageCasierStore.getCasiers()
        this.injected.rootStore.stockageCasierStore.getStockageCasier()
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.machineClient !== prevProps.machineClient ||
            this.injected.id !== prevProps.id ||
            this.injected.machinesClient !== prevProps.machinesClient||
            this.injected.user !== prevProps.user

    ) {
            this.setState({
                machineClient: this.injected.machineClient,
                id: this.injected.id,
                user: this.injected.user,
                machinesClient: this.injected.machinesClient ?? []
            })
        }
    }


    selection(appareil) {
        this.setState( {
            machineClient: appareil ?? ""
        })
    }


    enter(e) {
        if (e.key === 'Enter' ) {
            e.preventDefault()
            this.onUpdate(e, true)
        }
    }

    async onSubmit(e) {
        e.preventDefault()

        let data = {
            user_appareil_id: this.state.machineClient?.pivot?.id,
            prise_en_charge_id: this.state.id,
            accessoires: this.state.accessoires,
            casier_id: this.state.casier?.id
        }

        let authResponse= await this.injected.rootStore.prisenchargeStore.saveUserMachine(data)

        if(authResponse?.status === "success") {
            this.injected.callbackClientMachine(authResponse.datas)
        }
    }


    disabled() {
        return  !this.state.machineClient
    }

    setAccessory( element) {
      this.setState({accessoires: this.state.accessoires + element})
    }

    removeAccessory( element) {
        this.setState({accessoires: this.state.accessoires.replace(element,'')})
    }
    onCloseMarque = (childData) => {
        this.setState({
            addMarque: false,
            activeTab: "2"
        })
    };
    onCloseProduit = (childData) => {
        this.setState({
            addProduit: false,
            activeTab: "2"
        })
    };

    onCloseStockage = (childData) => {
        this.setState({
            addStockage: false,
            activeTab: "2",
            casier: childData
        })
    };

    onCloseClearStockage = () => {
        this.setState({
            addStockage: false,
            activeTab: "2"
        })
    };



    onClose = (childData) => {
        this.setState({
            addMachineClient: false,
            activeTab: "2"
        })

        if(childData) {
            this.setState({
                machinesClient: childData,
                activeTab: "2",
            })
        }
    };



    render() {
        return (
            <Card>
                <CardHeader style={{padding: "0.2em"}} className={"bg-primary"} >
                    <div  style={{display: "flex", justifyContent:"space-between", fontSize: "1.2em"}}>
                        <div  className="mr-4 ml-2">  Ajouter la prise en charge des machines appartenant au client  {this.state.user?.fullname} </div>
                       <div>
                        <Button
                            onClick={e=> this.setState({addMarque: true})}
                            variant="warning" type="button" className="mr-1">
                            Ajouter une marque <i className="fa fa-plus-circle"/>
                        </Button>

                        <Button
                            onClick={e=> this.setState({addProduit: true})}
                            variant="secondary"  type="button" className="mr-1">
                            Ajouter un produit à une marque <i className="fa fa-plus-circle"/>
                        </Button>

                        <Button
                            onClick={e=> this.setState({addMachineClient: true})}
                            variant="success"  type="button" className="mr-1">
                            Créer une machine au client <i className="fa fa-plus-circle"/>
                        </Button>
                       </div>
                    </div>
                </CardHeader>
                <div style={{ display: "flex", justifyContent: "center",     alignItems: "center"}}>

                    <Autocomplete
                        className={"mr-2 ml-2"}
                        value={this.state.machineClient}
                        options={this.state.machinesClient}
                        getOptionLabel={(option) => {
                            if(option?.id) {
                                return "Machine " + option?.marque?.nom + " " + option?.nom + " avec numéro de série:" + (option?.pivot?.serial ? option?.pivot?.serial  : "")
                            } else {
                                return ""
                            }
                        } }
                        style={{ width: "50em"}}
                        onChange={async (event, newValue) => {
                            await this.selection(newValue);
                        }}
                        selectOnFocus
                        renderInput={(params) => <TextField {...params}
                                                            margin="normal"
                                                            label={"Rechercher une machine appartenant au client " + this.state.user?.fullname}
                                                            variant="outlined" />}
                    />


                    <div className={"item"}>
                        <Input
                            placeholder={"Accessoires"}
                            onKeyPress={e=> this.enter(e)}
                            value={this.state.accessoires}
                            className="mt-4"
                            type="textarea" onChange={e => this.setState({ accessoires:  e.target.value})}>
                        </Input>

                        <div style={{display: "flex", justifyContent: "space-around"}}>
                            <Chip
                                size="small"
                                icon={<i className="fa fa-check" aria-hidden="true"></i>}
                                label="Alimentation"
                                clickable
                                color="primary"
                                onClick={e=> this.setAccessory("Alimentation")}
                                onDelete={e=> this.removeAccessory(" Alimentation")}
                            />

                            <Chip
                                size="small"
                                icon={<i className="fa fa-check" aria-hidden="true"></i>
                                }
                                label="Sac"
                                clickable
                                color="default"
                                onClick={e=> this.setAccessory(" Sac")}
                                onDelete={e=> this.removeAccessory(" Sac")}
                            />
                            <Chip
                                variant="outlined"
                                size="small"
                                icon={<i className="fa fa-check" aria-hidden="true"></i>
                                }
                                label="HDMI"
                                clickable
                                color="primary"
                                onClick={e=> this.setAccessory(" HDMI")}
                                onDelete={e=> this.removeAccessory(" HDMI")}
                            />
                            <Chip
                                variant="outlined"
                                size="small"
                                icon={<i className="fa fa-check" aria-hidden="true"></i>
                                }
                                label="Boite"
                                clickable
                                color="secondary"
                                onClick={e=> this.setAccessory(" Boite")}
                                onDelete={e=> this.removeAccessory(" Boite")}
                            />
                            <Chip
                                variant="outlined"
                                size="small"
                                icon={<i className="fa fa-check" aria-hidden="true"></i>
                                }
                                label="Chargeur"
                                clickable
                                color="default"
                                onClick={e=> this.setAccessory(" Chargeur")}
                                onDelete={e=> this.removeAccessory(" Chargeur")}
                            />
                        </div>
                    </div>

                    {this.state.casier && (
                        <div className={"mr-2"}> {this.state.casier.name} - {this.state.casier.stockage.name}</div>
                    )}
                    <Button
                        onClick={e => this.setState({ addStockage: true})}
                        variant="warning" size="sm" type="button" className="mr-1">
                        Choisir un stockage
                    </Button>

                    <Button
                        onClick={(e) => {
                            this.onSubmit(e, true);
                        }} variant="success" size="sm" type="button" className="mr-1">
                        <i className="fa fa-check"/>
                    </Button>
                </div>
                <ModalAkitatek title={"Créer une machine au client " + this.state.user?.fullname} show={this.state.addMachineClient}  children={<AddMachineUser card={false} id={this.state.user?.id} onCallback={this.onClose}></AddMachineUser>} onCallback={this.onClose} />
                <ModalAkitatek title={"Créer une machine une marque"} show={this.state.addMarque}  children={<CreateMarque card={false} onCallback={this.onCloseMarque}></CreateMarque>} onCallback={this.onCloseMarque} />
                <ModalAkitatek title={"Ajouter un produit à une marque"} show={this.state.addProduit}  children={<CreateProduct card={false} onCallback={this.onCloseProduit}></CreateProduct>} onCallback={this.onCloseProduit} />
                <ModalAkitatek title={"Choisir un stockage" } show={this.state.addStockage}  children={<Casier onSelection={false} onCallback={this.onCloseStockage}></Casier>} onCallback={this.onCloseClearStockage} />

            </Card>


        )
    }
}));

export default SearchMachineUser;
