import React from "react";
import {inject, observer} from "mobx-react";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import TableGeneric from "../../../../components/Akitatek/Headers/TableGeneric";
import ServicesRow from "./ServicesRow";
import {SRLWrapper} from "simple-react-lightbox";

const Service = inject("rootStore")(observer(class Service extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.homeStore.getServices();
        this.injected.rootStore.savedDatasStore.setHeader("Services", "Modifier les services sur la page d'accueil", "business.jpg")
    }


    render() {
        return (
            <>
                <div>
                    <Container className="mt-2" fluid>
                        <Card className="shadow">
                            <CardHeader
                                style={{display: "flex", justifyContent: "space-between"}}>
                                <h4 className="mb-0">
                                    <i className="fa fa-file-pdf-o mr-2" aria-hidden="true"/>
                                    Services
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <SRLWrapper>
                                    <TableGeneric key="card1"
                                                  titles={["Ordre", "image", "titre", "Position", "système", "status", "Actions"]}>
                                        {this.injected.rootStore.homeStore.services
                                            .map(invoice => {
                                                return <ServicesRow data={invoice} key={invoice.id}/>
                                            })
                                        }
                                    </TableGeneric>
                                </SRLWrapper>
                            </CardBody>

                        </Card>
                    </Container>
                </div>
            </>
        );
    }
}));

export default Service;
