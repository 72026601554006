import {action, decorate, observable} from "mobx";

/* Custom import */
import Api from '../util/api'
import i18n from "i18next";

export default class CarrouselStore {
    carrousels = observable([]);

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }


    async getCarrousels()  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.carrousels.getCarrousels();
            this.carrousels = authResponse;
            this.rootStore.savedDatasStore.setLoading(false)

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async createCarrousel(state) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.carrousels.createCarrousel(state);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( i18n.t('errors.success.title'),  "success");
                this.getCarrousels();
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {this.rootStore.savedDatasStore.setLoading(false)}
    }

    async updateCarrousel(state) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.carrousels.updateCarrousel(state);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( i18n.t('errors.success.title'),  "success");
                this.getCarrousels();
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {this.rootStore.savedDatasStore.setLoading(false)}
    }
}

decorate(CarrouselStore, {
    getCarrousels: action,
    carrousels: observable
});