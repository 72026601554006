import {inject, observer} from "mobx-react";
import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import styles from "./Stock.module.css";
import ModalAkitatek from "../../../components/Akitatek/Modal/Modal";
import {withRouter} from "react-router-dom";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import InputGroup from "reactstrap/lib/InputGroup";
import {InputGroupAddon} from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import ChoiceCategory from "../../../components/Akitatek/Category/ChoiceCategory";
import CATEGORY from "../../../components/Akitatek/Category/Category";
import Casier from "../Casier/Casier";
import getRoutes from "../../../components/Akitatek/getRoutes";


const AddStock = inject("rootStore")(observer(class Stock extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            nom: "",
            quantite: 0,
            prix_vente: 0,
            second_com: "",
            checkbox: 0,
            file: "",
            first_com: "",
            ref: "",
            subtitle: "",
            category_id: "",
            parents: [],
            path: "",
            showProduct: this.injected.showProduct ?? true,
            shopcategory_id: "",
            casier: ""
        }

        this.onChangeCallbackCategory = this.onChangeCallbackCategory.bind(this)

        if (this.injected.card) {
            this.injected.rootStore.savedDatasStore.setHeader("Stock", "Gestion des stocks", "motherboard.jpg")
        }
    }


    async onSubmit(e) {
        e.preventDefault()

        let data = {
            photo: this.state.file,
            nom: this.state.nom,
            quantite: 0,
            category_id: this.state.category_id ?? "",
            type: "CREATE",
            casier_id: this.state.casier?.id,
            prix_vente: this.state.prix_vente ?? 0,
            second_com: this.state.second_com,
            first_com: this.state.first_com,
            ref: this.state.ref,
            subtitle: this.state.subtitle,
        }

        const authResponse = await this.injected.rootStore.stockStore.productStore.createStock(data)

        if (authResponse?.status === "success") {
            if (this.state.showProduct) {
                this.injected.history.push(getRoutes("Modifier stock", authResponse?.data.id.toString()))
            } else {
                this.injected.onCallback(authResponse.data)
            }
        }
    }

    onChangeCallbackCategory(value) {
        this.setState({
            category: value
        })
    }

    onChangeCategory = () => {
        if (this.injected.rootStore.annuaireStore.categoryStore.selectionCategory) {
            this.setState({
                parents: this.injected.rootStore.annuaireStore.categoryStore.selectionCategory.parents,
                path: this.injected.rootStore.annuaireStore.categoryStore.selectionCategory.path,
                category_id: this.injected.rootStore.annuaireStore.categoryStore.selectionCategory.id,
            })
        }  else {
            this.setState({
                parents: "",
                path: "",
                category_id: ""
            })
        }
    }


    addStock() {
        return (<div>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleNumber">Nom du stock</Label>
                        <Input disabled={this.state.disabled}
                               type="text"
                               name="name"
                               onChange={e => this.setState({nom: e.target.value})}
                               value={this.state.nom}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleNumber">Sous titre</Label>
                        <Input disabled={this.state.disabled}
                               type="text"
                               name="name"
                               value={this.state.subtitle}
                               onChange={e => this.setState({subtitle: e.target.value})}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <ChoiceCategory
                            name={"Catégorie"}
                            path={this.state.path}
                            type={CATEGORY.NORMAL}
                            disabled={this.state.disabled}
                            onChangeCategory={this.onChangeCategory}
                            defaultExpanded={this.state.parents}/>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleRef">Référence</Label>
                        <Input disabled={this.state.disabled}
                               type="text"
                               name="name"
                               value={this.state.ref}
                               onChange={e => this.setState({ref: e.target.value})}
                        />
                    </FormGroup>
                </Col>
            </Row>


            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleCom">Commentaire 1</Label>
                        <Input disabled={this.state.disabled}
                               type="textarea"
                               name="name"
                               value={this.state.first_com}
                               onChange={e => this.setState({first_com: e.target.value})}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="exampleSecondCom">Commentaire 2</Label>
                        <Input disabled={this.state.disabled}
                               type="textarea"
                               name="name"
                               value={this.state.second_com}
                               onChange={e => this.setState({second_com: e.target.value})}
                        />
                    </FormGroup>
                </Col>
            </Row>


            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label> Prix de vente conseillé</Label>
                        <InputGroup>
                            <Input
                                disabled={this.state.disabled}
                                value={this.state.prix_vente}
                                onChange={e => this.setState({prix_vente: e.target.value})}
                                placeholder="Prix de vente conseillé" min={0} max={100} type="number" step="1"/>
                            <InputGroupAddon addonType="append">€</InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col md={6} style={{display: "flex", alignItems: "center"}}>
                    <Button
                        onClick={e => this.setState({addStockage: true})}
                        variant="warning" size="sm" type="button" className="mr-1">
                        Choisir un stockage
                    </Button>
                    {this.state.casier && (
                        <div className={"mr-2"}> {this.state.casier.name} - {this.state.casier.stockage.name}</div>
                    )}
                </Col>
            </Row>


            <Button className={"mt-2"} variant="success" onClick={e => this.onSubmit(e)}> Valider </Button></div>)
    }

    onCloseClearStockage = () => {
        this.setState({
            addStockage: false,
        })
    };

    onCloseStockage = (childData) => {
        this.setState({
            addStockage: false,
            casier: childData
        })
    };


    render() {
        return <>
            <CardBody>
                {this.state.showProduct && (
                    <Card className={styles.item}>
                        <Card.Header className={"bg-warning"}>
                            <h4 className="mb-0"> Ajouter un stock </h4>
                        </Card.Header>
                        <Card.Body>
                            {this.addStock()}
                        </Card.Body>
                    </Card>)}

                {!this.state.showProduct && (
                    this.addStock()
                )}
            </CardBody>
            <ModalAkitatek title={"Choisir un stockage"} show={this.state.addStockage}
                           children={<Casier onSelection={false} onCallback={this.onCloseStockage}></Casier>}
                           onCallback={this.onCloseClearStockage}/>

        </>;
    }

}));

export default withRouter(AddStock);
