import React from "react";
import {inject, observer} from "mobx-react";
import Button from "react-bootstrap/Button";
import {Input} from "reactstrap";
import {Link} from "react-router-dom";
import getRoutes from "../../../../components/Akitatek/getRoutes";

const PiecesRow = inject("rootStore") (observer( class PiecesRow extends React.Component {

    piece;
    id;
    lignes;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            index: this.injected.index,
            lignes: this.injected.lignes,
            id: this.injected.id,
            piece: this.injected.piece,
            disabled: this.injected.disabled,
            prix_unitaire: this.injected.piece?.pivot.prix_unitaire,
            quantity:    this.injected.piece?.pivot.quantity

        };

        this.toggleDanger = this.toggleDanger.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.piece !== prevProps.piece ||
            this.injected.id !== prevProps.id ||
            this.injected.index !== prevProps.index
        ) {
            this.setState({
                id: this.injected.id,
                piece: this.injected.piece,
                prix_unitaire: this.injected.piece?.pivot.prix_unitaire,
                quantity:    this.injected.piece?.pivot.quantity
            })
        }

        if (
            this.injected.disabled !== prevProps.disabled
        ) {
            this.setState({
                disabled: this.injected.disabled
            })
        }

        if (
            this.injected.lignes !== prevProps.lignes
        ) {
            this.setState({
                lignes: this.injected.lignes
            })
        }
    }

    toggleDanger() {
        this.injected.rootStore.notificationStore.setWarning("Pièce #" + this.state.piece?.pivot.id, event => this.onDelete( this.state.piece?.pivot.id, this.state.id))
    }

    async onDelete(deleteId, prestation_id) {
        let data= {
            prestation_id: prestation_id,
            id: deleteId
        }
        let authResponse = await this.injected.rootStore.annuaireStore.prestationStore.deletePrestaLine(data);

        if (authResponse?.status === "success") {
            this.injected.callbackRefreshLignes(authResponse.datas)
        }
    }


    async onUpdate(e) {
        e.preventDefault()

        let authResponse

        let data = {
            prix_unitaire: this.state.prix_unitaire ?? 0,
            id:  this.state.piece?.pivot.id ?? "",
            prestation_id: this.state.id,
            quantity: this.state.quantity
        }

        authResponse = await this.injected.rootStore.annuaireStore.prestationStore.updatePrestaLine(data)

        if (authResponse?.status === "success") {
            this.injected.callbackRefreshLignes(authResponse.datas)
        }
    }

    disabled() {
        return this.state.disabled
    }

    refresh() {
        let lignes = this.state.lignes
        let piece = this.state.piece;
        piece.pivot.quantity = this.state.quantity;
        piece.pivot.prix_unitaire = this.state.prix_unitaire;

        lignes[this.state.index] = piece
    }

    render() {
        return (
            <>
                <tr>
                    <th scope="row"className="text-center">
                        <span className="mb-0 text-sm">
                            <Link style={{ color: "#20a8d8"}} to={{
                                pathname: getRoutes("Modifier stock", this.state.piece?.id ),
                                aboutProps: {
                                    stock: this.state.piece
                                }}}>
                                <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
                                {this.state.piece?.nom}
                            </Link>
                        </span>
                    </th>
                    <th scope="row"className="text-center">
                        <span className="mb-0 text-sm">
                            <Link style={{ color: "#20a8d8"}} to={{
                                pathname: getRoutes("Modifier stock", this.state.piece?.id ),
                                aboutProps: {
                                    stock: this.state.piece
                                }}}>
                                <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
                                {this.state.piece?.ref}
                            </Link>
                        </span>
                    </th>
                    <th scope="row"className="text-center">
                        <Input
                            onKeyPress={e=> this.enter(e)}
                            data-toggle="tooltip" data-placement="right" title={this.state.quantity}
                            className="form-control-alternative"
                            type={"number"}
                            placeholder={"Tarif unitaire"}
                            value={ this.state.quantity }
                            disabled={this.disabled()}
                            onChange={async e=> {
                                await this.setState({ quantity: e.target.value})
                                this.refresh()
                            }}
                        />
                    </th>
                    <th scope="row"className="text-center">
                        <Input
                            onKeyPress={e=> this.enter(e)}
                            data-toggle="tooltip" data-placement="right" title={this.state.prix_unitaire}
                            className="form-control-alternative"
                            type={"number"}
                            placeholder={"Tarif unitaire"}
                            value={ this.state.prix_unitaire }
                            disabled={this.disabled()}
                            onChange={async e=> {
                                await this.setState({ prix_unitaire: e.target.value})
                                this.refresh()
                            }}
                        />
                    </th>
                    <td  style={{ display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                        <Button
                            disabled={this.disabled()}
                            onClick={(e) => {
                                this.onUpdate(e, true);
                            }} variant="success" size="sm" type="button" className="mr-1">
                            <i className="fa fa-check"/>
                        </Button>

                        <Button
                            disabled={this.disabled()}
                            onClick={(e) => {
                                this.toggleDanger()
                            }} variant="danger" size="sm" type="button" className="mr-1">
                            <i className="fa fa-minus"/>
                        </Button>
                    </td>
                </tr>
            </>
        );
    }

}));

export default PiecesRow;
