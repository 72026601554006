import React from 'react';
import {inject, observer} from "mobx-react";
import 'moment/locale/fr';
import Button from "reactstrap/lib/Button";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";


const PrintImpression = inject("rootStore") (observer( class PrintImpression extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props)


        this.state = {
            datas: this.injected.datas ?? [],
            id: this.injected.id,
            download: "all",
            printerId: "",
            printers: [],
            showOtherPrinter: false
        }
    }

    async componentDidMount() {
        await this.injected.rootStore.printerStore.getDatas()
        this.setState({
            printerId:  this.injected.rootStore.printerStore.datas[0]?.id,
        })
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.appareils !== prevProps.appareils ||      this.injected.id !== prevProps.id
        ) {
            this.setState({
                appareils : this.injected.appareils ?? [],
                idAppareil: this.injected.appareils?.length  ? this.injected.appareils[0].id : "",
                id: this.injected.id
            })
        }
    }

    render() {
        const {api, type} = this.props
        return (

            <div>
                    <Card>
                        <CardBody>
                            <h4>Imprimer les étiquettes</h4>
                            <FormGroup>
                                <Label for="exampleSelect">Choisir l'étiquettes à imprimer</Label>
                                <Input type="select" name="select" id="exampleSelect"
                                       value={this.state.download}
                                       onChange={ e => {
                                           this.setState({download: e.target.value})
                                       }}
                                >

                                    {this.state.datas.length !== 0 && (
                                        <option value={"all"}>Toutes</option>
                                    )}
                                    {   this.state.datas.map((data)=> {
                                        return (
                                            <option value={data?.id}>
                                                {type === "pec"  && (
                                                    "Machine " + data?.appareil?.marque?.nom + " " + data?.appareil?.nom + " - Casier: " + (data?.pivot?.casier?.name ? data?.pivot?.casier.name  : "")
                                                )}

                                                {type === "stock"&& (
                                                    data.pid + " - "  + data.fournisseur?.nom + "-Qte: " + data.stock
                                                )}
                                                </option>

                                        )})}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" onChange={e => this.setState({showOtherPrinter: !this.state.showOtherPrinter})}/>{' '}
                                        Choisir une autre imprimante
                                    </Label>
                                </FormGroup>

                                {this.state.showOtherPrinter && (
                                <Input type="select" name="select" id="exampleSelect"
                                       value={this.state.printerId}
                                       onChange={ e => {
                                           this.setState({printerId: e.target.value})
                                       }}
                                >
                                    {   this.injected.rootStore.printerStore.datas?.map((printer, key)=> {
                                        return (
                                            <option key={key} value={printer?.id}>{printer.name}</option>

                                        )})}
                                </Input>)}
                            </FormGroup>
                            {this.injected.rootStore.authStore.modules['print'] === 1 && (
                            <Button color="primary"
                                    onClick={e => api.downloadPdf(e, this.state.id, this.state.download,this.state.showOtherPrinter ? this.state.printerId : "")}
                                    disabled={this.state.datas.length === 0}
                            >
                                Imprimer
                            </Button>)}

                            <Button
                                className={"ml-2"}
                                disabled={this.state.datas.length === 0 || this.state.download === "all"}
                                color="success"
                                onClick={e => api.openPdf(e, this.state.download, this.state.id)}>
                                Visualiser
                            </Button>
                        </CardBody>
                    </Card>
            </div>

        );
    }
}));

export default PrintImpression

