import axios from 'axios'
import i18n from 'i18next';

import AuthStore from './authStore'
import NotificationStore from './notificationStore';
import UserStore from './userStore'
import ErrorStore from "./errorStore";
import BlogStore from "./blogStore";
import StatsStore from "./statsStore";
import InvoiceStore from "./invoice/invoiceStore";
import AnnuaireStore from "./annuaire/annuaireStore";
import DevisStore from "./devis/devisStore";
import SavedDataStore from "./savedDatasStore";
import StockStore from "./stock/stockStore";
import CommandeStore from './commandStore'
import CarrouselStore from "./carrouselStore";
import PriseEnChargeStore from "./priseencharge";
import StockageCasierStore from "./stockageCasierStore";
import TicketStore from "./ticketStore";
import Echo from "laravel-echo"
import socketio from 'socket.io-client';
import UserNotificationStore from "./userNotificationStore";
import {decorate, observable} from "mobx";
import BoutiqueStore from "./boutiqueStore";
import ImageStore from "./imageStore";
import PageStore from "./pageStore";
import HomeStore from "./homeStore";
import CommandeClientStore from "./commandeClientStore";
import PriseEnChargeInternetStore from "./priseenchargeinternet";
import PrinterStore from "./printerStore";
import SpoolerStore from "./spoolerStore";
import AnnuaireGlobalCategory from "./annuaireGlobalCategory";
import Configuration from "../configuration/configuration";

export default class RootStore {
    echo

    constructor() {
        this.authStore = new AuthStore(this);
        this.notificationStore = new NotificationStore(this);
        this.userStore = new UserStore(this);
        this.invoiceStore = new InvoiceStore(this);
        this.errorStore = new ErrorStore(this);
        this.blogStore = new BlogStore(this);
        this.statsStore = new StatsStore(this);
        this.carrouselStore = new CarrouselStore(this);
        this.annuaireGlobalStore = new AnnuaireGlobalCategory(this);
        this.annuaireStore = new AnnuaireStore(this);
        this.devisStore = new DevisStore(this);
        this.savedDatasStore = new SavedDataStore(this);
        this.stockStore = new StockStore(this);
        this.commandeStore = new CommandeStore(this);
        this.prisenchargeInternetStore = new PriseEnChargeInternetStore(this);
        this.prisenchargeStore = new PriseEnChargeStore(this);
        this.stockageCasierStore = new StockageCasierStore(this);
        this.ticketsStore = new TicketStore(this);
        this.userNotificationStore = new UserNotificationStore(this);
        this.boutiqueStore = new BoutiqueStore(this);
        this.imageStore = new ImageStore(this);
        this.pageStore = new PageStore(this);
        this.homeStore = new HomeStore(this);
        this.commandeClientStore = new CommandeClientStore(this);
        this.printerStore = new PrinterStore(this);
        this.spoolerStore = new SpoolerStore(this);

        const self = this;

        axios.interceptors.request.use((config) => {
            const token = localStorage.getItem('token') || '';
            config.headers['authorization'] = "Bearer " + token;
            return config
        }, (error) => Promise.reject(error));

        axios.interceptors.response.use((response) => response, (error) => {
            this.savedDatasStore.setLoading(false)
            if (error.response) {
                const message = error.response?.data?.message;

                if (error.response.status === 401 && error.response.data.message === "Unauthenticated.") {
                    self.authStore.logout();
                    self.notificationStore.setNotification(
                        message ?? i18n.t('errors.login-fail'),
                        'error'
                    );
                } else if (error.response.status === 0) {
                    self.notificationStore.setNotification(
                        message ?? 'Pas de connection internet.',
                        'error'
                    );
                } else if (error.response.status === 401) {
                    self.notificationStore.setNotification(
                        message ?? 'Déconnexion pour inactivité [401]',
                        'error'
                    );
                } else if (error.response.status === 419) {
                    self.notificationStore.setNotification(
                        message ?? 'Erreur Token CSRF - Merci de rafraichir la page [419]',
                        'error'
                    );
                } else if (error.response.status === 404) {
                    self.notificationStore.setNotification(
                        message ?? '[404] Page Introuvable Oo',
                        'error'
                    );
                } else if (error.response.status === 500) {
                    self.notificationStore.setNotification(
                        message ?? '[500] Erreur interne au serveur.',
                        'error'
                    );
                } else {
                    self.notificationStore.setNotification(
                        message ?? "Erreur inconnue. Contactez votre administrateur.",
                        'error'
                    )
                }
            } else {
                self.notificationStore.setNotification(
                    "Erreur inconnue. Contactez votre administrateur.",
                    'error'
                )
            }
            return Promise.reject(error)
        })
    }

    refresh(ev) {
        console.log(ev.status)

        this.check(ev, "invoice", this.invoiceStore)
        this.check(ev, "devis", this.devisStore)
        this.check(ev, "cmdClient", this.commandeClientStore)
        this.check(ev, "cmd", this.commandeStore)
        this.check(ev, "client", this.userStore)
        this.check(ev, "stock", this.stockStore.productStore)
        this.check(ev, "pec", this.prisenchargeStore)
        this.check(ev, "pecinternet", this.prisenchargeInternetStore)

        if (ev.type === "marque") {
            if (this.annuaireStore.marqueStore.marques.length > 0) {
                this.annuaireStore.marqueStore.getMarques()
            }
        }

        if (ev.type === "product") {
            if (this.annuaireStore.productStore.products.length > 0) {
                this.annuaireStore.productStore.getProducts()
            }
        }

        if (ev.type === "prestation") {
            if (this.annuaireStore.prestationStore.prestations.length > 0) {
                this.annuaireStore.prestationStore.getPrestation()
            }
        }
    }

    check(ev, typeText, api) {
        if (ev.type === typeText) {
            console.log("test")
            if (api.datas.length > 0) {
                console.log("to load")
                if (ev.status === "" || api.filter?.status.length === 0 || api.filter.status[0] === ev.status || api.filter.status[0] === "") {
                    console.log("update")

                    api.getDatas()
                }
            }
        }
    }


    getEcho() {
        if (this.echo) {
            this.echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            return this.echo;
        } else {
            this.echo = new Echo({
                host: Configuration.API,
                broadcaster: 'socket.io',
                client: socketio,
                transports: ['websocket']
            });
            this.echo.connector.options.auth.headers['Authorization'] = "Bearer " + localStorage.getItem('token')
            return this.echo
        }
    }
}

decorate(AuthStore, {
    echo: observable,
});

