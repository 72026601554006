/* Global import */
import {decorate, observable} from 'mobx';
/* Custom import */
import Api from '../../util/api'

export default class AnnuaireCategory {


    treesCategories = observable([])
    treeCategory = 1;
    treesCategoriesId = observable([])
    selectionCategory;

    constructor(rootStore, annuaireStore) {
        this.rootStore = rootStore
        this.annuaireStore = annuaireStore;
        this.api = new Api();
    }


    async getCategoriesByTree(marqueId) {
        let authResponse = await this.api.annuairecategories.getCategoriesByTree(marqueId)

        if (authResponse?.status === "success") {
            this.treesCategories = authResponse.datas
            this.treesCategoriesId = authResponse.data.flat()
        }
    }

    async createCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.annuairecategories.createCategory(myData);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Categorie " + authResponse.data.id + " sauvegardée", "success");
                this.setCategories(authResponse.datas)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.annuairecategories.updateCategory(myData);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Categorie #" + authResponse.data.id + " modifiée", "success");
                this.setCategories(authResponse.datas)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.annuairecategories.deleteCategory(myData);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Categorie" + myData.id + " supprimée", "success");
                this.setCategories(authResponse.datas)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    setCategories(annuairecategories) {
        this.treesCategories.replace(annuairecategories)
    }

    async getPdf(mediaId) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let pdf = await this.api.stockageCasier.getPdf(mediaId)
            this.rootStore.savedDatasStore.setLoading(false)
            return pdf;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async openPdf(e, id) {
        e.preventDefault();

        if (id) {
            const file = await this.getPdf(id);

            if (file) {
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
        }
    }

    async createPdf(e, id) {
        e.preventDefault();
        try {
            if (id) {
                this.rootStore.savedDatasStore.setLoading(true)

                let authResponse = await this.api.stockageCasier.createPdf(id);

                if(authResponse?.status === "success") {
                    this.rootStore.notificationStore.setNotification( "Impression en cours", "success");
                }
                this.rootStore.savedDatasStore.setLoading(false)
            }
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async createPdfStockage(e, id) {
        e.preventDefault();
        try {
            if (id) {
                this.rootStore.savedDatasStore.setLoading(true)

                let authResponse = await this.api.stockageCasier.createPdfStockage(id);

                if(authResponse?.status === "success") {
                    this.rootStore.notificationStore.setNotification( "Impression en cours", "success");
                }
                this.rootStore.savedDatasStore.setLoading(false)
            }
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


}

decorate(AnnuaireCategory, {
    treesCategories: observable,
    treesCategoriesId: observable,
    treeCategory: observable,
    selectionCategory: observable
});