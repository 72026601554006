import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import i18n from "i18next";
import styles from "../../Annuaire/Prestation/Prestation.module.css";
import {ContentState, EditorState} from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import ChoiceImageInput from "../../../../components/Akitatek/Form/ChoiceImageInput";
import EditorWord from "../../../../components/Akitatek/EditorWord";


const AddPresentation = (inject("rootStore")(observer(class AddPresentation extends React.Component {
    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);

        this.state = {
            file: "",
            img: null,
            id: null,
            subtitle: '',
            isVisible: 0,
            title: '',
            description: '',
            assetName: "",
            asset_id: "",
            card: this.injected.card ?? true,
            icon: ""
        }

        this.onBack = this.onBack.bind(this);
        this.toggleDanger = this.toggleDanger.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        if (this.state.card) {
            this.injected.rootStore.savedDatasStore.setHeader("Présentations", "Modifier la présentation de votre entreprise sur la page d'accueil", "business.jpg")
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            description:
            editorState,
        });
    };


    UNSAFE_componentWillMount() {
        if (this.injected.location?.aboutProps?.presentation) {
            this.setInitialData(this.injected.location.aboutProps?.presentation)
        }
    }

    async componentDidMount() {
        this.id = this.props.match && parseInt(this.props.match.params.id, 10);
        if (this.id) {
            let presentation = await this.injected.rootStore.homeStore.findPresById(this.id);
            if (presentation) {
                this.setInitialData(presentation)
            }
        }
    }

    setInitialData(presentation) {
        let editorState;
        if (presentation?.description != null) {
            let blocksFromHTML = htmlToDraft(presentation.description);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

            editorState = EditorState.createWithContent(contentState);
        } else {
            editorState = EditorState.createEmpty()
        }

        this.setState({
            id: presentation.id,
            subtitle: presentation?.subtitle,
            assetName: presentation?.asset?.filename,
            asset_id: presentation?.asset?.id,
            isVisible: presentation.isVisible,
            title: presentation?.title,
            description: editorState,
            icon: presentation?.icon
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        let authResponse;

        if (this.state.id) {
            authResponse = await this.injected.rootStore.homeStore.updatePres(this.state);
        } else {
            authResponse = await this.injected.rootStore.homeStore.savePres(this.state);
        }

        if (authResponse?.status === "success") {
            if (this.state.card) {
                this.setInitialData(authResponse?.data)
            } else {
                this.injected.onCallback()
            }
        }
    }


    styles;


    toggleDanger() {
        this.injected.rootStore.notificationStore.setWarning("Presentation #" + this.state.id, event => this.onDelete(this.state.id))
    }

    async onDelete(deleteId) {
        let status = await this.injected.rootStore.homeStore.deletePres(deleteId);
        if (status === "success") {
            this.injected.history.goBack()
        }
    }

    onChange(e) {
        const name = e.target.name
        const type = e.target.type
        const value = type === "radio" ? parseInt(e.target.value) : e.target.value
        this.setState({[name]: value});
    }

    setForm() {
        return (<Form style={{width: "100%"}}
                      role="form"
                      id="myFormUpdate"
                      onSubmit={this.onSubmit}
        >

            <div className="pl-lg-4">
                <Row>
                    <Col>
                        <FormGroup>
                            <label
                                className={styles.typeField}
                                htmlFor="input-first-name"
                            >
                                Titre
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="input-first-name"
                                type="text"
                                value={this.state.title || ''}
                                name="title"
                                onChange={this.onChange}
                                required
                            />
                        </FormGroup>
                    </Col>

                    {this.injected.rootStore.authStore.options['iconServiceHome'] === 0 ?
                        (
                            <ChoiceImageInput assetName={this.state.assetName}
                                              onPickAssetFilename={this.onPickAssetFilename}/>
                        ) : (
                            <Col>
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-first-name"
                                    >
                                        Icon
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-first-name"
                                        type="text"
                                        value={this.state.icon || ''}
                                        name="icon"
                                        onChange={this.onChange}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )

                    }

                </Row>

                {this.injected.rootStore.authStore.options.homeOrganisation === "soros" && (
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-first-name"
                                >
                                    Sous titre
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-first-name"
                                    type="textarea"
                                    name="subtitle"
                                    onChange={this.onChange}
                                    value={this.state.subtitle || ''}
                                />
                            </FormGroup>
                        </Col>
                    </Row>)}

                <EditorWord editorState={this.state.description} onEditorStateChange={this.onEditorStateChange}/>

                <Row>
                    <Col style={{display: "flex", justifyContent: "center"}}>
                        <FormGroup className={"mr-2"}>
                            <label>
                                <input type="radio"
                                       name="isVisible"
                                       value={1}
                                       onChange={this.onChange}
                                       checked={this.state.isVisible === 1}
                                />

                                <span className="badge badge-pill badge-success">PUBLIER</span>
                            </label>
                        </FormGroup>
                        <FormGroup>
                            <label>
                                <input type="radio"
                                       name="isVisible"
                                       onChange={this.onChange}
                                       value={0}
                                       checked={this.state.isVisible === 0}
                                />
                                <span className="badge badge-pill badge-warning">NON PUBLIER</span>
                            </label>
                        </FormGroup>
                    </Col>
                </Row>

                <div className="pl-lg-4 mt-3" style={{textAlign: "center"}}>
                    <Button color="success" className="mr-1" type="submit">
                        {i18n.t('button.submit')}
                    </Button>

                    {this.state.id &&
                    <Button color="danger" type="button" className="mr-1"
                            onClick={this.toggleDanger}>
                        Supprimer
                    </Button>
                    }
                </div>
            </div>
        </Form>)
    }


    onBack(e) {
        e.preventDefault()
        this.injected.history.goBack()
    }

    onPickAssetFilename = (action) => {
        if (action === "add") {
            this.setState({
                assetName: this.injected.rootStore.imageStore.pickAsset?.filename,
                asset_id: this.injected.rootStore.imageStore.pickAsset?.id,
            })
        } else {
            this.setState({
                assetName: "",
                asset_id: "",
            })
        }
    }


    render() {
        return (
            <>
                {this.state.card && (
                    <Container className="mt-2" fluid>
                        <Row className="mb-3">
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                        <h3 className="mb-0" style={{color: "#20a8d8"}}>  {
                                            this.state.id ? (
                                                <div>
                                                    <i onClick={this.onBack} style={{cursor: "pointer"}}
                                                       className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                                                    Présentation #{this.state.id}
                                                </div>
                                            ) : (
                                                "Présentation"
                                            )
                                        }</h3>
                                    </CardHeader>
                                    <CardBody> <Row>{this.setForm()} </Row></CardBody>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                )}


                {!this.state.card && (
                    this.setForm()
                )}
            </>
        );
    }
})));

export default AddPresentation;
