import React from "react";
import {inject, observer} from "mobx-react";
import {Card, CardBody, CardHeader} from "reactstrap";
import Form from "reactstrap/lib/Form";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import moment from "moment";

const DetailCommande = inject("rootStore") (observer( class DetailCommande extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            commande: this.injected.commande
        }
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.commande !== prevProps.commande
        ) {
            this.setState({
                commande: this.injected.commande
            })
        }
    }



    render() {
        return (
            <div className="bg-transparent mt-1">

                <Card style={{minWidth: "20em"}}>
                    <CardHeader  style={{display: "flex", justifyContent: "center", alignItems:"center", color: "#20a8d8"}}>
                        <h5> Ajouter les détails de la commande </h5>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="titreCommande">Titre de la commande</Label>
                                <Input type="text"  name="titreCommande"
                                       value={ this.state.commande?.title ||'' }
                                       onChange={  e => {
                                          let commande = this.state.commande;
                                          commande.title = e.target.value
                                          this.setState({commande: commande})
                                       }}
                                       required
                                />
                            </FormGroup>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="numCommande">Numéro de commande</Label>
                                        <Input type="text"  name="numCommande"
                                               value={ this.state.commande?.ref ||'' }
                                               onChange={  e => {
                                                   let commande = this.state.commande;
                                                   commande.ref = e.target.value
                                                   this.setState({commande: commande})
                                               }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="dateExpedition">Date de création</Label>
                                        <Input
                                            type="date"
                                            name="date"
                                            value={ moment(this.state.commande?.created_at).format("YYYY-MM-DD") ||moment().format("YYYY-MM-DD") }
                                            onChange={  e => {
                                                let commande = this.state.commande;
                                                commande.created_at = e.target.value
                                                this.setState({commande: commande})
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="notes">Notes</Label>
                                <Input type="textarea" name="notes"
                                       value={ this.state.commande?.comment ||'' }
                                       onChange={  e => {
                                           let commande = this.state.commande;
                                           commande.comment = e.target.value
                                           this.setState({commande: commande})
                                       }}/>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
            </div>

        )
    }
}));

export default DetailCommande;
