import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";

export default class HomeAPI {
    async getPresentations() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/presentations`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    savePres(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                if (datas.description) {
                    datas.description = draftToHtml(convertToRaw(datas.description.getCurrentContent()));
                }
                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/presentations`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async findPresById(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/presentations/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    updatePres(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                if (datas.description) {
                    datas.description = draftToHtml(convertToRaw(datas.description.getCurrentContent()));
                }
                const response = await axios({
                    method: 'PUT',
                    url: `${configuration.API}/api/presentations`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async deletePres(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/presentations/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }


    async getServices() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/services`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    saveService(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                if (datas.description) {
                    datas.description = draftToHtml(convertToRaw(datas.description.getCurrentContent()));
                }
                if (datas.title) {
                    datas.title = draftToHtml(convertToRaw(datas.title.getCurrentContent()));
                }
                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/services`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async findServiceById(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/services/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    movePres(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'PUT',
                    url: `${configuration.API}/api/move/presentations`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    moveService(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'PUT',
                    url: `${configuration.API}/api/move/services`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    updateService(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                if (datas.description) {
                    datas.description = draftToHtml(convertToRaw(datas.description.getCurrentContent()));
                }
                if (datas.title) {
                    datas.title = draftToHtml(convertToRaw(datas.title.getCurrentContent()));
                }

                const response = await axios({
                    method: 'PUT',
                    url: `${configuration.API}/api/services`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async deleteService(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/services/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

}