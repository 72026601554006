import React from "react";
import {inject, observer} from "mobx-react";
import UpdateCustomer from "./UpdateCustomer";

const InfosOnCustomers = inject("rootStore") (observer( class InfosOnCustomers extends React.Component {

    user

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: this.injected.user,
            invoices: [],
            activeTab: this.injected.location.aboutProps?.activeTab ?? "0",
            devis: [],
            appareils:  [],
            tickets: [],
            priseencharges: []
        }

        this.injected.rootStore.savedDatasStore.setHeader("Clients", "Gestion des clients et des documents liés", "clients.jpg", this.injected.rootStore.userStore)
    }


    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    async onRouteChanged() {
        let userId = this.props.match && parseInt(this.props.match.params.id, 10);

        if (userId) {
            const user =   await this.injected.rootStore.userStore.findUserById(userId);

            this.setState({
                user: user,
                invoices: user?.facture ?? [],
                devis: user?.devis ?? [],
                appareils: user?.appareils ?? [],
                tickets: user?.ticket ?? [],
                priseencharges: user?.prise_en_charge ?? []
            })
        }
    }

     componentDidMount() {
         this.onRouteChanged()
    }




    render() {
        return (
            <>
                <UpdateCustomer activeTab={this.state.activeTab} appareils={this.state.appareils} user={this.state.user} devis={this.state.devis} tickets={this.state.tickets} invoices={this.state.invoices} priseencharges={this.state.priseencharges}/>
            </>
        );
    }
}));

export default InfosOnCustomers;
