import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {Button, Input, TabPane} from "reactstrap";


const SecondAsidePage = inject("rootStore")(observer(class SecondAsidePage extends Component {

        get injected() {
            return this.props
        }

    async onUpdateLogo(e){
        e.preventDefault()
        await this.injected.rootStore.authStore.updateSidebar(this.state)
    }

        render() {
            return (

                <TabPane tabId="2" className="p-3">


                    <div className="aside-options">
                        <div className="clearfix mt-4">
                            <small><b>Changer le background de la sidebar</b></small>
                        </div>
                        <div>
                            <small className="text-muted">
                                <div> Fichier actuel : { this.injected.rootStore.authStore.sidebar}</div>

                                <div>
                                        <Input type="file"
                                               name="file"
                                               id="exampleFile"
                                               onChange={e => this.setState({file: e.target.files[0]})}
                                        />
                                    <Button onClick={e => this.onUpdateLogo(e)} color="success" >Modifier</Button>
                                </div>
                            </small>
                        </div>
                    </div>

                    <hr />
                </TabPane>
            )




        }
    }
));

export default SecondAsidePage;
