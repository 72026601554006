import React from "react";
import {FormGroup} from "reactstrap";
import ErrorBoundary from "./Error/ErrorBounday";
import {Editor} from "react-draft-wysiwyg";
import CustomOption from "../../views/admin/Magasin/Services/CustomOption";
import styles from "../../views/admin/Annuaire/Prestation/Prestation.module.css";
import i18n from "i18next";

function EditorWord({editorState, onEditorStateChange}) {
    return <FormGroup>
        <ErrorBoundary>
            <label
                className={styles.typeField}
            >
                {i18n.t('page.admin.prestations.description')}
            </label>
            <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapperClassName="wrapper-class"
                localization={{
                    locale: 'fr',
                }}
                stripPastedStyles={true}
                toolbarCustomButtons={[<CustomOption/>]}
                toolbar={{
                    history: {inDropdown: true},
                    inline: {inDropdown: false},
                    list: {inDropdown: true},
                    textAlign: {inDropdown: true},
                    image: {
                        urlEnabled: true,
                        uploadEnabled: false,
                        alignmentEnabled: true, // Whether to display the arrange button is equivalent to text-align
                        previewImage: true,
                        inputAccept: 'image/*',
                        defaultSize: {
                            height: '25em',
                            width: '100%',
                        },
                        alt: {present: true, mandatory: false}
                    }
                }}
            />
        </ErrorBoundary>
    </FormGroup>
}

export default EditorWord;
