/* Global import */
import {observable, decorate, action} from 'mobx';
import Api from "../../util/api";

/* Custom import */



export default class MouvStore{

    mouvements = observable([]);
    currentPage = 0;
    lastPage = 1;
    limit = 15
    search = ""

    constructor(rootStore, stockStore) {
        this.rootStore = rootStore
        this.stockStore = stockStore;
        this.api = new Api();
    }




    async getMouv()  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.mouvs.getMouv(this.limit, this.currentPage, this.search);

            if(this.mouvements.length > 0) {
                this.mouvements.replace(authResponse.data)
            } else {
                this.mouvements = authResponse.data;
            }

            this.currentPage = authResponse.currentPage;
            this.lastPage = authResponse.lastPage;
            this.rootStore.savedDatasStore.setLoading(false)

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveMouv(data)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.mouvs.saveMouv(data)

            if(authResponse?.status  === "success") {
                this.rootStore.savedDatasStore.setLoading(false)
                this.rootStore.notificationStore.setNotification("Ajout mouvement de stock", "success");
            }

            return authResponse.status

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async inventaire(data)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.mouvs.inventaire(data)

            if(authResponse?.status  === "success") {
                this.rootStore.savedDatasStore.setLoading(false)
                this.rootStore.notificationStore.setNotification("Ajout mouvement de stock", "success");
            }

            return authResponse.status

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateMouv(data)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.mouvs.updateMouv(data)


            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification("Mouvement des stocks # " + authResponse.data.id + " modifié", "success");
                this.stockStore.productStore.selectionProduct = undefined
                this.stockStore.productStore.setSelection(authResponse.product)
                this.stockStore.productStore.getDatas()
            }

         this.rootStore.savedDatasStore.setLoading(false)

            return authResponse

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

    }

    async deleteMouv(data, message)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.mouvs.deleteMouv(data)


            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Suppression du mouvement stocks # " + authResponse.data.id + " modifié", "success");
                this.stockStore.productStore.selectionProduct = undefined
                this.stockStore.productStore.setSelection(authResponse.product)
                this.stockStore.productStore.getDatas()
            }

         this.rootStore.savedDatasStore.setLoading(false)

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
}


decorate(MouvStore, {
    mouvements: observable,
    getMouv: action,
    currentPage: observable,
    lastPage: observable,
    limit: observable,
    search: observable
});