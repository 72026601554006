import React from "react";
import {inject, observer} from "mobx-react";
import i18n from "i18next";
import {Card, CardHeader, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './Prestation.module.css'
import Button from "react-bootstrap/Button";
import Select from "react-select";
import {ContentState, convertFromHTML, EditorState} from "draft-js";
import TableGeneric from "../../../../components/Akitatek/Headers/TableGeneric";
import PiecesRow from "./PiecesRow";
import AddPieces from "./AddPieces";
import {withRouter} from "react-router-dom";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import Tabs from "../../../../components/Akitatek/Tabs/Tabs";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import htmlToDraft from 'html-to-draftjs';
import ChoiceImageInput from "../../../../components/Akitatek/Form/ChoiceImageInput";
import EditorWord from "../../../../components/Akitatek/EditorWord";


const SeePrestation = inject("rootStore")(observer(class SeePrestation extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);


        this.state = {
            titleofpage: this.injected.presta?.titleofpage,
            metadescription: this.injected.presta?.metadescription,
            file: "",
            id: this.injected.presta?.id,
            editorState: this.injected.editorState,
            presta: this.injected.presta,
            pieces: this.injected.presta?.pieces,
            disabled: true,
            option_price: this.injected.presta?.option_price,
            title: this.injected.presta?.title,
            price: this.injected.presta?.price,
            img: this.injected.presta?.icon,
            assetName: this.injected.presta?.asset?.filename,
            asset_id: this.injected.presta?.asset?.id,
            isVisible: this.injected.presta?.isVisible,
            prix_unitaire: this.injected.presta?.prix_unitaire,
            global_price_mo: this.injected.presta?.global_price_mo,
            url: this.injected.presta?.url,
            product: this.injected.presta?.appareil,
            annuaire_appareil_id: this.injected.presta?.annuaire_appareil_id,
            productLabel: {value: this.injected.presta?.appareil?.id, label: this.injected.presta?.appareil?.nom}
        };
        this.change = this.change.bind(this)
        this.toggleDanger = this.toggleDanger.bind(this);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des marques", "Gérer vos marques", "annuaire_marque.jpg")
    }

    async componentDidUpdate(prevProps) {
        if (
            this.injected.presta !== prevProps.presta ||
            this.injected.editorState !== prevProps.editorState
        ) {
            await this.setDatas(this.injected.presta, this.injected.editorState)
        }
    }

    async setDatas(presta, editorState) {
        await this.setState({
            id: presta.id,
            editorState: editorState,
            presta: presta,
            title: presta?.title,
            price: presta?.price,
            option_price: presta?.option_price,
            status_id: presta?.status_id,
            status: presta.status?.title,
            img: presta?.icon,
            global_price_mo: presta?.global_price_mo,
            url: presta?.url,
            isVisible: presta?.isVisible,
            pieces: presta?.pieces,
            prix_unitaire: presta?.prix_unitaire,
            product: presta?.appareil,
            annuaire_appareil_id: presta?.annuaire_appareil_id,
            assetName: presta?.asset?.filename,
            asset_id: presta?.asset?.id,
            productLabel: {value: presta?.appareil?.id, label: presta?.appareil?.nom},
            titleofpage: presta?.titleofpage,
            metadescription: presta?.metadescription,
        })
    }


    async refresh() {
        let authResponse = await this.injected.rootStore.annuaireStore.prestationStore.findPrestationById(this.state.presta?.id);

        if (authResponse?.status === "success") {
            let presta = authResponse.datas
            let editorState;
            if (presta.description != null) {
                let blocksFromHTML = htmlToDraft(presta.description);
                const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

                editorState = EditorState.createWithContent(contentState);
            } else {
                editorState = EditorState.createEmpty()
            }

            await this.setDatas(presta, editorState)
            this.setState({disabled: true})
        }
    }

    showValidate() {
            return <div>
                {this.state.id &&
                <Button variant="danger" type="button" className="mr-1"
                        onClick={this.toggleDanger}>
                    Supprimer
                </Button>
                }

                <Button variant="primary" className={"mr-2"} onClick={this.refresh.bind(this)}> Annuler </Button>
                <Button variant="success" type="submit">
                    Valider
                </Button>
            </div>
    }


    change() {
        this.setState({disabled: !this.state.disabled})
    }

    changeTitle(e) {
        this.setState(
            {
                title: e.target.value,
            }
        )
    }


    enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            this.onSubmit(e, true)
        }
    }

    toggleDanger(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning("Prestation #" + this.state.id, event => this.onDelete(this.state.id))
    }

    async onDelete(deleteId) {
        await this.injected.rootStore.annuaireStore.prestationStore.deletePrestation(deleteId);
        this.injected.history.goBack()
    }


    async onSubmit(e, disabled = true) {
        e.preventDefault()
        let authResponse = await this.injected.rootStore.annuaireStore.prestationStore.updatePrestation(this.state);
        if (authResponse?.status === "success") {
            if (disabled) {
                this.setState({disabled: true})
            }

            let editorState = ""
            if (authResponse.datas?.description != null) {
                let blocksFromHTML = convertFromHTML(authResponse.datas?.description);
                const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

                editorState = EditorState.createWithContent(contentState);
            } else {
                editorState = EditorState.createEmpty()
            }
            await this.setDatas(authResponse.datas, editorState)
        }
    }


    onEditorStateChange = (editorState) => {
        this.setState({
            editorState:
            editorState,
        });
    };

    //Refresh commande
    callbackRefreshLignes = (childData) => {
        this.refresh()
    };

    onPickAssetFilename = (action) => {
        if (action === "add") {
            this.setState({
                assetName: this.injected.rootStore.imageStore.pickAsset?.filename,
                asset_id: this.injected.rootStore.imageStore.pickAsset?.id,
            })
        } else {
            this.setState({
                assetName: "",
                asset_id: "",
            })
        }
    }

    setForm() {
        return (
            <div>
                {this.injected.rootStore.authStore.options.metadata === 1 && (
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-first-name"
                                >
                                    Titre de la page (Si vide généré auto)
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-first-name"
                                    type="text"
                                    value={this.state.titleofpage || ''}
                                    onChange={e => this.setState({titleofpage: e.target.value})}
                                />

                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-first-name"
                                >
                                    Métadescription (si vide : généré auto)
                                </label>

                                <Input
                                    className="form-control-alternative"
                                    id="input-first-name"
                                    type="text"
                                    value={this.state.metadescription || ''}
                                    onChange={e => this.setState({metadescription: e.target.value})}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                <Row form>
                    <Col md={6}>
                        <FormGroup style={{display: "flex"}}>
                            <Label className={styles.typeField}>
                                {i18n.t('page.admin.prestations.name')}:
                            </Label>
                            <Input
                                onKeyPress={e => this.enter(e)}
                                className="form-control-alternative"
                                id="input-first-title"
                                type="text"
                                value={this.state.title || ''}
                                onChange={e => this.changeTitle(e)}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup style={{display: "flex"}}>
                            <Label className={styles.typeField}>
                                URL:
                            </Label>
                            <Input
                                onKeyPress={e => this.enter(e)}
                                className="form-control-alternative"
                                id="input-first-url"
                                type="text"
                                value={this.state.url || ''}
                                onChange={e => this.setState({url: e.target.value})}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <Label className={styles.typeField}>
                            Prix mo
                        </Label>
                        {this.state.prix_unitaire} € TTC
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className={styles.typeField}>
                                Prix global ( piece et main d'oeuvre) TTC
                            </Label>
                            <InputGroup>
                                <ButtonGroup>
                                    <Button color="primary" onClick={() => this.setState({option_price: "EGAL"})}
                                            active={this.state.option_price === "EGAL"}>Egal</Button>
                                    <Button color="primary" onClick={() => this.setState({option_price: "FROM"})}
                                            active={this.state.option_price === "FROM"}>A partir de</Button>
                                    <Button color="primary"
                                            onClick={() => this.setState({option_price: "SUR_DEVIS"})}
                                            active={this.state.option_price === "SUR_DEVIS"}>Sur devis</Button>
                                </ButtonGroup>
                                <Input
                                    onKeyPress={e => this.enter(e)}
                                    type="number"
                                    value={this.state.global_price_mo}
                                    onChange={e => this.setState({global_price_mo: e.target.value})}
                                    required
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>€</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label className={styles.typeField}>
                                Produit:
                            </Label>
                            <Select
                                value={this.state.productLabel}
                                className={"mb-3"}
                                onChange={e => this.setState({productLabel: {value: e.value, label: e.label}})}
                                options={this.injected.rootStore.annuaireStore.productStore.allProducts.map((product) => {
                                    return {value: product.id, label: product.nom}
                                })}
                            />
                        </FormGroup>
                    </Col>
                </Row>


                <EditorWord editorState={this.state.editorState} onEditorStateChange={this.onEditorStateChange}/>


                <hr className="my-4"/>

                <Row form>
                    <Col md={6} className={'m-auto'}
                         style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>

                        <ChoiceImageInput assetName={this.state.assetName}
                                          onPickAssetFilename={this.onPickAssetFilename}/>
                    </Col>
                </Row>


                <Row>
                    <Col style={{display: "flex", justifyContent: "center"}}>
                        <FormGroup className={"mr-2"}>
                            <label>
                                <input type="radio"
                                       checked={this.state.isVisible === 1}
                                       onClick={e => this.setState({isVisible: 1})}
                                />
                                <span className="badge badge-pill badge-success">PUBLIER</span>
                            </label>
                        </FormGroup>
                        <FormGroup>
                            <label>
                                <input type="radio"
                                       checked={this.state.isVisible === 0}
                                       onClick={e => this.setState({isVisible: 0})}
                                />
                                <span className="badge badge-pill badge-warning">NON PUBLIER</span>
                            </label>
                        </FormGroup>
                    </Col>
                </Row>
            </div>)
    }

    setPieces() {
        return (<Row form>
            <Col>
                <FormGroup style={{display: "flex"}}>
                    <Label className={styles.typeField}>
                        Pièces nécessaires pour la prestation:
                    </Label>

                    <div style={{flex: "1"}}>

                        <AddPieces
                            id={this.state.presta?.id}
                            lignes={this.state.presta?.pieces}
                            callbackRefreshLignes={this.callbackRefreshLignes}
                        />

                        <TableGeneric titles={[
                            "Produit",
                            "Référence",
                            "Quantité",
                            "Prix unitaire",
                            "Actions"
                        ]}>

                            {this.state.pieces
                                .map((piece, id) => {
                                    return <PiecesRow
                                        callbackRefreshLignes={this.callbackRefreshLignes}
                                        piece={piece}
                                        key={id}
                                        lignes={this.state.presta?.pieces}
                                        index={id}
                                        id={this.state.presta?.id}
                                    />

                                })
                            }
                        </TableGeneric>
                    </div>
                </FormGroup>
            </Col>
        </Row>)
    }

    onBack(e) {
        e.preventDefault()
        this.injected.history.goBack()
    }

    onSendBackTab = (nb) => {
        this.setState({
            activeTab: nb
        })
    }

    render() {
        return (
            <>
                <Card>
                    <Form style={{width: "100%"}}
                          role="form"
                          onSubmit={e => this.onSubmit(e)}>
                        <CardHeader
                            className={[this.injected.rootStore.savedDatasStore.setColor(this.state.status), styles.header]}>
                            <h4 className="mb-0" style={{color: "#20a8d8"}}>  {
                                <div>
                                    <i onClick={e => this.onBack(e)} style={{cursor: "pointer"}}
                                       className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                                    Prestation # {this.state.presta?.id}
                                </div>
                            }</h4>
                            <div>
                                {this.showValidate()}
                            </div>
                        </CardHeader>
                        <Tabs
                            onCallback={this.onSendBackTab}
                            activeTab={this.state.activeTab} children={[this.setForm(), this.setPieces()]}
                            titles={["General", "Pieces"]}/>

                    </Form>
                </Card>
            </>
        );
    }
}));

export default withRouter(SeePrestation);
