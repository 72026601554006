import ChoiceSearch from "../../Search/ChoiceSearch";
import React from "react";

function WidgetSearch({ icon, col  = "col-6", category = "user"}) {
    return (<div key={category} className={col}>
        <div className="card-block p-1 clearfix">
            <i className={icon}></i>
            <div className="text-primary text-uppercase font-xs">Rechercher</div>
            <div><ChoiceSearch category={category} onMenu={true}/></div>
        </div>
    </div>)
}

export default WidgetSearch