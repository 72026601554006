/* Global import */
import {action, decorate, observable} from 'mobx';

/* Custom import */
import Api from '../../util/api'

export default class MarqueStore {
    marques = observable([]) ;
    marqueId;
    allMarques = [];
    search = ""

    constructor(rootStore, annuaireStore) {
        this.rootStore = rootStore
        this.annuaireStore = annuaireStore;
        this.api = new Api();
    }

    async getAllMarques() {
        this.allMarques = await this.api.marques.allMarques()
    }

    async getMarques()  {

        try {
            this.rootStore.savedDatasStore.setLoading(true)
            const authResponse = await this.api.marques.getMarques(this.search);

            if(this.marques.length > 0) {
                this.marques.replace(authResponse.datas)
            } else {
                this.marques = authResponse.datas;
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;
        } catch(e) {}
    }



    async createMarque(data) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.marques.createMarqueWithFile(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(  "Marque " + authResponse.datas?.nom + " ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }



    async updateMarque(data) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)

            let authResponse = await this.api.marques.updateMarqueWithFile(data);
            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(  "Marque " + authResponse.datas?.nom + " modifiée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async findMarqueById(id) {
        this.rootStore.savedDatasStore.setLoading(true)
        let myMarque;
        try {
            myMarque = await this.api.marques.findMarqueById(id);
        } catch(e) {}
        this.rootStore.savedDatasStore.setLoading(false)
        return myMarque;
    }



    async deleteMarque(id) {
         this.rootStore.savedDatasStore.setLoading(true)
        try {
            let status = await this.api.marques.deleteMarques(id);


            if(status === "success") {
                this.rootStore.notificationStore.setNotification("Marque # " + id + " supprimé",  "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return status;

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
}

decorate(MarqueStore, {
    marques: observable,
    getMarques: action,
    lastPage: observable,
    currentPage: observable,
    marqueId: observable,
    allMarques: observable,
    limit: observable
});