import React from "react";
import {inject, observer} from "mobx-react";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router-dom";
import moment from "moment";


const ActionPrinters = inject("rootStore")(observer(class ActionPrinters extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        moment.locale('fr');

        this.state = {
            datas: this.injected.datas
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.datas !== prevProps.datas
        ) {
            this.setState({
                datas: this.injected.datas
            })
        }
    }

    makeDefault(e, type) {
        e.preventDefault()
        let datas = {
            id: this.state.datas.id,
            type:type
        }
        this.injected.rootStore.printerStore.makeDefault(datas, this.state.datas.name + " par défaut pour " + type)
    }

    render() {
        return (
            <>
                <td class="text-center" style={{maxWidth: "10em"}}>
                    <Button className="mr-2"
                            variant="danger"
                            type="button"
                            onClick={e=> this.makeDefault(e, "étiquettes")}
                    >
                        <i className="fa fa-star" aria-hidden="true"/> Devenir Imprimante Etiquettes par défaut
                    </Button>

                    <Button className="mr-2"
                            variant="warning"
                            type="button"
                            onClick={e=> this.makeDefault(e, "A4")}
                    >
                        <i className="fa fa-star" aria-hidden="true"/>
                        Devenir Imprimante A4 par défaut
                    </Button>
                </td>
            </>
        );
    }

}));

export default withRouter(ActionPrinters);
