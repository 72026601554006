import MarqueStore from "./marqueStore";
import PrestationStore from "./prestationStore";
import ProductStore from "./productStore";
import CategoryStore from "./categoryStore";
import AnnuaireCategory from "./annuaireCategory";


export default class AnnuaireStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.marqueStore = new MarqueStore(this.rootStore, this);
        this.prestationStore = new PrestationStore(this.rootStore, this);
        this.productStore = new ProductStore(this.rootStore, this);
        this.categoryStore = new CategoryStore(this.rootStore, this);
        this.annuaireCategoryStore = new AnnuaireCategory(this.rootStore, this);

    }
}
