import React from "react";
import {inject, observer} from "mobx-react";
import i18n from "i18next";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import 'react-phone-input-2/lib/style.css'
import CardImg from "reactstrap/lib/CardImg";
import CardText from "reactstrap/lib/CardText";
import configuration from "../../../configuration/configuration";
import styles from "../Annuaire/Prestation/Prestation.module.css";
import PlaceAutoComplete from "../../../components/Akitatek/PlaceAutoCompletes";


const Settings = inject("rootStore")(observer(class Settings extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            name: this.injected.rootStore.authStore.nameCompany,
            complement_adresse: this.injected.rootStore.authStore.complement_adresse,
            adresse: this.injected.rootStore.authStore.adresse,
            postcode: this.injected.rootStore.authStore.postcode,
            tel: this.injected.rootStore.authStore.telCompany,
            email: this.injected.rootStore.authStore.emailCompany,
            type: this.injected.rootStore.authStore.typeCompany,
            city: this.injected.rootStore.authStore.city,
            logo: this.injected.rootStore.authStore.logo,
            file: "",
            horaires: this.injected.rootStore.authStore.horaires,
        };

        this.injected.rootStore.savedDatasStore.setHeader("Paramètres Entreprise", "", "business.jpg")
        this.setValue = this.setValue.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(data) {
        this.setState({
            name: data.name,
            complement_adresse: data.complement_adresse,
            adresse: data.adresse,
            postcode: data.postcode,
            tel: data.tel,
            email: data.email,
            type: data.type,
            city: data.city,
            logo: data.logo,
            horaires: data.horaires
        });
    }

    async onSubmit(e) {
        e.preventDefault()
        let authResponse = await this.injected.rootStore.authStore.updateDetails(this.state)

        if (authResponse?.status === "success") {
            this.onChange(authResponse.data)
        }
    }

    async onUpdateLogo(e) {
        e.preventDefault()
        let authResponse = await this.injected.rootStore.authStore.updateLogo(this.state)

        if (authResponse?.status === "success") {
            this.setState({
                logo: authResponse.data
            })
        }
    }

    setValue(e) {
        const type = e.target.type
        if (type !== "radio") {
            e.preventDefault()
        }

        const name = e.target.name
        const value = e.target.value

        this.setState({[name]: value});
    }

    handleChange = (address, city, postcode) => {
        this.setState({
            adresse: address,
            city: city ?? this.state.city, postcode: postcode ?? this.state.postcode
        });
    };


    addEntreprise() {
        return (
            <Row>
                <Form style={{width: "100%"}}
                      role="form"
                      id="myForm"
                      onSubmit={this.onSubmit}
                >
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-last-name"
                                    >
                                        Nom de l'entreprise
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        type="text"
                                        name="name"
                                        value={this.state.name || ''}
                                        onChange={this.setValue}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-last-name"
                                    >
                                        Email de l'entreprise
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        type="email"
                                        name="email"
                                        value={this.state.email || ''}
                                        onChange={this.setValue}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="pl-lg-4">
                        <Row>
                            <Col md="6">
                                <PlaceAutoComplete adresse={this.state.adresse} handleChange={this.handleChange}/>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-address2"
                                    >
                                        {i18n.t('page.admin.customers.address2')}
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        type="text"
                                        onChange={this.setValue}
                                        name="complement_adresse"
                                        value={this.state.complement_adresse || ''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-country"
                                    >
                                        {i18n.t('page.admin.customers.postal')}
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        type="number"
                                        onChange={this.setValue}
                                        name="postcode"
                                        value={this.state.postcode || ''}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-country"
                                    >
                                        Ville
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        type="text"
                                        onChange={this.setValue}
                                        name="city"
                                        value={this.state.city || ''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-phone"
                                    >
                                        {i18n.t('page.admin.customers.phone')}
                                    </label>

                                    <Input
                                        type={"tel"}
                                        autocomplete="chrome-off"
                                        value={this.state.tel}
                                        onChange={this.setValue}
                                        name="tel"
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-first-name"
                                    >
                                        Phrase sur les horaires
                                    </label>
                                    <Input
                                        type="textarea"
                                        onChange={this.setValue}
                                        name="horaires"
                                        value={this.state.horaires || ''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col style={{display: "flex", justifyContent: "center"}}>
                            <label
                                className={styles.typeField}
                                htmlFor="input-phone"
                            >
                                Type d'entreprise :
                            </label>
                            <FormGroup className={"mr-2 ml-2"}>
                                <label>
                                    <input type="radio"
                                           onChange={this.setValue}
                                           name="type"
                                           value="micro"
                                           checked={this.state.type === "micro"}
                                    />
                                    <span className="badge badge-pill">Micro-entreprise</span>
                                </label>
                            </FormGroup>
                            <FormGroup>
                                <label>
                                    <input type="radio"
                                           onChange={this.setValue}
                                           name="type"
                                           value="autres"
                                           checked={this.state.type === "autres"}
                                    />
                                    <span className="badge badge-pill"> Autres</span>
                                </label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="pl-lg-4" style={{textAlign: "center"}}>
                        <Row>
                            <Col>
                                <Button color="success" type="submit">
                                    {i18n.t('button.submit')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Row>)
    }

    render() {
        return (
            <>
                <div>
                    <Container className="mt-2" fluid>
                        <Row>
                            <div>
                                <div className="mb-2">
                                    <Card style={{width: "20em", margin: "auto", textAlign: "center"}}>
                                        <CardImg top src={configuration.API + "/magasin/svg/" + this.state.logo}
                                                 alt="Logo" style={{"margin": "auto"}}/>
                                    </Card>
                                </div>

                                <div className="mb-2">
                                    <Card style={{width: "20em", margin: "auto", textAlign: "center"}}>
                                        <CardBody>
                                            <CardText>
                                                <Input type="file"
                                                       name="file"
                                                       id="exampleFile"
                                                       onChange={e => this.setState({file: e.target.files[0]})}
                                                />
                                            </CardText>
                                            <Button onClick={e => this.onUpdateLogo(e)} color="success">Changer le
                                                logo</Button>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                        <h4 className="mb-0">Paramètres Entreprise</h4>
                                    </CardHeader>
                                    <CardBody>
                                        {this.addEntreprise()}
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}));

export default Settings;
