import {inject, observer} from "mobx-react";
import React from "react";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Button from "reactstrap/lib/Button";


const AddFournisseurs = (inject("rootStore") (observer( class AddFournisseurs extends React.Component {



    constructor(props, context) {
        super(props, context);

        this.state = {
            nom: "",
            first_name: "",
            last_name: "",
            commentaire: "",
            email: "",
            phone: "",
            address: "",
            city:"",
            state: "",
            postal_code: "",
            account_number: "",
            siteweb: ""
        }
    }


    get injected() {
        return this.props;
    }

    async onSubmit(event) {
        event.preventDefault()

        let authResponse  = await this.injected.rootStore.commandeStore.saveFournisseur(this.state)

        if(authResponse?.status  === "success") {
            this.setState({
                fournisseur: authResponse.datas
            })

            this.injected.modeCallback(authResponse.datas)
        }
    }

    render() {
        return(
            <>
                    <Form>
                        <FormGroup>
                            <Label for="nom">Nom de l'entreprise</Label>
                            <Input type="text" name="nom"
                                   value={ this.state.nom ||'' }
                                   onChange={  e => this.setState({
                                       nom: e.target.value
                                   })}
                                   required={true}
                            />
                        </FormGroup>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="lastName">Prénom</Label>
                                    <Input type="text"  name="lastName"
                                           value={ this.state.first_name ||'' }
                                           onChange={  e => this.setState({
                                               first_name: e.target.value
                                           })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="firstName">Nom</Label>
                                    <Input type="text"  name="firstName"
                                           value={ this.state.last_name ||'' }
                                           onChange={  e => this.setState({
                                               last_name: e.target.value
                                           })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                        <Col md={6}>
                                <FormGroup>
                                    <Label for="phone">Numéro de téléphone</Label>
                                    <Input type="text"  name="phone"
                                           value={ this.state.phone ||'' }
                                           onChange={  e => this.setState({
                                               phone: e.target.value
                                           })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="account">Numéro d'account</Label>
                                    <Input type="text"  name="account"
                                           value={ this.state.account_number ||'' }
                                           onChange={  e => this.setState({
                                               account_number: e.target.value
                                           })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                            <FormGroup>
                                <Label for="site">Site internet</Label>
                                <Input type="email" name="site"
                                       value={ this.state.siteweb ||'' }
                                       onChange={  e => this.setState({
                                           siteweb: e.target.value
                                       })}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address">Adresse</Label>
                                <Input type="text" name="address"
                                       value={ this.state.address ||'' }
                                       onChange={  e => this.setState({
                                           address: e.target.value
                                       })}
                                />
                            </FormGroup>

                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="city">Ville</Label>
                                        <Input type="text" name="city"
                                               value={ this.state.city ||'' }
                                               onChange={  e => this.setState({
                                                   city: e.target.value
                                               })}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="state">Pays</Label>
                                        <Input type="text" name="state"
                                               value={ this.state.state ||'' }
                                               onChange={  e => this.setState({
                                                   state: e.target.value
                                               })}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label for="zip">Code postal</Label>
                                        <Input type="text" name="zip"
                                               value={ this.state.zip ||'' }
                                               onChange={  e => this.setState({
                                                   zip: e.target.value
                                               })}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button color="primary" onClick={e=> this.onSubmit(e)}>Valider</Button>
                     </Form>
            </>
        )
    }
})));

export default AddFournisseurs;
