
import React from "react";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import i18n from "i18next";
import {inject, observer} from "mobx-react";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import Pagination from "../../../components/Akitatek/Pagination";
import Button from "reactstrap/lib/Button";
import TicketsRow from "./TicketsRow";


const Tickets = (inject("rootStore") (observer( class Tickets extends React.Component {

    componentDidMount() {
        this.injected.rootStore.ticketsStore.getTickets();
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des tickets", "Interface Technicien / Clients pour gestion des tickets", "default.jpg")
    }

    get injected() {
        return this.props;
    }

    parentCallback = (childData) => {
        this.injected.rootStore.ticketsStore.currentPage = childData
        this.injected.rootStore.ticketsStore.getTickets();
    };

    onChange(event, status, statusname) {
        event.preventDefault()
        this.injected.rootStore.ticketsStore.currentPage = 1;
        this.injected.rootStore.ticketsStore.statusname = statusname;
        this.injected.rootStore.ticketsStore.status = status;
        this.injected.rootStore.ticketsStore.getTickets();
    }




    render() {
        return (
            <>
                <Container  className="mt-2"  fluid>
                    <Card className="shadow">
                        <CardHeader
                            className={this.injected.rootStore.savedDatasStore.setColor(this.injected.rootStore.ticketsStore.status)}
                            style={{display: "flex", justifyContent: "space-between"}}>
                            <h4 className="mb-0"> { this.injected.rootStore.ticketsStore.statusname } </h4>
                            <div style={{display: "flex"}}>
                                <Button className="mr-2" color="info" onClick={e => this.onChange(e, "", "Tous")} >{i18n.t('page.admin.invoices.all')}</Button>
                                <Button className="mr-2" color="warning" onClick={e => this.onChange(e, "OPEN", "Tickets ouverts")}>Ouvert</Button>
                                <Button className="mr-2" color="danger" onClick={e => this.onChange(e, "TAKE_IN", "Tickets pris en charge")}>Pris en charge</Button>
                                <Button className="mr-2" color="success" onClick={e => this.onChange(e, "OVER", "Tickets clots")}>Clots</Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <TableGeneric key="card1"  titles={["id", "titre", i18n.t('page.admin.invoices.client'), 'status',  "technicien", i18n.t('page.admin.invoices.created'), "Modifiée le", "Documents", "Actions"]}>
                                {  this.injected.rootStore.ticketsStore.tickets
                                    .map(ticket => {
                                        return <TicketsRow ticket={ticket} key={ticket.id} />
                                    })
                                }
                            </TableGeneric>
                            <Pagination
                                currentPage={this.injected.rootStore.ticketsStore.currentPage}
                                lastPage={this.injected.rootStore.ticketsStore.lastPage}
                                parentCallback={this.parentCallback}/>
                        </CardBody>

                    </Card>
                </Container>
            </>
        );
    }
})));

export default Tickets;
