import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import moment from "moment";


const DefaultFooter = inject("rootStore")(observer(class DefaultFooter extends Component {

    get injected() {
        return this.props
    }

  render() {
    return (
      <React.Fragment>
        <span>
            Dashboard de <a target="_blank"  href={"//" + this.injected.rootStore.authStore.website}>{this.injected.rootStore.authStore.nameCompany} </a>
             By <a target="_blank"  href="https://akitatek.fr"> © AkitaTek.Fr</a>  2021 </span>
      </React.Fragment>
    );
  }
}))


export default DefaultFooter;
