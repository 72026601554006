
import React from "react";
import {Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import i18n from "i18next";
import Pagination from "../../../../components/Akitatek/Pagination";
import TableGeneric from "../../../../components/Akitatek/Headers/TableGeneric";
import PrestationRow from "./PrestationRow";
import GenericStaticCard from "../../../../components/Akitatek/Card/GenericStaticCard";


const Prestation = (inject("rootStore") (observer( class Prestation extends React.Component {

    parentCallbackPage = (childData) => {
        this.injected.rootStore.annuaireStore.prestationStore.currentPage = childData
        this.injected.rootStore.annuaireStore.prestationStore.getPrestation('');
    };

     componentDidMount() {
          this.injected.rootStore.annuaireStore.prestationStore.getPrestation('');
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            marque: "",
            img: ""
        };
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des marques", "Gérer vos marques", "annuaire_marque.jpg")
    }

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericStaticCard search={false} category={"prestations"} collapse={true} element={<h4 className="mb-0">{i18n.t('page.admin.prestations.title')}</h4>}>
                        <TableGeneric titles={[
                            "id",
                            i18n.t('page.admin.prestations.name'),
                            i18n.t('page.admin.prestations.price'),
                            "URL",
                            'Produit',
                            "Status",
                            "Actions"
                        ] }>

                            {  this.injected.rootStore.annuaireStore.prestationStore.prestations
                                .map((prestation, id) => {
                                    return <PrestationRow prestation={prestation} key={"presta" + id} />

                                })
                            }
                        </TableGeneric>
                        <Pagination    currentPage={this.injected.rootStore.annuaireStore.prestationStore.currentPage}
                                       lastPage={this.injected.rootStore.annuaireStore.prestationStore.lastPage}
                                    parentCallback={this.parentCallbackPage}/>
                    </GenericStaticCard>
                </Container>
            </>
        );
    }
})));

export default Prestation;
