import React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "reactstrap";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import {Link} from "react-router-dom";
import getRoutes from "../getRoutes";

const AutoCompleteLine = inject("rootStore") (observer( class AutoCompleteLine extends React.Component {


    lignes
    type
    id
    choiceType
    prix_unitaire;
    reference_produit;
    suggestion;
    isPiece;
    position;

    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);

        this.state = {
            isProv: this.injected.isProv,
            position:this.injected.position,
            disabled: this.injected.disabled,
            choiceType: this.injected.choiceType,
            id: this.injected.id,
            type: this.injected.type ?? "invoice",
            lignes: this.injected.lignes,
            search: this.injected.search,
            isOn: false,
            lot: this.injected.lot,
            isPiece: this.injected.isPiece,
            modeSearch: true,
            designation: this.injected.designation,
            quantity: this.injected.quantity,
            suggestion:this.injected.suggestion,
            total_unit_ht: this.injected.total_unit_ht ??  0,
            prix_unitaire: this.injected.prix_unitaire ?? 0,
            tva: !this.injected.rootStore.authStore.isMicro() || this.injected.type === "commande" ? 20 : 0,
            remise: 0,
            reference_produit: this.injected.reference_produit ?? "ref"
        }

    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.lot !== prevProps.lot ||
            this.injected.lignes !== prevProps.lignes ||
            this.injected.id !== prevProps.id ||
            this.injected.type !== prevProps.type ||
            this.injected.reference_produit !== prevProps.reference_produit||
            this.injected.prix_unitaire !== prevProps.prix_unitaire||
            this.injected.choiceType !== prevProps.choiceType||
            this.injected.search !== prevProps.search ||
            this.injected.suggestion !== prevProps.suggestion ||
            this.injected.isPiece !== prevProps.isPiece ||
            this.injected.position !== prevProps.position||
            this.injected.quantity !== prevProps.quantity ||
            this.injected.designation !== prevProps.designation

        ) {

            this.setState({
                position:this.injected.position,
                choiceType: this.injected.choiceType,
                lignes: this.injected.lignes,
                id: this.injected.id,
                lot: this.injected.lot,
                quantity: this.injected.quantity,
                suggestion:this.injected.suggestion,
                type: this.injected.type ?? "invoice",
                prix_unitaire: this.injected.prix_unitaire ?? 0,
                reference_produit: this.injected.reference_produit ?? " ",
                search: this.injected.search,
                isPiece: this.injected.isPiece,
                designation: this.injected.designation,
                tva: !this.injected.rootStore.authStore.isMicro() || this.injected.type === "commande" ? 20 : 0
            })
        }
    }

    componentDidMount() {
        document.getElementById("refInput").focus();
    }


    async onSubmit(event) {
        event.preventDefault()

        let authResponse

        let data = {
            remise: this.state.remise ?? 0,
            tva: this.state.tva ?? 0,
            position: this.state.lignes?.length,
            quantite: this.state.quantity,
            designation: this.state.designation,
            tarif_unitaire: this.state.prix_unitaire ?? 0,
            type: this.state.choiceType,
            reference_produit: this.state.reference_produit
        }


        if(this.state.choiceType === "product") {
            data.produit_id = this.state.suggestion?.id ?? ""
            data.lot_id = this.state.lot?.id
        }

        if(this.state.choiceType === "presta") {
            data.prestation_id = this.state.suggestion?.id ?? ""
        }

        if(this.state.type === "invoice") {
            if(this.state.isProv) {
                data.facture_prov_id = this.state.id ?? ""
            } else {
                data.facture_id = this.state.id ?? ""
            }
            authResponse = await this.injected.rootStore.invoiceStore.saveFactureLigne(data)
        } else if(this.state.type === "devis") {
            data.devis_id = this.state.id ?? ""
            authResponse = await this.injected.rootStore.devisStore.saveDevisLigne(data)
        } else if(this.state.type === "commande") {
            data.commande_id = this.state.id ?? ""
            data.nom = this.state.designation ?? " "
            data.prix_unitaire = this.state.prix_unitaire
            data.ref = this.state.reference_produit
            data.quantity = this.state.quantity
            data.index = this.state.lignes?.length

            authResponse = await this.injected.rootStore.commandeStore.saveCommandeProducts(data)
        }



        if(authResponse?.status  === "success") {
            let lignes = this.state.lignes
            lignes.splice(lignes.length + 1, 0, authResponse.datas);
            this.injected.callbackRefreshLignes(lignes)

            if(this.state.choiceType === "ligne") {
                this.setState({
                    designation: "",
                    prix_unitaire: 0,
                    remise: 0,
                    quantity: 1,
                    reference_produit: "mo",

                })

                document.getElementById("refInput").focus();
            }
        }

    }


    async enterDown(e) {
        if (e.key === 'Tab') {
            e.preventDefault()
            await this.onSubmit(e)
        }
    }

    async enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            await this.onSubmit(e)
        }
    }

    disabledMicro(){
        return this.state.type !== "commande" && this.injected.rootStore.authStore.isMicro()
    }

    disabled() {
        return (this.state.choiceType === "product"  && !this.state.disabled && !this.state.lot  && this.state.type !== "commande" || this.state.choiceType === "presta"  && !this.state.suggestion && this.state.type !== "commande" || this.state.choiceType === "product"  && this.state.type === "commande" && !this.state.suggestion)
    }

    color(){
        if (parseInt(this.state.quantity) === this.state.lot?.stock) {
            return "bg-warning"
        } else if(parseInt(this.state.quantity) > this.state.lot?.stock ||  parseInt(this.state.quantity) <= 0) {
            return "bg-danger"
        } else {
            return ""
        }
    }


    render() {
        return (  <tr>
                <td className="th-actions td-start">

                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                {this.state.choiceType === "presta" && (
                                    this.state.suggestion?.id ? (
                                    <Link style={{ color: "#4dbd74"}} to={{
                                        pathname: "/prestations/edit/" + this.state.suggestion?.id,
                                        aboutProps: {
                                            presta: this.state.suggestion
                                        }}}>
                                        <i className="fa fa-book mr-2" aria-hidden="true"/>
                                    </Link>) :
                                        (  <i  className="fa fa-book mr-2" aria-hidden="true"/>)
                                )}

                                {this.state.choiceType === "product" && (
                                    this.state.suggestion?.id ? (
                                    <Link style={{ color: "#20a8d8"}} to={{
                                        pathname: this.state.suggestion?.id === undefined ? "!#" :
                                            getRoutes("Modifier stock", this.state.suggestion?.id),
                                        aboutProps: {
                                            stock: this.state.suggestion
                                        }}}>
                                        <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
                                    </Link>) : (
                                        <i  className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
                                    )
                                )}

                                {( this.state.choiceType === "ligne"  ) && (
                                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                )}
                            </InputGroupText>
                        </InputGroupAddon>

                        <Input
                            onKeyPress={e => this.enter(e)}
                            disabled={this.state.choiceType !== "ligne" }
                            value={this.state.reference_produit}
                            placeholder={"Reference"}
                            type="text"  onChange={e => this.setState({ reference_produit:  e.target.value})}/>
                    </InputGroup>

                </td>

                <td className="td-start">
                        <Input
                            id={"refInput"}
                            onKeyPress={e => this.enter(e)}
                            disabled={ this.state.choiceType !== "ligne"}
                            value={this.state.designation}
                            placeholder={"Designation"}
                            type="text"
                            onChange={( e=> this.setState(
                                {designation: e.target.value}))}
                        />
                </td>

                <td className="th-number td-start">
                    <Input
                        placeholder={"Quantité"}
                        onKeyPress={e=> this.enter(e)}
                        value={this.state.quantity}
                        max={this.state.lot?.stock}
                        className={this.color() + " mr2"}
                        type="number" onChange={e => this.setState({ quantity:  e.target.value})}>
                    </Input>
                </td>


                <td className="th-price td-start">
                    <Input
                        placeholder={"Tarif U TTC"}
                        onKeyPress={e=> this.enter(e)}
                        value={this.state.prix_unitaire}
                        type="number" onChange={e => this.setState({  prix_unitaire: e.target.value, total_unit_ht: parseFloat(e.target.value / 1.2).toFixed(2)})}>
                    </Input>
                </td>

                <td className="th-price td-start">
                    <Input
                        placeholder={"Tarif U HT"}
                        onKeyPress={e=> this.enter(e)}
                        value={this.state.total_unit_ht}
                        type="number" onChange={e => this.setState({ total_unit_ht: e.target.value, prix_unitaire: parseFloat(e.target.value * 1.2).toFixed(2)})}>
                    </Input>
                </td>

                <td className="th-price td-start">
                    <Input
                        className={"mr-2"}
                        placeholder={"remise"}
                        onKeyPress={e=> this.enter(e)}
                        value={this.state.remise}
                        onKeyDown={e =>this.disabledMicro() ? this.enterDown(e) : ""}
                        type="number" onChange={e => this.setState({ remise:  e.target.value})}>
                    </Input>
                </td>

                <td className="th-price td-start">
                    <Input
                        className={"mr-2"}
                        placeholder={"tva"}
                        disabled={this.disabledMicro()}
                        onKeyDown={e =>this.enterDown(e)}
                        onKeyPress={e=> this.enter(e)}
                        value={this.state.tva}
                        type="number" onChange={e => this.setState({ tva:  e.target.value})}>
                    </Input>
                </td>

                <td className="td-start">
                        <Button
                        disabled={this.disabled()}
                        color={"success"}
                        onClick={e=> this.onSubmit(e)}>
                        <i className={"fa fa-plus"} />
                    </Button>
                </td>
            </tr>
        )
    }
}));

export default AutoCompleteLine;
