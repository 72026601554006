import axios from "axios";
import configuration from "../configuration/configuration";

export default class SearchAPI {

    async getSearch(search) {
        let url = `/api/search?search=${search}`;

        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}` + url,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve( response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

}