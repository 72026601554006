/* Global import */
import { decorate, observable} from 'mobx';

/* Custom import */
import Api from '../../util/api'

export default class PrestationStore {

    currentPage = 0;
    lastPage = 1;
    prestations = observable([]) ;
    allPrestations = observable([])
    optionsPrestation = observable([])
    limit = 15
    search = ""
    appareilId = ""
    appareil = ""

    constructor(rootStore, annuaireStore) {
        this.rootStore = rootStore
        this.annuaireStore = annuaireStore;
        this.api = new Api();
    }

    async getPrestation(search, limit)  {
       this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.prestations.getPrestation(limit ? limit : this.limit, this.currentPage, search ? search : this.search, this.appareilId );


            if(this.prestations.length > 0) {
                this.prestations.replace(authResponse.data)
            } else {
                this.prestations = authResponse.data;
            }

            this.currentPage = authResponse.currentPage;
            this.lastPage = authResponse.lastPage;

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse.data;
        } catch(e) {this.rootStore.savedDatasStore.setLoading(false)}
    }

    async getOptionsPrestations()  {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.prestations.getOptionPrestation();
            this.optionsPrestation = authResponse.datas;

            this.rootStore.savedDatasStore.setLoading(false)
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async getAllPrestations()  {
         this.rootStore.savedDatasStore.setLoading(true)
        try {
            this.allPrestations = await this.api.prestations.getAllPrestation();
            this.rootStore.savedDatasStore.setLoading(false)
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async deletePrestation(id) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.prestations.deletePrestation(id);


            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Prestation # " + id + " supprimée", "success");
                this.getPrestation()
            }

            this.rootStore.savedDatasStore.setLoading(false)
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }




    async createPrestation(data) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.prestations.createPrestation(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Prestation # " + authResponse?.data?.id + " sauvegardé",  "success");
                this.setPrestations(authResponse.datas)
            }

             this.rootStore.savedDatasStore.setLoading(false)
            return authResponse
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updatePrestation(data) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.prestations.updatePrestation(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Prestation # " + authResponse?.datas?.id + " modifiée",  "success");
                this.getPrestation()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
    async findPrestationById(id) {
         this.rootStore.savedDatasStore.setLoading(true)
        let authResponse;
        try {
            authResponse = await this.api.prestations.findPrestationById(id);
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
        return authResponse;
    }


    setPrestations(datas) {
        this.prestations.replace(datas)
    }

    async savePrestaLine(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.prestations.savePrestaLine(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Produit ajouté", "success");
                this.getPrestation()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updatePrestaLine(data, notHide = true) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.prestations.updatePrestaLine(data);

            if(authResponse?.status === "success") {
                if(notHide) {
                    this.rootStore.notificationStore.setNotification( "Produit modifié", "success");
                }
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
    async deletePrestaLine(data) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.prestations.deletePrestaLine(data);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( "Produit supprimé", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(PrestationStore, {
    lastPage: observable,
    currentPage: observable,
    search: observable,
    products: observable,
    prestations: observable,
    allPrestations: observable,
    optionsPrestation: observable,
    limit: observable,
    appareilId: observable,
    appareil: observable,
});