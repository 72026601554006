import React from "react";
import {inject, observer} from "mobx-react";
import i18n from "i18next";
import {CardHeader} from "reactstrap";
import Button from "reactstrap/lib/Button";
import {withRouter} from "react-router-dom";

const ChoiceSearch = inject("rootStore")(observer(class Search extends React.Component {

    category;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            value: '',
            api: this.injected.api,
            category: this.injected.category,
            onMenu: this.injected.onMenu ?? false,
            url: this.injected.url
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.enter = this.enter.bind(this);
        this.onClear = this.onClear.bind(this);
        this.search = this.search.bind(this);
    }

    async onClear(event) {
        event.preventDefault()
        await this.setState({value: ""});
        this.handleSearch(event)
    }

    async search(event) {
        event.preventDefault();
        this.setState({value: event.target.value});
    }

    async enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            await this.handleSearch(e)
        }
    }

    async handleSearch(event) {

        event.preventDefault()


        let value = this.state.value ?? ""

        if (this.state.api) {
            this.state.api.filter = Object.assign({}, this.state.api.defaultFilter)
            this.state.api.filter.search = value;
            if (value !== "") {
                this.state.api.filter.status = [];
            }

            this.state.api.getDatas()
        } else {
             if (this.state.category === "marques") {
                if (value !== "") {
                    this.injected.rootStore.annuaireStore.productStore.search = value;
                    this.injected.rootStore.annuaireStore.productStore.getProducts()

                    if (this.injected.rootStore.annuaireStore.productStore.marqueId === "") {
                        this.injected.rootStore.annuaireStore.marqueStore.search = value;
                        this.injected.rootStore.annuaireStore.marqueStore.getMarques()
                    }
                } else {
                    this.injected.rootStore.annuaireStore.productStore.marqueId = "";
                    this.injected.rootStore.annuaireStore.marqueStore.search = ""
                    this.injected.rootStore.annuaireStore.productStore.search = "";
                    this.injected.rootStore.annuaireStore.marqueStore.getMarques();
                    this.injected.rootStore.annuaireStore.productStore.marque = ""
                    this.injected.rootStore.annuaireStore.prestationStore.appareil = '';
                    this.injected.rootStore.annuaireStore.prestationStore.appareilId = '';
                    this.injected.rootStore.annuaireStore.prestationStore.prestations = [];
                    this.injected.rootStore.annuaireStore.productStore.products = [];
                }
            } else if (this.state.category === "images") {
                this.injected.rootStore.imageStore.search = value;
                this.injected.rootStore.imageStore.getImages()
            }
        }

        if (this.injected.location.pathname !== this.state.url) {
            this.injected.history.push(this.state.url)
        }

    }

    render() {
        return (
            <div>
                {!this.state.onMenu && (
                    <CardHeader className="bg-transparent mt-1" style={{display: "flex", width: "100%"}}>
                        <form className="m-auto" style={{display: "flex"}}>
                            <input value={this.state.value} className="form-control form-control-alternative"
                                   placeholder={i18n.t('page.admin.searchBar')} onKeyPress={this.enter} type="text"
                                   onChange={this.search}/>
                            <Button className="mr-2 ml-2"
                                    color="success"
                                    onClick={this.handleSearch}>Valider</Button>
                            <Button className="mr-2 ml-2" color="primary" onClick={this.onClear}>Clear</Button>
                        </form>
                    </CardHeader>)}
                {this.state.onMenu && (
                    <form className="m-auto" style={{display: "flex"}}>
                        <input value={this.state.value} className="form-control form-control-alternative"
                               placeholder={i18n.t('page.admin.searchBar')} onKeyPress={this.enter} type="text"
                               onChange={this.search}/>
                        <Button className="mr-2 ml-2"
                                color="success"
                                onClick={this.handleSearch}>Valider</Button>
                        <Button className="mr-2 ml-2" color="primary" onClick={this.onClear}>Effacer</Button>
                    </form>
                )}
            </div>
        )
    }
}));

export default withRouter(ChoiceSearch);
