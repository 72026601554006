import SearchPresta from "../admin/PriseEnCharge/SearchPresta";
import {Button, Card, CardBody, CardTitle} from "reactstrap";
import CardImg from "react-bootstrap/CardImg";
import configuration from "../../configuration/configuration";
import {Link} from "react-router-dom";
import React from "react";

function PrestationPec({priseencharge, callback, rootStore}) {

    async function removePresta(e, id) {
        e.preventDefault()

        let data = {
            presta_id: id,
            prise_en_charge_id: priseencharge.id,
        }

        let authResponse = await rootStore.prisenchargeStore.deletePresta(data)

        if (authResponse?.status === "success") {
            callback(authResponse.datas)
        }
    }

    return (

        <div>
            <SearchPresta id={priseencharge.id} callbackPresta={callback}/>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {priseencharge?.prestations?.map((data, id) => {
                    return <Card className={"mr-4"} key={id} style={{flex: "0 0 10%"}}>
                        <Button onClick={e => removePresta(e, data.id)} className={"bg-warning"}> X </Button>

                        <CardImg variant="top" style={{width: '10rem', height: '10rem', objectFit: "contain"}}
                                 src={configuration.Photo + "prestations/" + data.icon}/>
                        <CardBody>
                            <CardTitle>
                                <Link to={{
                                    pathname: "/prestations/edit/" + data.id,
                                    aboutProps: {
                                        presta: data
                                    }
                                }}>
                                    {data.title} - {data.global_price_mo} € {data.isVisible ? (
                                    <i className="fa fa-eye " aria-hidden="true"/>) : (
                                    <i className="fa fa-eye-slash " aria-hidden="true"/>)}
                                </Link>
                            </CardTitle>
                        </CardBody>
                    </Card>

                })}
            </div>
        </div>

    );
}

export default PrestationPec