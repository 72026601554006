import React from "react";
import {inject, observer} from "mobx-react";
import i18n from "i18next";
import {Button, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import GenericStaticCard from "../../../components/Akitatek/Card/GenericStaticCard";
import Tabs from "../../../components/Akitatek/Tabs/Tabs";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import AddMachineUser from "./AddMachineUser";
import MachineUserRow from "./MachineUserRow";
import 'react-phone-input-2/lib/style.css'
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {withRouter} from "react-router-dom";
import styles from "../Annuaire/Prestation/Prestation.module.css";
import getRoutes from "../../../components/Akitatek/getRoutes";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";
import Dropdown from "react-bootstrap/Dropdown";
import CustomerAdresse from "./AdresseCustomer";
import PhoneRow from "../../../components/Akitatek/Table/PhoneRow";

const UpdateCustomer = inject("rootStore")(observer(class UpdateCustomer extends React.Component {


    user
    invoices
    devis
    priseencharges

    get injected() {
        return this.props;
    }


    async componentDidUpdate(prevProps) {
        if (this.injected.user !== prevProps.user) {
            await this.setUser(this.injected.user);
        }

        if (this.injected.appareils !== prevProps.appareils) {
            this.setState({
                appareils: this.injected.appareils
            })
        }


        if (this.injected.activeTab !== prevProps.activeTab) {
            this.setState({
                activeTab: this.injected.activeTab
            })
        }

        if (this.injected.tickets !== prevProps.tickets) {
            this.setState({
                tickets: this.injected.tickets
            })
        }

        if (this.injected.invoices !== prevProps.invoices) {
            this.setState({
                invoices: this.injected.invoices
            })
        }

        if (this.injected.priseencharges !== prevProps.priseencharges) {
            this.setState({
                priseencharges: this.injected.priseencharges
            })
        }

        if (this.injected.devis !== prevProps.devis) {
            this.setState({
                devis: this.injected.devis
            })
        }
    }

    constructor(props, context) {
        super(props, context);
        if (this.injected.user?.facturation) {
            this.injected.user.profile = this.injected.user.facturation;
        }
        this.state = {
            user: this.injected.user,
            appareils: this.injected.appareils ?? [],
            activeTab: this.injected.activeTab ?? "0",
            password: '',
            typeAccount: this.injected.rootStore.authStore.modules['espace_client'] ? 'clientaccount' : 'clientnoaccount',
            fullname: "",
        };


        this.onUpdateCustomer.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.createDevis = this.createDevis.bind(this);
        this.createPec = this.createPec.bind(this);
    }

    setUser(user) {
        if (user.facturation) {
            user.profile = user.facturation;
        }
        if (user) {
            this.setState({
                user: user,
                adresse: user?.facturation ? user?.facturation : user?.profile,
                userId: user.id,
                name: user?.nom,
                firstname: user?.prenom,
                email: user.email,
                comment: user.comment,
                siret: user.siret,
                website: user.website,
                typeAccount: user.typeAccount,
                entreprise: user.entreprise,
                fullname: user.fullname,
                displayPassword: user.typeAccount === "clientaccount" || user.typeAccount === "companyaccount",
                displayPrenom: user.typeAccount === "clientaccount" || user.typeAccount === "clientnoaccount",
            });
        }
    }


    async onUpdateCustomer(e) {
        e.preventDefault();

        if (this.state.userId) {
            let authResponse = await this.injected.rootStore.userStore.updateUser(this.state);

            if (authResponse?.status === "success") {
                this.setUser(authResponse.datas)
            }
        }
    }

    //Refresh commande
    callbackRefreshLignes = (childData) => {
        this.setState({appareils: childData})
    };


    setForm() {
        return (
            <Form style={{width: "100%"}}
                  role="form"
                  id="myFormUpdate"
                  onSubmit={e => this.onUpdateCustomer(e)}
            >
                <div className="pl-lg-4">
                    <Row>
                        <Col style={{textAlign: "center"}}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Type de compte</FormLabel>
                                <RadioGroup row aria-label="position" defaultValue={this.state.typeAccount}
                                            value={this.state.typeAccount}>
                                    <div>
                                        <FormControlLabel id={"clientaccount"} value="clientaccount"
                                                          onClick={e => this.setState({
                                                              displayPassword: true,
                                                              displayPrenom: true,
                                                              typeAccount: "clientaccount"
                                                          })} control={<Radio/>} label="Client"/>
                                        <FormControlLabel id={"companyaccount"} value="companyaccount"
                                                          onClick={e => this.setState({
                                                              displayPassword: true,
                                                              displayPrenom: false,
                                                              typeAccount: "companyaccount",
                                                              firstname: ""
                                                          })} control={<Radio/>} label="Entreprise"/>
                                    </div>
                                    <FormControlLabel id={"clientnoaccount"} value="clientnoaccount"
                                                      onClick={e => this.setState({
                                                          displayPassword: false,
                                                          displayPrenom: true,
                                                          typeAccount: "clientnoaccount",
                                                          email: ""
                                                      })} control={<Radio/>} label="Client sans Compte"/>
                                    <FormControlLabel id={"companynoaccount"} value="companynoaccount"
                                                      onClick={e => this.setState({
                                                          displayPassword: false,
                                                          displayPrenom: false,
                                                          typeAccount: "companynoaccount",
                                                          email: "",
                                                          firstname: ""
                                                      })} control={<Radio/>} label="Entreprise sans Compte"/>
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row>
                    {!this.state.displayPrenom && (
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-first-name"
                                    >
                                        Nom de l'entreprise
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-first-name"
                                        type="text"
                                        value={this.state.entreprise || ''}
                                        onChange={e => this.setState({entreprise: e.target.value})}
                                        required
                                    />
                                </FormGroup></Col>
                        </Row>
                    )}
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-last-name"
                                >
                                    Nom du compte
                                </label>
                                <Input
                                    autocomplete="chrome-off"
                                    className="form-control-alternative"
                                    id="input-last-name"
                                    type="text"
                                    value={this.state.name || ''}
                                    onChange={e => this.setState({name: e.target.value})}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-first-name"
                                >
                                    Prenom du compte
                                </label>
                                <Input
                                    autocomplete="chrome-off"
                                    className="form-control-alternative"
                                    id="input-first-name"
                                    type="text"
                                    value={this.state.firstname || ''}
                                    onChange={e => this.setState({firstname: e.target.value})}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    {this.state.displayPassword && (
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-email"
                                    >
                                        Email
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-email"
                                        type="email"
                                        value={this.state.email || ''}
                                        onChange={e => this.setState({email: e.target.value})}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-password"
                                    >
                                        {i18n.t('page.auth.password')}

                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-password"
                                        type="text"
                                        value={this.state.password || ''}
                                        onChange={e => this.setState({password: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>)}

                    {!this.state.displayPrenom && (
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-first-name"
                                    >
                                        Siret
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-first-name"
                                        type="text"
                                        value={this.state.siret || ''}
                                        onChange={e => this.setState({siret: e.target.value})}
                                    />
                                </FormGroup></Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-first-name"
                                    >
                                        Website
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-first-name"
                                        type="text"
                                        value={this.state.website || ''}
                                        onChange={e => this.setState({website: e.target.value})}
                                    />
                                </FormGroup></Col>
                        </Row>
                    )}

                    <Row><Col>
                        <FormGroup>
                            <label
                                className={styles.typeField}
                                htmlFor="input-first-name"
                            >
                                Comment
                            </label>
                            <Input
                                autocomplete="chrome-off"
                                className="form-control-alternative"
                                id="input-first-name"
                                type="textarea"
                                value={this.state.comment || ''}
                                onChange={e => this.setState({comment: e.target.value})}
                            />
                        </FormGroup>
                    </Col>
                    </Row>
                </div>
                <div className="pl-lg-4" style={{textAlign: "center"}}>
                    <Row>
                        <Col>
                            <Button color="success" type="submit">
                                {i18n.t('button.update')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        )
    }


    setDevis() {
        return (
            <GenericTableAffichage api={this.injected.rootStore.devisStore}
                                   filters={
                                       {
                                           search: "",
                                           page: "1",
                                           user_id: this.state.userId,
                                           name: "updated_at",
                                           order: false,
                                           status: ""
                                       }
                                   }
                                   type="devis"/>)
    }


    setInvoices() {
        return (
            <GenericTableAffichage
                filters={
                    {
                        search: "",
                        page: "1",
                        user_id: this.state.userId,
                        name: "updated_at",
                        order: false,
                        status: ""
                    }
                }
                api={this.injected.rootStore.invoiceStore}
                type="invoice"/>)
    }

    setPriseEnCharge() {
        return (<GenericTableAffichage api={this.injected.rootStore.prisenchargeStore}
                                       filters={
                                           {
                                               page: "1",
                                               search: "",
                                               user_id: this.state.userId,
                                               name: "updated_at",
                                               order: false,
                                               status: ""
                                           }
                                       }
                                       type="pec"/>
        )
    }

    setCommandeClient() {
        return (<GenericTableAffichage api={this.injected.rootStore.commandeClientStore}
                                       filters={
                                           {
                                               page: "1",
                                               search: "",
                                               user_id: this.state.userId,
                                               name: "updated_at",
                                               order: false,
                                               status: ""
                                           }
                                       }
                                       type="cmdClient"/>
        )
    }



    setMachine() {
        return (
            <div>
                <AddMachineUser id={this.state.userId} callbackRefreshLignes={this.callbackRefreshLignes}/>
                <TableGeneric titles={[
                    "Marque",
                    "Produit",
                    "Numéro de série",
                    "Code",
                    "Defauts",
                    "Actions"
                ]}>

                    {this.state.appareils?.map((appareil, id) => {
                        return (<MachineUserRow
                            callbackRefreshLignes={this.callbackRefreshLignes}
                            appareil={appareil}
                            key={id}
                            lignes={this.state.appareils}
                            index={id}
                            id={this.state.user?.id}/>)
                    })}
                    {(this.state.appareils?.length === 0) && (
                        <tr>
                            <td className={"no_td"} colSpan={[
                                "Marque",
                                "Produit",
                                "Numéro de série",
                                "Code",
                                "Defauts",
                                "Actions"
                            ].length}>Pas d'appareils enregistrés pour {this.state.user?.fullname}</td>
                        </tr>
                    )}
                </TableGeneric>
            </div>)

    }

    createDevis(e) {
        e.preventDefault()
        this.injected.history.replace({
            pathname: "/devis/edit/add",
            aboutProps: {
                isCommandeValid: false,
                user: this.state.user,
            },
        });
    }

    createInvoice(e) {
        e.preventDefault()
        this.injected.history.replace({
            pathname: getRoutes("Gérer une facture", "add"),
            aboutProps: {
                isCommandeValid: false,
                user: this.state.user,
            },
        });
    }

    onUserSelection(e) {
        e.preventDefault()
        this.injected.rootStore.savedDatasStore.setUser(this.state.user, 1)
    }

    createPec(e) {
        e.preventDefault()
        this.injected.history.replace({
            pathname: "/priseencharge/add",
            aboutProps: {
                user: this.state.user,
            },
        });
    }

    onSendBackTab = (nb) => {
        this.setState({
            activeTab: nb
        })
    }

    render() {
        return (
            <>
                <Container className="mt-2 mb-2" fluid>
                    <GenericStaticCard element={
                        <div className="mb-0" style={{display: "flex", justifyContent: "space-between", width: "100%"}}>

                            <h3>#{this.state.userId} {this.state.fullname + " "}
                            <PhoneRow title={this.state.adresse?.telephone_portable} />
                            </h3>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Dropdown className={"mr-2"}>
                                    <Dropdown.Toggle variant="info">
                                        <i className="fa fa-plus-circle" aria-hidden="true"/>
                                        Nouveau
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={this.createPec}> Pec </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={this.createInvoice}>Facture</Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={this.createDevis}>Devis</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button color={"primary"} className={"mr-2"} onClick={e => this.onUserSelection(e)}>
                                    <i className="fa fa-hand-rock-o" aria-hidden="true"/> Sélectionner
                                </Button>
                                <Button color={"danger"} className={"mr-2"} disabled={true}>
                                    <i className="fa fa-times" aria-hidden="true"/>
                                    Clotûrer</Button>
                                {/*<Button onClick={e => this.onUpdateCustomer(e)} color="success" type="submit">*/}
                                {/*    {i18n.t('button.update')}*/}
                                {/*</Button>*/}
                            </div>

                        </div>
                    }>
                        <Tabs
                            onCallback={this.onSendBackTab}
                            activeTab={this.state.activeTab}
                            children={[this.setForm(),
                                <CustomerAdresse adresse={this.state.adresse}
                                                 userId={this.state.userId}
                                                 displayPrenom={this.state.displayPrenom}
                                                 typeAccount={this.state.typeAccount}/>, this.setMachine(), this.setPriseEnCharge(), this.setDevis(), this.setInvoices(), this.setCommandeClient()]}
                            titles={["Infos", "Adresses", "Machines", "Prises en charge", "Devis", "Factures", "Commandes"]}/>
                    </GenericStaticCard>
                </Container>


            </>
        );
    }
}));

export default withRouter(UpdateCustomer);
