import React from 'react';
import {inject, observer} from "mobx-react";
import CreateMarque from "./CreateMarque";

const Marques = (inject("rootStore") (observer( class Marques extends React.Component {
    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);
        let id = this.props.match && parseInt(this.props.match.params.id, 10)

        this.state = {
            id: id
        }

        this.injected.rootStore.savedDatasStore.setHeader("Gestion des marques", "Gérer vos marques", "annuaire_marque.jpg")
    }

    render() {
        return (
            <>
                <CreateMarque id={this.state.id}/>
            </>


    );
    }
})));

export default Marques;
