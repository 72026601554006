import React from "react";
import {Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import configuration from "../../../configuration/configuration";
import GenericStaticCard from "../../../components/Akitatek/Card/GenericStaticCard";
import getRoutes from "../../../components/Akitatek/getRoutes";
import AnnuaireCard from "./AnnuaireCard";


const Annuaire = (inject("rootStore")(observer(class Annuaire extends React.Component {

    parentCallbackPage = (childData) => {
        this.injected.rootStore.annuaireStore.marqueStore.currentPage = childData
        this.injected.rootStore.annuaireStore.marqueStore.getMarques();
    };

    async UNSAFE_componentWillMount() {
        this.injected.rootStore.annuaireStore.marqueStore.getMarques();
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            marque: "",
            img: "",
            title: ""
        };

        this.injected.rootStore.savedDatasStore.setHeader("Gestion des marques", "Gérer vos marques", "annuaire_marque.jpg")

    }

    get injected() {
        return this.props;
    }


    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericStaticCard element={
                        <h4 className="mb-0">
                            <i className="fa fa-star mr-2" aria-hidden="true"/>
                            {this.injected.rootStore.annuaireStore.productStore.search !== "" ? (
                                "Recherche " + this.injected.rootStore.annuaireStore.productStore.search
                            ) : (
                                "Marques"
                            )

                            }
                        </h4>
                    }>
                        <div style={{display: "flex", flexWrap: "wrap"}}>
                            {this.injected.rootStore.annuaireStore.marqueStore.marques
                                .map((data, id) => {
                                    return (
                                        <AnnuaireCard
                                            key={"card" + id}
                                            data={data}
                                            rootStore={this.injected.rootStore}
                                            name={"product"}
                                            url={data.asset ? configuration.PhotoBase + data.asset?.filename : configuration.Photo + "marque/" + data.photo}
                                            route={getRoutes("Modifier une marque", data.id)}
                                        />
                                    )
                                })
                            }
                        </div>
                    </GenericStaticCard>


                    {this.injected.rootStore.annuaireStore.productStore.products.length > 0 ? (
                        <GenericStaticCard element={<h4
                            className="mb-0">Produits {this.injected.rootStore.annuaireStore.productStore.marque}</h4>}>
                            <div id="productCard" style={{display: "flex", flexWrap: "wrap"}}>
                                {this.injected.rootStore.annuaireStore.productStore.products
                                    .map((data, id) => {
                                        return (
                                            <AnnuaireCard
                                                key={"card" + id}
                                                data={data}
                                                name={"presta"}
                                                rootStore={this.injected.rootStore}
                                                url={data.asset ? configuration.PhotoBase + data.asset?.filename : configuration.Photo + "product/" + data.photo}
                                                route={getRoutes("Afficher un Produit", data.id)}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </GenericStaticCard>) : (
                        <div id="productCard" style={{display: "flex", flexWrap: "wrap"}}>
                        </div>
                    )}


                    {this.injected.rootStore.annuaireStore.prestationStore.prestations.length > 0 ? (
                        <GenericStaticCard element={<h4
                            className="mb-0">Prestations {this.injected.rootStore.annuaireStore.prestationStore.appareil}</h4>}>
                            <div id="prestationCard" style={{display: "flex", flexWrap: "wrap"}}>
                                {this.injected.rootStore.annuaireStore.prestationStore.prestations
                                    .map((data, id) => {
                                        return (
                                            <AnnuaireCard
                                                key={"card" + id}
                                                data={data}
                                                name={""}
                                                rootStore={this.injected.rootStore}
                                                url={data.asset ? configuration.PhotoBase + data.asset?.filename : configuration.Photo + "prestations/" + data.icon}
                                                route={getRoutes("Modifier une prestation", data.id)}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </GenericStaticCard>) : (<div id="prestationCard" style={{display: "flex", flexWrap: "wrap"}}>
                    </div>)}
                </Container>
            </>
        );
    }
})));

export default Annuaire;
