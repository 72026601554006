import React from "react";
import {inject, observer} from "mobx-react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Row
} from "reactstrap";
import i18n from "i18next";
import HideComponenteHeader from "../../../components/Akitatek/Headers/HideComponenteHeader";
import Table from "react-bootstrap/Table";
import {Dropdown} from "react-bootstrap";
import getRoutes from "../../../components/Akitatek/getRoutes";
import ActionsPDF from "./ActionsPDF";


const GiveMeMoney = inject("rootStore") (observer( class GiveMeMoney extends React.Component {

    invoiceStore = this.injected.rootStore.invoiceStore.createInvoiceStore;
    errorStore = this.injected.rootStore.errorStore;
    invoiceId;


    get injected() {
        return this.props;
    }

    callbackDetail = (childData) => {
        this.setState({displayDetail: childData})
    };
    callbackPaiement = (childData) => {
        this.setState({displayPaiement: "block"})
    };

    callbackInformation = (childData) => {
        this.setState({displayInformation: childData})
    };

    async componentDidMount() {

        this.invoiceId = this.props.match && parseInt(this.props.match.params.id, 10);

        if (this.invoiceId) {
            let invoice = await this.invoiceStore.getMoney(this.invoiceId);

            this.setState({
                invoice: invoice.facture,
                status: invoice.facture.statusEnum,
                invoiceId: this.invoiceId,
                title: invoice.facture.intitule,
                deplacement: invoice.facture.deplacement,
                total: invoice.facture.total_facture,
                restant: invoice.info.restant,
                encaisser: invoice.info.encaisser,
                reglement: invoice.facture.facture_reglement
            });
        }
    }

    constructor(props, context) {
        super(props, context);

        this.invoiceId = this.props.match && parseInt(this.props.match.params.id, 10);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des factures", "Créer une facture / gérer les encaissements", "facture.jpg", this.injected.rootStore.invoiceStore)

        this.state = {
            invoice: {},
            status: "",
            invoiceId: "",
            displayDetail: "block",
            displayInformation: "block",
            title: "",
            deplacement: "",
            restant: "",
            encaisser: 0,
            total: " ",
            displayPaiement: "block",
            reglement: [],
            valeur: "",
            type: ""
        };

        this.onSubmit = this.onSubmit.bind(this)
    }

    addDetails() {
        let myReglementTab = [];
            this.state.reglement.forEach(element => {
                myReglementTab.push   ( <tr>
                    <td>{element.type}</td>
                    <td>{element.montant} € </td>
                    <td>{element.created_at}</td>
                </tr>);
            });

            return myReglementTab;
    }

    setMoney(e, type) {
        e.preventDefault();
        this.setState({
            valeur: this.state.restant,
            type: type
        })
    }

    async onSubmit(e) {
        e.preventDefault();
        await this.injected.rootStore.notificationStore.setEncaissement(this.state.valeur, this.state.type, event => this.giveMeMoney(this.invoiceId, this.state.type, this.state.valeur))

    }

    async giveMeMoney(invoiceId, type, valeur) {
         await this.invoiceStore.giveMoney(invoiceId, type, valeur);
        let invoice = await this.invoiceStore.getMoney(this.invoiceId);

        this.setState({
            status: invoice.facture.statusEnum,
            title: invoice.facture.title,
            deplacement: invoice.facture.deplacement,
            total: invoice.facture.total_facture,
            restant: invoice.info.restant,
            encaisser: invoice.info.encaisser,
            reglement: invoice.facture.facture_reglement
        });
    }

    createButtonReglement(type, label) {
        return (
              <Col>
                <Button color="success" type="submit"
                        disabled={this.state.restant === "0.00" || this.state.restant === 0}
                        onClick={ e => this.setMoney(e, type)} >
                    {label}
                </Button>
           </Col>
        )
    }

       createButtonPdf(title, color, onclickFunc) {
        return (
                <Button className="ml-2" color={color}
                        onClick={onclickFunc}>{title}</Button>
            )
    }



    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Row className="mb-3">
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className={this.injected.rootStore.savedDatasStore.setColor(this.state.status)} style={{display: "flex", justifyContent: "space-between", color: "white"}}>
                                    <a href={"/#" + getRoutes("Gérer une facture", this.invoiceId)} style={{display: "flex", alignItems: "center", color: "white"}}>
                                        <i style={{ cursor: "pointer"}}  className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                                        {this.state.status === "TO_PAID" && (
                                            <h4 className="mb-0">{i18n.t('page.admin.paid.title') + " Validée n°"  + this.invoiceId}</h4>
                                        )}

                                        {this.state.status === "PAID" && (
                                            <h4 className="mb-0"> {"Facture #"   + this.invoiceId + " Soldée"}</h4>
                                        )}
                                    </a>
                                    <ActionsPDF data={this.state.invoice} api={this.injected.rootStore.invoiceStore} filename={"facture" + this.state.invoice?.id + "_" + "nom" + "_" + this.state.invoice?.created_at + ".pdf"}/>
                                </CardHeader>
                                <Form style={{ width : "100%"}}
                                      role="form"
                                      id="myForm"
                                      onSubmit={ e => this.onSubmit(e) }
                                >
                                <CardBody>
                                    <Col >
                                        <div className="pl-lg-4"  >
                                                <Row>
                                                    <Input

                                                        style={{textAlign: "center"}}
                                                        className={"mb-3"}
                                                        placeholder="Titre de la facture"
                                                        id="input-title"
                                                        value={this.state.title || " "}
                                                        type="text"
                                                        disabled
                                                    />


                                                </Row>
                                            </div>

                                            <HideComponenteHeader parentCallback ={this.callbackInformation}
                                                                  default={this.state.displayInformation}
                                            >
                                                <h6 className="heading-small text-muted mb-0 mt-0">
                                                    {i18n.t('page.admin.paid.informations')}
                                                </h6>
                                            </HideComponenteHeader>
                                            <div className="pl-lg-4"  style={{display: this.state.displayInformation}}>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-first-name"
                                                            >
                                                                {i18n.t('page.admin.paid.deplacement')}
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-first-name"
                                                                type="number"
                                                                value={this.state.deplacement || " "}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-last-name"
                                                            >
                                                                {i18n.t('page.admin.paid.paid')}
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-last-name"
                                                                type="number"

                                                               value={this.state.encaisser || 0}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-first-name"
                                                            >
                                                               Total
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-first-name"
                                                                type="number"
                                                                value={this.state.total || " "}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                            </div>

                                        <HideComponenteHeader parentCallback ={this.callbackDetail}
                                                              default={this.state.displayDetail}
                                        >
                                            <h6 className="heading-small text-muted mb-0 mt-0">
                                                {i18n.t('page.admin.paid.detail')}
                                            </h6>
                                        </HideComponenteHeader>
                                        <div className="pl-lg-4"  style={{display: this.state.displayDetail}}>
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th>{ i18n.t('page.admin.paid.reglement')}</th>
                                                    <th>{ i18n.t('page.admin.paid.value')}</th>
                                                    <th>{ i18n.t('page.admin.paid.option')}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                { this.addDetails()}
                                                </tbody>
                                            </Table>
                                        </div>

                                        <HideComponenteHeader parentCallback ={this.callbackPaiement}
                                                                  default={this.state.displayPaiement}
                                            >
                                                <h6 className="heading-small text-muted mb-0 mt-0">
                                                    {i18n.t('page.admin.paid.paid')}
                                                </h6>
                                            </HideComponenteHeader>

                                            <div className="pl-lg-4"  style={{display: this.state.displayPaiement}}>


                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <Input
                                                                value={this.state.type}
                                                                disabled={this.state.restant === "0.00" || this.state.restant === 0}
                                                                onChange={ e => this.setState({type: e.target.value})}
                                                                type="select" name="select" id="select">
                                                                <option value="">--Sélectionner le type de paiement --</option>
                                                                <option value={"espece"}>Espèce</option>
                                                                <option value={"paypal"}>PayPal</option>
                                                                <option value={"carte"}>Cb</option>
                                                                <option value={"virement"}>Virement</option>
                                                                <option value={"cheque"}>Chèque</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row>
                                                                {this.createButtonReglement("carte",
                                                                    i18n.t('page.admin.paid.totalCb')
                                                                )}
                                                              {this.createButtonReglement("espece",
                                                                    i18n.t('page.admin.paid.totalE')
                                                                )}
                                                            {this.createButtonReglement("paypal",
                                                                "PayPal"
                                                            )}
                                                                 {this.createButtonReglement("virement",
                                                                    i18n.t('page.admin.paid.totalV')
                                                                )}
                                                             {this.createButtonReglement("",
                                                                       "Restant"
                                                              )}
                                                        </Row>

                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col>
                                                        <FormGroup>
                                                            <Input
                                                                   className="form-control-alternative"
                                                                   id="input-encaisser"
                                                                   placeholder={"Encaisser"}
                                                                   type="number"
                                                                   disabled={this.state.restant === "0.00" || this.state.restant === 0}
                                                                   onChange={ e => this.setState({valeur: e.target.value})}
                                                                   value={this.state.valeur || ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                            </div>
                                            <div className="pl-lg-4" style={{ textAlign: "center"}}>
                                                <Row style={{justifyContent: "center"}}>
                                                        <Button color="success" onClick={this.onSubmit}
                                                         disabled={this.state.restant === "0.00" || this.state.restant === 0 ||this.state.valeur === "" || this.state.type === ""}

                                                        >
                                                            {i18n.t('button.submit')}
                                                        </Button>
                                                         { this.createButtonPdf("Mail avec PDF", "primary", e =>
                                                             this.injected.rootStore.invoiceStore.togglePdf(e, this.state.invoice?.id, this.state.invoice?.user?.email))}
                                                </Row>
                                            </div>
                                      </Col>
                                </CardBody>
                                </Form>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}));

export default GiveMeMoney;
