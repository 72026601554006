import React from "react";
import {Link} from "react-router-dom";
import getRoutes from "../getRoutes";
import {Button, UncontrolledTooltip} from "reactstrap";

function ActionPencilLink({title, href, data}) {
    return <Link to={{
            pathname: getRoutes(href, data.id, data.status) ,
            aboutProps: {
                isCommandeValid: true,
                data: data
            }
        }}>
            <Button className={"mr-4"} color="primary" type="button"
                    id={"UncontrolledTooltipExampleEditer" + data.id}>
                <i className="fa fa-pencil" aria-hidden="true"/>
                <UncontrolledTooltip placement="top"
                                     target={"UncontrolledTooltipExampleEditer" + data.id}>
                    Editer
                </UncontrolledTooltip>
            </Button>
        </Link>

}

export default ActionPencilLink;
