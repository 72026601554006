import InvoiceAPI from "./invoice";
import AuthAPI from "./auth";
import UserAPI from "./user";
import StatusAPI from "./status";
import BlogAPI from "./blog";
import CategoryAPI from "./category";
import CommandeAPI from "./commande";
import DevisAPI from "./devis";
import FournisseurAPI from "./fournisseur";
import MarqueAPI from "./marque";
import MouvAPI from "./mouv";
import PrestationAPI from "./prestations";
import ProductAPI from "./product";
import StatAPI from "./stat";
import StockAPI from "./stock";
import CarrouselAPI from "./carrousel";
import PriseEnChargeAPI from "./priseencharge";
import StockageCasierAPI from "./stockageCasier";
import SearchAPI from "./search";
import TicketAPI from "./ticket";
import NotificationAPI from "./notification";
import ImageAPI from "./image";
import PageAPI from "./page";
import HomeAPI from "./home";
import ModeleAPI from "./annuaireCategory";
import CommandeClientAPI from "./commandeClient";
import PrinterAPI from "./printer";
import SpoolerAPI from "./spooler";
import AnnuaireGlobalAPI from "./annuaireGlobalCategory";

export default class Api {

    constructor() {
        this.auth = new AuthAPI();
        this.blogs = new BlogAPI();
        this.categories = new CategoryAPI();
        this.commandes = new CommandeAPI();
        this.devis = new DevisAPI();
        this.fournisseurs = new FournisseurAPI();
        this.invoices = new InvoiceAPI();
        this.marques = new MarqueAPI();
        this.mouvs = new MouvAPI();
        this.prestations = new PrestationAPI();
        this.products = new ProductAPI();
        this.annuaireglobalcategories = new AnnuaireGlobalAPI();
        this.annuairecategories = new ModeleAPI();
        this.stats = new StatAPI();
        this.status = new StatusAPI();
        this.stocks = new StockAPI();
        this.users = new UserAPI();
        this.priseencharges = new PriseEnChargeAPI();
        this.stockageCasier = new StockageCasierAPI();
        this.searchs = new SearchAPI();
        this.carrousels = new CarrouselAPI();
        this.tickets = new TicketAPI();
        this.notifications = new NotificationAPI();
        this.images = new ImageAPI();
        this.pages = new PageAPI();
        this.home = new HomeAPI();
        this.commandesClient = new CommandeClientAPI();
        this.printer = new PrinterAPI();
        this.spooler = new SpoolerAPI();

    }
}

