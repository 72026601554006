import React from "react";
import {inject, observer} from "mobx-react";
import {Link, withRouter} from "react-router-dom";
import {Button} from "react-bootstrap";
import {UncontrolledTooltip} from "reactstrap";
import moment from "moment";
import getRoutes from "../../../components/Akitatek/getRoutes";


const TicketsRow = inject("rootStore") (observer( class TicketsRow extends React.Component {

    ticket;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            nom: this.injected.ticket?.user?.nom ?? this.injected.user?.nom,
            email: this.injected.ticket.user?.email ?? this.injected.user?.email,
            ticket : this.injected.ticket,
        }
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.ticket !== prevProps.ticket
        ) {
            this.setState({
                ticket : this.injected.ticket
            })
        }
    }

    check(status, title) {
        if(status === "ouvert") {
            return (<span className="badge badge-pill badge-warning">{title}</span>)
        } else if(status === "cloture") {
            return (<span className="badge badge-pill badge-success">{title}</span>)
        } else if(status === "pris en charge") {
            return(<span className="badge badge-pill badge-danger">{title}</span>) }
    }

    render() {
        return (
            <>
                <tr>
                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                                <Link to={{
                                    pathname: "/tickets/update/" + this.state.ticket.id,
                                    aboutProps: {
                                        invoice: this.state.ticket
                                    }
                                }}>
                                #{this.state.ticket?.id}
                                 </Link>
                        </span>
                    </th>


                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            { this.state.ticket?.description_simple}
                        </span>
                    </td>

                    { this.state.ticket?.user?.fullname && (
                        <th className="text-center">
                        <span className="mb-0 text-sm">
                        <Link to={{pathname: getRoutes("Modifier Client", this.state.ticket?.user?.id)}}>
                             { this.state.ticket?.user?.fullname }
                        </Link>
                        </span>
                        </th>)}



                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            { this.check(this.state.ticket.status, this.state.ticket.statusname)}
                        </span>
                    </td>

                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            { this.state.ticket?.technicien?.fullname}
                        </span>
                    </td>

                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {moment(this.state.ticket?.created_at).format("lll")}
                        </span>
                    </td>

                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {moment(this.state.ticket?.updated_at).format("lll")}
                        </span>
                    </td>


                    <th scope="row"  className="text-center">
                        <span className="mb-0 text-sm">
                        </span>
                    </th>
                    <td className={"th-actions"}>
                        <div style={{display : "flex"}}>

                            <Link to={{
                                pathname: "/tickets/update/" + this.state.ticket.id,
                                aboutProps: {
                                    ticket: this.state.ticket
                                }
                            }}>
                                <Button className={"ml-2 mr-2"} color="primary" type="button" id={"UncontrolledTooltipExampleVoir" + this.state.ticket.id}>
                                    <i className="fa fa-eye" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top" target={"UncontrolledTooltipExampleVoir" + this.state.ticket.id}>
                                        Voir
                                    </UncontrolledTooltip>
                                </Button>
                            </Link>
                        </div>
                    </td>
                </tr>
            </>
        );
    }

}));

export default withRouter(TicketsRow);
