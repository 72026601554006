import Api from "../util/api";
import {action, decorate, observable} from "mobx";
import i18n from "i18next";

export default class UserStore {
    user = observable(
        {
            // observable properties:
            id: "",
            nom: "",
            prenom: ""
        });

    searchUsers = observable([])
    datas = observable([])
    favUsers = [];
    loadingUser = true;

    filter = {
        limit: "15",
        search: "",
        page: "1",
        last_page: "1",
        name: "updated_at",
        order: false,
    }

    defaultFilter = {
        limit: "15",
        search: "",
        page: "1",
        last_page: "1",
        name: "updated_at",
        order: false,
        status: []
    }

    statusname = "";

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    setUser(user) {
        this.user.name = user.prenom + " " + user.nom;
    }

    async searchUsersAction() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.users.getAllUsers()

            if (this.searchUsers.length > 0) {
                this.searchUsers.replace(authResponse)
            } else {
                this.searchUsers = authResponse;
            }
        } catch (e) {
        }

        this.rootStore.savedDatasStore.setLoading(false)

    }


    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.users.getUsers(this.filter);

            if (this.datas.length > 0) {
                this.setUsers(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }

            this.filter.page = authResponse.current_page;
            this.filter.last_page = authResponse.last_page;
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
        }


    }


    async createUser(state) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.users.createUser(state);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(i18n.t('errors.success.title'), "success");
                await this.rootStore.savedDatasStore.getAllUsers()
                await this.rootStore.savedDatasStore.setUser(authResponse.datas, 1);
            }

            return authResponse;
        } catch (e) {
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }

    async updateUser(state) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.users.updateUser(state);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Client modifié !", "success");

            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateUserProfile(state) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.users.updateUserProfile(state);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Client modifié !", "success");

            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async findUserById(id) {
        this.rootStore.savedDatasStore.setLoading(true)
        this.loadingUser = true;
        let myUser;
        try {
            myUser = await this.api.users.findUserById(id);
        } catch (e) {
        }

        this.loadingUser = false;
        this.rootStore.savedDatasStore.setLoading(false)

        return myUser;
    }

    setUsers(users) {
        this.datas.replace(users)
    }

    async saveCustomersMachine(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.users.saveCustomersMachine(data);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Machine ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateCustomersMachine(data, notHide = true) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.users.updateCustomersMachine(data);

            if (authResponse?.status === "success") {
                if (notHide) {
                    this.rootStore.notificationStore.setNotification("Machine modifiée", "success");
                }
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteCustomersMachine(data) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.users.deleteCustomersMachine(data);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Machine supprimée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    FrTel(tel) {
        if (tel) {
            return tel.replace(/(\d\d(?!$))/g, "$1-");
        }
        return "";
    }

}

decorate(UserStore, {
    setUser: action,
    user: observable,
    getDatas: action,
    datas: observable,
    favUsers: observable,
    getFavUsers: action,
    createUser: action,
    loadingUser: observable,
    findUserById: action,
    filter: observable,
    allStatus: observable,
    searchUsers: observable
});