import React from "react";
import {inject, observer} from "mobx-react";
import Button from "react-bootstrap/Button";
import {Input} from "reactstrap";
import {Link} from "react-router-dom";
import getRoutes from "../../../components/Akitatek/getRoutes";

const MachineUserRow = inject("rootStore") (observer( class MachineUserRow extends React.Component {

    appareil;
    id;
    lignes;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            index: this.injected.index,
            lignes: this.injected.lignes,
            id: this.injected.id,
            appareil: this.injected.appareil,
            code: this.injected.appareil?.pivot.code,
            defauts:    this.injected.appareil?.pivot.defauts,
            serial:    this.injected.appareil?.pivot.serial

        };

        this.toggleDanger = this.toggleDanger.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.appareil !== prevProps.appareil ||
            this.injected.id !== prevProps.id ||
            this.injected.index !== prevProps.index
        ) {
            this.setState({
                id: this.injected.id,
                appareil: this.injected.appareil,
                defauts: this.injected.appareil?.pivot.defauts,
                serial:    this.injected.appareil?.pivot.serial,
                code: this.injected.appareil?.pivot.code
            })
        }

        if (
            this.injected.lignes !== prevProps.lignes
        ) {
            this.setState({
                lignes: this.injected.lignes
            })
        }
    }

    toggleDanger() {
        this.injected.rootStore.notificationStore.setWarning("Pièce #" + this.state.appareil?.pivot?.id, event => this.onDelete( this.state.appareil?.pivot?.id, this.state.id))
    }

    async onDelete(deleteId, user_id) {
        let data= {
            user_id:user_id,
            id: deleteId
        }
        let authResponse = await this.injected.rootStore.userStore.deleteCustomersMachine(data);

        if (authResponse?.status === "success") {
            this.injected.callbackRefreshLignes(authResponse.datas)
        }
    }


    async onUpdate(e) {
        e.preventDefault()

        let authResponse

        let data = {
            defauts: this.state.defauts ?? "",
            serial: this.state.serial ?? "",
            id :  this.state.appareil?.pivot?.id ?? "",
            user_id: this.state.id,
            code: this.state.code
        }

        authResponse = await this.injected.rootStore.userStore.updateCustomersMachine(data)

        if (authResponse?.status === "success") {
            this.injected.callbackRefreshLignes(authResponse.datas)
        }
    }

    disabled() {
        return this.state.disabled
    }

    refresh() {
        let lignes = this.state.lignes
        let appareil = this.state.appareil;
        appareil.pivot.serial = this.state.serial;
        appareil.pivot.code = this.state.code;
        appareil.pivot.defaut = this.state.defauts;

        lignes[this.state.index] = appareil
        this.injected.callbackRefreshLignes(lignes)
    }

    enter(e) {
        if (e.key === 'Enter' ) {
            e.preventDefault()
            this.onUpdate(e, true)
        }
    }

    render() {
        return (
            <>
                <tr>
                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                            <Link to={{
                                pathname: "/marques/update/" + this.state.appareil?.marque?.id,
                                aboutProps: {
                                    stock: this.state.appareil
                                }}}>
                                {this.state.appareil?.marque?.nom}
                            </Link>
                        </span>
                    </th>
                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                            <Link to={{
                                pathname: getRoutes("Afficher un Produit", this.state.appareil?.id),
                                aboutProps: {
                                    stock: this.state.appareil
                                }}}>
                                {this.state.appareil?.nom}

                            </Link>
                        </span>
                    </th>
                    <th scope="row" className="text-center">
                        <Input
                            onKeyPress={e=> this.enter(e)}
                            className="form-control-alternative"
                            type={"text"}
                            placeholder={"Serial"}
                            value={ this.state.serial }
                            disabled={this.disabled()}
                            onChange={async e=> {
                                await this.setState({ serial: e.target.value})
                                this.refresh()
                            }}
                        />
                    </th>
                    <th scope="row" className="text-center">
                        <Input
                            onKeyPress={e=> this.enter(e)}
                            className="form-control-alternative"
                            type={"text"}
                            placeholder={"Code"}
                            value={ this.state.code }
                            disabled={this.disabled()}
                            onChange={async e=> {
                                await this.setState({ code: e.target.value})
                                this.refresh()
                            }}
                        />
                    </th>
                    <th scope="row" className="text-center">
                        <Input
                            onKeyPress={e=> this.enter(e)}
                            className="form-control-alternative"
                            type={"textarea"}
                            placeholder={"Defauts..."}
                            value={ this.state.defauts }
                            disabled={this.disabled()}
                            onChange={async e=> {
                                await this.setState({ defauts: e.target.value})
                                this.refresh()
                            }}
                        />
                    </th>
                    <td >
                        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                        <Button
                            disabled={this.disabled()}
                            onClick={(e) => {
                                this.onUpdate(e, true);
                            }} variant="success" size="sm" type="button" className="mr-1">
                            <i className="fa fa-check"/>
                        </Button>

                        <Button
                            disabled={this.disabled()}
                            onClick={(e) => {
                                this.toggleDanger()
                            }} variant="danger" size="sm" type="button" className="mr-1">
                            <i className="fa fa-minus"/>
                        </Button>
                        </div>
                    </td>
                </tr>
            </>
        );
    }

}));

export default MachineUserRow;
