import React from "react";
import {Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import styles from "./HeaderCat.module.css"
import {inject, observer} from "mobx-react";
import ChoiceSearch from "../Search/ChoiceSearch";
import getRoutes from "../getRoutes";

const HeaderCat = inject("rootStore") (observer(class HeaderCat extends React.Component {


    user

    get injected() {
        return this.props;
    }

    async componentDidUpdate(prevProps) {
        if(this.injected.user !== prevProps.user) {
            this.setState({
                user: this.injected.user
            })
        }

        if(this.injected.subCategory !== prevProps.subCategory) {
            this.setState({
                subCategory: this.injected.subCategory
            })
        }

        if(this.injected.category !== prevProps.category) {
            this.setState({
                category: this.injected.category
            })
        }

        if(this.injected.api !== prevProps.api) {
            this.setState({
                category: this.injected.category
            })
        }
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            user: this.injected.user,
            category: this.injected.category,
            subCategory: this.injected.subCategory,
            api: this.injected.api
        }
    }

    onUserSelection(e, user) {
        e.preventDefault()
        this.injected.rootStore.savedDatasStore.findUserById(user.id)
    }

    createFunctionalCard(title, icon, col, onClick, title1 = "") {
        return(<div className={col}>
            <div   onClick={onClick} className="card" style={{ marginBottom: "0.2em", marginTop: "-1em", cursor: "pointer"}}>
                <div className="card-block p-1 clearfix">
                    <i className={icon}></i>
                        <div className="text-primary text-uppercase font-xs">{title}</div>
                      <div  className="h5 text-primary mb-0 mt-h">{title1}</div>

                </div>
            </div>
        </div>)
    }


    createWidget(title1, title, icon, url, col = "col-6", subCategory) {
        return(<div key={title1} className={col}>
            <div className="card" style={{ marginBottom: "0.2em", marginTop: "-1em"}}>
                <div className="card-block p-1 clearfix">
                    <i className={icon}></i>

                        <Link to={{
                            pathname: url,
                            aboutProps: {
                                subCategory: subCategory,
                                user: this.state.user
                            }
                        }}
                        >
                    <div className="text-primary text-uppercase font-xs">{title}</div>
                    <div className="h5 text-primary mb-0 mt-h">{title1}</div>
                    </Link>

                </div>
            </div>
        </div>)
    }

    createWidgetSearch( icon, col = "col-6", category = "user", url) {
        return(<div key={category} className={col}>
            <div className="card" style={{ marginBottom: "0.2em", marginTop: "-1em"}}>
                <div className="card-block p-1 clearfix">
                    <i className={icon}></i>
                        <div className="text-primary text-uppercase font-xs">Rechercher</div>
                    <div> <ChoiceSearch category={category} api={this.state.api} url={url} onMenu={true}/></div>
                </div>
            </div>
        </div>)
    }

    async clearAll(e) {
        e.preventDefault();
        this.injected.rootStore.stockStore.productStore.selectionProduct = undefined;
        this.injected.rootStore.stockStore.productStore.selectionCategory = undefined;
        this.injected.rootStore.stockStore.productStore.filter.search = "";
        this.injected.rootStore.stockStore.productStore.filter.category = "";
        this.injected.rootStore.stockStore.mouvStore.search = "";
        this.injected.rootStore.stockStore.productStore.filter.mode = "ADD"
        await this.injected.rootStore.stockStore.productStore.getDatas();
    }


    cardCategory() {

        if (this.state.subCategory === "updateCustomer") {
            return [
                // this.createRetour("Retour", "Retour", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", "/customers", "col-1"),
                this.createWidget("Liste des clients", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Clients"), 'col-2'),
                this.createWidget("Mon Client", "Accueil", "fa fa-user bg-orange p-1 font-10xl mr-3 float-left", getRoutes("Modifier Client", this.state.user?.id), "col-2"),
                this.createFunctionalCard("Sélectionner",  "fa fa-hand-o-right bg-primary p-1 font-10xl mr-3 float-left", "col-2", e => this.onUserSelection(e, this.state.user)),
                this.createWidget("Prise en charge pour ce client", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", "/priseencharge/add", "col-2"),
                this.createWidget("Devis pour ce client", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", "/devis/edit/add", "col-2"),
                this.createWidget("Facture pour ce client", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", getRoutes("Gérer une facture", "add"), "col-2")
            ]
        }

        if (this.state.category === "Commandes Client") {
            return [
                this.createWidget("Liste des commandes client", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Commande Client"), 'col-6'),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left", "col-6", "commandesclient", getRoutes("Commande Client"))
            ]
        }

        if (this.state.category === "Gestion des tickets") {
            return [
                this.createWidget("Liste des tickets", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Tickets"), 'col-6'),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left", "col-6", "tickets",  getRoutes("Tickets"))
            ]
        }
        if (this.state.category === "Prises En Charge Internet") {
            return [
                this.createWidget("Liste des pec internet", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Prise En Charge Internet"), 'col-4'),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left", "col-4", "pecinternet", getRoutes("Prise En Charge Internet")),
                this.createWidget("Prise en charge", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", getRoutes("Créer une prise En Charge", "add"), 'col-4')
            ]
        }
        if (this.state.category === "Prises En Charge") {
            return [
                this.createWidget("Liste des prises en charge", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Prise En Charge"), 'col-4'),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left", "col-4", "priseencharge", getRoutes("Prise En Charge")),
                this.createWidget("Prise en charge", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", getRoutes("Créer une prise En Charge", "add"), 'col-4')
            ]
        }
        if (this.state.category === "Gestion des marques") {
            return [
                this.createWidget("Liste des marques", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Annuaire"), "col-3"),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left", "col-3", "marques", getRoutes("Annuaire")),
                this.createWidget("Marque", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", "/marques/create", "col-2"),
                this.createWidget("Produit", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", "/marques/products/create", "col-2"),
                this.createWidget("Prestation", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", "/prestations/edit/add", "col-2", 'marque'),
            ]
        }
        if (this.state.category === "Gestion des prestations") {
            return [
                this.createWidget("Liste des prestations", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Liste des prestations"), "col-4"),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left",  "col-4", "prestations", getRoutes("Liste des prestations")),
                // this.createWidget("Marque", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", "/prestations/marques", "col-3"),
                // this.createWidget("Produit", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", "/prestations/products", "col-3"),
                this.createWidget("Prestation", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", getRoutes("Créer une prestation"), "col-4"),
            ]
        }
        if (this.state.category === "Clients") {
            return [
                this.createWidget("Liste des clients", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Clients"), "col-4"),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left",  "col-4", "clients", getRoutes("Clients")),
                this.createWidget("Client", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", getRoutes("Ajout Client"), "col-4")
             ]
        }

        if (this.state.category === "Gestion des factures") {
            return [
                this.createWidget("Liste des factures", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Factures"), "col-4"),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left", "col-4", "invoices",  getRoutes("Factures")),
                this.createWidget("Facture", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", getRoutes("Gérer une facture", "add"), "col-4")]
        }

        if (this.state.category === "Gestion des devis") {
            return [
                this.createWidget("Liste des devis", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left",  getRoutes("Devis"), "col-4"),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left", "col-4","devis",  getRoutes("Devis")),
                this.createWidget("Devis", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left",  getRoutes("Gérer un devis", "add"), "col-4")]
        }

        if (this.state.category === "Blogs") {
            return [
                this.createWidget("Liste des articles", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Blogs")),
                this.createWidget("Article", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left",  getRoutes("Ajout Blog"))]
        }

        if (this.state.category === "Commandes") {
            return [
                this.createWidget("Liste des commandes", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Commande"), "col-4"),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left", "col-4","commande", getRoutes("Commande")),
                this.createWidget("Commande", "NOUVEAU", "fa fa-plus bg-green p-1 font-10xl mr-3 float-left", getRoutes("Gérer une commande", "add"), "col-4")
               ]
        }
        if (this.state.category === "Stock") {
            return [
                this.createWidget("Liste des pieces", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left",  getRoutes("Mon stock"), "col-4"),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left", "col-4", "stocks",  getRoutes("Mon stock")),
                this.createWidget("Piece", "NOUVEAU",  "fa fa-plus bg-green p-1 font-10xl mr-3 float-left",   getRoutes("Ajout stock"), "col-4"),
            ]
        }

        if (this.state.category === "Images") {
            return [
                this.createWidget("Liste des images", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Gestion des images"), "col-4"),
                this.createWidgetSearch("fa fa-search bg-primary p-1 font-10xl mr-3 float-left", "col-4", "images", getRoutes("Gestion des images")),
                this.createWidget("Image", "NOUVEAU",  "fa fa-plus bg-green p-1 font-10xl mr-3 float-left",  getRoutes("Ajouter des images"), "col-4"),
            ]
        }


        if (this.state.category === "Services") {
            return [
                this.createWidget("Liste des services", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left",  getRoutes("Présenter vos services") ),
                this.createWidget("Service", "NOUVEAU",  "fa fa-plus bg-green p-1 font-10xl mr-3 float-left",  getRoutes("Ajouter un service")  ),
            ]
        }


        if (this.state.category === "Présentations") {
            return [
                this.createWidget("Liste des présentations", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Présenter vos atouts") ),
                this.createWidget("Présentation", "NOUVEAU",  "fa fa-plus bg-green p-1 font-10xl mr-3 float-left",  getRoutes("Ajouter un atout") ),
            ]
        }

        if (this.state.category === "Gestion des pages") {
            return [
                this.createWidget("Liste des pages", "Accueil", "fa fa-home bg-indigo p-1 font-10xl mr-3 float-left", getRoutes("Gérer les pages")),
                this.createWidget("Page", "NOUVEAU",  "fa fa-plus bg-green p-1 font-10xl mr-3 float-left",  getRoutes("Modifier une page", "add")),
            ]
        }


        //     return [
        //         this.createFunctionalCard("Sélectionner", "fa fa-user-plus", "ghost-info", e => this.onUserSelection(e, this.state.user)),
        //         // this.createCard("Editer", "fa fa-user-plus", "ghost-info", ""),
        //         this.createCard("Nouveau devis", "fa fa-user-plus", "ghost-info", "/devis/edit/add"),
        //         this.createCard("Nouvelle facture", "fa fa-user-plus", "ghost-info", "/factures/update/add")]
        // }
    }

    render() {
        return (
            <>
                <Container fluid>
                <div className="header">
                <Row className={styles.myButton}>
                    {this.cardCategory()}

                </Row>
                </div>
                </Container>
            </>
        );
    }
}));

export default HeaderCat;
