import React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";

const PrestationRow = inject("rootStore") (observer( class PrestationRow extends React.Component {

    prestation;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            prestation: this.injected.prestation
        };

        this.toggleDanger = this.toggleDanger.bind(this);
        this.toggleWarning = this.toggleWarning.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.prestation !== prevProps.prestation
        ) {
            this.setState({
                prestation: this.injected.prestation
            })
        }
    }

    toggleWarning() {
        this.injected.rootStore.notificationStore.setQuestion("Voulez vous changer le status pour " + this.setStatus(),  "status " + this.setStatus(), event => this.changeStatus(this.state.prestation.status?.title, this.state.prestation.id))
    }



    setStatus() {
        if(this.state.prestation.isVisible) {
            return <span className="badge badge-pill badge-success">PUBLIER</span>
        } else {
            return <span className="badge badge-pill badge-warning"> NON PUBLIER</span>
        }
    }

    toggleDanger(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning("Prestation #" + this.state.prestation.id, event => this.onDelete( this.state.prestation.id))
    }

    async onDelete(deleteId) {
        await this.injected.rootStore.annuaireStore.prestationStore.deletePrestation(deleteId);
    }



    check(title) {
        if(title === "PUBLIE") {
            return (<span className="badge badge-pill badge-success">{title}</span>)
        } else  {
            return (<span className="badge badge-pill badge-warning">{title}</span>)
        }
    }


    render() {
        return (
            <>
                <tr>
                    <th scope="row"className="text-center">
                        <span className="mb-0 text-sm">
                             <Link to={{
                                 pathname: "/prestations/edit/" + this.state.prestation.id,
                                 aboutProps: {
                                     isCommandeValid: true,
                                     presta: this.state.prestation
                                 }
                             }}>
                                {this.state.prestation.id}
                        </Link>

                        </span>
                    </th>
                    <th scope="row"className="text-center">
                        <span className="mb-0 text-sm">
                           {this.state.prestation.fullname}
                        </span>
                    </th>
                    <td className="text-center">
                        <span className="mb-0 text-sm">
                         {this.state.prestation.global_price_mo}  €
                        </span>
                    </td>

                    <td  className="text-center">
                        <span className="mb-0 text-sm">
                         {this.state.prestation.url}
                        </span>
                    </td>

                   <td  className="text-center">
                        <span className="mb-0 text-sm">
                         {this.state.prestation.appareil?.nom}
                        </span>
                    </td>

                    <td  className="text-center">
                        <span className="mb-0 text-sm">
                           {this.setStatus()}
                        </span>
                    </td>

                    <td  style={{ display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                        <Link to={{
                            pathname: "/prestations/edit/" + this.state.prestation.id,
                            aboutProps: {
                                isCommandeValid: true,
                                presta: this.state.prestation
                            }
                        }}>
                           <Button  color="primary" type="button">
                               <i className="fa fa-pencil" aria-hidden="true"/>
                           </Button>
                        </Link>

                        <Button color="danger" type="button"  onClick={this.toggleDanger}>
                            <i className="fa fa-minus"/>
                        </Button>
                    </td>
                </tr>
            </>
        );
    }

}));

export default PrestationRow;
