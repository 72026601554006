
/* Custom import */
import Api from '../util/api'
import {action, decorate, observable} from "mobx";

export default class ErrorStore {

    error = observable({ has: false, field: ""});

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    checkArray(fields) {

        for(let i = 0; i  < fields.length ; i++) {
            Object.keys(fields[i]).forEach(function (key) {
                if(fields[i][key] === "") {
                    return true;
                }
            });
        }

        return false;
    }

    checkErrorNotFalseAnymore(name, field) {
        if( field !== "" && field != null) {
            this.error =  { has: false, field: ""};
            return true;
        }
    }

    checkError(fields) {
        for (let key in fields) {
            if( fields[key] === "" || fields[key] === null || fields.length === 0) {
                this.error =  { has: true, field: key};
                return true;
            }
        }

        return false;
    }

}

decorate(ErrorStore, {
    error: observable,
    checkError: action,
    checkErrorNotFalseAnymore: action
});

