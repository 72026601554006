import {inject, observer} from "mobx-react";
import React from "react";
import {Container} from "react-bootstrap";
import TreeView from '@material-ui/lab/TreeView';
import styles from "./Category.module.css"
import {CardBody, CardHeader} from "reactstrap";
import Card from "react-bootstrap/Card";
import Button from "reactstrap/lib/Button";
import AnnuaireTreeCategories from "./TreeCategories";
import Input from "@material-ui/core/Input";
import Square from "../../../../components/Akitatek/Icon/Square";

const AnnuaireCategory = (inject("rootStore")(observer(class AnnuaireCategory extends React.Component {


    componentDidMount() {
        this.injected.api.getCategoriesByTree(this.state.annuaire_marque_id ?? "")
    }

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            api: this.injected.api,
            looking: this.injected.looking ?? true,
            update: this.injected.update ?? true,
            card: this.injected.card ?? false,
            selectionCategory: null,
            selectionCategoryName: "",
            selectionCategoryForEditable: null,
            selectionCategoryForDelete: null,
            selectionCategoryNameForDelete: "",
            selectionCategoryForReset: null,
            selectionCategoryForUpdate: null,
            onAdd: false,
            annuaire_marque_id: this.injected.marqueId ?? "",
            expanded: this.injected.expanded,
            defaultExpanded: this.injected.defaultExpanded,
            onSelection: this.injected.onSelection ?? true
        }
    }


    async componentDidUpdate(prevProps) {
        if (
            this.injected.update !== prevProps.update ||
            this.injected.looking !== prevProps.looking ||
            this.injected.api !== prevProps.api ||
            this.injected.expanded !== prevProps.expanded ||
            this.injected.defaultExpanded !== prevProps.defaultExpanded ||
            this.injected.marqueId !== prevProps.marqueId
        ) {
            await this.setState({
                api: this.injected.api,
                update: this.injected.update,
                defaultExpanded: this.injected.defaultExpanded,
                expanded: this.injected.expanded,
                annuaire_marque_id: this.injected.marqueId ?? "",
            })
        }
    }


    onClear(event) {
        event.preventDefault()
        this.setState({
            selectionCategory: null,
            selectionCategoryName: "",
            selectionCategoryForEditable: null,
            selectionCategoryForDelete: null,
            selectionCategoryNameForDelete: "",
            selectionCategoryForReset: null,
            selectionCategoryForUpdate: null,
            onAdd: false
        })

        this.state.api.selectionCategory = ""
    }

    getSelection = (childData, nameData, child) => {
        this.setState({
            selectionCategory: childData,
            selectionCategoryName: nameData
        })

        this.state.api.treeCategory = childData

        if (this.state.onSelection) {
            if (this.injected.onClose) {
                this.injected.onClose(child)
            }
        }
    };

    getSuccessEditableCallback = (childData) => {
        this.setState({
            selectionCategoryForEditable: childData
        })
    };

    getSuccessUpdateCallback = (childData) => {
        this.setState({
            selectionCategoryForUpdate: childData
        })
    };


    onAdd(event) {
        event.preventDefault()

        if (this.state.selectionCategory) {
            this.setState({
                    selectionCategoryForEditable: this.state.selectionCategory
                }
            )
        } else {
            this.setState({
                    onAdd: !this.state.onAdd
                }
            )
        }

    }

    onUpdate(event) {
        event.preventDefault()

        this.setState({
                selectionCategoryForUpdate: this.state.selectionCategory
            }
        )
    }

    toggleDelete(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning(this.state.selectionCategoryName, event => this.onDelete(this.state.selectionCategory))
    }

    async onDelete(deleteId) {
        await this.state.api.deleteCategory({id: deleteId})
    }

    async enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()

            let data = {
                nom: this.state.newValueForEditable,
                annuaire_marque_id: this.state.annuaire_marque_id
            }


            await this.state.api.createCategory(data)
            await this.setState({
                onAdd: false
            })
        }

    }

    setCard() {
        return (<div>
                {this.state.update && (
                    <CardHeader className="bg-transparent" style={{display: "flex", color: "#20a8d8"}}>
                        <Button className="mr-2" color="success" onClick={event => this.onAdd(event)}>Ajouter</Button>
                        <Button className="mr-2" color="warning"
                                onClick={event => this.onUpdate(event)}>Renommer</Button>
                        <Button className="mr-2" color="danger"
                                onClick={event => this.setState(this.toggleDelete(event))}>Supprimer</Button>
                        <Button className="mr-2" color="primary" onClick={event => this.onClear(event)}>Clear</Button>
                    </CardHeader>)}
                <CardBody>
                    <TreeView
                        className={styles.root}
                        expanded={this.state.expanded}
                        defaultExpanded={this.state.defaultExpanded}
                        defaultCollapseIcon={<Square type={"minus"}/>}
                        defaultExpandIcon={<Square type={"plus"}/>}
                        defaultEndIcon={<Square type={"close"}/>}
                    >

                        {(this.state.onAdd) && (
                            <i className="fa fa-arrow-right" aria-hidden="true">
                                <Input
                                    onChange={(e) => this.setState({newValueForEditable: e.target.value})}
                                    onKeyPress={(e) => {
                                        this.enter(e)
                                    }}
                                    value={this.state.newValueForEditable}
                                /></i>
                        )
                        }

                        {this.state.api.treesCategories?.map((category) => {
                            return (
                                <AnnuaireTreeCategories
                                    key={category.id}
                                    api={this.state.api}
                                    onSelection={this.state.onSelection}
                                    getSuccessEditableCallback={this.getSuccessEditableCallback}
                                    getSuccessUpdateCallback={this.getSuccessUpdateCallback}
                                    selectionCategoryForEditable={this.state.selectionCategoryForEditable}
                                    selectionCategoryForUpdate={this.state.selectionCategoryForUpdate}
                                    getSelectionCallback={this.getSelection}
                                    category={category}
                                    rootStore={this.injected.rootStore}
                                    annuaire_marque_id={this.state.annuaire_marque_id}
                                    looking={this.state.looking}
                                    update={this.state.update}
                                />)
                        })}
                    </TreeView>
                </CardBody>

                {!this.state.onSelection && (
                    <Button color={"success"} onClick={e => this.injected.onClose()}>Valider</Button>
                )}</div>
        )
    }

    render() {
        return (
            <>
                {!this.state.card ? (
                    <Container fluid>
                        <Card>
                            {this.setCard()}</Card>
                    </Container>) : (this.setCard())
                }
            </>
        );
    }

})));

export default AnnuaireCategory;
