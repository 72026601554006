import {inject, observer} from "mobx-react";
import React from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import Input from "@material-ui/core/Input";
import withStyles from "@material-ui/core/styles/withStyles";
import {fade} from "@material-ui/core";

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
}))((props) => <TreeItem {...props}  />);

const AnnuaireTreeCategories = (inject("rootStore")(observer(class AnnuaireTreeCategories extends React.Component {

    category;
    selectionCategoryForEditable;
    rootStore;
    selectionCategoryForUpdate;

    get injected() {
        return this.props;
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.category !== prevProps.category ||
            this.injected.looking !== prevProps.looking ||
            this.injected.api !== prevProps.api ||
            this.injected.update !== prevProps.update ||
            this.injected.selectionCategoryForEditable !== prevProps.selectionCategoryForEditable ||
            this.injected.selectionCategoryForUpdate !== prevProps.selectionCategoryForUpdate

        ) {
            this.setState({
                selectionCategoryForUpdate: this.injected.selectionCategoryForUpdate,
                selectionCategoryForEditable: this.injected.selectionCategoryForEditable,
                rootStore: this.injected.rootStore,
                api: this.injected.api,
                category: this.injected.category,
                update: this.injected.update,
                looking: this.injected.looking,
                annuaire_marque_id: this.injected.annuaire_marque_id
            })
        }
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            category: this.injected.category,
            selectionCategoryForUpdate: this.injected.selectionCategoryForUpdate,
            selectionCategoryForEditable: this.injected.selectionCategoryForEditable,
            newValueForEditable: "",
            api: this.injected.api,
            looking: this.injected.looking,
            update: this.injected.update,
            newValueForUpdate: this.injected.category?.nom,
            rootStore: this.injected.rootStore,
            onSelection: this.injected.onSelection,
            annuaire_marque_id: this.injected.annuaire_marque_id
        }

    }

    getSelection(e) {
        e.preventDefault()
        this.injected.api.selectionCategory = this.state.category
        this.injected.getSelectionCallback(this.state.category?.id, this.state.category?.nom, this.state.category)

        if(this.state.looking) {
            this.injected.rootStore.stockStore.productStore.filter.category = this.state.category.id
            this.injected.rootStore.stockStore.productStore.filter.shop = ""
            this.injected.rootStore.stockStore.productStore.selectionCategory = this.state.category
            this.injected.rootStore.stockStore.productStore.getDatas()
        }
    }

    async enter(e, type) {
        if (e.key === 'Enter') {
            e.preventDefault()

            if (type === "ADD") {
                let data = {
                    parent_id: this.state.category?.id,
                    nom: this.state.newValueForEditable,
                    annuaire_marque_id: this.state.annuaire_marque_id
                }


                await this.state.api.createCategory(data)
                this.injected.getSuccessEditableCallback(null)
            }

            if (type === "UPDATE") {
                let data = {
                    id: this.state.category?.id,
                    nom: this.state.newValueForUpdate,
                    annuaire_marque_id: this.state.annuaire_marque_id
                }


                await this.state.api.updateCategory(data)
                this.injected.getSuccessUpdateCallback(null)
            }

        }
    }


    setSubCategory(subCategory) {
        return (
            <AnnuaireTreeCategories
                api={this.state.api}
                key={subCategory.id}
                onSelection={this.state.onSelection}
                looking={this.state.looking}
                update={this.state.update}
                rootStore={this.injected.rootStore}
                selectionCategoryForEditable={this.state.selectionCategoryForEditable}
                selectionCategoryForUpdate={this.state.selectionCategoryForUpdate}
                getSelectionCallback={this.injected.getSelectionCallback}
                getSuccessUpdateCallback={this.injected.getSuccessUpdateCallback}
                getSuccessEditableCallback={this.injected.getSuccessEditableCallback}
                annuaire_marque_id={this.state.annuaire_marque_id}
                category={subCategory}/>
        )
    }

    async onDrop(event) {
        event.preventDefault()
        event.stopPropagation()

        if (event.dataTransfer.getData("id")) {
            let data = {
                id: event.dataTransfer.getData("id"),
                parent_id: this.state.category?.id
            }

            this.injected.rootStore.notificationStore.setQuestion("Voulez vous changer de place cette catégorie ?", "la catégorie " + event.dataTransfer.getData("nom") + " sera mise sous " + this.state.category?.nom, e => this.onChange(data))
        }
    }

    async onChange(data) {
        await this.state.api.updateCategory(data)
        this.injected.getSuccessUpdateCallback(null)
    }

    onDragStart(event) {
        if (this.state.category?.parent_id !== null) {
            event.dataTransfer.setData("id", this.state.category?.id);
            event.dataTransfer.setData("nom", this.state.category?.nom);

        }

        event.stopPropagation()
    }

    onDragOver(event) {
        event.preventDefault()
    }


    render() {
        return (
            <>

                {(this.state.selectionCategoryForUpdate === this.state.category?.id) && (
                    <Input
                        onChange={(e) => this.setState({newValueForUpdate: e.target.value})}
                        onKeyPress={(e) => {
                            this.enter(e, "UPDATE")
                        }}
                        value={this.state.newValueForUpdate}
                    />
                )}

                {(this.state.selectionCategoryForUpdate === this.state.category?.id) &&
                this.state.category?.subCategories.map((subCategory) => {
                        return (this.setSubCategory(subCategory))
                    }
                )}


                {(this.state.selectionCategoryForUpdate !== this.state.category?.id) && (
                    this.state.update ? (
                        <StyledTreeItem
                            draggable
                            onDragOver={e => this.onDragOver(e)}
                            onDrop={e => this.onDrop(e)}
                            onDragStart={e => this.onDragStart(e)}
                            onClick={(e) => {
                                this.getSelection(e)
                            }} nodeId={this.state.category?.id} label={this.state.category?.nom}>
                            {(this.state.category?.subCategories.map((subCategory) => {
                                return (this.setSubCategory(subCategory))
                            }))}
                        </StyledTreeItem>) : (
                        <StyledTreeItem
                            collapseIcon={true}
                            onClick={(e) => {
                                this.getSelection(e)
                            }} nodeId={this.state.category?.id} label={this.state.category?.nom}>
                            {(this.state.category?.subCategories.map((subCategory) => {
                                return (this.setSubCategory(subCategory))
                            }))}
                        </StyledTreeItem>
                    )

                )
                }


                {(this.state.selectionCategoryForEditable === this.state.category?.id) && (
                    <i className="fa fa-arrow-right ml-4" aria-hidden="true">
                        <Input
                            className={"ml-1"}
                            onChange={(e) => this.setState({newValueForEditable: e.target.value})}
                            onKeyPress={(e) => {
                                this.enter(e, "ADD")
                            }}
                            value={this.state.newValueForEditable}
                        /></i>
                )
                }
            </>
        );
    }

})));

export default AnnuaireTreeCategories;
