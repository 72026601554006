import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import InfoIcon from '@material-ui/icons/Info';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {inject, observer} from "mobx-react";
import styles from './Timeline/Timeline.module.css'
import AddIcon from '@material-ui/icons/Add';
import Input from "reactstrap/lib/Input";
import moment from "moment";
import 'moment/locale/fr';

const Historique = inject("rootStore") (observer( class Historique extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props)
        moment.locale('fr');
        this.state = {
            id: this.injected.id,
            notes: this.injected.notes ?? [],
            description: "",
            title: "",
            timer: null
        }
        this.countingSecond = this.countingSecond.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.notes !== prevProps.notes
        ) {
            this.setState({
                notes: this.injected.notes ?? [],
            })
        }
    }

    countingSecond() {
        this.setState({
            time: moment().format('LTS'),
        })
    }

    componentWillUnmount() {
        window.clearInterval(this.state.timer)
        this.setState({timer: null})

    }

    componentDidMount() {
        this.setState({timer: window.setInterval(this.countingSecond, 1000)})
    }

    setComponent(note) {
        return ( <TimelineItem key={note.id}>
            <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                    {moment(note.created_at).format('LLLL')}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                    <InfoIcon />
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={3} className={styles.paper}>
                    <Typography variant="h6" component="h1">
                        {note.title}
                    </Typography>
                    <Typography> {note.description}</Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>)
    }


    createComponent() {
        return ( <TimelineItem>
            <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                    {moment().format('LL')} {this.state.time}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                    <AddIcon />
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={3} className={styles.paper}>
                    <Typography variant="h6" component="h1">
                        <Input
                            id={"refTimelineHistorique"}
                            value={this.state.title}
                            onChange={e => this.setState({ title: e.target.value})}
                            placeholder={"Entrer votre titre"}
                            type="text"
                            name="text"  />
                    </Typography>
                    <Typography><Input
                        value={this.state.description}
                        onChange={e => this.setState({ description: e.target.value})}
                        onKeyPress={e => this.enter(e)}
                        placeholder={"Notez et appuyez sur entrer"}
                        type="textarea"
                        name="text" />

                    </Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>)
    }

    async enter(e) {
        if (e.key === 'Enter' ) {
            e.preventDefault()
            await this.onSubmit(e)
        }
    }

    async onSubmit(e) {
        e.preventDefault();

        let data = {
            prise_en_charge_id: this.state.id,
            title: this.state.title,
            description: this.state.description
        }

        let authResponse = await this.injected.rootStore.prisenchargeStore.saveNote(data)

        if(authResponse?.status === "success") {
            this.setState({ notes: authResponse.datas, description: "", title: ""})
            document.getElementById("refTimelineHistorique").focus();
        }
    }


    render() {
        return (

            <Timeline align="alternate">
                {this.createComponent()}

                {this.state.notes.map(note => {
                    return this.setComponent(note)
                })}
            </Timeline>
        );
    }


}));

export default Historique

