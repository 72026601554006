/* Global import */
import {action, decorate, observable} from 'mobx';
/* Custom import */
import Api from '../../util/api'

export default class CategoryStore {
    selectionCategory;

    treesCategories = observable([])
    treeCategory = 1;
    treesCategoriesId = observable([])

    constructor(rootStore, annuaireStore) {
        this.rootStore = rootStore
        this.annuaireStore = annuaireStore;
        this.api = new Api();
    }


    async getCategoriesByTree() {
        let authResponse = await this.api.categories.getCategoriesByTree()

        if (authResponse?.status === "success") {
            this.treesCategories = authResponse.datas
            this.treesCategoriesId = authResponse.data.flat()
        }
    }


    async createCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.categories.createCategory(myData);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Category " + authResponse.data.id + " sauvegardée", "success");
                this.setCategories(authResponse.datas)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.categories.updateCategory(myData);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Categorie #" + authResponse.data.id + " modifiée", "success");
                this.setCategories(authResponse.datas)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.categories.deleteCategory(myData);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Category " + myData.id + " supprimée", "success");
                this.setCategories(authResponse.datas)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    setCategories(categories) {
        this.treesCategories.replace(categories)
    }
}

decorate(CategoryStore, {
    getAllCategories: action,
    allCategories: observable,
    treesCategories: observable,
    treesCategoriesId: observable,
    treeCategory: observable,
    treeShopCategory: observable,
    selectionCategory: observable,
    treesCategoriesShop: observable,
    treesCategoriesShopId: observable,
});