import axios from "axios";
import configuration from "../configuration/configuration";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {default as i18n} from "i18next";

export default class ProductAPI {

    createProductWithFile(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                if (datas.description) {
                    datas.description = draftToHtml(convertToRaw(datas.description.getCurrentContent()));
                }
                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/annuaire/products`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    updateProductWithFile(datas) {
        return new Promise(async (resolve, reject) => {
            try {

                if (datas.description) {
                    datas.description = draftToHtml(convertToRaw(datas.description.getCurrentContent()));
                }
                const response = await axios({
                    method: 'PUT',
                    url: `${configuration.API}/api/annuaire/products`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: datas
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async findProductById(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/products/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async allProducts() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/annuaire/all/products`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getProducts(marqueId, search) {
        let url = `/api/annuaire/products?search=${search}`;
        if (marqueId) {
            url = `/api/annuaire/products?marqueId=${marqueId}&search=${search}`
        }

        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}` + url,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async deleteProducts(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/products/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data.status)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

}