import React from "react";
import PlusSquare from "./PlusSquare";
import MinusSquare from "./MinusSquare";
import CloseSquare from "./CloseSquare";

function Square({type}) {
    switch (type) {
        case 'plus':
            return <PlusSquare/>
        case 'minus':
            return <MinusSquare/>
        case 'close':
            return <CloseSquare/>
        default:
            return <PlusSquare/>
    }
}

export default Square