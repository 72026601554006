import React from "react";
import {FormGroup, Input} from "reactstrap";
import styles from "../../views/admin/Annuaire/Prestation/Prestation.module.css";
import PlacesAutocomplete, {geocodeByPlaceId} from "react-places-autocomplete";
import {inject, observer} from "mobx-react";

const PlaceAutoComplete = (inject("rootStore") (observer( class Address extends React.Component {


    handleSelect = (address, placeId, suggestion) => {
        geocodeByPlaceId(suggestion?.placeId)
            .then(results =>
                this.props.handleChange(
                    results[0].address_components[0]?.long_name + " " + results[0].address_components[1]?.long_name,
                    results[0].address_components[2]?.long_name,
                    results[0].address_components[6]?.long_name)
            )
            .catch(error => console.error(error))
    }

    render() {
        const {adresse, handleChange} =this.props
        return <FormGroup>
            <label
                className={styles.typeField}
                htmlFor="input-address2"
            >
                Adresse
            </label>
            <PlacesAutocomplete
                value={adresse}
                onChange={handleChange}
                onSelect={this.handleSelect}
                shouldFetchSuggestions={adresse?.length > 10}

            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <div>
                        <Input
                            {...getInputProps({
                                placeholder: 'Adresse',
                                className: 'location-search-input',
                            })}
                        />
                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                    : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </FormGroup>
    }
})))

export default PlaceAutoComplete;
