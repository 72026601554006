

import { decorate, observable} from "mobx";
import Api from "../util/api";

export default class TicketStore {

    search = ""
    tickets = observable([]);
    currentPage = 0;
    lastPage = 1;
    status = ""
    statusname = "Tous"
    limit = 15

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    async getTickets()  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.tickets.getTickets(this.limit, this.currentPage, this.status, this.search);
            this.tickets = authResponse.data;

            if(this.tickets.length > 0) {
                this.tickets.replace(authResponse.data)
            } else {
                this.tickets = authResponse.data;
            }
            this.currentPage = authResponse.current_page;
            this.lastPage = authResponse.last_page ;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }

    async getTicketForId(id)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.tickets.findById(id);
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async saveNote(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.tickets.saveNote(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Note ajoutée au ticket", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(TicketStore, {
    tickets: observable,
    search: observable,
    statusname: observable,
    currentPage: observable,
    lastPage: observable,
    status: observable,
    limit:observable,
});

