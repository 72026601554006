import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import i18n from "i18next";
import {ContentState, EditorState} from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Tabs from "../../../components/Akitatek/Tabs/Tabs";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import PrestationRow from "./Prestation/PrestationRow";
import Pagination from "../../../components/Akitatek/Pagination";
import styles from "./Prestation/Prestation.module.css";
import htmlToDraft from 'html-to-draftjs';
import ChoiceImageInput from "../../../components/Akitatek/Form/ChoiceImageInput";
import ChoiceCategory from "../../../components/Akitatek/Category/ChoiceCategory";
import CATEGORY from "../../../components/Akitatek/Category/Category";
import EditorWord from "../../../components/Akitatek/EditorWord";

var slugify = require('slugify')


const CreateProduct = (inject("rootStore")(observer(class CreateProduct extends React.Component {
    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);

        this.state = {
            name: '',
            file: "",
            img: null,
            id: null,
            description: EditorState.createEmpty(),
            url: '',
            isVisible: 0,
            title: '',
            metadescription: '',
            assetName: "",
            asset_id: "",
            card: this.injected.card ?? true,
            parents: [],
            path: "",
            category_id: "",
            marqueId: this.injected.rootStore.annuaireStore.productStore.marqueId
        }

        this.toggleDanger = this.toggleDanger.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBack = this.onBack.bind(this);
        this.changeProduct = this.changeProduct.bind(this);

        if (this.state.card) {
            this.injected.rootStore.savedDatasStore.setHeader("Gestion des marques", "Gérer vos marques", "annuaire_marque.jpg")
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            description:
            editorState,
        });
    };

    UNSAFE_componentWillMount() {
        if (this.injected.location?.aboutProps?.data) {
            this.setInitialData(this.injected.location.aboutProps?.data)
            this.injected.rootStore.annuaireStore.prestationStore.appareilId = this.injected.location.aboutProps?.data?.id
            this.injected.rootStore.annuaireStore.prestationStore.appareil = this.injected.location.aboutProps?.data?.nom
            this.injected.rootStore.annuaireStore.productStore.marque = "De " + this.injected.location.aboutProps?.data?.marque?.nom
            this.injected.rootStore.annuaireStore.prestationStore.getPrestation();
        }
    }

    async componentDidMount() {
        await this.injected.rootStore.annuaireStore.marqueStore.getMarques();
        this.id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (this.id) {
            let product = await this.injected.rootStore.annuaireStore.productStore.findProductById(this.id);
            if (product) {
                this.injected.rootStore.annuaireStore.prestationStore.appareilId = product?.id
                this.injected.rootStore.annuaireStore.prestationStore.appareil = product?.nom
                this.injected.rootStore.annuaireStore.productStore.marque = "De " + product?.marque?.nom
                this.injected.rootStore.annuaireStore.prestationStore.getPrestation();
                this.setInitialData(product)
            }
        }
    }

    setInitialData(product) {
        let editorState;
        if (product?.description != null) {
            let blocksFromHTML = htmlToDraft(product.description);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

            editorState = EditorState.createWithContent(contentState);
        } else {
            editorState = EditorState.createEmpty()
        }

        this.setState({
            name: product.nom ?? "",
            img: product.photo ?? "",
            id: product.id ?? "",
            marqueId: product.annuaire_marque_id ?? "",
            danger: false,
            description: editorState,
            url: product?.url ?? "",
            assetName: product?.asset?.filename ?? "",
            asset_id: product?.asset?.id ?? "",
            pickAsset: false,
            isVisible: product.isVisible ?? "",
            title: product?.title ?? "",
            metadescription: product?.metadescription ?? "",
            parents: product?.category?.parents ?? "",
            path: product?.category?.path ?? "",
            category_id: product?.category?.id ?? "",
            parentsGlobal: product?.globalcategory?.parents ?? "",
            pathGlobal: product?.globalcategory?.path ?? "",

        });
    }


    async onSubmit(e) {
        e.preventDefault();
        let authResponse;

        if (this.state.id) {
            authResponse = await this.injected.rootStore.annuaireStore.productStore.updateProduct(this.state);
        } else {
            authResponse = await this.injected.rootStore.annuaireStore.productStore.createProduct(this.state);
        }

        if (authResponse?.status === "success") {
            if (this.state.card) {
                this.setInitialData(authResponse?.datas)
            } else {
                this.injected.onCallback()
            }
        }
    }

    changeProduct(e) {

        if(!this.state.id) {
            this.setState({name: e.target.value, url: slugify(e.target.value)})
        } else {
            this.setState({name: e.target.value})
        }
    }


    toggleDanger() {
        this.injected.rootStore.notificationStore.setWarning("Produit #" + this.state.id, event => this.onDelete(this.state.id, this.state.marqueId))
    }


    async onDelete(deleteId, annuaireMarqueId) {
        let status = await this.injected.rootStore.annuaireStore.productStore.deleteProduct(deleteId);
        if (status === "success") {
            await this.injected.rootStore.annuaireStore.productStore.getProducts(annuaireMarqueId);
            this.injected.history.goBack()
        }
    }

    onPickAssetFilename = (action) => {
        if (action === "add") {
            this.setState({
                assetName: this.injected.rootStore.imageStore.pickAsset?.filename,
                asset_id: this.injected.rootStore.imageStore.pickAsset?.id,
            })
        } else {
            this.setState({
                assetName: "",
                asset_id: "",
            })
        }
    }

    onChange(e) {
        const name = e.target.name
        const type = e.target.type
        const value = type === "radio" ? parseInt(e.target.value) : e.target.value
        this.setState({[name]: value});
    }

    onChangeCategory = () => {
        if (this.injected.rootStore.annuaireStore.annuaireCategoryStore.selectionCategory) {
            this.setState({
                parents: this.injected.rootStore.annuaireStore.annuaireCategoryStore.selectionCategory.parents,
                path: this.injected.rootStore.annuaireStore.annuaireCategoryStore.selectionCategory.path,
                category_id: this.injected.rootStore.annuaireStore.annuaireCategoryStore.selectionCategory.id,
            })
        } else {
            this.setState({
                parents: "",
                path: "",
                category_id: "",
            })
        }
    }

    onChangeCategoryGlobal = () => {
        if (this.injected.rootStore.annuaireGlobalStore.selectionCategory) {
            this.setState({
                parentsGlobal: this.injected.rootStore.annuaireGlobalStore.selectionCategory.parents,
                pathGlobal: this.injected.rootStore.annuaireGlobalStore.selectionCategory.path,
                category_global_id: this.injected.rootStore.annuaireGlobalStore.selectionCategory.id,
            })
        } else {
            this.setState({
                parentsGlobal: "",
                pathGlobal: "",
                category_global_id: "",
            })
        }
    }


    setForm() {
        return (<Form style={{width: "100%"}}
                      role="form"
                      id="myFormUpdate"
                      onSubmit={this.onSubmit}
        >

            <div className="pl-lg-4">
                {this.injected.rootStore.authStore.options.metadata === 1 && (
                    <Row>
                        <Col>
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-first-name"
                                >
                                    Titre de la page (Si vide généré auto)
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-first-name"
                                    type="text"
                                    name={'title'}
                                    onChange={this.onChange}
                                    value={this.state.title || ''}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-first-name"
                                >
                                    Métadescription (si vide : généré auto)
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-first-name"
                                    type="text"
                                    name={'metadescription'}
                                    onChange={this.onChange}
                                    value={this.state.metadescription || ''}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col>
                        <FormGroup>
                            <label
                                className={styles.typeField}
                                htmlFor="input-first-name"
                            >
                                {i18n.t('page.admin.product.title')}
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="input-first-name"
                                type="text"
                                value={this.state.name || ''}
                                onChange={this.changeProduct}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <ChoiceImageInput assetName={this.state.assetName}
                                      onPickAssetFilename={this.onPickAssetFilename}/>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <label
                                className={styles.typeField}
                                htmlFor="input-first-name"
                            >
                                {i18n.t('page.admin.marque.title')}
                            </label>
                            <Input
                                value={this.state.marqueId}
                                name={'marqueId'}
                                onChange={this.onChange}
                                type="select" id="select">
                                <option value="">--Sélectionner la marque --</option>
                                {this.injected.rootStore.annuaireStore.marqueStore.marques
                                    .map((data) => {
                                        return (<option key={data.id} value={data.id}>{data.nom}</option>)
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ChoiceCategory
                            name={"Modèle"}
                            path={this.state.path}
                            type={CATEGORY.ANNUAIRE}
                            onChangeCategory={this.onChangeCategory}
                            marqueId={this.state.marqueId}
                            defaultExpanded={this.state.parents}/>

                    </Col>
                    <Col>
                        <ChoiceCategory
                            name={"Catégorie Global"}
                            path={this.state.pathGlobal}
                            type={CATEGORY.GLOBAL}
                            onChangeCategory={this.onChangeCategoryGlobal}
                            defaultExpanded={this.state.parentsGlobal}/>

                    </Col>

                </Row>


                <Row>
                    <Col>
                        <FormGroup>
                            <label
                                className={styles.typeField}
                                htmlFor="input-first-name"
                            >
                                URL
                            </label>

                            <Input
                                className="form-control-alternative"
                                id="input-first-url"
                                type="text"
                                name={'url'}
                                onChange={this.onChange}
                                value={this.state.url || ''}
                            />
                        </FormGroup>
                    </Col>
                </Row>


                <EditorWord editorState={this.state.description} onEditorStateChange={this.onEditorStateChange}/>

                <Row>
                    <Col style={{display: "flex", justifyContent: "center"}}>
                        <FormGroup className={"mr-2"}>
                            <label>
                                <input type="radio"
                                       name={'isVisible'}
                                       onChange={this.onChange}
                                       value={1}
                                       checked={this.state.isVisible === 1}
                                />
                                <span className="badge badge-pill badge-success">PUBLIER</span>
                            </label>
                        </FormGroup>
                        <FormGroup>
                            <label>
                                <input type="radio"
                                       name={'isVisible'}
                                       onChange={this.onChange}
                                       value={0}
                                       checked={this.state.isVisible === 0}
                                />
                                <span className="badge badge-pill badge-warning">NON PUBLIER</span>
                            </label>
                        </FormGroup>
                    </Col>
                </Row>

                <div className="pl-lg-4 mt-3" style={{textAlign: "center"}}>
                    <Button color="success" className="mr-1" type="submit">
                        {i18n.t('button.submit')}
                    </Button>

                    {this.state.id &&
                    <Button color="danger" type="button" className="mr-1"
                            onClick={this.toggleDanger}>
                        Supprimer
                    </Button>
                    }
                </div>
            </div>
        </Form>)
    }

    setPrestation() {
        return (<div><TableGeneric titles={[
            "id",
            i18n.t('page.admin.prestations.name'),
            i18n.t('page.admin.prestations.price'),
            "URL",
            'Produit',
            "Status",
            "Actions"
        ]}>

            {this.injected.rootStore.annuaireStore.prestationStore.prestations
                .map((prestation, id) => {
                    return <PrestationRow prestation={prestation} key={id}/>

                })
            }
        </TableGeneric>
            <Pagination currentPage={this.injected.rootStore.annuaireStore.prestationStore.currentPage}
                        lastPage={this.injected.rootStore.annuaireStore.prestationStore.lastPage}
                        parentCallback={this.parentCallbackPage}/></div>)
    }


    onBack(e) {
        e.preventDefault()
        this.injected.history.goBack()
    }

    onSendBackTab = (nb) => {
        this.setState({
            activeTab: nb
        })
    }

    render() {
        return (
            <>
                {this.state.card && (
                    <Container className="mt-2" fluid>
                        <Row className="mb-3">
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                        <h3 className="mb-0" style={{color: "#20a8d8"}}>  {
                                            this.state.id ? (
                                                <div>
                                                    <i onClick={this.onBack} style={{cursor: "pointer"}}
                                                       className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                                                    Produit #{this.state.id}
                                                </div>
                                            ) : (
                                                "Produit"
                                            )
                                        }</h3>
                                    </CardHeader>
                                    {this.state.id ? (
                                            <Tabs
                                                onCallback={this.onSendBackTab}
                                                activeTab={this.state.activeTab}
                                                children={[this.setForm(), this.setPrestation()]}
                                                titles={["General", "Prestations"]}/>
                                        ) :
                                        (<CardBody> <Row>{this.setForm()} </Row></CardBody>)
                                    }
                                </Card>
                            </div>
                        </Row>
                    </Container>
                )}


                {!this.state.card && (
                    this.setForm()
                )}
            </>
        );
    }
})));

export default CreateProduct;
