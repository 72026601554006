import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {createPortal} from "react-dom";

function ModalAkitatek({show = false, title, onCallback, children}) {
    return createPortal(<Modal show={show}
                               size="lg"
                               aria-labelledby="contained-modal-title-vcenter"
                               centered
    >
        <Modal.Header>
            <Modal.Title style={{width: "100%"}}>{title}</Modal.Title>
            <Button variant="secondary" onClick={onCallback}>
                <i className="fa fa-close"/>
            </Button>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
    </Modal>, document.body);
}

export default ModalAkitatek;
