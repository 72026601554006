import {inject, observer} from "mobx-react";
import React from "react";
import {Container} from "react-bootstrap";
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import styles from "./Stats.module.css"
import * as moment from 'moment'
import 'moment/locale/fr';
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import TableStats from "./TableStats";


const StatsDetails = (inject("rootStore")(observer(class Stats extends React.Component {


    statsStore = this.injected.rootStore.statsStore;

    get injected() {
        return this.props;
    }


    componentDidMount() {
        this.statsStore.getStats();
    }


    constructor(props, context) {
        super(props, context);
        moment.locale('fr');
        this.injected.rootStore.savedDatasStore.setHeader("Statistique de l'activité", "", "stats.jpg")
    }

    async sendRequest(month, year) {
        this.statsStore.actual.year = year;
        this.statsStore.actual.moisNumber = month;
        await this.statsStore.getStats(year, month);
    }


    async getToPrecedent(e) {
        e.preventDefault();
        await this.statsStore.getStats(this.statsStore.precedent.moisMoment.format("YYYY"), this.statsStore.precedent.moisMoment.format("MM"));
    }

    async getToNext(e) {
        e.preventDefault();
        await this.statsStore.getStats(this.statsStore.suivant.moisMoment.format("YYYY"), this.statsStore.suivant.moisMoment.format("MM"));
    }

    setOptions() {
        let m = moment();
        let options = [];
        for (let i = 0; i < 12; i++) {
            let month = m.months(i).format('MMMM');
            options.push(
                <option
                    className={styles.customInput}
                    value={i + 1}>
                    {month.charAt(0).toUpperCase() + month.slice(1)}
                </option>
            );
        }

        return options;
    }


    render() {
        return (
            <Container fluid>

                <div className="animated fadeIn">
                    <Row>

                        <Col xs="12" sm="4" lg="4">
                            <Card className="text-white bg-success">
                                <CardBody className="pb-0" style={{textAlign: "center", height: "10em"}}>
                                    <Row>
                                        <Col className={styles.imageCard} sm={4}>
                                            <i className="fa fa-chevron-left fa-5x" style={{cursor: "pointer"}}
                                               onClick={e => this.getToPrecedent(e)}/>
                                        </Col>

                                        <Col className={"m-auto"}>
                                            <div className="text-value  mb-4"><span
                                                style={{textTransform: "capitalize"}}>    {this.injected.rootStore.statsStore.precedent.moisString} </span>
                                            </div>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs="12" sm="4" lg="4">
                            <Card className="text-white bg-success">
                                <CardBody className="pb-0" style={{textAlign: "center", height: "10em"}}>
                                    <Row>
                                        <Col>
                                            <Input style={{
                                                fontSize: "1.3125rem",
                                                fontWeight: "600",
                                                backgroundColor: "transparent",
                                                color: "white"
                                            }}
                                                   type="select" name="select"
                                                   value={this.statsStore.actual.moisNumber || 0}
                                                   onChange={e => this.sendRequest(e.target.value, this.statsStore.actual.year)}
                                            >
                                                {this.setOptions()}
                                            </Input>
                                        </Col>
                                        <Col>
                                            <Input style={{
                                                fontSize: "1.3125rem",
                                                fontWeight: "600",
                                                backgroundColor: "transparent",
                                                color: "white"
                                            }}
                                                   type="number" name="year"
                                                   value={this.statsStore.actual.year || 0}
                                                   onChange={e => this.sendRequest(this.statsStore.actual.moisNumber, e.target.value)}
                                            >
                                            </Input>
                                        </Col>
                                    </Row>

                                    <div className={"mt-2"}>
                                        Ca : {this.statsStore.actual.ca} € =
                                        Services : {this.statsStore.caDetails.mo} € /
                                        Marchandises : {this.statsStore.caDetails.othersAll} €
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs="12" sm="4" lg="4">
                            <Card className="text-white bg-warning">
                                <CardBody className="pb-0" style={{textAlign: "center", height: "10em"}}>
                                    <Row>

                                        <Col className={"m-auto"}>
                                            <div className="text-value  ml-4"><span
                                                style={{textTransform: "capitalize"}}>    {this.injected.rootStore.statsStore.suivant.moisString} </span>
                                            </div>
                                        </Col>

                                        <Col className={styles.imageCard} sm={4}>
                                            <i className="fa fa-chevron-right fa-5x" style={{cursor: "pointer"}}
                                               onClick={e => this.getToNext(e)}/>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <Card>
                    <TableGeneric key="card1"
                                  titles={["Numéro Facture", "Date Création", "Date Encaissement", "Total Service", "Total Marchandises", "Total Facture HT", "Montant TVA", "Total Facture HT2 (Frais de port)",  "Total Facture TTC", "Total Réglement"]}>
                        {this.injected.rootStore.statsStore.details
                            .map(invoice => {
                                return <TableStats invoice={invoice} key={invoice.id}/>
                            })
                        }
                    </TableGeneric>

                    <div>
                        <div> Total Services Calculé : <span
                            style={{fontWeight: "bold"}}> {this.statsStore.caDetails.mo} € </span></div>
                        {/*<div>  Total Marchandises Calculé :  <span style={{ fontWeight: "bold"}}> {this.statsStore.caDetails.notMo} € </span></div>*/}
                        <div> Total Marchandises Par déduction : <span
                            style={{fontWeight: "bold"}}> {this.statsStore.caDetails.othersAll} € </span></div>
                        <div> Total Fdp Calculé : <span
                            style={{fontWeight: "bold"}}>{this.statsStore.caDetails.fdp} € </span></div>
                        <div> Total Marchandises sans FDP : <span
                            style={{fontWeight: "bold"}}> {this.statsStore.caDetails.notMo} € </span></div>

                        <div className={"mt-2"}>
                            <div> Total Paypal : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.paypal} € </span></div>
                            <div> Total Espèce : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.espece} € </span></div>
                            <div> Total Virement : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.virement} € </span></div>
                            <div> Total CB : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.cb} € </span></div>
                        </div>

                        <div className={"mt-2"}>
                            <div> Total Facture : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.nb}  </span></div>
                            <div> Total Facture Paypal : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.paypalNb}  </span></div>
                            <div> Total Facture contenant  Espèce : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.especeNb} </span></div>
                            <div> Total Facture contenant Virement : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.virementNb} </span></div>
                            <div> Total Facture contenant CB : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.cbNb} </span></div>
                        </div>



                        <div className={"mt-2"}>
                            <div> Total Pec : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.pec}  </span></div>
                            <div>Total Pec Internet : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.pecInternet}  </span></div>
                            <div> Total Pec Magasin : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.pecMagasin} </span></div>
                        </div>

                        <div className={"mt-2"}>
                            <div> Total CA de ce mois: <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.total}  €</span></div>
                            <div> Total CA de l'année jusqu'à la fin de ce mois : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.totalCAUntil} € </span></div>
                            <div> TVA : <span
                                style={{fontWeight: "bold"}}> {this.statsStore.caDetails.tva}  </span></div>
                        </div>
                    </div>
                </Card>


            </Container>)
    }
})));

export default StatsDetails;
