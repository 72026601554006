import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {MenuItem} from "react-pro-sidebar";
import {Link} from "react-router-dom";

const MenuItemActive = inject("rootStore")(observer(class MenuItemActive extends Component {

    child;
    key;
    active;

        get injected() {
            return this.props
        }

        constructor(props) {
            super(props);

            this.state = {
                child: this.injected.child,
                active: this.injected.active ?? false,
                key: this.injected.key
            }
        }


    async componentDidUpdate(prevProps) {
        if (
            this.injected.child !== prevProps.child ||
            this.injected.key !== prevProps.key ||
            this.injected.active !== prevProps.active
        ) {
            await this.setState({
                child: this.injected.child,
                key: this.injected.key,
                active: this.injected.active
            })
        }
    }

        render() {
            return (
                <MenuItem
                    key={this.state.key}
                    active={this.state.active}
                    onClick={e => {
                        this.injected.callbackActive(false);
                        this.setState({
                            active: window.location.hash.includes(this.state.child.url)
                        })
                    }}
                    icon={<i className={this.state.child.icon} aria-hidden="true"/>}>{this.state.child.name} <Link to={this.state.child.url}/></MenuItem>
            );
        }
    }
));

export default observer(MenuItemActive);
