import {decorate, observable} from "mobx";
import Api from "../util/api";

export default class PageStore {

    pages = observable([]);

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    async getPages() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.pages.index();

            if (this.pages.length > 0) {
                this.pages.replace(authResponse.datas)
            } else {
                this.pages = authResponse.datas;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }


    async find(name) {
        return await this.api.pages.find(name);
    }

    async create(datas) {
        this.rootStore.savedDatasStore.setLoading(true)

        let authResponse = await this.api.pages.create(datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("Page ajoutée", "success");
        }
        this.rootStore.savedDatasStore.setLoading(false)

        return authResponse
    }

    async update(datas) {
        this.rootStore.savedDatasStore.setLoading(true)

        let authResponse = await this.api.pages.update(datas);

        if (authResponse?.status === "success") {
            this.rootStore.notificationStore.setNotification("Page modifiée", "success");
        }
        this.rootStore.savedDatasStore.setLoading(false)

        return authResponse
    }


    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.pages.delete(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Page # " + id + " supprimée", "success");
                this.getPages()
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(PageStore, {
    pages: observable
});

