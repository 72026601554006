import React from "react";
import {inject, observer} from "mobx-react";
import {Input} from "reactstrap";
import Button from "reactstrap/lib/Button";
import {withRouter} from "react-router-dom";
import getRoutes from "../getRoutes";

const GlobalSearch = inject("rootStore")(observer(class Search extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            value: '',
            innerRef: React.createRef()
        }
        this.search = this.search.bind(this);

    }

    componentDidMount() {
        setTimeout(() => {
            document.getElementById("searchBar").focus();
        }, 1)
    }


    async search(event) {
        event.preventDefault();
        await this.handleSearch()
    }

    enter(e) {
        if (e.key === 'Tab') {
            e.preventDefault()
            this.search(e)
        }
    }

    async handleSearch() {
        let authResponse = await this.injected.rootStore.savedDatasStore.getSearch(this.state.value)
        if (authResponse.datas.length === 1) {
            this.setSearch(authResponse.type, authResponse.datas[0])
            this.injected.onCallback()
        } else if (authResponse.datas.length > 1) {
            this.injected.history.replace("/searchs")
            this.injected.onClose()
        }
    }


    setSearch(type, search) {
        if (type === "stocks") {
            this.injected.history.replace({
                pathname: getRoutes("Modifier stock", search.id ),
                aboutProps: {
                    stock: search
                },
            });
        }

        if (type === "peca" || type === "pec") {
            this.injected.history.replace({
                pathname: "/priseencharge/update/" + search.id,
                aboutProps: {
                    isCommandeValid: true,
                    priseencharge: search
                },
            });
        }

        if (type === "commandes") {
            this.injected.history.replace({
                pathname: "/command/update/" + search.id,
                aboutProps: {
                    isCommandeValid: true,
                    command: search
                },
            });
        }

        if (type === "users") {
            this.injected.history.replace({
                pathname: getRoutes("Modifier Client", search.id)
            });
        }

        if (type === "devis") {
            this.injected.history.replace({
                pathname: "/devis/edit/" + search.id,
                aboutProps: {
                    isCommandeValid: true,
                    devis: search
                }
            });
        }

        if (type === "factures") {
            this.injected.history.replace({
                pathname: getRoutes("Gérer une facture", search.id),
                aboutProps: {
                    isCommandeValid: true,
                    invoice: search
                }
            });
        }
    }

    render() {
        return (
            <div>
                <form style={{display: "flex"}}>
                    <Input
                        id={"searchBar"}
                        className="mousetrap"
                        ref={this.state.innerRef}
                        onKeyDown={e => this.enter(e)}
                        value={this.state.value}
                        placeholder="Rechercher par code barre"
                        type="text"
                        onChange={e => this.setState({value: e.target.value})}/>
                    <Button className="mr-2 ml-2" color="success" onClick={event => this.search(event)}>Entrer</Button>
                </form>
            </div>
        )
    }
}));

export default withRouter(GlobalSearch);
