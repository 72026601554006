import {inject, observer} from "mobx-react";
import React from "react";
import {Col, Container} from "react-bootstrap";
import {Card, CardBody, CardHeader, Form, FormGroup, Input, Row, Table} from "reactstrap";
import i18n from "i18next";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import AutoCompleteCommande from "./AutoCompleteCommande";
import DetailCommande from "./DetailCommande";
import Button from "reactstrap/lib/Button";
import LineCommandeRow from "./LineCommandeRow";
import SuiviCommande from "./SuiviCommande";
import moment from "moment";
import {withRouter} from "react-router-dom";
import AutoCompleteInvoices from "../../../components/Akitatek/AutoCompleteInvoices/AutoCompleteInvoices";
import Tabs from "../../../components/Akitatek/Tabs/Tabs";
import styles from "../Stock/Stock.module.css";
import TableLotRow from "../Stock/TableLotRow";


const AddCommande = (inject("rootStore")(observer(class AddCommande extends React.Component {

    commandeStore = this.injected.rootStore.commandeStore;
    commande


    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Commandes", "Gestion des commandes", "administration.jpg", this.injected.rootStore.commandeStore)

        //Pass data throught the last page
        this.state = {
            isCommandeValid: this.injected.location.aboutProps?.isCommandeValid ?? false,
            commande: this.injected.location.aboutProps?.commande ?? {
                title: "",
                created_at: moment().format("YYYY-MM-DD"),
                status: "DRAFT",
                comment: "",
                fdp: "",
            },
            lots: this.injected.location.aboutProps?.commande?.lots ?? [],
            printers: [],
            printerId: "",
            casier: this.injected.location.aboutProps?.casier ?? "",
            stockage: this.injected.location.aboutProps?.casier?.stockage ?? "",

        }

        this.injected.rootStore.stockageCasierStore.getCasiers()
        this.injected.rootStore.stockageCasierStore.getStockageCasier()
    }

    //update component if the id change, or if there is a refresh
    async componentDidUpdate(prevProps) {
        if (this.injected.location !== prevProps.location) {
            await this.onRouteChanged()
        }

        if (this.state.commande === "" && this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }


    //check if the values are up to date
    async componentDidMount() {
        if (this.injected.match.params.id) {
            await this.onRouteChanged()
        }

        await this.injected.rootStore.prisenchargeStore.getPrinters()
        this.setState({
            printerId: this.injected.rootStore.prisenchargeStore.printers[0]?.id,

        })
    }

    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (id) {
            let authResponse = await this.injected.rootStore.commandeStore.findCommandById(id);

            if (authResponse?.status === "success") {
                this.setState({
                    commande: authResponse.datas,
                    isCommandeValid: true,
                    lots: authResponse.datas?.lots ?? []

                })
            }
        } else if (this.props.match.params.id === "add") {
            this.setState({
                isCommandeValid: false,
                commande: {
                    title: "",
                    created_at: moment().format("YYYY-MM-DD"),
                    status: "DRAFT",
                    comment: "",
                    fdp: ""
                },
                lots: this.injected.location.aboutProps?.commande?.lots ?? []
            })
        }
    }


    get injected() {
        return this.props;
    }

    async onUpdateCommande(event) {
        event.preventDefault();

        let authResponse = await this.injected.rootStore.commandeStore.updateCommande(this.state.commande, true)
        if (authResponse?.status === "success") {
            this.setState({
                commande: authResponse.datas,
                lots: authResponse.datas?.lots ?? []
            })
        }
    }


    async saveAll(event) {
        event.preventDefault();

        let authResponse = await this.injected.rootStore.commandeStore.saveAllCommande(this.state.commande)
        if (authResponse?.status === "success") {
            this.setState({
                commande: authResponse.datas,
                lots: authResponse.datas?.lots ?? []
            })
        }
    }

    async onSubmitFirstStep(event) {
        event.preventDefault();

        let authResponse = await this.injected.rootStore.commandeStore.saveCommande(this.state.commande)
        if (authResponse?.status === "success") {
            this.setState({
                isCommandeValid: true,
                commande: authResponse.datas,
                lots: authResponse.datas?.lots ?? []
            })

            this.injected.history.replace(authResponse.datas.id.toString())


        }
    }

    setTitle() {
        if (this.state.isCommandeValid) {
            return "Commande #" + this.state.commande?.id
        } else {
            return "Ajouter une commande"
        }
    }

    //Refresh commande
    callbackRefresh = () => {
        this.refreshList()
    };

    //Refresh commande
    callbackRefreshTotal = () => {
        this.calculateTotal()
    };

    //Refresh commande
    callbackRefreshLignes = (childData) => {
        let commande = this.state.commande
        commande.lignes = childData
        this.setState({commande: commande})

        this.calculateTotal()
    };

    //Refresh commande
    fournisseurCallback = (fournisseur) => {
        let commande = this.state.commande
        commande.fournisseur = fournisseur
        this.setState({commande: commande})
    };


    calculateTotal() {
        let totalht = this.state.commande.lignes.reduce((a, {totalht}) => parseFloat(a) + parseFloat(totalht), 0);
        let totalttc = this.state.commande.lignes.reduce((a, {totalttc}) => parseFloat(a) + parseFloat(totalttc), 0);

        if (this.state.commande.remise != null) {
            let remise = parseFloat(totalht) * (parseFloat(this.state.commande.remise) / parseFloat(100))
            totalht = parseFloat(totalht) - parseFloat(remise);
            totalttc = parseFloat(totalttc) - parseFloat(remise);
        }

        if (this.state.commande.fdp != null) {
            totalttc = parseFloat(totalttc) + parseFloat(this.state.commande.fdp)
        }

        let commande = this.state.commande
        commande.totalttc = totalttc.toFixed(2)
        commande.totalht = totalht.toFixed(2)
        this.setState({
            commande: commande
        })
    }


    setActionsStatus() {
        let title;
        let status;
        if (this.state.commande.status === "DRAFT") {
            status = "ORDERED"
            title = "Commandée"
        } else if (this.state.commande.status === "ORDERED") {
            status = "SEND"
            title = "Expédiée"
        } else if (this.state.commande.status === "SEND") {
            status = "RECEPTION"
            title = "Réceptionnée"
        } else if (this.state.commande.status === "RECEPTION") {
            status = "VALID"
            title = "Validée"
        }

        return <Button block
                       className={this.injected.rootStore.savedDatasStore.setColor(status)}
                       onClick={e => this.setStatus(e, status, title)}
                       color="warning">Marqué comme {title}</Button>

    }

    async setStatus(e, status, title) {
        e.preventDefault()

        let commande = this.state.commande
        commande.status = status

        let authResponse = await this.injected.rootStore.commandeStore.updateCommande(commande, true, "Commande # " + this.state.commande.id + " " + title)
        if (authResponse?.status === "success") {
            this.setState({
                commande: authResponse.datas
            })
        }
    }


    refreshList = () =>
        this.setState({refreshList: !this.state.refreshList})


    disabled() {
        return this.state.commande.status === "SEND" || this.state.commande.status === "RECEPTION" || this.state.commande.status === "VALID"
    }

    async toggleDelete(e) {
        e.preventDefault()

        await this.injected.rootStore.notificationStore.setWarning("Commande #" + this.state.commande?.id, event => this.onDelete(this.state.commande?.id), false)
    }

    async onDelete(id) {
        let authResponse = await this.injected.rootStore.commandeStore.delete(id)

        if (authResponse?.status === "success") {
            this.injected.history.push("/command");
        }
    }

    setForm() {
        return (
            <Form
                className="mb-4"
                id="form-add-invoice"
                name="form-add-invoice"
                style={{textAlign: "center"}}>
                <CardBody>
                    <Col>
                        <Row className="ml-2 mr-2" style={{justifyContent: "space-between"}}>
                            <DetailCommande
                                commande={this.state.commande}
                            />
                            <SuiviCommande
                                callbackRefresh={this.callbackRefresh}
                                commande={this.state.commande}
                            />
                            <AutoCompleteCommande
                                fournisseurCallback={this.fournisseurCallback}
                                commande={this.state.commande}
                            />
                        </Row>


                        {
                            this.injected.rootStore.errorStore.error.has && (
                                <div className="alert alert-danger" role="alert">
                                    {this.errorStore.error.field} est nécessaire.
                                </div>
                            )
                        }

                        {(this.state.isCommandeValid && !this.disabled()) && (
                            <AutoCompleteInvoices
                                id={this.state.commande?.id}
                                type={"commande"}
                                lignes={this.state.commande.lignes}
                                callbackRefreshLignes={this.callbackRefreshLignes}
                            />
                        )}

                        {this.state.isCommandeValid && (
                            <TableGeneric titles={[
                                <i className="fa fa-bars" aria-hidden="true"/>,
                                "Reférence",
                                "Designation",
                                i18n.t('page.admin.invoices.facturation.quantity'),
                                i18n.t('page.admin.invoices.facturation.tarifu'),
                                "Tarif U HT",
                                i18n.t('page.admin.invoices.facturation.remise'),
                                i18n.t('page.admin.invoices.facturation.tva'),
                                "Montant TVA",
                                i18n.t('page.admin.invoices.facturation.totalHT'),
                                i18n.t('page.admin.invoices.facturation.totalTTC'),
                                "Quantités reçues",
                                "Quantités en attente",
                                "Quantités à recevoir",
                                "Actions"
                            ]}>


                                {this.state.commande?.lignes?.map((lines, index) => {
                                    return (<LineCommandeRow
                                        key={index}
                                        id={lines.id}
                                        callbackRefreshTotal={this.callbackRefreshTotal}
                                        commande={this.state.commande}
                                        callbackRefreshLignes={this.callbackRefreshLignes}
                                        line={lines}
                                        index={index}
                                        refresh={this.state.refreshList}
                                    />);

                                })}


                            </TableGeneric>)
                        }
                    </Col>


                    {this.state.isCommandeValid && (
                        <Row className={"mr-2"} style={{justifyContent: "flex-end"}}>
                            <Card style={{width: "25em"}}>
                                <CardHeader>
                                    Total dû
                                </CardHeader>
                                <CardBody>

                                    <Table>
                                        <tbody>
                                        <td>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-deplacement"
                                            >
                                                Remise
                                            </label>

                                        </td>
                                        <td>
                                            <FormGroup className={"mt-2"} style={{display: "flex"}}>
                                                <Input
                                                    disabled={this.disabled()}
                                                    className="form-control-alternative"
                                                    type="number"
                                                    value={this.state.commande?.remise || 0}
                                                    onChange={async e => {
                                                        let commande = this.state.commande
                                                        commande.remise = e.target.value
                                                        await this.setState({commande: commande})
                                                        this.calculateTotal()
                                                    }}
                                                    min={0}
                                                />
                                                <span style={{margin: "auto"}}>% </span>
                                            </FormGroup>
                                        </td>
                                        <tr>
                                            <td>
                                                <label className="form-control-label">
                                                    Total HT hors frais de port
                                                </label>
                                            </td>
                                            <td>
                                                <FormGroup className={"mt-2"} style={{display: "flex"}}>
                                                    {this.state.commande?.totalht}
                                                    <span style={{margin: "auto"}}>€ </span>
                                                </FormGroup>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <label className="form-control-label">
                                                    Montant TVA
                                                </label>
                                            </td>
                                            <td>
                                                {this.state.commande?.montant_tva} €
                                            </td>
                                        </tr>

                                        <tr>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-deplacement"
                                                >
                                                    Frais de port
                                                </label>

                                            </td>
                                            <td>
                                                <FormGroup className={"mt-2"} style={{display: "flex"}}>
                                                    <Input
                                                        disabled={this.disabled()}
                                                        className="form-control-alternative"
                                                        type="number"
                                                        value={this.state.commande?.fdp || ''}
                                                        onChange={async e => {
                                                            let commande = this.state.commande
                                                            commande.fdp = e.target.value
                                                            await this.setState({commande: commande})
                                                            this.calculateTotal()
                                                        }}
                                                        min={0}
                                                    />
                                                    <span style={{margin: "auto"}}>€ </span>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="form-control-label">
                                                    Total TTC
                                                </label>
                                            </td>
                                            <td>
                                                <FormGroup className={"mt-2"} style={{display: "flex"}}>
                                                    {this.state.commande?.totalttc}
                                                    <span style={{margin: "auto"}}>€ </span>
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Row>
                    )
                    }

                    <Row>
                        {this.state.isCommandeValid && (
                            <Col>
                                <Button block color="success" className={"mr-2"}
                                        onClick={e => this.onUpdateCommande(e)}>Sauvegarder</Button>
                            </Col>

                        )}

                        {this.state.isCommandeValid && (this.state.commande.status !== "RECEPTION" && this.state.commande.status !== "VALID") && (
                            <Col>
                                {this.setActionsStatus()}
                            </Col>
                        )}

                        {this.state.isCommandeValid && this.state.commande.status === "RECEPTION" && (
                            <Col>
                                <Button block onClick={e => this.saveAll(e)} color="primary">Tout valider et mettre en
                                    stock</Button>
                            </Col>
                        )}

                        {!this.state.isCommandeValid && (
                            <Col>
                                <Button block color="success" onClick={e => this.onSubmitFirstStep(e)}>Valider</Button>
                            </Col>
                        )
                        }

                        {this.state.isCommandeValid && this.state.commande?.status !== "VALID" && (
                            <Col>
                                <Button block color={"danger"}
                                        type="submit"
                                        onClick={e => this.toggleDelete(e)}>Supprimer la commande</Button>
                            </Col>)
                        }
                    </Row>
                </CardBody>
            </Form>
        )
    }


    setImpression() {
        return (
            <div>
                <div className={[styles.headerTab]}>
                    <p> Classer & Imprimer les différents lots pour cette commande. </p>
                </div>
                <div style={{minHeight: "20em"}}>
                    <TableGeneric

                        key="cardLot"
                        titles={["Id", "Fournisseur", "Prix unitaire", "Tva", "Remise", "Quantité", i18n.t('page.admin.invoices.created'), "Type impression", "Actions"]}>
                        {this.state.lots
                            .map(lot => {
                                return <TableLotRow item={lot} key={lot.id}/>
                            })
                        }
                        {(this.state.lots.length === 0) && (
                            <tr>
                                <td className={"no_td"}
                                    colSpan={["Id", "Fournisseur", "Prix unitaire", "Tva", "Remise", "Quantité", i18n.t('page.admin.invoices.created'), "Type impression", "Actions"].length}>Pas
                                    de lots disponibles
                                </td>
                            </tr>
                        )}

                    </TableGeneric>
                </div>

                <Card>
                    <CardBody>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "self-end"}}>
                            {this.injected.rootStore.authStore.modules['print'] === 1 && (
                                <Button
                                    onClick={e => this.injected.rootStore.commandeStore.downloadPdf(e, this.state.commande?.id, this.state.printerId)}

                                    color="primary"
                                    style={{width: "25%"}}


                                >
                                    Imprimer tout
                                </Button>)}
                        </div>
                    </CardBody></Card>

            </div>)
    }

    onSendBackTab = (nb) => {
        this.setState({
            activeTab: nb
        })
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Row className="mb-3">
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader
                                    className={this.injected.rootStore.savedDatasStore.setColor(this.state.commande?.status)}
                                    style={{display: "flex", justifyContent: "center"}}>
                                    <h4 className="mb-0"> {this.setTitle()} </h4>
                                </CardHeader>
                                <Tabs
                                    onCallback={this.onSendBackTab}
                                    activeTab={this.state.activeTab} children={[this.setForm(), this.setImpression()]}
                                    titles={["General", "Impression"]}/>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
})));

export default withRouter(AddCommande);
