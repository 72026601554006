import {action, decorate, observable} from "mobx";
import Api from "../util/api";

export default class PriseEnChargeStore {

    priseEnChargeWait = observable([]);
    priseenchargesBoard = observable([]);
    datas = observable([]);
    printers = observable([]);
    allStatus = observable([]);
    statusname = "";


    filter = {
        limit: "15",
        search: "",
        page: "1",
        last_page: "1",
        name : "date_arrived",
        order: false,
        online: "",
        user_id: "",
        status: ["EN_COURS"]
    }

    defaultFilter  = {
        limit: "15",
        search: "",
        page: "1",
        last_page: "1",
        name : "date_arrived",
        order: false,
        online: "",
        user_id: "",
        status: ["EN_COURS"]
    }

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    getStatusname() {
        this.statusname = ""
        this.allStatus.forEach(element => {
            if (element.value === this.filter.status[0]) {
                this.statusname = element.label
            }}
        );
    }

    async getAllStatus()  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            this.allStatus = await this.api.priseencharges.getAllStatus();

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

        this.rootStore.savedDatasStore.setLoading(false)
    }

    async getPriseEnChargeBoard(search, board = false)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const defaultFilter  = {
                search: "",
                page: "1",
                last_page: "1",
                name : "updated_at",
                order: false,
                online: "",
                status: ["ARRIVED", 'REPARATION', 'WAITING_PAID' , 'TO_SEND_BACK' ,   'TO_GET_BACK_SUCESSFULLY',  'TO_GET_BACK', 'TESTS' ]
            }
            const authResponse = await this.api.priseencharges.getPriseEnCharge( board, defaultFilter);
            this.priseenchargesBoard = authResponse.data;
            this.priseEnChargeWait = authResponse.dataWait;

            this.rootStore.savedDatasStore.setLoading(false)
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getDatas(board = false)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.priseencharges.getPriseEnCharge( board, this.filter);

            if(this.datas.length > 0) {
                this.setPriseEnCharges(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }
            this.filter.page = authResponse.current_page;
            this.filter.last_page = authResponse.last_page;
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getPriseEnChargeForId(id)  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.priseencharges.find(id);
            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse;

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async savePriseEnCharge(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.priseencharges.savePriseEnCharge(datas);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Prise en charge #" + authResponse.datas.id + " créee",   "success");

                this.rootStore.prisenchargeInternetStore.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async changePriseEnCharge(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.priseencharges.changePriseEnCharge(datas);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Prise en charge acceptée",   "success");

                this.rootStore.prisenchargeInternetStore.getDatas()

            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async expediate(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.priseencharges.expediate(datas);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Prise en charge #" + authResponse.datas.id + " expédiée",   "success");

                this.rootStore.prisenchargeInternetStore.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updatePriseEnCharge(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.priseencharges.updatePriseEnCharge(datas);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Prise en charge #" + authResponse.datas.id + " modifiée",   "success");

                this.rootStore.prisenchargeInternetStore.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    setPriseEnCharges(datas) {
        this.datas.replace(datas)
    }

    setStatusOnline(status) {
        if(this.filter.status !== status) {
            this.filter.status = status
            this.filter.online = 1
            this.getDatas("", false)
        }
    }

    setStatus(status, statusEnum) {
        if(this.filter.status !== status) {
            this.filter.status = status
            this.statusEnum = statusEnum

        }
    }

    async saveUserMachine(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.priseencharges.saveUserMachine(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Machine ajoutée à la prise en charge", "success");

                this.rootStore.prisenchargeInternetStore.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateUserMachine(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.priseencharges.updateUserMachine(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Machine modifiée pour la prise en charge et l'utilisateur", "success");

                this.rootStore.prisenchargeInternetStore.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async deleteUserMachine(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.priseencharges.deleteUserMachine(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Machine supprimée pour la prise en charge seulement", "success");

                this.rootStore.prisenchargeInternetStore.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deletePresta(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.priseencharges.deletePresta(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Presta supprimée pour la prise en charge", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.priseencharges.delete(id);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( "Prise en charge # " + id  + " cloturée", "success");

                this.rootStore.prisenchargeInternetStore.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveNote(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.priseencharges.saveNote(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Note ajoutée à la prise en charge", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async savePresta(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.priseencharges.savePresta(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Presta ajoutée à la prise en charge", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveNotePec(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.priseencharges.saveNotePec(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( message ?? "Note ajoutée à la prise en charge", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async downloadPdf(e, id, download, printerId) {
        e.preventDefault();
        try {

        if (id && download === "all") {
            this.rootStore.savedDatasStore.setLoading(true)

            let authResponse = await this.api.priseencharges.createPdf(id, printerId);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( "Impression en cours", "success");
            }
            this.rootStore.savedDatasStore.setLoading(false)
        } else {
            this.rootStore.savedDatasStore.setLoading(true)

            let authResponse = await this.api.priseencharges.createPdfAppareil(id,download, printerId);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification( "Impression en cours", "success");
            }
            this.rootStore.savedDatasStore.setLoading(false)
        }
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async openPdf(e, idAppareil, idPec) {
        e.preventDefault();

        if (idAppareil && idPec) {
            const file = await this.getPdf(idAppareil, idPec);

            if (file) {
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }
        }
    }


    async openPdfPec(e, idPec) {
        e.preventDefault();

        if (idPec) {
            const file = await this.getPdfPec(idPec);

            if (file) {
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }
        }
    }


    async openPdfPecOnline(e, idPec) {
        e.preventDefault();

        if (idPec) {
            const file = await this.getPdfPecOnline(idPec);

            if (file) {
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }
        }
    }

    async getPdfPecOnline(idPec) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let pdf =  await this.api.priseencharges.createPdfPecOnline(idPec)
            this.rootStore.savedDatasStore.setLoading(false)
            return pdf;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getPdfPec(idPec) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let pdf =  await this.api.priseencharges.createPdfPec(idPec)
            this.rootStore.savedDatasStore.setLoading(false)
            return pdf;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getPdf(idAppareil, idPec) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let pdf =  await this.api.priseencharges.getPdf(idAppareil, idPec)
            this.rootStore.savedDatasStore.setLoading(false)
            return pdf;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async getPrinters() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
             let authResponse = await this.api.priseencharges.getPrinters()

            if(authResponse?.status === "success") {
                this.rootStore.savedDatasStore.setLoading(false)
                this.printers = authResponse.datas;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(PriseEnChargeStore, {
    priseenchargesBoard: observable,
    datas: observable,
    priseEnChargeWait: observable,
    delete:action,
    getDatas: action,
    printers: observable,
    filter: observable,
    allStatus: observable,
    statusname: observable
});

