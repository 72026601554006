import React from "react";
import ActionRow from "../../../views/admin/Invoices/ActionRow";
import ActionDevisRow from "../../../views/admin/Devis/ActionDevisRow";
import ActionUserRow from "../../../views/admin/Customers/ActionUserRow";
import ActionPriseEnChargeRow from "../../../views/admin/PriseEnCharge/ActionPriseEnChargeRow";
import ActionCommande from "../../../views/admin/Commande/ActionCommande";
import ActionPrinters from "../../../views/admin/Printer/ActionPrinters";
import ActionSpooler from "../../../views/admin/Printer/ActionSpooler";

function ActionType({type, data}) {
    if (type === "invoice") {
        return <ActionRow data={data} key={data.id}/>
    }
    if (type === "devis") {

        return <ActionDevisRow datas={data} key={data.id}/>
    }

    if (type === "customer") {
        return <ActionUserRow data={data} key={data.id}/>
    }

    if (type === "pec" || type === "pecinternet") {
        return <ActionPriseEnChargeRow data={data} key={data.id}/>
    }

    if (type === "cmd") {
        return <ActionCommande data={data} key={data.id}/>
    }

    if (type === "printers") {
        return <ActionPrinters datas={data} key={data.id}/>
    }

    if (type === "spoolers") {
        return <ActionSpooler datas={data} key={data.id}/>
    }
    return <React.Fragment/>
}

export default ActionType;
