/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {inject, observer} from "mobx-react";


const OrderTh = (inject("rootStore")(observer(class TableGeneric extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            order: false
        }

        this.onChange = this.onChange.bind(this)
    }

    onChange(name) {
        this.setState({order: !this.state.order})
        this.injected.onFilter(name, !this.state.order)
    }

    render() {
       let  {title, filters, index} = this.props
        return (
            <>
                <th scope="col" key={title.title ? title.title : title}>
                    {title.filter && (<i className="clicker mr-2 fa fa-sort"
                                           onClick={e => this.onChange(title.filter)}/>)}
                    {filters[index] && (<i className="clicker mr-2 fa fa-sort"
                                              onClick={e => this.onChange(filters[index])}/>)}
                    {title.title ? title.title : title}
                </th>
            </>
        );
    }
})));

export default OrderTh;
