import React from "react";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import {Button, UncontrolledTooltip} from "reactstrap";
import moment from "moment";
import PictureHidden from "../../../components/Akitatek/PictureHidden";

const TableBlogRow = inject("rootStore")(observer(class TableBlogRow extends React.Component {

    blog;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            blog: this.injected.blog,
        };

        moment.locale('fr');

        this.toggleDanger = this.toggleDanger.bind(this);
        this.toggleWarning = this.toggleWarning.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.blog !== prevProps.blog
        ) {
            this.setState({
                blog: this.injected.blog
            })
        }
    }


    toggleDanger(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning("l'article #" + this.state.blog?.id, event => this.onDelete(this.state.blog?.id))
    }


    async onDelete(deleteId) {
        await this.injected.rootStore.blogStore.delete(deleteId);
    }


    toggleWarning() {
        this.injected.rootStore.notificationStore.setQuestion("Voulez vous changer le status pour " + this.setStatus(), "status " + this.setStatus(), event => this.changeStatus(this.state.blog.status?.title, this.state.blog.id))
    }


    render() {
        return (
            <>
                <tr>
                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                            <Link to={{pathname: "/blogs/update/" + this.state.blog.id}}>
                                 #{this.state.blog.id}
                            </Link>
                        </span>
                    </th>
                    <td className="text-center">
                        <PictureHidden assetName={this.state.blog?.asset?.filename ?? ""} height={"8em"} width={"8em"}/>
                    </td>
                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {this.state.blog.title}
                        </span>
                    </td>
                    <td className="text-center">
                        <span className="mb-0 text-sm">
                             {this.state.blog.author != null ? this.state.blog.author?.fullname : ""}
                        </span>
                    </td>
                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            <span>{this.state.blog.description}</span>

                           <div className={"mt-2"}>{this.state.blog?.tags?.map(tag => {
                               return <span key={tag.id} className="font-weight-bold">{"#" + tag.nom} </span>

                           })}</div>
                        </span>
                    </td>


                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {moment(this.state.blog?.created_at).format("l")}
                        </span>
                    </td>

                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {this.state.blog.isVisible ? <span className="badge badge-pill badge-success">Publié</span>
                                : <span className="badge badge-pill badge-warning">Non publié</span>
                            }
                        </span>
                    </td>
                    <td className="text-center">
                        <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                            <Link to={{
                                pathname: "/blogs/update/" + this.state.blog.id,
                                aboutProps: {
                                    data: this.state.blog
                                }
                            }}>
                                <Button className={"ml-2 mr-2"} color="primary" type="button"
                                        id={"UncontrolledTooltipExampleVoir" + this.state.blog.id}>
                                    <i className="fa fa-eye" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top"
                                                         target={"UncontrolledTooltipExampleVoir" + this.state.blog.id}>
                                        Voir
                                    </UncontrolledTooltip>
                                </Button>
                            </Link>

                            <Button color="danger" type="button" className="mr-1" onClick={this.toggleDanger}
                                    id={"UncontrolledTooltipExampleSelection" + this.state.blog.id}>
                                <i className="fa fa-minus" aria-hidden="true"/>
                                <UncontrolledTooltip placement="top"
                                                     target={"UncontrolledTooltipExampleSelection" + this.state.blog.id}>
                                    Supprimer
                                </UncontrolledTooltip>
                            </Button>
                        </div>
                    </td>
                </tr>
            </>
        );
    }

}));

export default TableBlogRow;
