import React from "react";
import {CardHeader, Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";
import Card from "reactstrap/lib/Card";
import i18n from "i18next";
import Button from "reactstrap/lib/Button";
import configuration from "../../../configuration/configuration";


const Printer = (inject("rootStore")(observer(class Printer extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Impression", "Gestion des paramètres d'impression", "default.jpg")
    }

    get injected() {
        return this.props;
    }


    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericTableAffichage api={this.injected.rootStore.printerStore}
                                           user={false}
                                           icon={<i className="fa fa-print mr-2" aria-hidden="true"/>}
                                           name={"Mes impressions"}
                                           type="printers"/>

                    <GenericTableAffichage api={this.injected.rootStore.spoolerStore}
                                           user={false}
                                           icon={<i className="fa fa-refresh mr-2" aria-hidden="true"/>}
                                           name={"Mon spooler"}
                                           type="spoolers"/>
                </Container>
            </>
        );
    }
})));

export default Printer;
