import React from "react";
import {inject, observer} from "mobx-react";
import Button from "react-bootstrap/Button";
import {Input} from "reactstrap";
import {Link} from "react-router-dom";
import ModalAkitatek from "../../../components/Akitatek/Modal/Modal";
import Casier from "../Casier/Casier";
import getRoutes from "../../../components/Akitatek/getRoutes";

const PriseEnChargeMachineRow = inject("rootStore")(observer(class PriseEnChargeMachineRow extends React.Component {

    appareil;
    id;
    lignes;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            index: this.injected.index,
            lignes: this.injected.lignes,
            accessoires: this.injected.appareil?.pivot?.accessoires,
            id: this.injected.id,
            appareil: this.injected.appareil,
            code: this.injected.appareil?.code,
            defauts: this.injected.appareil?.defauts,
            serial: this.injected.appareil?.serial,
            user: this.injected.user,
            casier: this.injected.appareil?.pivot?.casier,
            stockage: this.injected.appareil?.pivot?.casier?.stockage
        };

        this.toggleDanger = this.toggleDanger.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.appareil !== prevProps.appareil ||
            this.injected.id !== prevProps.id ||
            this.injected.index !== prevProps.index ||
            this.injected.user !== prevProps.user
        ) {
            this.setState({
                index: this.injected.index,
                accessoires: this.injected.appareil?.pivot?.accessoires,
                id: this.injected.id,
                appareil: this.injected.appareil,
                code: this.injected.appareil?.code,
                defauts: this.injected.appareil?.defauts,
                user: this.injected.user,
                casier: this.injected.appareil?.pivot?.casier,
                stockage: this.injected.appareil?.pivot?.casier?.stockage
            })
        }

        if (
            this.injected.lignes !== prevProps.lignes
        ) {
            this.setState({
                lignes: this.injected.lignes
            })
        }
    }

    toggleDanger() {
        this.injected.rootStore.notificationStore.setWarning("Pièce #" + this.state.appareil?.id, event => this.onDelete(this.state.appareil?.id, this.state.id))
    }

    async onDelete(appareilId, priseEnChargeId) {
        let data = {
            user_appareil_id: appareilId,
            prise_en_charge_id: priseEnChargeId
        }
        let authResponse = await this.injected.rootStore.prisenchargeStore.deleteUserMachine(data);

        if (authResponse?.status === "success") {
            this.injected.callbackRefreshLignes(authResponse.datas)
        }
    }


    async onUpdate(e) {
        e.preventDefault()

        let authResponse

        let data = {
            accessoires: this.state.accessoires ?? "",
            defauts: this.state.defauts ?? "",
            serial: this.state.serial ?? "",
            user_appareil_id: this.state.appareil?.id ?? "",
            prise_en_charge_id: this.state.id,
            code: this.state.code,
            casier_id: this.state.casier?.id

        }

        authResponse = await this.injected.rootStore.prisenchargeStore.updateUserMachine(data)

        if (authResponse?.status === "success") {
            this.injected.callbackRefreshLignes(authResponse.datas)
        }
    }

    disabled() {
        return this.state.disabled
    }

    onCloseClearStockage = () => {
        this.setState({
            addStockage: false,
            activeTab: "2"
        })
    };

    onCloseStockage = (childData) => {
        this.setState({
            addStockage: false,
            activeTab: "2",
            casier: childData
        })
    };

    enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            this.onUpdate(e, true)
        }
    }

    render() {
        return (
            <>
                <tr>
                    <th scope="row" className="text-center">
                      <span className="mb-0 text-sm">
                           <Link to={{
                               pathname: getRoutes("Modifier Client", this.state.user?.id),
                               aboutProps: {
                                   user: this.state.user,
                                   activeTab: "2"
                               }
                           }}>
                                #{this.state.index}
                               </Link>
                        </span>
                    </th>

                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                            <Link to={{
                                pathname: "/marques/update/" + this.state.appareil?.appareil?.marque?.id,
                                aboutProps: {
                                    stock: this.state.appareil.appareil
                                }
                            }}>
                                {this.state.appareil?.appareil?.marque?.nom}
                            </Link>
                        </span>
                    </th>
                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                            <Link to={{
                                pathname: getRoutes("Afficher un Produit", this.state.appareil?.id),
                                aboutProps: {
                                    stock: this.state.appareil.appareil
                                }
                            }}>
                                {this.state.appareil?.appareil?.nom}

                            </Link>
                        </span>
                    </th>
                    <th scope="row" className="text-center">
                        <Input
                            onKeyPress={e => this.enter(e)}
                            className="form-control-alternative"
                            type={"text"}
                            placeholder={"Serial"}
                            value={this.state.serial}
                            disabled={this.disabled()}
                            onChange={async e => {
                                await this.setState({serial: e.target.value})
                            }}
                        />
                    </th>
                    <th scope="row" className="text-center">
                        <Input
                            onKeyPress={e => this.enter(e)}
                            className="form-control-alternative"
                            type={"text"}
                            placeholder={"Code"}
                            value={this.state.code}
                            disabled={this.disabled()}
                            onChange={async e => {
                                await this.setState({code: e.target.value})
                            }}
                        />
                    </th>
                    <th scope="row" className="text-center">
                        <Input
                            onKeyPress={e => this.enter(e)}
                            className="form-control-alternative"
                            type={"textarea"}
                            placeholder={"Defauts..."}
                            value={this.state.defauts}
                            disabled={this.disabled()}
                            onChange={async e => {
                                await this.setState({defauts: e.target.value})
                            }}
                        />
                    </th>

                    <th scope="row" className="text-center">
                        <Input
                            onKeyPress={e => this.enter(e)}
                            className="form-control-alternative"
                            type={"textarea"}
                            placeholder={"Defauts..."}
                            value={this.state.accessoires}
                            disabled={this.disabled()}
                            onChange={async e => {
                                await this.setState({accessoires: e.target.value})
                            }}
                        />
                    </th>
                    <th>
                        {this.state.casier && (
                            <div className={"mr-2"}> {this.state.casier.name} - {this.state.casier.stockage.name}</div>
                        )}
                        <Button
                            onClick={e => this.setState({addStockage: true})}
                            variant="warning" size="sm" type="button" className="mr-1">
                            Choisir un stockage
                        </Button>
                    </th>
                    <td>
                        <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                            <Button
                                disabled={this.disabled()}
                                onClick={(e) => {
                                    this.onUpdate(e, true);
                                }} variant="success" size="sm" type="button" className="mr-1">
                                <i className="fa fa-check"/>
                            </Button>

                            <Button
                                disabled={this.disabled()}
                                onClick={(e) => {
                                    this.toggleDanger()
                                }} variant="danger" size="sm" type="button" className="mr-1">
                                <i className="fa fa-minus"/>
                            </Button>
                        </div>
                    </td>
                </tr>

                <ModalAkitatek title={"Choisir un stockage"} show={this.state.addStockage}
                               children={<Casier onSelection={false} onCallback={this.onCloseStockage}></Casier>}
                               onCallback={this.onCloseClearStockage}/>
            </>
        );
    }

}));

export default PriseEnChargeMachineRow;
