import React from "react";
import {inject, observer} from "mobx-react";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import TableGeneric from "../../../../components/Akitatek/Headers/TableGeneric";
import PresentationRow from "./PresentationRow";
import {SRLWrapper} from "simple-react-lightbox";

const Presentation = inject("rootStore")(observer(class Presentation extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.homeStore.getPresentations();
        this.injected.rootStore.savedDatasStore.setHeader("Présentations", "Modifier la présentation de votre entreprise sur la page d'accueil", "business.jpg")
    }


    render() {
        return (
            <>
                <div>
                    <Container className="mt-2" fluid>
                        <Card className="shadow">
                            <CardHeader
                                style={{display: "flex", justifyContent: "space-between"}}>
                                <h4 className="mb-0">
                                    <i className="fa fa-file-pdf-o mr-2" aria-hidden="true"/>
                                    Présentations
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <SRLWrapper>
                                    <TableGeneric key="card1"
                                                  titles={this.injected.rootStore.authStore.options.homeOrganisation === "soros" ? ["Ordre", "image", "titre", "subtitle",  "status", "Actions"] : ["Ordre", "image", "titre",  "status", "Actions"]}>

                                        {this.injected.rootStore.homeStore.presentations
                                            .map(invoice => {
                                                return <PresentationRow data={invoice} key={invoice.id}/>
                                            })
                                        }
                                    </TableGeneric>
                                </SRLWrapper>
                            </CardBody>

                        </Card>
                    </Container>
                </div>
            </>
        );
    }
}));

export default Presentation;
