import React from 'react';
import {inject, observer} from "mobx-react";
import 'moment/locale/fr';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Button} from "reactstrap";
import Form from "reactstrap/lib/Form";
import htmlToDraft from 'html-to-draftjs';
import EditorWord from "../../../../components/Akitatek/EditorWord";

const TimelineComponent = inject("rootStore")(observer(class TimelineComponent extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props)
        this.state = {
            id: this.injected.id,
            comment: EditorState.createEmpty(),
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.comment !== prevProps.comment || this.injected.id !== prevProps.id
        ) {
            let editorState = EditorState.createEmpty()

            if (this.injected.comment !== null) {
                let blocksFromHTML = htmlToDraft(this.injected.comment);
                const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
                editorState = EditorState.createWithContent(contentState);
            }

            this.setState({comment: editorState, id: this.injected.id});
        }
    }

    async componentDidMount() {
        let editorState = EditorState.createEmpty()

        if (this.injected.comment !== null) {
            let blocksFromHTML = htmlToDraft(this.injected.comment);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            editorState = EditorState.createWithContent(contentState);
        }
    }


    async enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            await this.onSubmit(e)
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            comment:
            editorState,
        });
    };

    async onUpdate(e) {
        e.preventDefault();

        let data = {
            comment: draftToHtml(convertToRaw(this.state.comment.getCurrentContent())),
            id: this.state.id
        }

        let authResponse = await this.injected.rootStore.prisenchargeStore.updatePriseEnCharge(data)

        if (authResponse?.status === "success") {
            this.injected.callbackPriseEnCharge(authResponse.datas, "3")
        }
    }

    render() {
        return (

            <Form>
                <EditorWord editorState={this.state.comment} onEditorStateChange={this.onEditorStateChange}/>
                <Button color={"success"} style={{margin: "auto", display: "flex"}}
                        onClick={e => this.onUpdate(e)}>Sauvegarder</Button>

            </Form>

        );
    }
}));

export default TimelineComponent

