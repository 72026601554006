import React from "react";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, Button, Table} from "reactstrap";
import Input from "reactstrap/lib/Input";

const AddPieces = inject("rootStore") (observer( class AddPieces extends React.Component {


    id
    lignes
    titles = ["Produit", "Reference", "Quantite", "Prix", "Actions"];

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            id: this.injected.id,
            prix_unitaire: 0,
            produit: "",
            quantity: 1,
            reference_produit: "",
            lignes: this.injected.lignes,
            search: "",
            suggestion: "",
            isOn: false
        }

        this.injected.rootStore.commandeStore.getStocks()
        this.search = this.search.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.lignes !== prevProps.lignes
        ) {
            this.setState({
                lignes: this.injected.lignes,
                id: this.injected.id
            })
        }
    }



    setSuggestions() {
        if(this.state.isOn) {
                if (this.injected.rootStore.commandeStore.stocks.length) {
                    return (
                        <div className="list-group">
                            {this.injected.rootStore.commandeStore.stocks.map((suggestion, index) => {
                                return (
                                    <a href="/#" className="list-group-item list-group-item-action" key={index}
                                       onClick={event => {
                                           event.preventDefault();
                                           this.selection(event, suggestion)
                                       }}>
                                        {"#" + suggestion.id + " " + suggestion.nom}
                                    </a>
                                );
                            })}
                        </div>
                    );
                }
        }
    }


    search(event) {
        event.preventDefault();

        this.setState( {
            search: event.target.value,
            suggestion: ""
        })
        this.injected.rootStore.commandeStore.filter.search = event.target.value
        this.injected.rootStore.commandeStore.getStocks()
    }


    selection(event, suggestion) {
        this.setState( {
            reference_produit: suggestion?.ref ?? "product",
            prix_unitaire: suggestion?.prix_vente ?? 0,
            search: suggestion?.nom ?? "",
            isOn: false,
            suggestion: suggestion ?? "",
        })
    }

    onClearSelection() {
        this.setState( {
            reference_produit: "",
            prix_unitaire:  0,
            search: "",
            quantity: 1,
            isOn: false,
            suggestion: ""
        })
    }


    setTitle() {
            return (
                <div  style={{display: "flex", alignItems:"center", fontSize: "1.2em"}}>
                    <div  className="mr-4 ml-2">  Ajouter Produit </div>
                </div>
            )
    }

    async onSubmit(event) {
        event.preventDefault()

        let authResponse

        let data = {
            reference_produit: this.state.reference_produit,
            prix_unitaire: this.state.prix_unitaire ?? 0,
            produit_id :  this.state.suggestion?.id ?? "",
            id: this.state.id,
            quantity: this.state.quantity
        }

       authResponse = await this.injected.rootStore.annuaireStore.prestationStore.savePrestaLine(data)

        if(authResponse?.status  === "success") {
            this.onClearSelection(event, null)
            this.injected.callbackRefreshLignes(authResponse.datas)
        }

    }

    setCallbackStock = (product) => {
        this.setState({
            suggestion: product,
            search: product?.nom ?? "",
            modeSearch: !this.state.modeSearch,
            reference_produit:product?.ref ,
            prix_unitaire: product?.prix_unitaire ,
        })
    };


    async enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            await this.onSubmit(e)
        }
    }


    disabled() {
        return  !this.state.suggestion
    }

    onSuggestion() {
      this.setState({ isOn:  !this.state.isOn})
    }

    setMode() {
            return(
                <Table style={{"marginBottom": "0em"}}>
                    <thead>
                    <tr className="text-center" >
                        {
                            this.titles.map(title => {
                                return <th scope="col" key={title}>{title}</th>

                            })
                        }
                    </tr>
                    </thead>
                    <tbody >
                    <tr>

                        <td className="th-actions td-start">
                            <Input
                                onKeyPress={e => this.enter(e)}
                                disabled={true}
                                value={this.state.reference_produit}
                                placeholder={"Reference"}
                                type="text"  onChange={e => this.setState({ reference_produit:  e.target.value})}/>
                        </td>

                        <td className="td-start">
                            <Input
                                onKeyPress={e=> this.enter(e)}
                                onClick={e => this.onSuggestion()}
                                value={this.state.search}
                                placeholder={"Designation"}
                                type="text" onChange={(event) => this.search(event)}/>
                            {this.setSuggestions()}
                        </td>

                        <td className="th-number td-start">
                            <Input
                                placeholder={"Quantité"}
                                onKeyPress={e=> this.enter(e)}
                                value={this.state.quantity}
                                className="mr-2"
                                type="number" onChange={e => this.setState({ quantity:  e.target.value})}>
                            </Input>
                        </td>

                        <td className="th-price td-start">
                            <Input
                                placeholder={"Prix unitaire"}
                                onKeyPress={e=> this.enter(e)}
                                value={this.state.prix_unitaire}
                                type="number" onChange={e => this.setState({ prix_unitaire:  e.target.value})}>
                            </Input>
                        </td>

                        <td className="td-start">
                            <Button
                                disabled={this.disabled()}
                                color={"success"}
                                onClick={e=> this.onSubmit(e)}>
                                <i className={"fa fa-plus"} />
                            </Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            )
    }

    render() {
        return (
            <Card>
                <CardHeader style={{padding: "0.2em"}} className={"bg-primary"} >
                    {this.setTitle()}
                </CardHeader>
                {this.setMode()}
            </Card>

        )
    }
}));

export default AddPieces;
