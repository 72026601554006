import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Container, Form, Input, Row,} from "reactstrap";
import {Col} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Tabs from "../../../components/Akitatek/Tabs/Tabs";
import NotesRich from "./Notes/NotesRich";
import Historique from "./Historique";
import getRoutes from "../../../components/Akitatek/getRoutes";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";
import MachinePec from "./MachinePec";
import PrestationPec from "../../Prestations/Prestations";
import TimelinePec from "./TimelinePec";
import ErrorBoundary from "../../../components/Akitatek/Error/ErrorBounday";
import Dropdown from "react-bootstrap/Dropdown";
import PrintImpression from "../Printer/PrintImpression";
import UserInformation from "../../../components/Akitatek/AdressCustomer";
import moment from "moment";

const UpdatePriseEnCharge = inject("rootStore")(observer(class AddPriseEnCharge extends React.Component {

    errorStore = this.injected.rootStore.errorStore;
    priseencharge;

    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);

        moment.locale('fr');

        this.state = {
            machineClient: "",
            priseencharge: this.injected.location.aboutProps?.priseencharge ?? {
                title: "",
                user: {appareils: []},
                appareils: [],
                status: "DRAFT",
                factures: [],
                pecLignes: [],
                devis: [],
                comment: "",
                activeTab: "1",
                online: "0"
            }
        };

        if (this.injected.location.aboutProps?.online === 1) {
            this.injected.rootStore.savedDatasStore.setHeader("Prises En Charge Internet", "Gérer les Prises En Charge", "facture.jpg", this.injected.rootStore.prisenchargeStore)
        } else {
            this.injected.rootStore.savedDatasStore.setHeader("Prises En Charge", "Gérer les Prises En Charge", "tools.jpg", this.injected.rootStore.prisenchargeStore)
        }
        this.acceptedPec = this.acceptedPec.bind(this);
        this.refusedPec = this.refusedPec.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.createDevis = this.createDevis.bind(this);
        this.expediate = this.expediate.bind(this);
        this.arrivedPec = this.arrivedPec.bind(this);
    }

    //update component if the id change, or if there is a refresh
    async componentDidUpdate(prevProps) {
        if (this.injected.location !== prevProps.location) {
            await this.onRouteChanged()
        }
        if (this.state.priseencharge === "" && this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }

    //check if the values are up to date
    async componentDidMount() {
        if (this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }

    async expediate(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.priseencharge.id, e => this.onExpedition(this.state.priseencharge.id), this.state.priseencharge.title, "Je valide l'expédition de la pec #" + this.state.priseencharge.id)
    }


    async onExpedition(id) {
        var datas = {
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.expediate(datas)
    }

    async arrivedPec(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.priseencharge.id, e => this.onArrived(this.state.priseencharge.id), this.state.priseencharge.title, "Je valide la réception de la pec #" + this.state.priseencharge.id)
    }


    async onArrived(id) {
        var datas = {
            status: "ARRIVED",
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.changePriseEnCharge(datas)
        await this.onReload(id)
    }

    async acceptedPec(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.priseencharge.id, e => this.onValidate(this.state.priseencharge.id), this.state.priseencharge.title, "Je valide la demande #" + this.state.priseencharge.id, "#4dbd74")
    }

    async onValidate(id) {
        var datas = {
            status: "VALIDATE",
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.changePriseEnCharge(datas)
        await this.onReload(id)

    }


    async refusedPec(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.priseencharge.id, e => this.onRemove(this.state.priseencharge.id), this.state.priseencharge.title, "Je refuse la demande #" + this.state.priseencharge.id)
    }

    async onRemove(id) {
        var datas = {
            status: "REFUSED",
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.changePriseEnCharge(datas)
        await this.onReload(id)
    }


    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (id) {
            await this.onReload(id)
        }
    }

    async onReload(id) {
        let priseencharge = await this.injected.rootStore.prisenchargeStore.getPriseEnChargeForId(id);

        if (priseencharge) {
            if (priseencharge.description === null) {
                priseencharge.description = ""
            }
            this.setState({
                priseencharge: priseencharge
            })
        }
    }

    async onUpdate(e) {
        e.preventDefault();

        let data = {
            user_id: this.state.priseencharge.user_id,
            title: this.state.priseencharge.title,
            status: this.state.priseencharge.status,
            priority: this.state.priseencharge.priority,
            description: this.state.priseencharge.description,
            id: this.state.priseencharge.id,
            suivi_text: this.state.priseencharge.suivi_text,
            online: this.state.priseencharge.online
        }

        let authResponse = await this.injected.rootStore.prisenchargeStore.updatePriseEnCharge(data)

        if (authResponse?.status === "success") {
            this.setState({
                priseencharge: authResponse.datas
            })
        }
    }

    createButtonSave(title, color, onclickFunc) {
        return (
            <Button color={color}
                    type="submit"
                    onClick={onclickFunc}>{title}</Button>)
    }

    //Refresh commande
    callbackUser = (childData) => {
        let priseencharge = Object.assign({}, this.state.priseencharge);
        priseencharge.user = childData
        priseencharge.user_id = childData?.id
        this.setState({priseencharge: priseencharge})
    };


    form() {
        return (

            <div>
                <Form
                    className="mb-4"
                    id="form-add-priseencharge"
                    name="form-add-priseencharge"
                    style={{textAlign: "center"}}
                >
                    <CardBody>
                        <Col>
                            <Input
                                style={{textAlign: "center"}}
                                className={"mb-3"}
                                placeholder="Titre (description courte)"
                                id="input-title"
                                type="text"
                                value={this.state.priseencharge?.title}
                                onChange={(e) => {
                                    let priseencharge = Object.assign({}, this.state.priseencharge);
                                    priseencharge.title = e.target.value
                                    this.setState({priseencharge: priseencharge})
                                }}
                            />


                            <Row className="ml-2 mr-2" style={{justifyContent: "space-between"}}>
                                <Col>
                                    {this.state.priseencharge?.online.toString() === "1" && (
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="exampleEmail">Description complémentaire par le
                                                    client</Label>
                                            </Col>
                                            <Col md={4}>
                                                {this.state.priseencharge?.description_client}
                                            </Col>
                                        </Row>)}

                                    <Row form>
                                        <Col md={2}>
                                            <Label for="exampleEmail">Prestations</Label>
                                        </Col>
                                        <Col md={4}>
                                            <ul>
                                                {this.state.priseencharge?.prestations?.map(presta => {
                                                    return <Link
                                                        key={presta.id}
                                                        to={{
                                                            pathname: "/prestations/edit/" + presta.id,
                                                            aboutProps: {
                                                                product: presta
                                                            }
                                                        }}>
                                                        <li className={"mt-2"}>  {presta?.title} - {presta?.global_price_mo} €</li>
                                                    </Link>

                                                })}
                                            </ul>
                                        </Col>
                                    </Row>

                                    <Row form>
                                        <Col md={2}>
                                            <Label for="exampleEmail">Description longue par le technicien</Label>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Input
                                                    value={this.state.priseencharge?.description}
                                                    type="textarea"
                                                    placeholder="Description..."
                                                    onChange={(e) => {
                                                        let priseencharge = Object.assign({}, this.state.priseencharge);
                                                        priseencharge.description = e.target.value
                                                        this.setState({
                                                            priseencharge: priseencharge
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/*{this.state.priseencharge.online !== 1 && (*/}
                                    {/*    <Row form>*/}
                                    {/*        <Col md={2}>*/}
                                    {/*            <Label for="exampleEmail">Priorité</Label>*/}
                                    {/*        </Col>*/}
                                    {/*        <Col md={4}>*/}
                                    {/*            <FormGroup>*/}
                                    {/*                <Input type="select"*/}
                                    {/*                       name="select"*/}
                                    {/*                       value={this.state.priseencharge?.priority}*/}
                                    {/*                       onChange={(e) => {*/}
                                    {/*                           e.preventDefault();*/}
                                    {/*                           let priseencharge = this.state.priseencharge*/}
                                    {/*                           priseencharge.priority = e.target.value*/}
                                    {/*                           this.setState({*/}
                                    {/*                               priseencharge: priseencharge,*/}
                                    {/*                               activeTab: "1"*/}
                                    {/*                           })*/}
                                    {/*                       }}*/}
                                    {/*                >*/}
                                    {/*                    <option value={1}>HAUTE</option>*/}
                                    {/*                    <option value={2}>ELEVEE</option>*/}
                                    {/*                    <option value={3}>MOYENNE</option>*/}
                                    {/*                    <option value={4}>BASSE</option>*/}
                                    {/*                </Input>*/}
                                    {/*            </FormGroup>*/}
                                    {/*        </Col>*/}
                                    {/*    </Row>)}*/}
                                    <Row form>
                                        <Col md={2}>
                                            <Label for="exampleEmail">Option</Label>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <FormGroup>
                                                    <Input type="select"
                                                           name="select"
                                                           value={this.state.priseencharge.online}
                                                           onChange={(e) => {
                                                               e.preventDefault();
                                                               let priseencharge = Object.assign({}, this.state.priseencharge);
                                                               priseencharge.online = e.target.value
                                                               this.setState({
                                                                   priseencharge: priseencharge
                                                               })
                                                           }}

                                                    >
                                                        <option value={"0"}>Magasin</option>
                                                        <option value={"1"}>Internet</option>
                                                    </Input>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={2}>
                                            <Label for="exampleEmail">Status</Label>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <FormGroup>
                                                    <ErrorBoundary>
                                                        <Input type="select"
                                                               name="select"
                                                               value={this.state.priseencharge?.status}
                                                               onChange={(e) => {
                                                                   e.preventDefault();
                                                                   let priseencharge = Object.assign({}, this.state.priseencharge);
                                                                   priseencharge.status = e.target.value
                                                                   this.setState({
                                                                       priseencharge: priseencharge
                                                                   })
                                                               }}

                                                        >
                                                            <option value={"DRAFT"}>BROUILLON</option>
                                                            <option value={"ARRIVED"}>ARRIVEE</option>
                                                            <option value={"DIAGNOSTIC"}>DIAGNOSTIC</option>

                                                            {this.state.priseencharge.online.toString() === "1" && (
                                                                <option value={"VALIDATE"}>VALIDER</option>
                                                            )}

                                                            {this.state.priseencharge.online.toString() === "1" && (
                                                                <option value={"SUBMIT"}>SOUMIS</option>
                                                            )}

                                                            {this.state.priseencharge.online.toString() === "1" && (
                                                                <option value={"SUBMIT"}>REFUSER</option>
                                                            )}
                                                            <option value={"WAITING_DEVIS"}>EN ATTENTE DE DEVIS</option>
                                                            <option value={"WAITING_REPAIR"}>EN ATTENTE DE REPARATION
                                                            </option>
                                                            <option value={"WAITING_PAID"}>EN ATTENTE DU PAIEMENT
                                                            </option>
                                                            <option value={"WAITING_CLIENT"}>EN ATTENTE REPONSE CLIENT
                                                            </option>
                                                            <option value={"WAITING_PIECES"}>EN ATTENTE DE PIECES
                                                            </option>
                                                            <option value={"REPARATION"}>REPARATION EN COURS</option>
                                                            <option value={"TESTS"}>TESTS QUALITES</option>
                                                            <option value={"TO_GET_BACK_SUCESSFULLY"}> A RECUPERER ET
                                                                PAYER
                                                            </option>
                                                            <option value={"TO_GET_BACK"}>A RECUPERER SANS PAYER (ECHEC)
                                                            </option>
                                                            <option value={"TO_SEND_BACK"}>A RENVOYER</option>
                                                            <option value={"OVER"}>TERMINE AVEC SUCCES</option>
                                                            <option value={"CLOT"}> CLOT</option>
                                                        </Input>
                                                    </ErrorBoundary>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {this.state.priseencharge.online.toString() === "1" && (
                                        <div><Row form>
                                            <Col md={2}>
                                                <Label for="exampleEmail">Suivi</Label>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Input
                                                        className={"mb-3"}
                                                        placeholder="Suivi"
                                                        id="input-suivi"
                                                        type="text"
                                                        value={this.state.priseencharge?.suivi_text}
                                                        onChange={(e) => {
                                                            let priseencharge = Object.assign({}, this.state.priseencharge);
                                                            priseencharge.suivi_text = e.target.value
                                                            this.setState({priseencharge: priseencharge})
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                            <Row>
                                                <Col md={2}>
                                                    <Label> Signature</Label>
                                                </Col>
                                                <Col md={4}>
                                                    {this.state.priseencharge?.suivi}
                                                </Col>
                                            </Row>
                                        </div>
                                    )}

                                    {this.state.priseencharge?.online.toString() === "1" && (
                                        <Row>
                                            <Col md={2}>
                                                <Label> Date création </Label>
                                            </Col>
                                            <Col md={4}>
                                                {moment(this.state.priseencharge?.created_at).format("LLLL")}
                                            </Col>
                                        </Row>)}
                                    <Row>
                                        <Col md={2}>
                                            <Label> Date d'arrivée </Label>
                                        </Col>
                                        <Col md={4}>
                                            {moment(this.state.priseencharge?.date_arrived).format("LLLL")}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Label> Date de fin </Label>
                                        </Col>
                                        <Col md={4}>
                                            {this.state.priseencharge?.date_end && (
                                                moment(this.state.priseencharge?.date_end).format("LL")
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Card>
                                    <CardBody>
                                        <UserInformation user={this.state.priseencharge?.user}
                                                         adresse={this.state.priseencharge?.user?.adresse}
                                                         hasAdresse={false}/>
                                    </CardBody>
                                </Card>
                            </Row>
                        </Col>
                    </CardBody>
                </Form></div>)
    }


    setDevis() {
        return (
            <GenericTableAffichage api={this.injected.rootStore.devisStore}
                                   filters={
                                       {
                                           page: "1",
                                           search: "",
                                           user_id: "",
                                           prise_en_charge_id: this.state.priseencharge.id,
                                           name: "updated_at",
                                           order: false,
                                           status: ""
                                       }
                                   }
                                   type="devis"/>)
    }


    setInvoices() {
        return (
            <GenericTableAffichage api={this.injected.rootStore.invoiceStore}
                                   filters={
                                       {
                                           page: "1",
                                           search: "",
                                           user_id: "",
                                           prise_en_charge_id: this.state.priseencharge.id,
                                           name: "updated_at",
                                           order: false,
                                           status: ""
                                       }
                                   }
                                   type="invoice"/>)
    }


    callbackClientMachine = (childData) => {
        this.setState({priseencharge: childData, activeTab: "1"})
    };


    callbackPresta = (childData) => {
        this.setState({priseencharge: childData, activeTab: "2"})
    };


    //Refresh priseencharge
    callbackPriseEnCharge = (childData, number) => {
        this.setState({priseencharge: childData, activeTab: number})
    };


    setTimeline() {
        return <Historique notes={this.state.priseencharge.notes ?? []} id={this.state.priseencharge?.id}/>
    }


    setTimelinePec() {
        return <TimelinePec id={this.state.priseencharge?.id}
                            notes={this.state.priseencharge?.pec_lignes}/>
    }

    setComment() {
        return <NotesRich callbackPriseEnCharge={this.callbackPriseEnCharge} id={this.state.priseencharge?.id}
                          comment={this.state.priseencharge.comment}/>
    }

    setImpression() {
        return <PrintImpression id={this.state.priseencharge?.id} datas={this.state.priseencharge.appareils}
                                api={this.injected.rootStore.prisenchargeStore} type={"pec"}/>
    }

    setPdf() {
        return (
            <Card>
                <CardBody>
                    <h4>Prise en charge à signer</h4>
                    {this.state.priseencharge?.online === 1 ? (
                        <Button
                            color="success"
                            onClick={e => this.injected.rootStore.prisenchargeStore.openPdfPecOnline(e, this.state.priseencharge?.id)}>
                            Visualiser
                        </Button>
                    ) : (

                        <Button
                            disabled={this.state.priseencharge?.appareils?.length === 0}
                            color="success"
                            onClick={e => this.injected.rootStore.prisenchargeStore.openPdfPec(e, this.state.priseencharge?.id)}>
                            Visualiser
                        </Button>)}
                </CardBody>
            </Card>

        )
    }

    createDevis(e) {
        e.preventDefault()
        this.injected.history.replace({
            pathname: "/devis/edit/add",
            aboutProps: {
                isCommandeValid: false,
                devis: {
                    ligne: [],
                    titre: "",
                    user: this.state.priseencharge.user,
                    status: "DRAFT",
                    prise_en_charge_id: this.state.priseencharge?.id
                }
            },
        });
    }

    createInvoice(e) {
        e.preventDefault()
        this.injected.history.replace({
            pathname: getRoutes("Gérer une facture", "add"),
            aboutProps: {
                isCommandeValid: false,
                invoice: {
                    user: this.state.priseencharge.user,
                    statusEnum: "DRAFT",
                    prise_en_charge_id: this.state.priseencharge?.id,
                    facture_ligne: [],
                    intitule: "",
                }
            },
        });
    }


    onSendBackTab = (nb) => {
        this.setState({
            activeTab: nb
        })
    }

    render() {
        return (
            <>
                {this.state.priseencharge?.id ? (
                        <Container className="mt-2" fluid>
                            <Row className="mb-3">
                                <div className="col">
                                    <Card className="shadow">
                                        <CardHeader
                                            className={this.injected.rootStore.savedDatasStore.setColor(this.state.priseencharge?.status)}
                                            style={{display: "flex", justifyContent: "space-between"}}>

                                            <h4 className="mb-0">

                                                {this.state.priseencharge?.id && (
                                                    "Prise En Charge #" + this.state.priseencharge?.id + " " + this.state.priseencharge?.statusname.toUpperCase()
                                                )}
                                            </h4>
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <Dropdown className={"mr-2"}>
                                                    <Dropdown.Toggle variant="info">
                                                        <i className="fa fa-plus-circle" aria-hidden="true"/>
                                                        Nouveau
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={this.createInvoice}>Facture</Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={this.createDevis}>Devis</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {this.state.priseencharge?.online === 1 && (
                                                    this.state.priseencharge?.status === "SUBMIT" || this.state.priseencharge?.status === "VALIDATE" || this.state.priseencharge?.status === "OVER"
                                                ) &&
                                                <Dropdown className={"mr-2"}>
                                                    <Dropdown.Toggle variant="warning">
                                                        <i className="fa fa-wrench" aria-hidden="true"/>
                                                        Actions
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {this.state.priseencharge?.status === "SUBMIT" && (
                                                            <div>
                                                                <Dropdown.Item
                                                                    onClick={this.acceptedPec}>Accepter</Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={this.refusedPec}>Refuser</Dropdown.Item>
                                                            </div>
                                                        )}


                                                        {this.state.priseencharge?.status === "VALIDATE" && (
                                                            <div>
                                                                <Dropdown.Item
                                                                    onClick={this.arrivedPec}>Réceptionner</Dropdown.Item>
                                                            </div>
                                                        )}


                                                        {this.state.priseencharge?.status === "OVER" && (
                                                            <div>
                                                                <Dropdown.Item
                                                                    disabled={!this.state.priseencharge?.suivi_text}
                                                                    onClick={this.expediate}>Marquer comme
                                                                    expédier</Dropdown.Item>
                                                            </div>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>}


                                                {
                                                    this.createButtonSave("Modifier", "success", e => this.onUpdate(e))
                                                }
                                            </div>


                                        </CardHeader>
                                        <Tabs
                                            onCallback={this.onSendBackTab}
                                            activeTab={this.state.activeTab}
                                            children={[this.form(), <MachinePec priseencharge={this.state.priseencharge}
                                                                                callback={this.callbackClientMachine}/>,
                                                <PrestationPec priseencharge={this.state.priseencharge}
                                                               rootStore={this.injected.rootStore}
                                                               callback={this.callbackPresta}/>,
                                                this.setDevis(), this.setInvoices(), this.setTimelinePec(), this.setImpression(), this.setPdf(), this.setComment(), this.setTimeline()]}
                                            titles={["Infos", "Machines", "Prestations", "Devis", "Factures", "Réponses", "Impressions", "PDF", "Notes", "Historique"]}/>
                                    </Card>
                                </div>
                            </Row>
                        </Container>) :
                    (<Container className={"mt-2"} fluid> <Card><CardBody>Prise en charge introuvable. Merci de
                        réessayer</CardBody></Card> </Container>)}
            </>
        );
    }
}));

export default withRouter(UpdatePriseEnCharge);

