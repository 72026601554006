import React from "react";

import {
  Container,
  Card,
  Col,
  Row
} from "reactstrap";


import { StandardGamepad } from "./ControllerArt";
import { JoystickDebugger } from "./JoystickDebugger";

interface GamepadProps {
  index: number;
  gamepad: Gamepad | null;
}

declare global {
  interface Gamepad {
    vibrationActuator: any;
  }
}

function GamepadArt({ gamepad }: { gamepad: Gamepad }) {
  //  18 Bouton = Manette Playstation
  if (gamepad.buttons.length === 18 && gamepad.axes.length === 4) {
    return <StandardGamepad gamepad={gamepad}/>;
  }


  //  17 Bouton = Manette de Xbox
  if (gamepad.buttons.length === 17 && gamepad.axes.length === 4) {
    return <StandardGamepad gamepad={gamepad} />;
  }

  // manette de merde
  return null;
}

class AxisItem extends React.Component<any> {
  render() {
    return (
      <li className={this.props.className} style={this.props.style}>
        <label>{this.props.label}</label>
        <span className="value"> {this.props.value}</span>
      </li>
    )
  }
}

export default class GamepadItem extends React.Component<GamepadProps, any> {
  axisStyle(n: number) {
    return {
      opacity: Math.abs(n) + 0.3
    }
  }

  buttonStyle(id: GamepadButton) {
    var val = this.buttonValue(id);
    return {
      opacity: Math.abs(val) + 0.3,
      border: this.buttonPressed(id) ? '1px solid #888' : '1px solid transparent'
    }
  }

  buttonValue(b: GamepadButton) {
    return (typeof (b) == 'number') ? b : b.value;
  }

  buttonPressed(b: GamepadButton) {
    return (typeof (b) == 'number') ? b > 0.1 : b.pressed
  }

  mappingString(mapping: GamepadMappingType) {
    return mapping || 'n/a';
  }

  formatFloat(n: number, places: number) {
    var m = Math.pow(10, places);
    return "" + parseFloat("" + Math.round(n * m) / m).toFixed(places);
  }

  testVibration() {
    if (this.props.gamepad && this.props.gamepad.vibrationActuator) {
      this.props.gamepad.vibrationActuator.playEffect("dual-rumble", {
        startDelat: 0,
        duration: 1000,
        weakMagnitude: 1.0,
        strongMagnitude: 1.0
      });
    }
  }

  render() {
    var gamepad = this.props.gamepad;
    if (gamepad  && gamepad.index === 1 && gamepad as Gamepad) {
      return (
        <Container fluid>
        <div className="animated fadeIn">
          <Row>
            <Col xs="12" sm="12" lg="12">
              <Card className="text-white bg-success px-2">
                <div className="gamepad">
                  <h2 className="active">
                    <span>{gamepad.id}</span>
                  </h2>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
          <Col xs="12" sm="6" lg="6">
            <Card className="text-white bg-primary">
              <Row>
            <Col xs="12" sm="8" lg="8">
                <div className="text-center py-3">
                  <GamepadArt gamepad={gamepad} />
                </div>
                </Col>
              <Col xs="12" sm="4" lg="4">
                {gamepad.axes.map((axis, i) => (
                  <AxisItem
                    intensity={Math.abs(axis)}
                    key={i}
                    label={"AXIS " + i}
                    value={this.formatFloat(axis, 5)}
                  />
                ))}

                {gamepad.mapping === "standard" && (
                  <JoystickDebugger x={gamepad.axes[0]} y={gamepad.axes[1]} />
                )}
                {gamepad.mapping === "standard" && (
                  <JoystickDebugger x={gamepad.axes[2]} y={gamepad.axes[3]} />
                )}
              </Col>
              </Row>
            </Card>
            </Col>
            <Col xs="12" sm="6" lg="6">
             <Card className="text-white bg-primary">
              <ul className="info">
                <AxisItem className="large" label="Timer" value={this.formatFloat(gamepad.timestamp, 5)} />
              </ul>


              <ul className="buttons">
                {gamepad.buttons.map((button, i) =>
                  <AxisItem key={i} label={"Bouton " + i} value={this.formatFloat(this.buttonValue(button), 2)} style={this.buttonStyle(button)} />
                )}
              </ul>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" lg="12">
              <Card className="text-white bg-primary">
                <ul className="info">
                  <AxisItem className="med" label="Vibration" value={gamepad.vibrationActuator ? "Yes" : "n/a"} />
                  {
                    gamepad.vibrationActuator &&
                    <li className="med test-button" onClick={() => this.testVibration()}>
                      <label>TEST </label>
                      <span className="value"> Vibration</span>
                    </li>
                  }
                </ul>
              </Card>
            </Col>
          </Row>
        </div>
        </Container>
      )
    } else {
      return (
        <div className="gamepad">

        </div>
      )
    }
  }
}