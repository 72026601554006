import React from "react";
import ErrorBoundary from "../../../components/Akitatek/Error/ErrorBounday";
import {Link} from "react-router-dom";
import getRoutes from "../../../components/Akitatek/getRoutes";

function CasierContent({data}) {
    return <ErrorBoundary><div>
        {
            data.stock && data.stock?.map((element) => {
                return <Link style={{color: "#20a8d8"}} to={{
                    pathname: getRoutes("Modifier stock", element.id ),
                    aboutProps: {
                        stock: element
                    }
                }}>
                    <li> {element.nom} - {element.stock} </li>
                </Link>
            })
        }
    </div></ErrorBoundary>
}

export default CasierContent