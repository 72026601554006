import { default as i18next } from 'i18next';

export default i18next
    .init({
        resources: {
            fr: require('../statics/locales/fr.json'),
            en: require('../statics/locales/en.json'),
        },
        defaultNS: 'akitatek',
        fallbackLng: 'fr'
    })
