import React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import getRoutes from "../../../../components/Akitatek/getRoutes";

const ProductRow = inject("rootStore") (observer( class ProductRow extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = ({
            product: this.injected.data
        });

        this.toggleDanger = this.toggleDanger.bind(this);

    }


    toggleDanger() {
        this.injected.rootStore.notificationStore.setWarning("Produit #" + this.state.product.id, event => this.onDelete( this.state.product.id, this.state.product.annuaire_marque_id))
    }

    async onDelete(deleteId, annuaireMarqueId) {
        let status = await this.injected.rootStore.annuaireStore.productStore.deleteProduct(deleteId);
        if(status === "success") {
            await   this.injected.rootStore.annuaireStore.productStore.getProducts(annuaireMarqueId);
        }
    }


    render() {
        return (
            <>
                <tr>
                    <th scope="row"  className="text-center">
                        <span className="mb-0 text-sm">
                            <Link to={{pathname: getRoutes("Afficher un Produit", this.state.product?.id),
                                aboutProps: {
                                    product: this.state.product
                                }
                            }}>
                            {this.state.product.nom}
                          </Link>
                        </span>
                    </th>

                    <td  className="text-center">
                        <span className="mb-0 text-sm">
                            <div className="list-group list-group-accent">
                                {this.state.product.prestations.map(
                                    (presta) => {
                                        return (
                                            <div key={presta.id} className="list-group-item list-group-item-accent-primary">{presta.title}</div>
                                        )
                                    }
                                )}
                            </div>
                        </span>
                    </td>


                    <td className="text-center">
                        <span className="mb-0 text-sm">
                         {this.state.product.url}
                        </span>
                    </td>


                    <td className="text-center">
                        <Link to={{pathname: getRoutes("Afficher un Produit", this.state.product?.id),
                            aboutProps: {
                                product: this.state.product
                            }
                        }}>
                            <Button className="mr-1" color="primary" type="button"><i className="fa fa-pencil" aria-hidden="true"> </i></Button>
                       </Link>


                        <Button color="danger" type="button" className="ml-1 mr-1" onClick={this.toggleDanger}>
                            <i className="fa fa-minus"/>
                        </Button>
                    </td>
                </tr>
            </>
        );
    }

}));

export default ProductRow;
