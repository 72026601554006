import SearchMachineUser from "./SearchMachineUser";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import PriseEnChargeMachineRow from "./PriseEnChargeMachineRow";
import React from "react";
import ErrorBoundary from "../../../components/Akitatek/Error/ErrorBounday";

function MachinePec({priseencharge, callback}) {
    return (<ErrorBoundary>
            <SearchMachineUser id={priseencharge.id} user={priseencharge.user}
                               machinesClient={priseencharge.user?.appareils}
                               callbackClientMachine={callback}/>

            <TableGeneric titles={[
                "Index",
                "Marque",
                "Produit",
                "Numéro de série",
                "Code",
                "Defauts",
                "Accessoires",
                "Stockage",
                "Actions"
            ]}>

                {priseencharge.appareils.map((appareil, id) => {
                    return (<PriseEnChargeMachineRow
                        callbackRefreshLignes={callback}
                        user={priseencharge?.user}
                        appareil={appareil}
                        key={id}
                        lignes={priseencharge?.appareils}
                        index={id}
                        id={priseencharge?.id}/>)
                })}

                {(priseencharge.appareils.length === 0) && (
                    <tr>
                        <td className={"no_td"} colSpan={[
                            "Index",
                            "Marque",
                            "Produit",
                            "Numéro de série",
                            "Code",
                            "Defauts",
                            "Accessoires",
                            "Stockage",
                            "Actions"
                        ].length}>Aucun appareil lié
                        </td>
                    </tr>
                )}

            </TableGeneric>

        </ErrorBoundary>
    )
}

export default MachinePec;