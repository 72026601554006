import * as React from "react";

function JoystickDebuggerBase({ x, y }: { x: number; y: number }) {
  return (
    <div className="divAlacon"

    >
      <div className="x-axis" />
      <div className="y-axis" />
      <div className="circle-outline" />
      <div
        className="joystick-position"
        style={{
          top: y * 50 + 50 + "%",
          left: x * 50 + 50 + "%",
        }}
      />
    </div>
  );
}

export const JoystickDebugger = React.memo(JoystickDebuggerBase);