import React from "react";
import {inject, observer} from "mobx-react";
import {Input} from "reactstrap";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import {Link} from "react-router-dom";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import getRoutes from "../../../components/Akitatek/getRoutes";


const CreateDevis = inject("rootStore")(observer(class CreateDevis extends React.Component {

    devisStore = this.injected.rootStore.devisStore.devisCreate;
    line;
    devis;
    refresh;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            max: this.injected.lot?.stock ?? this.injected.line?.stock,
            type: this.injected.type ?? this.injected.line?.type,
            devis: this.injected.devis,
            line: this.injected.line,
            lot: this.injected.line?.lot,
            designation: this.injected.line?.designation,
            refresh: this.injected.refresh,
            produit_id: this.injected.line?.produit_id,
            position: this.injected.position,
            quantite: this.injected.line?.quantite,
            total_unit_ht: this.injected.line?.total_unit_ht ?? 0,
            tarif_unitaire: this.injected.line?.tarif_unitaire,
            tva: this.injected.line?.tva ?? 0,
            remise: this.injected.line?.remise ?? 0,
            totalht: this.injected.line?.totalht,
            totalttc: this.injected.line?.totalttc,
            montant_tva: this.injected.line?.montant_tva ?? 0,
            product: this.injected.line?.product,
            presta: this.injected.line?.presta,
            prestation_id: this.injected.line?.prestation?.id,
            reference_produit: this.injected.line?.reference_produit
        }
        this.enter = this.enter.bind(this)
        this.toggleDanger = this.toggleDanger.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.line !== prevProps.line || this.injected.devis !== prevProps.devis
        ) {
            this.setState({
                max: this.injected.lot?.stock ?? this.injected.line?.stock,
                type: this.injected.type ?? this.injected.line?.type,
                devis: this.injected.devis,
                line: this.injected.line,
                lot: this.injected.line?.lot,
                designation: this.injected.line?.designation,
                produit_id: this.injected.line?.produit_id,
                position: this.injected.position,
                quantite: this.injected.line?.quantite,
                tarif_unitaire: this.injected.line?.tarif_unitaire,
                tva: this.injected.line?.tva ?? 0,
                total_unit_ht: this.injected.line?.total_unit_ht ?? 0,
                remise: this.injected.line?.remise ?? 0,
                totalht: this.injected.line?.totalht,
                totalttc: this.injected.line?.totalttc,
                product: this.injected.line?.product,
                reference_produit: this.injected.line?.reference_produit,
                presta: this.injected.line?.presta,
                prestation_id: this.injected.line?.prestation?.id
            })
        }
    }

    onChangeData(line) {
        this.setState({
            max: line.lot?.stock ?? line?.stock,
            line: line,
            lot: line?.lot,
            type: line?.type,
            designation: line?.designation,
            produit_id: line?.produit_id,
            prestation_id: line?.prestation?.id,
            position: line?.position,
            quantite: line?.quantite,
            tarif_unitaire: line?.tarif_unitaire,
            tva: line?.tva ?? 0,
            total_unit_ht: line?.total_unit_ht ?? 0,
            remise: line?.remise ?? 0,
            totalht: line?.totalht,
            totalttc: line?.totalttc,
            product: line?.product,
            presta: line?.presta,
            reference_produit: line?.reference_produit ?? line?.ref
        })
    }

    async copyLine(e) {
        let data = {
            remise: this.state.remise ?? 0,
            tva: this.state.tva ?? 0,
            lot_id: this.state.lot?.id ?? "",
            produit_id: this.state.product?.id ?? "",
            position: this.state.position + 1,
            devis_id: this.state.devis?.id ?? "",
            quantite: this.state.quantite,
            total_unit_ht: this.state.total_unit_ht ?? 0,
            designation: this.state.designation ?? " ",
            tarif_unitaire: this.state.tarif_unitaire ?? 0,
            type: this.state.type,
            reference_produit: this.state.reference_produit,
            presta: this.state.presta,
            prestation_id: this.state.prestation?.id
        }

        let newIndex = data.position + 1

        let authResponse = await this.injected.rootStore.devisStore.saveDevisLigne(data, "Ligne " + data.position + " copiée en " + newIndex)
        if (authResponse?.status === "success") {
            let lignes = this.state.devis?.ligne
            lignes.splice(this.state.position + 1, 0, authResponse.datas);
            this.injected.callbackRefreshLignes(lignes)
        }

    }

    async onUpdate(e, hide = true) {
        e.preventDefault()
        let data = {
            lot_id: this.state.lot?.id,
            id: this.state.line?.id,
            remise: this.state.remise ?? 0,
            tva: this.state.tva ?? 0,
            produit_id: this.state.product?.id ?? "",
            position: this.state.position,
            quantite: this.state.quantite,
            designation: this.state.designation ?? " ",
            tarif_unitaire: this.state.tarif_unitaire ?? 0,
            reference_produit: this.state.reference_produit,
            type: this.state.type,
            sup_id: this.state.devis.id,
            statusEnum: this.state.devis.status,
            presta: this.state.presta,
            prestation_id: this.state.prestation?.id
        }

        let authResponse = await this.injected.rootStore.devisStore.updateDevisLigne(data, hide)
        if (authResponse?.status === "success") {
            let lignes = this.state.devis.ligne
            lignes.splice(this.state.position, 1, authResponse.datas);
            this.injected.callbackRefreshLignes(lignes)
        }
    }

    specialEnter(e) {
        if (e.key === "Tab") {
            e.preventDefault()
            if (!this.disabled()) {
                let key = 4
                const form = e.target.form;
                const index = Array.prototype.indexOf.call(form, e.target);
                form.elements[index + key].focus();

                this.onUpdate(e, true)

            }
        }
    }

    enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            if (!this.disabled()) {
                const form = e.target.form;
                const index = Array.prototype.indexOf.call(form, e.target);
                form.elements[index + 1].focus();
                this.onUpdate(e, true)
            }
        }
    }


    async toggleDanger() {
        let index = this.state.position + 1
        let nom = this.state.designation
        await this.injected.rootStore.notificationStore.setWarning("Ligne " + index + " " + nom, event => this.onDelete(this.state?.line.id, index, this.state.devis?.ligne))
    }

    async onDelete(deleteId, index, lignes) {
        let data = {
            id: deleteId
        }

        let status = await this.injected.rootStore.devisStore.deleteDevisLigne(data, index)

        if (status === "success") {
            lignes.splice(this.state.position, 1);
            this.injected.callbackRefreshLignes(lignes)
        }
    }


    async calculateTotal() {
        let prixVenteArtBrut = parseFloat(this.state.tarif_unitaire) * parseFloat(this.state.quantite);

        let remise = prixVenteArtBrut * (parseFloat(this.state.remise) / parseFloat(100))
        let totalttc = parseFloat(prixVenteArtBrut - remise);
        let totalht = totalttc / (parseFloat(this.state.tva) / 100 + 1);
        let tva = totalht * (parseFloat(this.state.tva) / 100);

        let line = {
            id: this.state.line?.id,
            lot: this.state.lot,
            lot_id: this.state.lot?.id,
            totalht: parseFloat(totalht).toFixed(2),
            totalttc: parseFloat(totalttc).toFixed(2),
            total_unit_ht: this.state.total_unit_ht ?? 0,
            montant_tva: parseFloat(tva).toFixed(2) ?? 0,
            remise: this.state.remise ?? 0,
            tva: this.state.tva ?? 0,
            produit_id: this.state.product?.id ?? "",
            position: this.state.position,
            devis_id: this.state.devis?.id ?? "",
            quantite: this.state.quantite,
            designation: this.state.designation ?? " ",
            tarif_unitaire: this.state.tarif_unitaire ?? 0,
            product: this.state.product,
            type: this.state.type,
            reference_produit: this.state.reference_produit,
            presta: this.state.presta,
            prestation_id: this.state.prestation?.id
        }

        let devis = this.state.devis
        devis.ligne[this.state.position] = line

        await this.setState({
            totalht: parseFloat(totalht).toFixed(2),
            totalttc: parseFloat(totalttc).toFixed(2),
            devis: devis
        })
        this.injected.callbackRefreshTotal()
    }

    disabledMicro() {
        return this.state.devis?.status === "ARCHIVED" ||
            this.state.devis?.status === "VALID" || this.injected.rootStore.authStore.isMicro()
    }

    async onDrop(event) {
        event.preventDefault()
        event.stopPropagation()
        if (event.dataTransfer.getData("index")) {
            let lignes = this.state.devis.ligne
            let index = event.dataTransfer.getData("index")
            let oldLine = lignes[index]
            let newLine = lignes[this.state.position]
            oldLine.position = this.state.position
            newLine.position = index

            let authResponse = await this.injected.rootStore.devisStore.updateDevisLigne(oldLine)
            let authResponse2 = await this.injected.rootStore.devisStore.updateDevisLigne(newLine)

            if (authResponse?.status === "success") {
                lignes.splice(this.state.position, 1, oldLine);
            }

            if (authResponse2.status === "success") {
                lignes.splice(index, 1, newLine);
            }

            this.injected.callbackRefreshLignes(lignes)
        }

    }

    onDragStart(event) {
        event.dataTransfer.setData("index", this.state.position);
        event.stopPropagation()
    }

    onDragOver(event) {
        event.preventDefault()
    }


    setTotalttc() {
        if (this.injected.rootStore.authStore.isMicro()) {
            return (<Badge variant="info">NA</Badge>)
        } else {
            return this.state.totalttc + "€"
        }
    }

    disabled() {
        return this.state.devis.status !== "DRAFT" && this.state.devis.status !== "WAIT"
    }

    color() {
        if (parseInt(this.state.quantite) === this.state.lot?.stock) {
            return "bg-warning"
        } else if (parseInt(this.state.quantite) > this.state.lot?.stock || parseInt(this.state.quantite) <= 0) {
            return "bg-danger"
        } else {
            return ""
        }
    }

    changeLot(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.changeLot(this.state.devis.id, "devis", this.state.line.id, this.state.quantite, this.state.product, this.onUpdateLine)
    }

    onUpdateLine = (childData) => {
        this.onChangeData(childData)
    };

    render() {
        return (
            <>
                <tr key={this.injected.key}>
                    <th scope="row"
                        draggable={true}
                        onDragOver={e => this.onDragOver(e)}
                        onDrop={e => this.onDrop(e)}
                        onDragStart={e => this.onDragStart(e)}
                    >
                        <span className="mb-0 text-sm">
                            #{this.injected.position + 1}
                        </span>
                    </th>

                    <td>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    {this.state.presta !== null && (
                                        <Link style={{color: "#4dbd74"}} to={{
                                            pathname: "/prestations/edit/" + this.state.presta?.id,
                                            aboutProps: {
                                                presta: this.state.presta
                                            }
                                        }}>
                                            <i className="fa fa-book mr-2" aria-hidden="true"/>
                                        </Link>
                                    )}

                                    {this.state.product !== null && (
                                        <Link style={{color: "#20a8d8"}} to={{
                                            pathname: getRoutes("Modifier stock", this.state.line?.product?.id),
                                            aboutProps: {
                                                stock: this.state.product
                                            }
                                        }}>
                                            <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
                                            ( {this.state.lot?.pid ?? ""} {this.state.lot?.fournisseur?.nom ?? this.injected.rootStore.authStore.nameCompany})
                                        </Link>
                                    )}

                                    {(this.state.product === null && this.state.presta === null) && (
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    )}
                                </InputGroupText>
                            </InputGroupAddon>

                            <Input
                                onKeyPress={e => this.enter(e)}
                                data-toggle="tooltip" data-placement="right" title={this.state.reference_produit}
                                className="form-control-alternative"
                                type={"text"}
                                placeholder={"Référence"}
                                value={this.state.reference_produit}
                                disabled={this.disabled()}
                                onChange={async e => {
                                    await this.setState({reference_produit: e.target.value})
                                    await this.calculateTotal()
                                }}/>
                        </InputGroup>
                    </td>

                    <td>
                        <Input
                            onKeyPress={e => this.enter(e)}
                            data-toggle="tooltip" data-placement="right" title={this.state.designation}
                            className="form-control-alternative"
                            type={"text"}
                            placeholder={"Designation"}
                            value={this.state.designation}
                            disabled={this.disabled()}
                            onChange={async e => {
                                await this.setState({designation: e.target.value})
                                await this.calculateTotal()
                            }}
                        />
                    </td>
                    <td className={"th-number"}>
                        <span className="mb-0 text-sm">
                            <Input
                                onKeyPress={e => this.enter(e)}
                                data-toggle="tooltip" data-placement="right" title={this.state.quantite}
                                className={this.color() + " form-control-alternative"}
                                type={"number"}
                                placeholder={"Quantité"}
                                value={this.state.quantite}
                                disabled={this.disabled()}
                                onChange={async e => {
                                    await this.setState({quantite: e.target.value})
                                    await this.calculateTotal()
                                }}
                            />
                        </span>
                    </td>
                    <td className={"th-price"}>
                        <span className="mb-0 text-sm">
                                  <Input
                                      onKeyPress={this.enter}
                                      data-toggle="tooltip" data-placement="right" title={this.state.tarif_unitaire}
                                      className="form-control-alternative"
                                      type={"number"}
                                      value={this.state.tarif_unitaire}
                                      disabled={this.disabled()}
                                      onChange={async e => {
                                          await this.setState({
                                              tarif_unitaire: e.target.value,
                                              total_unit_ht: parseFloat(e.target.value / 1.2).toFixed(2)
                                          })
                                          await this.calculateTotal()
                                      }}
                                  />
                        </span>
                    </td>
                    <td className={"th-price"}>
                        <span className="mb-0 text-sm">
                                  <Input
                                      onKeyPress={this.enter}
                                      data-toggle="tooltip" data-placement="right" title={this.state.total_unit_ht}
                                      className="form-control-alternative"
                                      type={"number"}
                                      value={this.state.total_unit_ht}
                                      disabled={this.disabled()}
                                      onChange={async e => {
                                          await this.setState({
                                              total_unit_ht: e.target.value,
                                              tarif_unitaire: parseFloat(e.target.value * 1.2).toFixed(2)
                                          })
                                          await this.calculateTotal()
                                      }}
                                  />
                        </span>
                    </td>
                    <td className={"th-price"}>
                        <span className="mb-0 text-sm">
                               <Input
                                   onKeyPress={e => this.enter(e)}
                                   data-toggle="tooltip" data-placement="right" title={this.state.remise}
                                   className="form-control-alternative"
                                   type={"number"}
                                   placeholder={"Remise"}
                                   value={this.state.remise}
                                   disabled={this.disabled()}
                                   onChange={async e => {
                                       await this.setState({remise: e.target.value})
                                       await this.calculateTotal()
                                   }}
                               />
                        </span>
                    </td>
                    <td>
                        <span className="mb-0 text-sm">
                            {this.state.totalht} €
                        </span>
                    </td>
                    <td className={"th-price"}>
                        <span className="mb-0 text-sm">
                              <Input
                                  onKeyDown={e => this.specialEnter(e)}
                                  onKeyPress={e => this.enter(e)}
                                  data-toggle="tooltip" data-placement="right" title={this.state.tva}
                                  className="form-control-alternative"
                                  type={"number"}
                                  placeholder={"Tva"}
                                  value={this.state.tva}
                                  disabled={this.disabledMicro()}
                                  onChange={async e => {
                                      await this.setState({tva: e.target.value})
                                      await this.calculateTotal()
                                  }}
                              />
                        </span>
                    </td>

                    <td>
                        <span className="mb-0 text-sm">
                            {this.state.montant_tva} €
                        </span>
                    </td>
                    <td>
                        <span className="mb-0 text-sm">
                            {this.setTotalttc()}
                        </span>
                    </td>
                    <td>
                        <span className="mb-0 text-sm">
                                   <Button
                                       disabled={this.disabled()}
                                       onClick={(e) => {
                                           this.onUpdate(e, true);
                                       }} variant="success" size="sm" type="button" className="mr-1">
                                        <i className="fa fa-check"/>
                               </Button>

                               <Button
                                   disabled={this.state.product === null}
                                   type="button"
                                   className={"mr-1"}
                                   variant={this.state.product === null ? "secondary" : "warning"}
                                   size="sm"
                                   onClick={e => this.changeLot(e)}>
                                    <i className={"fa fa-search"}/>
                                 </Button>


                                 <Button
                                     disabled={this.disabled()}
                                     onClick={(e) => {
                                         this.copyLine(e);
                                     }} color="primary" size="sm" type="button" className="mr-1">
                                    <i className="fa fa-copy"/>
                               </Button>

                                  <Button
                                      disabled={this.disabled()}
                                      onClick={(e) => {
                                          this.toggleDanger()
                                      }} variant="danger" size="sm" type="button" className="mr-1">
                                    <i className="fa fa-minus"/>
                               </Button>
                        </span>
                    </td>
                </tr>
            </>
        );
    }

}));

export default CreateDevis;
