import React from "react";
import {Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";

const Invoices = (inject("rootStore")(observer(class Invoices extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des factures", "Créer une facture / gérer les encaissements", "facture.jpg", this.injected.rootStore.invoiceStore)
    }

    get injected() {
        return this.props;
    }


    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericTableAffichage api={this.injected.rootStore.invoiceStore}
                                           user={false}
                                           status={false}
                                           statusHeader={true}
                                           icon={<i className="fa fa-file-pdf-o mr-2" aria-hidden="true"/>}
                                           name={"Factures "}
                                           type="invoice"/>
                </Container>
            </>
        );
    }
})));

export default Invoices;
