import React from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import moment from "moment";
import TypeRow from "./TypeRow";


const TabledataRow = inject("rootStore")(observer(class TabledataRow extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        moment.locale('fr');
    }


    render() {
        let {titles, data, actions} = this.props
        return (
            <>
                <tr>
                    {titles.map((element) => (
                        <TypeRow element={element} rootStore={this.injected.rootStore} data={data}/>
                    ))}
                    <TypeRow>
                        {actions}
                    </TypeRow>

                </tr>
            </>
        );
    }

}));

export default withRouter(TabledataRow);
