import React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "react-bootstrap";
import Input from "reactstrap/lib/Input";
import Badge from "reactstrap/lib/Badge";
import {Link} from "react-router-dom";
import getRoutes from "../../../components/Akitatek/getRoutes";


const LineCommandeRow = inject("rootStore")(observer(class LineCommandeRow extends React.Component {

    line;
    commande;
    refresh

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);


        this.state = {
            nom: this.injected.line?.nom,
            refresh: this.injected.refresh,
            commande: this.injected.commande,
            produit_id: this.injected.line?.produit_id,
            index: this.injected.index,
            line: this.injected.line,
            ref: this.injected.line?.ref,
            quantity: this.injected.line?.quantity,
            prix_unitaire: this.injected.line?.prix_unitaire ?? 0,
            quantity_received: this.injected.line?.quantity_received ?? 0,
            tva: this.injected.line?.tva ?? 0,
            remise: this.injected.line?.remise ?? 0,
            totalht: this.injected.line?.totalht,
            totalttc: this.injected.line?.totalttc,
            product: this.injected.line?.product,
            mouv: this.injected.line?.mouv,
            montant_tva: this.injected.line?.montant_tva ?? 0,
            total_unit_ht: this.injected.line?.total_unit_ht ?? 0,
            quantity_stock: this.injected.line?.quantity_stock ?? 0,
            quantity_left: this.injected.line?.quantity_left ?? this.injected.line?.quantity
        }
        this.disabled = this.disabled.bind(this);
        this.enter = this.enter.bind(this);

        this.toggleDanger = this.toggleDanger.bind(this);
    }

    async toggleDanger() {
        let index = this.state.index + 1
        let nom = this.state.product?.nom !== undefined ? this.state.product?.nom : this.state.nom
        await this.injected.rootStore.notificationStore.setWarning("Ligne " + index + " " + nom, event => this.onDelete(this.state?.line.id, index, this.state.commande.lignes))
    }

    async onDelete(deleteId, index, lignes) {
        let data = {
            id: deleteId
        }

        let status = await this.injected.rootStore.commandeStore.deleteCommandeProducts(data, index)

        if (status === "success") {
            lignes.splice(this.state.index, 1);
            this.injected.callbackRefreshLignes(lignes)
        }
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.line !== prevProps.line ||
            this.injected.commande !== prevProps.commande
        ) {
            this.setState({
                nom: this.injected.line.nom,
                commande: this.injected.commande,
                index: this.injected.index,
                line: this.injected.line,
                ref: this.injected.line?.ref,
                quantity: this.injected.line?.quantity,
                prix_unitaire: this.injected.line?.prix_unitaire,
                quantity_received: this.injected.line?.quantity_received ?? 0,
                tva: this.injected.line?.tva ?? 0,
                remise: this.injected.line?.remise ?? 0,
                montant_tva: this.injected.line?.montant_tva ?? 0,
                totalht: this.injected.line?.totalht,
                totalttc: this.injected.line?.totalttc,
                total_unit_ht: this.injected.line?.total_unit_ht ?? 0,
                produit_id: this.injected.line?.produit_id,
                product: this.injected.line?.product,
                mouv: this.injected.line?.mouv ?? null,
                quantity_stock: this.injected.line?.quantity_stock,
                quantity_left: this.injected.line?.quantity_left
            })
        }
    }

    async onUpdate(e, hide = true) {
        e.preventDefault()
        let data = {
            id: this.state?.line.id,
            prix_unitaire: this.state.prix_unitaire,
            quantity: this.state.quantity,
            tva: this.state.tva,
            remise: this.state.remise,
            quantity_received: this.state.quantity_received,
            nom: this.state.nom,
            index: this.state.index,
            ref: this.state.ref

        }

        let authResponse = await this.injected.rootStore.commandeStore.updateCommandeProducts(data, hide)

        if (authResponse?.status === "success") {
            let lignes = this.state.commande.lignes
            lignes.splice(this.state.index, 1, authResponse.datas);
            this.injected.callbackRefreshLignes(lignes)
        }
    }

    async calculateTotal() {

        let prixVenteArtBrut = parseFloat(this.state.prix_unitaire) * parseFloat(this.state.quantity);

        let remise = prixVenteArtBrut * (parseFloat(this.state.remise) / parseFloat(100))
        let totalttc = parseFloat(prixVenteArtBrut - remise);
        let totalht = totalttc / (parseFloat(this.state.tva) / 100 + 1);
        let tva = totalht * (parseFloat(this.state.tva) / 100);

        let line = {
            nom: this.state.nom,
            id: this.state.line.id,
            quantity: this.state.quantity,
            tva: this.state.tva,
            remise: this.state.remise,
            quantity_left: this.state.quantity_left,
            quantity_stock: this.state.quantity_stock,
            totalht: parseFloat(totalht).toFixed(2),
            totalttc: parseFloat(totalttc).toFixed(2),
            montant_tva: parseFloat(tva).toFixed(2) ?? 0,
            prix_unitaire: this.state.prix_unitaire ?? 0,
            total_unit_ht: this.state.total_unit_ht ?? 0,
            quantity_received: this.state.quantity_received,
            produit_id: this.state.produit_id,
            product: this.state.line?.product,
            mouv: this.state.line.mouv,
            commande_id: this.state?.line.commande_id,
            index: this.state.index,
            ref: this.state.ref
        }

        let commande = this.state.commande
        commande.lignes[this.state.index] = line

        await this.setState({
            totalht: parseFloat(totalht).toFixed(2), totalttc: parseFloat(totalttc).toFixed(2),
            commande: commande
        })
        this.injected.callbackRefreshTotal()
    }


    async setMouvStock(e) {
        e.preventDefault()

        let data = {
            type: "BUY",
            quantite: this.state.quantity_received,
            produit_id: this.state.line.produit_id ?? "",
            commande_produit_id: this.state.line.id
        }

        let authResponse = await this.injected.rootStore.commandeStore.saveMouvCommande(data)

        if (authResponse) {
            let lignes = this.state.commande.lignes
            lignes[this.state.index] = authResponse
            this.injected.callbackRefreshLignes(lignes)

            this.setState({
                line: lignes[this.state.index]
            })
        }

    }

    disabled() {
        return this.state.commande?.status === "SEND" || this.state.commande?.status === "RECEPTION" || this.state.commande?.status === "VALID"
    }

    async copyLine(e) {
        e.preventDefault()

        let data = {
            index: this.state.index + 1,
            produit_id: this.state.produit_id ?? "",
            commande_id: this.state.commande?.id ?? "",
            quantity: this.state.quantity,
            nom: this.state.nom,
            ref: this.state.ref,
            total_unit_ht: this.state.total_unit_ht,
            prix_unitaire: this.state.prix_unitaire
        }

        let newIndex = data.index + 1

        let authResponse = await this.injected.rootStore.commandeStore.saveCommandeProducts(data, "Ligne " + data.index + " copiée en " + newIndex)

        if (authResponse?.status === "success") {
            let lignes = this.state.commande.lignes
            lignes.splice(this.state.index + 1, 0, authResponse.datas);
            this.injected.callbackRefreshLignes(lignes)
        }

    }

    async onDrop(event) {
        event.preventDefault()
        event.stopPropagation()
        if (event.dataTransfer.getData("index")) {
            let lignes = this.state.commande.lignes
            let index = event.dataTransfer.getData("index")
            let oldLine = lignes[index]
            let newLine = lignes[this.state.index]
            oldLine.index = this.state.index
            newLine.index = index

            let authResponse = await this.injected.rootStore.commandeStore.updateCommandeProducts(oldLine)
            let authResponse2 = await this.injected.rootStore.commandeStore.updateCommandeProducts(newLine)

            if (authResponse?.status === "success") {
                lignes.splice(this.state.index, 1, oldLine);
            }

            if (authResponse2.status === "success") {
                lignes.splice(index, 1, newLine);
            }

            this.injected.callbackRefreshLignes(lignes)
        }

    }

    onDragStart(event) {
        event.dataTransfer.setData("index", this.state.index);


        event.stopPropagation()
    }

    onDragOver(event) {
        event.preventDefault()
    }

    specialEnter(e) {
        if (e.key === "Tab") {
            e.preventDefault()

            if (!this.disabled()) {
                let key = 5
                if (this.state.product === null) {
                    key = 4
                }
                const form = e.target.form;
                const index = Array.prototype.indexOf.call(form, e.target);
                form.elements[index + key].focus();

                this.onUpdate(e, true)

            }
        }
    }

    enter(e) {
        if (e.key === 'Enter') {
            if (!this.disabled()) {
                const form = e.target.form;
                const index = Array.prototype.indexOf.call(form, e.target);
                form.elements[index + 1].focus();
                this.onUpdate(e, true)
            }
        }
    }


    render() {
        return (
            <>
                <tr>

                    <th scope="row"
                        draggable={true}
                        onDragOver={e => this.onDragOver(e)}
                        onDrop={e => this.onDrop(e)}
                        onDragStart={e => this.onDragStart(e)}
                        className="text-center">
                        {this.state.index + 1}
                    </th>

                    <td className={"th-actions"}>
                        {this.state.product !== null && (
                            <Link style={{color: "#20a8d8"}} to={{
                                pathname: getRoutes("Modifier stock", this.state.line?.product.id),
                                aboutProps: {
                                    stock: this.state.product
                                }
                            }}>
                                <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
                                {this.state.line?.product?.ref} ( {this.state.line?.lot?.pid ?? ""} {this.state.line?.lot?.fournisseur?.nom ?? this.injected.rootStore.authStore.nameCompany})
                            </Link>
                        )}


                        {this.state.product === null && (
                            <Input
                                onKeyPress={e => this.enter(e)}
                                type={"text"}
                                placeholder={"Référence"}
                                value={this.state.ref}
                                disabled={this.disabled()}
                                onChange={async e => {
                                    await this.setState({ref: e.target.value})
                                    await this.calculateTotal()
                                }
                                }
                            />
                        )}
                    </td>

                    <td>

                        {this.state.product !== null && (
                            <Link style={{color: "#20a8d8"}} to={{
                                pathname: getRoutes("Modifier stock", this.state.line?.product?.id),
                                aboutProps: {
                                    stock: this.state.product
                                }
                            }}>
                                <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
                                {this.state.nom}
                            </Link>
                        )}


                        {this.state.product === null && (
                            <Input
                                onKeyPress={e => this.enter(e)}
                                disabled={this.disabled()}
                                type="text"
                                name="nom"
                                value={this.state.nom ?? ""}
                                onChange={async e => {
                                    await this.setState({nom: e.target.value})
                                    await this.calculateTotal()
                                }}
                            />)}
                    </td>


                    <td className={"th-number"}>
                        <Input
                            disabled={this.disabled()}
                            type="number"
                            onKeyPress={e => this.enter(e)}
                            name="pu"
                            value={this.state.quantity ?? 1}
                            onChange={async e => {
                                await this.setState({
                                    quantity: e.target.value,
                                    quantity_received: e.target.value,
                                    quantity_left: parseFloat(this.state.quantity_left) + (parseFloat(e.target.value) - parseFloat(this.state.quantity_left))
                                })
                                await this.calculateTotal()
                            }}
                        />
                    </td>

                    <td className={"th-price"}>
                        <span className="mb-0 text-sm">
                                  <Input
                                      onKeyPress={e => this.enter(e)}
                                      data-toggle="tooltip" data-placement="right" title={this.state.prix_unitaire}
                                      className="form-control-alternative"
                                      type={"number"}
                                      value={this.state.prix_unitaire}
                                      disabled={this.disabled()}
                                      onChange={async e => {
                                          await this.setState({
                                              prix_unitaire: e.target.value,
                                              total_unit_ht: parseFloat(e.target.value / 1.2).toFixed(2)
                                          })
                                          await this.calculateTotal()
                                      }}
                                  />
                        </span>
                    </td>
                    <td className={"th-price"}>
                        <span className="mb-0 text-sm">
                                  <Input
                                      onKeyPress={e => this.enter(e)}
                                      data-toggle="tooltip" data-placement="right" title={this.state.total_unit_ht}
                                      className="form-control-alternative"
                                      type={"number"}
                                      value={this.state.total_unit_ht}
                                      disabled={this.disabled()}
                                      onChange={async e => {
                                          await this.setState({
                                              total_unit_ht: e.target.value,
                                              prix_unitaire: parseFloat(e.target.value * 1.2).toFixed(2)
                                          })
                                          await this.calculateTotal()
                                      }}
                                  />
                        </span>
                    </td>


                    <td className={"th-number"}>
                        <Input
                            disabled={this.disabled()}
                            type="number"
                            onKeyPress={e => this.enter(e)}
                            name="remise"
                            value={this.state.remise ?? 0}
                            onChange={async e => {
                                await this.setState({remise: e.target.value})
                                await this.calculateTotal()
                            }}
                        />
                    </td>

                    <td className={"th-number"}>
                        <Input
                            disabled={this.disabled()}
                            type="number"
                            onKeyDown={e => this.specialEnter(e)}
                            onKeyPress={e => this.enter(e)}
                            name="tva"
                            value={this.state.tva ?? 0}
                            onChange={async e => {
                                await this.setState({tva: e.target.value})
                                await this.calculateTotal()
                            }}
                        />
                    </td>

                    <td>
                        <span className="mb-0 text-sm">
                            {this.state.montant_tva} €
                        </span>
                    </td>


                    <td>
                        {this.state.totalht} €
                    </td>

                    <td>
                        {this.state.totalttc} €
                    </td>


                    <td>

                        {this.state.product === null && (
                            <Badge color="primary"> <i className="fa fa-exchange" aria-hidden="true"/>
                            </Badge>
                        )}

                        {(this.state.product !== null && this.state.quantity_stock === 0) && (
                            <Badge color="danger">Aucun reçue </Badge>
                        )}

                        {(this.state.product !== null && this.state.quantity_stock !== 0 && this.state.quantity_stock !== this.state.quantity) && (
                            <Badge color="success">{this.state.quantity_stock} reçue(s)</Badge>
                        )}

                        {(this.state.product !== null && this.state.quantity_stock === this.state.quantity) && (
                            <Badge color="warning">Tout reçue</Badge>
                        )}
                    </td>

                    <td>
                        {this.state.product === null && (
                            <Badge color="primary"> <i className="fa fa-exchange" aria-hidden="true"/> </Badge>
                        )}

                        {(this.state.product !== null && this.state.quantity_left !== 0) && (
                            <Badge color="danger">{this.state.quantity_left} restante(s) </Badge>
                        )}

                        {(this.state.product !== null && this.state.quantity_left === 0) && (
                            <Badge color="success">{this.state.quantity_left} restante </Badge>
                        )}
                    </td>

                    <td className={"th-number"}>
                        {this.state.product !== null && (
                            <Input
                                disabled={!this.disabled() || this.state.quantity_stock === this.state.quantity}
                                type="number"
                                min={0}
                                max={this.state.quantity_left}
                                name="quantity_receive"
                                value={this.state.quantity_received}
                                onChange={async e => {
                                    await this.setState({quantity_received: e.target.value})
                                    await this.calculateTotal()
                                }}
                            />
                        )}

                        {this.state.product === null && (
                            <Badge color="primary"> <i className="fa fa-exchange" aria-hidden="true"/> </Badge>
                        )}

                    </td>


                    <td className={"th-price"}>

                        {(this.state.product !== null && this.state.commande.status === "RECEPTION" && this.state.quantity_stock !== this.state.quantity) && (

                            <Button className="mr-2" variant="success" size="sm" type="button"
                                    onClick={e => this.setMouvStock(e)}>
                                Recevoir
                                <i className="fa fa-check"/>
                            </Button>

                        )}

                        {(this.state.commande.status === "DRAFT" || this.state.commande.status === "ORDERED") && (
                            <Button className="mr-2" variant="success" size="sm" type="button"
                                    onClick={e => this.onUpdate(e)}>
                                <i className="fa fa-check"/>
                            </Button>)}

                        {(this.state.commande.status === "DRAFT" || this.state.commande.status === "ORDERED") && (
                            <Button
                                onClick={(e) => {
                                    this.copyLine(e, "copy");
                                }} color="primary" size="sm" type="button" className="mr-1">
                                <i className="fa fa-copy"/>
                            </Button>)}

                        {(this.state.commande.status === "DRAFT" || this.state.commande.status === "ORDERED") && (
                            <Button variant="danger" size="sm" onClick={this.toggleDanger} type="button">
                                <i className="fa fa-minus"/>
                            </Button>)}
                    </td>

                </tr>
            </>
        );
    }

}));

export default LineCommandeRow;
