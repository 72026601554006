import React from "react";
import {inject, observer} from "mobx-react";
import styles from "./Accueil.module.css"
import configuration from "../../../configuration/configuration";
import {Container} from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Card from "reactstrap/lib/Card";
import {Animated} from "react-animated-css";
import CardTitle from "reactstrap/lib/CardTitle";

const Accueil = inject("rootStore")(observer(class Accueil extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.removeHeader()
    }

    render() {
        return (
            <>

                <div className={styles.header}>
                    <div className={styles.overlay}></div>
                    <video className={styles.video} playsInline="playsinline" autoPlay="autoplay" muted="muted"
                           loop="loop">
                        <source src={configuration.API + "/backend/video/it.mp4"} type="video/mp4"/>
                    </video>
                    <div className="container h-100">
                        <div className="d-flex h-100 text-center align-items-center">
                            <div className="w-100 text-white">
                                <h1 className={styles.element + " display-3"}>{this.injected.rootStore.authStore.nameCompany} Dashboard</h1>
                                <p className={styles.element + " lead mb-0"}>Des réparations de qualité pour tous !</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<Container className="mt-2" fluid>*/}

                {/*    <Row>*/}
                {/*        <Col sm="3">*/}
                {/*            <Card*/}
                {/*                body*/}
                {/*                color={this.injected.rootStore.authStore.isLoggedEcho ? "success" : "danger"}*/}
                {/*                inverse*/}
                {/*            >*/}
                {/*                <Animated animationIn="fadeIn" animationOut="fadeOut"*/}
                {/*                          isVisible={true}>*/}
                {/*                    <CardTitle tag="h5" className={this.injected.rootStore.authStore.isLoggedEcho ?  "blinkText" : ""}>*/}

                {/*                        {this.injected.rootStore.authStore.isLoggedEcho ? (*/}
                {/*                            "Connecté au serveur d'évenements"*/}
                {/*                        ) : (*/}
                {/*                            "Déconnecté du serveur d'évenements"*/}
                {/*                        )}*/}
                {/*                    </CardTitle> </Animated>*/}
                {/*            </Card>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</Container>*/}

            </>
        );
    }

}));

export default Accueil;
