import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";

export default class BlogAPI {


    // BLOG PART

    async getBlogs(limit, pageNumber) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/blogs?page=${pageNumber}&limit=${limit}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve({blogs: response.data.data, lastPage :response.data.last_page, currentPage: response.data.current_page})
            } catch (error) {
                reject(error)
            }
        })
    }

    async findBlogById(id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/blogs/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async createBlog(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/blogs`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data )
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async changeStatus(data, title) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/status/` + title,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve( response.data )
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async update(data, id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/blogs/` + id,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve( response.data )
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async delete(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/blogs/delete/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }


}