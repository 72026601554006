
import React from "react";
import i18n from "i18next";
import {inject, observer} from "mobx-react";
import Pagination from "../../../../components/Akitatek/Pagination";
import TableGeneric from "../../../../components/Akitatek/Headers/TableGeneric";
import ProductRow from "./ProductRow";


const Products = (inject("rootStore") (observer( class Products extends React.Component {

    parentCallbackPage = (childData) => {
        this.injected.rootStore.annuaireStore.productStore.currentPage = childData
        this.injected.rootStore.annuaireStore.productStore.getProducts();
    };

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                        <TableGeneric titles={[
                            i18n.t('page.admin.prestations.name'),
                            "Prestations",
                            "url",
                            "Actions"
                        ] }>

                            {  this.injected.rootStore.annuaireStore.productStore.products
                                .map((product, id) => {
                                    return <ProductRow data={product} key={id} />

                                })
                            }
                        </TableGeneric>
                        <Pagination currentPage={this.injected.rootStore.annuaireStore.productStore.currentPage}
                                    lastPage={this.injected.rootStore.annuaireStore.productStore.lastPage}
                                    parentCallback={this.parentCallbackPage}/>
            </>
        );
    }
})));

export default Products;
