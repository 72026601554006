import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {inject, observer} from "mobx-react";
import styles from './../PriseEnCharge/Timeline/Timeline.module.css'
import AddIcon from '@material-ui/icons/Add';
import Input from "reactstrap/lib/Input";
import moment from "moment";
import 'moment/locale/fr';
import {Button} from "reactstrap";
import i18n from "i18next";

const TimelinePec = inject("rootStore") (observer( class TimelinePec extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props)
        this.state = {
            status: this.injected.status,
            id: this.injected.id,
            notes: this.injected.notes ?? [],
            texte: "",
            titre: "",
            timer: null
        }
        this.countingSecond = this.countingSecond.bind(this)
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.notes !== prevProps.notes ||
            this.injected.status !== prevProps.status  ||
            this.injected.id !== prevProps.id
        ) {
            this.setState({
                status: this.injected.status,
                notes : this.injected.notes,
                id: this.injected.id,

            })
        }
    }

    countingSecond() {
        this.setState({
            time: moment().format('LTS'),
        })
    }

    componentWillUnmount() {
        window.clearInterval(this.state.timer)
        this.setState({timer: null})

    }

    componentDidMount() {
        this.setState({timer: window.setInterval(this.countingSecond, 1000)})
    }


    setComponent(note) {
            if(note.user?.role  === "admin") {
                return  <TimelineItem>
                    <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined">
                        <i className={note.icone.replace("fas", "fa") + " fa-2x"}></i>
                    </TimelineDot>
                    <TimelineConnector/>
                </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={3} className={styles.paper}>
                            <Typography variant="h6" component="h1">
                                {note.titre}
                            </Typography>
                            <Typography> {note.texte}</Typography>
                        </Paper>
                        <Typography variant="body2" color="textSecondary">
                            {note.user?.fullname} {" le "}
                            {moment(note.created_at, 'YYYY-MM-DD hh:mm:ss').format('Do MMMM YYYY hh:mm:ss')}
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            } else  return <TimelineItem >
                <TimelineOppositeContent>
                    <Paper elevation={3}  className={styles.paper2}>
                        <Typography variant="h6" component="h1">
                            {note.titre}
                        </Typography>
                        <Typography> {note.texte}</Typography>
                    </Paper>
                    <Typography variant="body2" color="textSecondary">
                        {note.user?.fullname} {" le "}
                        {moment(note.created_at, 'YYYY-MM-DD hh:mm:ss').format('Do MMMM YYYY hh:mm:ss')}
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                    <i className={note.icone.replace("fas", "fa") + " fa-2x"}></i>
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
                <TimelineContent>
                    <Typography variant="body2" color="textSecondary">
                    </Typography>
                </TimelineContent></TimelineItem>

    }


    createComponent() {
        return ( <TimelineItem>
            <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                    {this.injected.rootStore.authStore.user?.fullname} {" le "}
                    {moment().format('Do MMMM YYYY')} {this.state.time}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                    <AddIcon />
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={3} className={styles.paper}>
                    <Typography variant="h6" component="h1">
                        <Input
                            id={"refTimelinePec"}
                            value={this.state.titre}
                            onChange={e => this.setState({ titre: e.target.value})}
                            placeholder={"Entrer votre titre"}
                            type="text"
                            name="text"  />
                    </Typography>
                    <Typography><Input
                        value={this.state.texte}
                        onChange={e => this.setState({ texte: e.target.value})}
                        placeholder={"Notez"}
                        type="textarea"
                        name="text" />

                        <Button color="success" onClick={e => this.enter(e)}>
                            {i18n.t('button.submit')}
                        </Button>


                    </Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>)
    }

    async enter(e) {
        e.preventDefault()
        await this.onSubmit(e)
    }

    async onSubmit(e) {
        e.preventDefault();

        let data = {
            id: this.state.id,
            titre: this.state.titre,
            texte: this.state.texte,
            user_id: this.injected.rootStore.authStore.user?.id
        }

        let authResponse = await this.injected.rootStore.prisenchargeStore.saveNotePec(data)

        if(authResponse?.status === "success") {
            this.setState({ notes: authResponse.datas, titre: "", texte: ""})
            document.getElementById("refTimelinePec").focus();
        }
    }





    render() {
        return (

            <Timeline>
                {this.createComponent()}

                {this.state.notes.map(note => {
                    return this.setComponent(note)
                })}
            </Timeline>
        );
    }
}));

export default TimelinePec

