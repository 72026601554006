import React from "react";
import {inject, observer} from "mobx-react";
import {Link, withRouter} from "react-router-dom";
import moment from "moment";
import getRoutes from "../../../components/Akitatek/getRoutes";


const TableStats = inject("rootStore")(observer(class TableStats extends React.Component {

    invoice;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.invoice = this.injected.invoice;

        this.state = {
            nom: this.injected.invoice.user?.nom ?? this.injected.user?.nom,
            email: this.injected.invoice.user?.email ?? this.injected.user?.email,
            invoice: this.injected.invoice,
        }
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.invoice !== prevProps.invoice
        ) {
            this.setState({
                invoice: this.injected.invoice
            })
        }
    }

    check(title) {
        if (title === "solde") {
            return (<span className="badge badge-pill badge-success">{title}</span>)
        } else if (title === "élaboration") {
            return (<span className="badge badge-pill badge-light">{title}</span>)
        } else if (title === "validé") {
            return (<span className="badge badge-pill badge-danger">A encaisser</span>)
        }
    }


    async onCopy(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setQuestion("Voulez vous copier la facture " + this.state.invoice.intitule + " ?", "Copier #" + this.state.invoice.id, e => this.onChange())

    }

    async onChange() {
        let authResponse = await this.injected.rootStore.invoiceStore.copyInvoice(this.state.invoice.id)
        if (authResponse?.status === "success") {
            this.injected.history.push(
                getRoutes("Gérer une facture", authResponse.datas.id))
        }
    }


    render() {
        return (
            <>
                <tr>
                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                                <Link to={{
                                    pathname:  getRoutes("Gérer une facture", this.state.invoice.id),
                                    aboutProps: {
                                        isCommandeValid: true,
                                        invoice: this.state.invoice
                                    }
                                }}>
                                #{this.state.invoice?.id}
                                 </Link>
                        </span>
                    </th>


                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {moment(this.state.invoice?.created_at).format("lll")}
                        </span>
                    </td>

                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {/*{moment(this.state.invoice?.created_at).format("lll")}*/}
                            {(this.state.invoice?.facture_reglement.map((reglement) => {
                                return moment(reglement.date_encaissement).format("lll")
                            }))}
                        </span>
                    </td>


                    <td className="text-center">
                                <span className="mb-0 text-sm">

                                {this.state.invoice?.totalhtMo} €
                            </span>
                    </td>

                    <td className="text-center">
                                <span className="mb-0 text-sm">

                                {this.state.invoice?.totalhtNotMo} €
                            </span>
                    </td>

                    <td className="text-center">
                                <span className="mb-0 text-sm">

                                {this.state.invoice?.totalht} €
                            </span>
                    </td>

                    <td className="text-center">
                                <span className="mb-0 text-sm">

                                {this.state.invoice?.montant_tva} €
                            </span>
                    </td>

                    <td className="text-center">
                                <span className="mb-0 text-sm">

                                {this.state.invoice?.totalht2} €
                            </span>
                    </td>


                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                            {this.state.invoice.totalttc} €
                        </span>
                    </th>

                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                            {this.state.invoice.facture_reglement.length === 1 && this.state.invoice.facture_reglement[0].montant}

                            {this.state.invoice.facture_reglement.length > 1 && this.state.invoice.facture_reglement?.reduce((a, b) => Number(a.montant) +  Number(b.montant))} €
                        </span>
                    </th>
                </tr>
            </>
        );
    }

}));

export default withRouter(TableStats);
