import axios from "axios";
import configuration from "../configuration/configuration";

export default class CarrouselAPI {

    async getCarrousels() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/carrousel`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    createCarrousel(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                const data = new FormData();

                if (datas.file) {
                    data.append('file', datas.file)
                }

                data.append('descriptif', datas.descriptif);
                data.append('link', datas.link)
                data.append('titre', datas.titre);
                data.append('order', datas.order);
                data.append('actif', datas.actif);
                data.append('data', JSON.stringify(datas.file));

                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/carrousel`,
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    data
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    updateCarrousel(datas) {
        return new Promise(async (resolve, reject) => {
            try {
                const data = new FormData();

                if (datas.file) {
                    data.append('file', datas.file)
                }

                data.append('_method', 'PUT');
                data.append('id', datas.id);
                data.append('descriptif', datas.descriptif);
                data.append('link', datas.link)
                data.append('titre', datas.titre);
                data.append('order', datas.order);
                data.append('actif', datas.actif);
                data.append('data', JSON.stringify(datas.file));

                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/carrousel`,
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    data
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }
}