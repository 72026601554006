/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Table} from "reactstrap";
import {inject, observer} from "mobx-react";
import OrderTh from "./OrderTh";


const TableGeneric = (inject("rootStore")(observer(class TableGeneric extends React.Component {

    titles = [];
    key;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.titles = this.injected.titles || [];
        this.filters = this.injected.filters || [];
    }

    render() {
        return (
            <>
                <Table bordered striped hover>
                    <thead>
                    <tr className="text-center">
                        {
                            this.titles.map((title, key) => {
                                return <OrderTh title={title} index={key} onFilter={this.injected.onFilter} filters={this.filters}/>
                            })
                        }
                    </tr>
                    </thead>
                    <tbody key={this.props.key}>
                    {this.props.children}
                    </tbody>
                </Table>

            </>
        );
    }
})));

export default TableGeneric;
