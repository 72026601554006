import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Container, Form, Row,} from "reactstrap";
import {Col} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import Label from "reactstrap/lib/Label";
import Tabs from "../../../components/Akitatek/Tabs/Tabs";
import TimelineTicket from "./TimelineTicket";
import moment from "moment";
import getRoutes from "../../../components/Akitatek/getRoutes";
import UserInformation from "../../../components/Akitatek/AdressCustomer";

const EditTicket = inject("rootStore")(observer(class EditTicket extends React.Component {

    errorStore = this.injected.rootStore.errorStore;
    ticket;

    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des tickets", "Interface Technicien / Clients pour gestion des tickets", "default.jpg")

        this.state = {
            ticket_lignes: this.injected.location.aboutProps?.ticket?.ticket_lignes,
            refresh: true,
            ticket: this.injected.location.aboutProps?.ticket ?? {
                user: {appareils: []},
                activeTab: "1",
            }
        };
    }

    //update component if the id change, or if there is a refresh
    async componentDidUpdate(prevProps) {
        if (this.injected.location !== prevProps.location) {
            await this.onRouteChanged()
        }
        if (this.state.ticket === "" && this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }

    //check if the values are up to date
    async componentDidMount() {
        if (this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }

    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (id) {
            let ticket = await this.injected.rootStore.ticketsStore.getTicketForId(id);

            if (ticket) {
                this.setState({
                    ticket: ticket,
                    ticket_lignes: ticket.ticket_lignes,
                })
            }
        }
    }


    form() {
        return (

            <div>
                <Form
                    className="mb-4"
                    id="form-add-priseencharge"
                    name="form-add-priseencharge"
                    style={{textAlign: "center"}}
                >
                    <CardBody>
                        <div style={{display: "flex"}}>
                            <Col>
                                <Row>
                                    <Col md={2}>
                                        <Label for="exampleEmail">Description simple :</Label>
                                    </Col>
                                    <Col md={4} style={{fontStyle: "italic"}}>
                                        {this.state.ticket?.description_simple}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <Label for="exampleEmail">Description longue : </Label>
                                    </Col>
                                    <Col md={4} style={{fontStyle: "italic"}}>
                                        {this.state.ticket?.description_complete}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <Label for="exampleEmail">Crée le :</Label>
                                    </Col>
                                    <Col md={4} style={{fontStyle: "italic"}}>
                                        {moment(this.state.ticket?.created_at).format("lll")}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={2}>
                                        <Label for="exampleEmail">Pris en charge par :</Label>
                                    </Col>
                                    <Col md={4} style={{fontStyle: "italic"}}>
                                        {this.state.ticket?.technicien?.fullname}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={2}>
                                        <Label for="exampleEmail">Machine:</Label>
                                    </Col>
                                    <Col md={4} style={{fontStyle: "italic"}}>

                                    </Col>
                                </Row>
                            </Col>

                            <Card>
                                <CardHeader>
                                    <CardTitle style={{fontWeight: "bold"}}>
                                        <h4>
                                            <Link
                                                to={{pathname: getRoutes("Modifier Client", this.state.ticket?.user?.id)}}>
                                                {this.state.ticket?.user?.fullname}
                                            </Link>
                                        </h4>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <UserInformation user={this.state.ticket?.user} hasAdresse={false}/>
                                </CardBody>
                            </Card>
                        </div>
                    </CardBody>
                </Form>
            </div>)
    }

    async takeIn(e) {
        if (e) {
            e.preventDefault();
        }
        let data = {
            ticket_id: this.state.ticket?.id,
            titre: "Ticket pris en charge par " + this.injected.rootStore.authStore.user?.fullname,
            texte: "Le technicien " + this.injected.rootStore.authStore.user?.fullname + " a pris en charge votre ticket, AkitaTek vous tient informé de l'avancement de votre ticket sur cette page.",
            status: "TAKE_IN",
            icone: "fab fa-creative-commons-by",
            technicien_id: this.injected.rootStore.authStore.user?.id,
            user_id: this.injected.rootStore.authStore.user?.id
        }

        let authResponse = await this.injected.rootStore.ticketsStore.saveNote(data, "Ticket n°" + this.state.ticket?.id + " pris en charge")

        if (authResponse?.status === "success") {
            let ticket = this.state.ticket
            ticket.statusenum = "TAKE_IN"
            ticket.statusname = "PRIS EN CHARGE"
            ticket.ticket_lignes = authResponse.datas
            this.setState({
                ticket: ticket,
                titre: "",
                texte: "",
                activeTab: "2",
                ticket_lignes: authResponse.datas,
                refresh: !this.state.refresh
            })
        }
    }

    takeInBis = (childData) => {
        let ticket = this.state.ticket
        ticket.statusenum = "TAKE_IN"
        ticket.statusname = "PRIS EN CHARGE"
        ticket.ticket_lignes = childData
        this.setState({ticket: ticket, titre: "", texte: "", activeTab: "2", ticket_lignes: childData})
    }

    async closeIt(e) {
        e.preventDefault();
        let data = {
            ticket_id: this.state.ticket?.id,
            titre: this.injected.rootStore.authStore.user?.fullname + " a cloturé le ticket",
            texte: "Nous avons clôturé le ticket n°" + this.state.ticket?.id + ", vous pouvez nous contacter pour demander à ouvrir ce ticket.",
            status: "OVER",
            icone: "fas fa-folder-plus",
            user_id: this.injected.rootStore.authStore.user?.id
        }

        let authResponse = await this.injected.rootStore.ticketsStore.saveNote(data, "Ticket  n°" + this.state.ticket?.id + " cloturé")

        if (authResponse?.status === "success") {
            let ticket = this.state.ticket
            ticket.statusenum = "OVER"
            ticket.statusname = "CLOT"
            ticket.ticket_lignes = authResponse.datas
            this.setState({
                ticket: ticket,
                titre: "",
                texte: "",
                activeTab: "2",
                ticket_lignes: authResponse.datas,
                refresh: !this.state.refresh
            })
        }
    }

    setTimeline() {
        return (<TimelineTicket
            takeIn={this.takeInBis}
            refresh={this.state.refresh}
            status={this.state.ticket?.statusenum}
            notes={this.state.ticket_lignes ?? []}
            id={this.state.ticket?.id}/>)
    }

    onSendBackTab = (nb) => {
        this.setState({
            activeTab: nb
        })
    }

    render() {
        return (
            <>
                {this.state.ticket?.id ? (
                        <Container className="mt-2" fluid>
                            <Row className="mb-3">
                                <div className="col">
                                    <Card className="shadow">
                                        <CardHeader
                                            className={this.injected.rootStore.savedDatasStore.setColor(this.state.ticket?.statusenum)}
                                            style={{display: "flex", justifyContent: "space-between"}}>
                                            <h4 className="mb-0">
                                                {this.state.ticket?.id && (
                                                    "Ticket #" + this.state.ticket?.id + " " + this.state.ticket?.statusname.toUpperCase()
                                                )}
                                            </h4>
                                        </CardHeader>
                                        <Tabs
                                            onCallback={this.onSendBackTab}
                                            activeTab={this.state.activeTab}
                                            children={[this.form(), this.setTimeline()]} titles={["Infos", "Timeline"]}/>
                                    </Card>

                                    <CardFooter>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <Button color={"success"}
                                                    onClick={e => this.takeIn(e)}
                                                    disabled={this.state.ticket.statusenum !== "OPEN"} className={"mr-2"}>Prendre
                                                en charge le ticket</Button>
                                            <Button color={"primary"} disabled={this.state.ticket.statusenum === "OVER"}
                                                    onClick={e => this.setState({
                                                        activeTab: "2"
                                                    })} className={"mr-2"}>Compléter la timeline</Button>

                                            <Button disabled={this.state.ticket.statusenum === "OVER"}
                                                    href={"#/priseencharge/add"}
                                                    color={"warning"}
                                                    className={"mr-2"}>Créer une une prise en charge</Button>
                                            <Button color={"danger"} disabled={this.state.ticket.statusenum === "OVER"}
                                                    onClick={e => this.closeIt(e)} className={"mr-2"}>Clotûrer le
                                                ticket</Button>
                                        </div>
                                    </CardFooter>
                                </div>
                            </Row>
                        </Container>) :
                    (<Container className={"mt-2"} fluid> <Card><CardBody>Ticket introuvable. Merci de
                        réessayer</CardBody></Card> </Container>)}
            </>
        );
    }
}));

export default withRouter(EditTicket);

