import React from "react";
import {Link} from "react-router-dom";
import getRoutes from "../getRoutes";

function MapRow({element, href, type}) {

    function title(data) {
        if(type === "appareil") {
            return data?.appareil?.marque?.nom + " " + data?.appareil?.nom
        }

        if(type === "printer") {
            return data.type
        }

        return data.title
    }

    function id(data) {
        if(type === "appareil") {
            return data?.appareil?.id
        }

        return data.id
    }

    return <td className="text-center">
                    <span className="mb-0  text-sm">
                        {
                            element?.map(data => {
                                return <p key={data.id}
                                          className={"mt-2"}>
                                    {href ?   (<Link to={{
                                        pathname: getRoutes(href, id(data)) ,
                                        aboutProps: {
                                            data: data
                                        }
                                    }}>
                                        {title(data)}
                                    </Link> )
                                        : ( title(data))
                                    }
                                </p>

                        })}
                    </span>
    </td>
}

export default MapRow;
