import {Card, CardBody, CardTitle} from "reactstrap";
import React from "react";
import {inject, observer} from "mobx-react";

const Address = (inject("rootStore") (observer( class Address extends React.Component {

    render() {
        return <Card style={{width: "20em"}}>
            <CardBody>
                <CardTitle style={{fontWeight: "bold"}}>{this.props.rootStore.authStore.nameCompany}</CardTitle>
                <div style={{ fontStyle: "italic"}}>
                    <div> {this.props.rootStore.authStore.complement_adresse} </div>
                    <div> {this.props.rootStore.authStore.adresse} </div>
                    <div>{this.props.rootStore.authStore.postcode} </div>
                    <div>{this.props.rootStore.authStore.city} </div>
                    <div>  {this.props.rootStore.authStore.telCompany} </div>
                    <div> {this.props.rootStore.authStore.emailCompany} </div>
                </div>
            </CardBody>
        </Card>
    }
})));

export default Address;