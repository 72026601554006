/* Global import */
import {decorate, observable} from 'mobx';

/* Custom import */
import Api from '../../util/api'

export default class ProductStore {

    products = observable([]);
    allProducts = observable([]);
    search = ""
    marqueId = ""
    marque = ""


    constructor(rootStore, annuaireStore) {
        this.rootStore = rootStore
        this.annuaireStore = annuaireStore;
        this.api = new Api();
    }


    async getAllProducts() {
        this.allProducts =  await  this.api.products.allProducts()
    }

    async getProducts(marqueId, search= "")  {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.products.getProducts(marqueId ? marqueId : this.marqueId, search? search : this.search);
            this.products = [];

            if(this.products.length > 0) {
                this.products.replace(authResponse.datas)
            } else {
                this.products = authResponse.datas;
            }

           this.rootStore.savedDatasStore.setLoading(false)

            return authResponse.data;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async createProduct(data) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.products.createProductWithFile(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(  "Product " + authResponse?.datas?.nom + " ajouté", "success");
                this.getProducts()
            }

             this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateProduct(data) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.products.updateProductWithFile(data);

            if(authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(  "Product " + authResponse?.datas?.nom + " modifié", "success");
                this.getProducts()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async findProductById(id) {
        this.rootStore.savedDatasStore.setLoading(true)

        let myProduct;
        try {
            myProduct = await this.api.products.findProductById(id);
        } catch(e) {}
        this.rootStore.savedDatasStore.setLoading(false)
        return myProduct;
    }


    async deleteProduct(id) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let status = await this.api.products.deleteProducts(id);


            if(status === "success") {
                this.rootStore.notificationStore.setNotification("Produit # " + id + " supprimé",  "success");
                this.getProducts()
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return status;

        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
}

decorate(ProductStore, {
    lastPage: observable,
    currentPage: observable,
    products: observable,
    allProducts: observable,
    limit: observable,
    marqueId: observable,
    marque: observable,
    search: observable,
});