import CreateInvoiceStore from "./createInvoiceStore";
import {action, decorate, observable} from "mobx";
import Api from "../../util/api";
import printJS from "print-js";
import Dropdown from "react-bootstrap/Dropdown";
import React from "react";

export default class InvoiceStore {
    datas = observable([]);
    filter = {
        limit: "15",
        search: "",
        page: "1",
        last_page: "1",
        name: "updated_at",
        order: false,
        user_id: "",
        status: ["TO_PAID"]
    }

    defaultFilter = {
        limit: "15",
        search: "",
        page: "1",
        last_page: "1",
        user_id: "",
        name: "updated_at",
        order: false,
        status: ["TO_PAID"]
    }
    allStatus = observable([]);
    statusname = "";

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.createInvoiceStore = new CreateInvoiceStore(this.rootStore, this);
        this.api = new Api();
    }

    getStatusname() {
        this.statusname = ""
        this.allStatus.forEach(element => {
            if (element.value === this.filter.status[0]) {
                this.statusname = element.status
            }}
        );
    }

    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.invoices.getInvoices(this.filter);

            if (this.datas.length > 0) {
                this.setInvoices(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }
            this.filter.last_page = authResponse.last_page;
            this.filter.page = authResponse.current_page;
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getAllStatus() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            this.allStatus = await this.api.invoices.getAllStatus();

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

        this.rootStore.savedDatasStore.setLoading(false)
    }

    async copyInvoice(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.invoices.copyInvoice(datas.id, datas.status);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Facture #" + authResponse.datas.id + " ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async cancelInvoice(id, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.invoices.cancelInvoice(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Facture #" + authResponse.datas.id + " annulée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async replaceInvoice(id, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.invoices.replaceInvoice(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Facture #" + authResponse.datas.id + " remplacée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveInvoice(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.invoices.saveInvoice(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Facture #" + authResponse.datas.id + " créee", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async archived(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.invoices.archived(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Facture #" + authResponse.datas.id + " archivée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async updateInvoice(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.invoices.updateInvoice(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Facture #" + authResponse.datas.id + " modifiée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    setInvoices(datas) {
        this.datas.replace(datas)
    }


    async getPdf(mediaId, status, print = false, type) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let pdf = await this.api.invoices.getPdf(mediaId, status, print, type)

            if (this.rootStore.authStore.modules['print'] === 1 && print) {
                this.rootStore.notificationStore.setNotification("Impression en cours", "success");
            }
            this.rootStore.savedDatasStore.setLoading(false)
            return pdf;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async sendPdf(mediaId) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let status = await this.api.invoices.sendPdf(mediaId);

            if (status === "success") {
                this.rootStore.notificationStore.setNotification("Facture #" + mediaId + " bien envoyée !", "success");
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }

    async saveFactureLigne(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.invoices.saveFactureLigne(data);

            if (authResponse?.status === "success") {
                let index = data.position + 1
                this.rootStore.notificationStore.setNotification(message ?? "Ligne #" + index + " ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateFactureLigne(data, notHide) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.invoices.updateFactureLigne(data);

            if (authResponse?.status === "success") {
                if (notHide) {
                    let index = data.position + 1
                    this.rootStore.notificationStore.setNotification("Ligne #" + index + " sauvegardée", "success");
                }
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteFactureLigne(data, index = "") {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.invoices.deleteFactureLigne(data);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Ligne " + index + " supprimée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse.status
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.invoices.delete(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Facture # " + id + " supprimée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async togglePdf(e, id, email) {
        e.preventDefault()
        await this.rootStore.notificationStore.setQuestion("Etes vous sûr(e) de vouloir envoyer le devis par mail à " + email, " Envoyer", e => {
            this.sendMyPdf(id)
        })
    }

    async sendMyPdf(id) {
        if (id) {
            await this.sendPdf(id);
        }
    }

    async printPdf(e, id, status, type) {
        e.preventDefault();

        if (id) {
            const file = await this.getPdf(id, status, this.rootStore.authStore.modules['print'] === 1, type);

            if (file) {
                const fileURL = URL.createObjectURL(file);
                printJS(fileURL);

            }
        }
    }

    async downloadPdf(e, id, filename, status, type) {
        e.preventDefault();

        if (id) {
            const file = await this.getPdf(id, status, false, type);

            if (file) {
                const fileURL = URL.createObjectURL(file);
                var downloadLink = document.createElement("a");
                downloadLink.href = fileURL;
                downloadLink.download = filename

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

            }
        }
    }


    async openPdf(e, id, status, type) {
        e.preventDefault();

        if (id) {
            const file = await this.getPdf(id, status, false, type);

            if (file) {
                const fileURL = URL.createObjectURL(file);

                window.open(fileURL);

            }
        }
    }
}

decorate(InvoiceStore, {
    allStatus: observable,
    datas: observable,
    filter: observable,
    getDatas: action,
    statusname: observable
});

