import React from "react";
import {inject, observer} from "mobx-react";
import {CardHeader} from "reactstrap";
import styles from "../../../views/admin/Customers/Customers.module.css";

const HideComponenteHeader = inject("rootStore") (observer( class HideComponentHeader extends React.Component {

    get injected() {
        return this.props
    }

    constructor(props, context) {
        super(props, context);
        this.state = {display: this.props.default};
    }

    hideCard(e) {
        e.preventDefault();

        let hideCard = this.state.display === "none" ? "block" : "none";
        this.setState({display: hideCard});

        this.injected.parentCallback(hideCard);

    }

    render() {


        return (
            <a href="/#" onClick={(e) => this.hideCard(e)} style={{ cursor: "pointer"}}>
                <CardHeader className="bg-transparent"  style={{display: "flex", marginBottom: "1em", marginTop: "1em"}}>
                    <i  className={[styles.hideButton + " fa fa-arrow-down"]} />
                    {this.injected.children}
                </CardHeader>
            </a>
        )
    }
}));

export default HideComponenteHeader;
