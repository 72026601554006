import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {ListGroup, ListGroupItem, TabPane} from "reactstrap";
import {Button, FormGroup} from "react-bootstrap";
import Select from 'react-select';
import getRoutes from "../../../../components/Akitatek/getRoutes";
import PhoneRow from "../../../../components/Akitatek/Table/PhoneRow";


const FirstAsidePageUser = inject("rootStore")(observer(class FirstAsidePageUser extends Component {
        userStore = this.injected.rootStore.savedDatasStore;

        componentDidMount() {
            this.userStore.getAllUsers()
        }

        constructor(props, context) {
            super(props, context);
            let user = this.userStore.user;

            if (user.id !== "") {
                this.state = {
                    label: {value: user.id, label: "#" + user.id + " " + user.fullname}
                }
            } else {
                this.state = {
                    label: {value: "0", label: "Sélectionnez un client"}
                }
            }

        }

        get injected() {
            return this.props
        }


        onClear(e) {
            e.preventDefault()
            this.userStore.clearUser()
        }

        async changeUser(e) {
            await this.userStore.findUserById(e.value)

        }


        render() {
            return (
                <TabPane tabId="1">
                    {this.userStore.user.id !== "" && (
                        <ListGroup className="list-group-accent" tag={'div'}>
                            <div>
                                <ListGroupItem tag="a" action
                                               href={"/#" + getRoutes("Modifier Client", this.userStore.user.id)}
                                               className="list-group-item-accent-transparent bg-light text-center font-weight-bold text-muted text-uppercase small">
                                    # {this.userStore.user.id} {this.userStore.getUsername()}
                                </ListGroupItem>


                                {this.userStore.user.profile?.telephone_portable !== null && (
                                    <ListGroupItem href={"tel:" + this.userStore.user.profile?.telephone_portable} action
                                                   tag="a" className="list-group-item-accent-info list-group-item-divider">
                                        <div>Appeler le <strong>
                                            <PhoneRow title={this.userStore.user.profile?.telephone_portable}/>
                                        </strong></div>

                                    </ListGroupItem>
                                )}

                                {this.userStore.user.profile?.telephone_fixe !== null && (
                                    <ListGroupItem href={"tel:" + this.userStore.user.profile?.telephone_fixe} action
                                                   tag="a" className="list-group-item-accent-info list-group-item-divider">
                                        <div>Appeler le <strong>
                                            {this.userStore.user.profile?.telephone_fixe
                                            }</strong></div>
                                    </ListGroupItem>
                                )}

                                {this.userStore.user.profile?.email !== null && (
                                    <ListGroupItem href={"mailto:" + this.userStore.user?.email} action tag="a"
                                                   className="list-group-item-accent-info list-group-item-divider">
                                        <div>Envoyer un mail au <strong>{this.userStore.user?.email}</strong></div>
                                    </ListGroupItem>
                                )}
                            </div>

                            {Array.isArray(this.userStore.user.facture) && this.userStore.user.facture.length !== 0 && (
                                <ListGroupItem tag="a" action href={"/#" + getRoutes("Gérer une facture", "add")}
                                               className="list-group-item-accent-transparent bg-light text-center font-weight-bold text-muted text-uppercase small">3
                                    dernieres factures</ListGroupItem>
                            )}

                            {Array.isArray(this.userStore.user.facture) && this.userStore.user.facture.length === 0 && (
                                <ListGroupItem tag="a" action href={"/#" + getRoutes("Gérer une facture", "add")}
                                               className="list-group-item-accent-transparent bg-light text-center font-weight-bold text-muted text-uppercase small">Pas
                                    de factures ? Créer une Facture.</ListGroupItem>
                            )}


                            {Array.isArray(this.userStore.user.facture) && this.userStore.user.facture.length !== 0 && (
                                this.userStore.user.facture.slice(0, 3).map((facture) => {
                                    return (
                                        <ListGroupItem key={facture.id} action tag="a"
                                                       href={"/#" + getRoutes("Gérer une facture", facture.id)}
                                                       className={"list-group-item-divider " + this.injected.rootStore.savedDatasStore.setColorItemDivider(facture.statusEnum)}>
                                            <div> {facture.intitule} - <strong> {facture.total_facture} € </strong></div>
                                            <small className="text-muted mr-3"><i
                                                className="icon-calendar"></i>&nbsp; {facture.created_at} </small>
                                        </ListGroupItem>
                                    )
                                })
                            )}


                            {Array.isArray(this.userStore.user.devis) && this.userStore.user.devis.length !== 0 && (
                                <ListGroupItem tag="a" action href={"#/devis/edit/add"}
                                               className="list-group-item-accent-transparent bg-light text-center font-weight-bold text-muted text-uppercase small"> 3
                                    Derniers devis</ListGroupItem>
                            )}

                            {Array.isArray(this.userStore.user.devis) && this.userStore.user.devis.length === 0 && (
                                <ListGroupItem tag="a" action href={"#/devis/edit/add"}
                                               className="list-group-item-accent-transparent bg-light text-center font-weight-bold text-muted text-uppercase small">Pas
                                    de devis ? Créer un devis </ListGroupItem>
                            )}


                            {Array.isArray(this.userStore.user.devis) && this.userStore.user.devis.length !== 0 && (
                                this.userStore.user.devis.slice(0, 3).map((devis) => {
                                    return (
                                        <ListGroupItem key={devis.id} action tag="a" href={"/#/devis/edit/" + devis.id}
                                                       className={"list-group-item-divider " + this.injected.rootStore.savedDatasStore.setColorItemDivider(devis.status)}>
                                            <div> {devis.titre} - <strong> {devis.total_facture} € </strong></div>
                                            <small className="text-muted mr-3"><i
                                                className="icon-calendar"></i>&nbsp; {devis.created_at} </small>
                                        </ListGroupItem>
                                    )
                                })
                            )}


                            {Array.isArray(this.userStore.user.prise_en_charge) && this.userStore.user.prise_en_charge.length !== 0 && (
                                <ListGroupItem tag="a" action href={"#/priseencharge/add"}
                                               className="list-group-item-accent-transparent bg-light text-center font-weight-bold text-muted text-uppercase small"> 3
                                    Dernieres prise en charge</ListGroupItem>
                            )}

                            {Array.isArray(this.userStore.user.prise_en_charge) && this.userStore.user.prise_en_charge.length === 0 && (
                                <ListGroupItem tag="a" action href={"#/priseencharge/add"}
                                               className="list-group-item-accent-transparent bg-light text-center font-weight-bold text-muted text-uppercase small">Pas
                                    de pec ? Créer une pec </ListGroupItem>
                            )}


                            {Array.isArray(this.userStore.user.prise_en_charge) && this.userStore.user.prise_en_charge.length !== 0 && (
                                this.userStore.user.prise_en_charge.slice(0, 3).map((devis) => {
                                    return (
                                        <ListGroupItem key={devis.id} action tag="a"
                                                       href={"/#/priseencharge/update/" + devis.id}
                                                       className={"list-group-item-divider " + this.injected.rootStore.savedDatasStore.setColorItemDivider(devis.status)}>
                                            <div> {devis.title} - {devis.statusname}</div>
                                            <small className="text-muted mr-3"><i
                                                className="icon-calendar"></i>&nbsp; {devis.created_at} </small>
                                        </ListGroupItem>
                                    )
                                })
                            )}


                            <Button onClick={e => this.onClear(e)} variant="warning" type="button">
                                Détaché l'utilisateur
                            </Button>

                        </ListGroup>
                    )}
                    {this.userStore.user.id === "" && (
                        <FormGroup>
                            <Select
                                value={this.state.label}
                                className={"mb-3"}
                                onChange={e => this.changeUser(e)}
                                options={this.userStore.allUsers.map((user) => {
                                    return {value: user.id, label: "#" + user.id + " " + user.fullname}
                                })}
                            />
                        </FormGroup>

                    )}
                </TabPane>
            );
        }
    }
));

export default FirstAsidePageUser;
