import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";

export default class StatusAPI {
    async changeStatus(data, title) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/status/` + title,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve( response.data )
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async getStatus() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/status`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }
}