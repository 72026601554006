import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";

export default class AuthAPI {


    loginUrl(url) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/loginUrl`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        url
                    }
                });
                resolve({token: response.data.token, data: response.data})
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    login(email, password) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/login`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        email,
                        password
                    }
                });
                resolve({token: response.data.token, data: response.data})
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    updateLogo(datas) {
        return new Promise(async(resolve, reject) => {
            try {
                const data = new FormData();

                if (datas.file) {
                    data.append('file', datas.file)
                }

                data.append('data', JSON.stringify(datas.file));

                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/logo`,
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    data
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    updateSidebar(datas) {
        return new Promise(async(resolve, reject) => {
            try {
                const data = new FormData();
                if (datas.file) {
                    data.append('file', datas.file)
                }
                data.append('data', JSON.stringify(datas.file));

                const response = await axios({
                    method: 'POST',
                    url: `${configuration.API}/api/sidebar`,
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    data
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }


    async updateDetails(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/details`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async checkIsLoged(token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/details`,
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                });

                resolve({token: token, data: response.data });

            } catch (error) {
                reject('')
            }
        })
    }

    async refreshToken() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/refreshToken`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve({token: response.data.token, data: response.data})
            } catch (error) {
                reject('')
            }
        })
    }
}