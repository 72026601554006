import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import i18n from "i18next";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from "../Annuaire/Prestation/Prestation.module.css";
import CardImg from "react-bootstrap/CardImg";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";

const AddAsset = (inject("rootStore")(observer(class AddAsset extends React.Component {
    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);

        this.state = {
            files: [],
            card: this.injected.card ?? false
        }

        if (!this.state.card) {
            this.injected.rootStore.savedDatasStore.setHeader("Images", "Gestion des images", "motherboard.jpg")
        }

        this.injected.rootStore.imageStore.getAllTags()
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.card !== prevProps.card
        ) {
            this.setState({
                card: this.injected.card
            })
        }
    }

    async onSubmit(e) {
        e.preventDefault();

        let authResponse = await this.injected.rootStore.imageStore.save(this.state)

        if (authResponse?.status === "success" && !this.state.card) {
            this.onBack(e)
        }

        if (this.state.card) {
            await this.injected.rootStore.imageStore.getImages(6);
        }
    }

    handleFile(longName, index) {
        var data = this.state.files;
        data[index].longname = longName
        this.setState({files: data});
    }

    handleFileDescription(description, index) {
        var data = this.state.files;
        data[index].description = description
        this.setState({files: data});
    }


    async handleInputChange(inputValue, index) {
        var data = this.state.files;
        data[index].tags = [];

        if (inputValue) {
            for (const element of inputValue) {
                if (element.__isNew__ === true) {
                    var tag = await this.injected.rootStore.imageStore.createTag({
                        name: element.label
                    })

                    if (data[index].tags.indexOf(element.value) === -1) data[index].tags.push(tag.id);
                } else {
                    if (data[index].tags.indexOf(element.value) === -1) data[index].tags.push(element.value);
                }
            }
        }


        this.setState({files: data});
    };

    filterOption = (candidate, input) => {
        return input !== "" || candidate.nom === input;
    };

    setForm() {
        return (<Form style={{width: "100%"}}
                      role="form"
                      id="myFormUpdate"
                      onSubmit={e => this.onSubmit(e)}
        >

            <div className="pl-lg-4">
                <FormGroup>
                    <Label for="exampleFile">Image</Label>
                    <Input
                        type="file"
                        className={"mb-2"}
                        name="file"
                        multiple
                        onChange={e => this.setState({files: e.target.files})}
                    />

                    <div style={{display: "flex", flexWrap: "wrap"}}>

                        {/* Display all selected images. */}
                        {this.state.files &&
                        [...this.state.files].map((file, index) => {
                            return <Card style={{flex: "0 0 30%"}}>
                                <CardImg style={{maxWidth: "10em", maxHeight: "10em"}} src={URL.createObjectURL(file)}>
                                </CardImg>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <label
                                                    className={styles.typeField}
                                                >
                                                    Nom de l'image
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    type="text"
                                                    required
                                                    value={file.longname || ''}
                                                    onChange={e => this.handleFile(e.target.value, index)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <label
                                                    className={styles.typeField}
                                                >
                                                    Tags
                                                </label>
                                                <CreatableSelect
                                                    isClearable
                                                    isMulti
                                                    options={this.injected.rootStore.imageStore.tags.map(opt => ({
                                                        label: opt.nom,
                                                        value: opt.id
                                                    }))}
                                                    onChange={e => this.handleInputChange(e, index)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {this.injected.rootStore.authStore.options.metadata === 1 && (
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <label
                                                        className={styles.typeField}
                                                    >
                                                        Description - ALT
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        value={file.description || ''}
                                                        onChange={e => this.handleFileDescription(e.target.value, index)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>)}
                                </CardBody>
                            </Card>
                        })}
                    </div>
                </FormGroup>


                <div className="pl-lg-4 mt-3" style={{textAlign: "center"}}>
                    <Button color="success" className="mr-1" type="submit">
                        {i18n.t('button.submit')}
                    </Button>
                </div>
            </div>
        </Form>)
    }

    onBack(e) {
        e.preventDefault()
        this.injected.history.goBack()
    }

    render() {
        return (
            <>
                {!this.state.card && (
                    <Container className="mt-2" fluid>
                        <Row className="mb-3">
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                        <h3 className="mb-0" style={{color: "#20a8d8"}}>
                                            Ajouter des images
                                        </h3>
                                    </CardHeader>
                                    <CardBody>
                                        {this.setForm()}
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                )}

                {this.state.card && (this.setForm())}
            </>
        );
    }
})));

export default AddAsset;
