import React from "react";

function RowTD({title, action = "", alerte = ""}) {
    return <td className="text-center">
        {alerte !== "" ?
        (
            <span className="mb-0 text-sm"  style={{ color: alerte ? "red"  : "green", fontWeight: "bolder" }}>
                            {title}
                {action === "total" && "€" }
                        </span>
        ) : (
                <span className="mb-0 text-sm">
                            {title}
                    {action === "total" && "€" }
                        </span>

            )}
      </td>
}

export default RowTD;
