import React  from 'react'
import { Route, Redirect } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import DefaultLayout from "../../../views/layouts/containers/DefaultLayoutAdmin/DefaultLayout";
const PrivateRoute = inject("rootStore")(observer(class PrivateRoute extends React.Component {
    get injected() {
        return this.props
    }

    componentDidMount() {
        this.injected.rootStore.userNotificationStore.getNotifications()
    }

    render() {
        return this.injected.rootStore.authStore.isLogged ? (
            <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
        ) : (
            <Redirect to='/login' />
        )
    }
}));

export default  PrivateRoute;