import React, {Component} from 'react';
import navigation from '../_nav'
import {Menu, ProSidebar, SubMenu} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import {Link} from "react-router-dom";
import configuration from "../../../../configuration/configuration";
import {inject, observer} from "mobx-react";
import MenuItemActive from "./MenuItemActive";

const DefaultSidebar = inject("rootStore")(observer(class DefaultSidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false
        }
    }

    get injected() {
        return this.props
    }

    callbackActive = (childData) => {
        this.setState({active: childData})
    };

  render() {
    return (  <ProSidebar
        image={configuration.API + "/magasin/sidebar/" + this.injected.rootStore.authStore.sidebar}
        collapsed={false}
        toggled={false}
    >
        <Menu iconShape="circle">
            {navigation?.items.filter((item) => !item.module || (item.module && this.injected.rootStore.authStore.modules[item.module.toString()])  ).map((item, key) => {
                return ( item?.children?.length > 0  ? (
                           <SubMenu
                               key={key}
                            // suffix={<span className="badge yellow">3</span>}
                            title={item.name}
                            icon={<i className={item.icon} aria-hidden="true"/>
                            }
                        >
                            {item.children?.filter((item) =>(!item.identifiant ||  this.injected.rootStore.authStore.identifiant === item.identifiant) && (!item.module || this.injected.rootStore.authStore.modules[item.module.toString()])).map((child, key) => {

                                return(
                                    <MenuItemActive child={child} key={key} active={window.location.hash.includes(child.url)} callbackActive={this.callbackActive}/>)
                            })}
                        </SubMenu>) : (
                            <MenuItemActive
                                child={item}
                                key={key}
                                callbackActive={this.callbackActive}
                                active={window.location.hash.includes(item.url)}
                               >{item.name}  <Link to={item.url}/>
                    </MenuItemActive>)
                    )})
            }
        </Menu>
    </ProSidebar>)
  }
}
));
export default DefaultSidebar;
