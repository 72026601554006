import React from "react";
import {inject, observer} from "mobx-react";
import {CardBody, Container,} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import Card from "reactstrap/lib/Card";
import CardTitle from "reactstrap/lib/CardTitle";
import moment from "moment";

const SeeAll = inject("rootStore") (observer( class SeeAll extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des notifications",
            "",
            "facture.jpg")

        this.injected.rootStore.userNotificationStore.getAllNotifications()
    }

    onStar(e, isStar, id) {
        e.preventDefault()
        this.injected.rootStore.userNotificationStore.saveNotification({
            id: id,
            is_star: !isStar
        }, true)
    }

    setCard(notif) {
        return (  <Card
            key={notif.id}
            className={notif.is_star === 1 ? "alert alert-warning" : ""}
            body outline color="secondary">
            <CardTitle tag="h5" style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Link to={notif.link}>
                    <div><i className={notif.icon}></i> {notif.category}</div>
                </Link>

                <div onClick={e => this.onStar(e, notif.is_star, notif.id)} style={{
                    fontStyle: "italic",
                    cursor: "pointer"
                }}>
                    {notif.is_star ? (
                        <i className="fa fa-star" aria-hidden="true"></i>
                    ) : (
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    )
                    }
                </div>
            </CardTitle>

            <CardTitle tag="h5" style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Link to={notif.link}>
                    <div>{notif.message}</div>
                </Link>

                <div style={{
                    fontStyle: "italic"
                }}>  {moment(notif.created_at).format("lll")}</div>
            </CardTitle>
        </Card>)
    }

    render() {
        return (
            <>
                <Container className="mt-2"  fluid>
                    <Card>
                        <CardBody>
                            {this.injected.rootStore.userNotificationStore.allNotifications.filter(notif => notif.is_star === 1).map(notif => {
                                return   ( this.setCard(notif))
                            })}
                            <hr/>
                            {this.injected.rootStore.userNotificationStore.allNotifications.filter(notif => notif.is_star === 0).map(notif => {
                                return   ( this.setCard(notif))
                            })}
                        </CardBody>
                    </Card>
                </Container>

            </>
        );
    }
}));

export default withRouter(SeeAll);
