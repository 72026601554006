/* Global import */
import {action, decorate, observable} from 'mobx';
import Api from "../../util/api";

/* Custom import */

export default class ProductStore {

    datas = observable([]);
    selectionProduct;
    selectionCategory;
    statusname = ""
    filter = {
        limit: "20",
        search: "",
        page: "1",
        last_page: "1",
        category: "",
        name: "updated_at",
        order: false,
        mode: "ADD",
        status: [""],
        shop: "",
        alerte: ""

    }


    defaultFilter = {
        limit: "20",
        search: "",
        page: "1",
        last_page: "1",
        category: "",
        name: "updated_at",
        order: false,
        mode: "ADD",
        status: [""],
        shop: "",
        alerte: ""
    }


    constructor(rootStore, stockStore) {
        this.rootStore = rootStore
        this.stockStore = stockStore;
        this.api = new Api();
    }

    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stocks.getStocks(this.filter);

            if (this.datas.length > 0) {
                this.datas.replace(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }

            this.filter.page = authResponse.currentPage;
            this.filter.last_page = authResponse.lastPage;
            this.rootStore.savedDatasStore.setLoading(false)


        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getStocksForId(id) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stocks.find(id);
            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse.datas;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

    }


    async createWithPID(data) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stocks.createWithPID(data)

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Ajoutée", "success");

            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateLot(data) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.stocks.updateLot(data)

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Modifiée", "success");

            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateMyLot(data) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.stocks.updateMyLot(data)

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Modifiée", "success");


            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async createStock(data) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stocks.createStock(data)

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Stock # " + authResponse.data.id + " ajoutée", "success");


            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateStock(data) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stocks.updateStock(data)

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Stock # " + authResponse.data.id + " modifié", "success");


            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.stocks.delete(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Produit " + id + " supprimé", "success");

            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    setSelection(item) {
        this.selectionProduct = item
    }
}


decorate(ProductStore, {
    datas: observable,
    selectionCategory: observable,
    selectionProduct: observable,
    filter: observable,
    getDatas: action,
    products: observable,
    pids: observable
});