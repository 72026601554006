import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";

export default class PageAPI {

    async index() {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/pages`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async find(name) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/pages/${name}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async create(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/pages`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async update(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/pages`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async delete(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/pages/` + id ,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }
}