import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {CardBody, CardFooter, CardHeader, CardTitle, Container, Input, Row} from "reactstrap";
import Card from "reactstrap/lib/Card";
import {inject, observer} from "mobx-react";
import styles from './Stepper.module.css'
import clsx from "clsx";
import {Col} from "react-bootstrap";
import Label from "reactstrap/lib/Label";
import FormGroup from "reactstrap/lib/FormGroup";
import {Link} from "react-router-dom";
import AutoCompleteSearch from "../../../../components/Akitatek/Search/AutoCompleteSearch";
import getRoutes from "../../../../components/Akitatek/getRoutes";
import MachinePec from "../MachinePec";
import UserInformation from "../../../../components/Akitatek/AdressCustomer";

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    const icons = {
        1: <i className="fa fa-address-book font-2xl" aria-hidden="true"></i>
        ,
        2: <i className="fa  fa-exclamation-triangle font-2xl" aria-hidden="true"></i>
        ,
        3: <i className="fa fa-laptop font-2xl" aria-hidden="true"></i>
        ,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));
const AddPriseEnChargeStepper = inject("rootStore")(observer(class AddPriseEnChargeStepper extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Prises En Charge", "Gérer les Prises En Charge", "priseencharge.png", this.injected.location.aboutProps?.subCategory ?? "", this.injected.location.aboutProps?.user)

        this.state = {
            activeStep: 0,
            steps: this.getSteps(),
            user: this.injected.location.aboutProps?.user ?? {},
            priseencharge: [],
            status: "ARRIVED",
            title: "",
            online: 0,
            description: ""
        }
    }

    componentDidMount() {
        if (!this.injected.location.aboutProps?.user) {
            if (this.injected.rootStore.savedDatasStore.user) {
                this.setState({
                    user: this.injected.rootStore.savedDatasStore.user
                })
            }
        }
    }

    getSteps() {
        return ['Choisir ou créer un client', 'Renseigner le problème', 'Ajouter les machines clients'];
    }

    searchClient() {
        return (
            <div style={{display: "flex", justifyContent: "center"}}>
                <AutoCompleteSearch
                    callbackUser={this.callbackUser}
                    myStatus={''}
                    user={this.state.user}
                    store={this.injected.rootStore.priseenchargeStore} status="PAID" status2="TO_PAID"/>
            </div>
        )
    }

    setProblem() {
        return (<CardBody>
            <Col>
                <Input
                    style={{textAlign: "center"}}
                    className={"mb-3"}
                    placeholder="Titre (description courte)"
                    id="input-title"
                    type="text"
                    value={this.state.title}
                    onChange={(e) => {
                        e.preventDefault();
                        this.setState({title: e.target.value})
                    }}
                />


                <Row className="ml-2 mr-2" style={{justifyContent: "space-between"}}>
                    <Col form>
                        <Row form>
                            <Col md={2}>
                                <Label for="exampleEmail">Description longue</Label>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Input
                                        value={this.state.description}
                                        type="textarea"
                                        placeholder="Description..."
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({description: e.target.value})
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/*<Row form>*/}
                        {/*    <Col md={2}>*/}
                        {/*        <Label for="exampleEmail">Priorité</Label>*/}
                        {/*    </Col>*/}
                        {/*    <Col md={4}>*/}
                        {/*        <FormGroup>*/}
                        {/*            <Input type="select"*/}
                        {/*                   name="select"*/}
                        {/*                   value={this.state.priority}*/}
                        {/*                   onChange={(e) => {*/}
                        {/*                       e.preventDefault();*/}
                        {/*                       this.setState({priority: e.target.value})*/}
                        {/*                   }}*/}
                        {/*            >*/}
                        {/*                <option value={1}>HAUTE</option>*/}
                        {/*                <option  value={2}>ELEVEE</option>*/}
                        {/*                <option  value={3}>MOYENNE</option>*/}
                        {/*                <option  value={4}>BASSE</option>*/}
                        {/*            </Input>*/}
                        {/*        </FormGroup>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {/*<Row form>*/}
                        {/*    <Col md={2}>*/}
                        {/*        <Label for="exampleEmail">Status</Label>*/}
                        {/*    </Col>*/}
                        {/*    <Col md={4}>*/}
                        {/*        <FormGroup>*/}
                        {/*            <FormGroup>*/}
                        {/*                <Input type="select"*/}
                        {/*                       name="select"*/}
                        {/*                       value={this.state.status}*/}
                        {/*                       onChange={(e) => {*/}
                        {/*                           e.preventDefault();*/}
                        {/*                           this.setState({status:  e.target.value})*/}
                        {/*                       }}*/}

                        {/*                >*/}
                        {/*                    <option value={"DRAFT"}>BROUILLON</option>*/}
                        {/*                    {this.state.online === "1" && (*/}
                        {/*                        <option value={"VALIDATE"}>VALIDER</option>)}*/}
                        {/*                    <option value={"ARRIVED"}>ARRIVEE</option>*/}
                        {/*                </Input>*/}
                        {/*            </FormGroup>*/}
                        {/*        </FormGroup>*/}
                        {/*    </Col>*/}
                        {/*  </Row>*/}
                        <Row form>
                            <Col md={2}>
                                <Label for="exampleEmail">Option</Label>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <FormGroup>
                                        <Input type="select"
                                               name="select"
                                               value={this.state.online}
                                               onChange={(e) => {
                                                   e.preventDefault();
                                                   this.setState({online: e.target.value})
                                               }}

                                        >
                                            <option value={"0"}>Magasin</option>
                                            <option value={"1"}>Internet</option>
                                        </Input>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Card>
                        <CardHeader>
                            <CardTitle style={{fontWeight: "bold"}}>
                                <h4>
                                    <Link to={{pathname: getRoutes("Modifier Client", this.state.user?.id)}}>
                                        {this.state.user?.fullname}
                                    </Link>
                                </h4>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <UserInformation user={this.state.user} adresse={this.state.user?.adresse}
                                             adresseLivraison={this.state.user?.adresse_livraison}/>
                        </CardBody>
                    </Card>
                </Row>
            </Col></CardBody>)
    }


    //Refresh
    callbackUser = (childData) => {
        this.setState({user: childData})
    };


    callbackClientMachine = (childData) => {
        this.setState({priseencharge: childData})
    };


    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return this.searchClient();
            case 1:
                return this.setProblem();
            case 2:
                return <MachinePec priseencharge={this.state.priseencharge} callback={this.callbackClientMachine}/>;
            default:
                return 'Une erreur est survenue. Désolée pour le dérangement. Merci de réessayer ou de contacter la société ' + this.injected.rootStore.authStore.nameCompany;
        }
    }

    async onSubmit(index) {
        let data = {
            user_id: this.state.user?.id,
            title: this.state.title,
            status: this.state.status,
            priority: this.state.priority,
            description: this.state.description,
            online: this.state.online
        }

        let authResponse = await this.injected.rootStore.prisenchargeStore.savePriseEnCharge(data)

        if (authResponse?.status === "success") {
            this.setState({
                priseencharge: authResponse.datas,
                activeStep: index,
            })

            this.injected.rootStore.savedDatasStore.setUser(this.state.priseencharge?.user)
        }
    }


    async toggleWarning(index) {
        let htmlWarningSave = '<p><strong>Etes vous sûre de votre prise en charge ?<br /></strong></p>' +
            '<ul>' +
            '   <li>Il n\'est plus possible de modifier le client</li>' +
            '   <li>Vous pourrez cependant modifier le reste des informations.</li>' +
            '   <li>La prochaine étape vous permettra d ajouter les machines du client et les emplacements de  stockage.</li>' +
            '</ul>';
        await this.injected.rootStore.notificationStore.validateWarning(htmlWarningSave, event => this.onSubmit(index))
    }

    setActiveStep(index) {
        if (index === 2) {
            this.toggleWarning(2)
        } else {
            this.setState({
                activeStep: index,
            })
        }
    }

    render() {
        return (
            <>
                <Container className={"mt-2"} fluid>
                    <Card>
                        <div className={styles.root}>
                            <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                {this.state.steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div>
                                <CardBody>
                                    {this.state.activeStep !== this.state.steps.length && (
                                        <div>
                                            {this.getStepContent(this.state.activeStep)}
                                        </div>)}
                                </CardBody>
                                <CardFooter>
                                    {this.state.activeStep !== this.state.steps.length - 1 ? (
                                            <div>
                                                <Button
                                                    disabled={this.state.activeStep === 0}
                                                    onClick={e => this.setActiveStep(this.state.activeStep - 1)}
                                                    className={styles.backButton}
                                                >
                                                    Précédent
                                                </Button>
                                                <Button
                                                    disabled={!this.state.user?.id}
                                                    variant="contained" color="primary"
                                                    onClick={e => this.setActiveStep(this.state.activeStep + 1)}>
                                                    {this.state.activeStep === this.state.steps.length - 1 ? 'Terminé' : 'Suivant'}
                                                </Button>
                                            </div>) :
                                        (<div>
                                            <Typography className={styles.instructions}>Félicitation ! Vous avez crée
                                                votre prise en charge.
                                                <Link to={{
                                                    pathname: "/priseencharge/update/" + this.state.priseencharge.id,
                                                    aboutProps: {
                                                        isCommandeValid: true,
                                                        priseencharge: this.state.priseencharge
                                                    }
                                                }}>
                                                    <Button className={"ml-2 mr-2"} color="primary" type="button"
                                                            id={"UncontrolledTooltipExampleVoir" + this.state.priseencharge.id}>
                                                        Voir la prise en charge # {this.state.priseencharge.id}
                                                    </Button>
                                                </Link>
                                            </Typography>
                                        </div>)}
                                </CardFooter>
                            </div>
                        </div>
                    </Card>
                </Container>
            </>
        );
    }
}));


export default AddPriseEnChargeStepper;




