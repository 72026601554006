import React from "react";
import {inject, observer} from "mobx-react";
import {Button, UncontrolledTooltip} from "reactstrap";
import {Link} from "react-router-dom";
import getRoutes from "../../../components/Akitatek/getRoutes";

const ActionUserRow = inject("rootStore")(observer(class ActionUserRow extends React.Component {

    data;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            data: this.injected.data
        }
    }

    onUserSelection(e, data) {
        e.preventDefault()
        this.injected.rootStore.savedDatasStore.setUser(data, 1)
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.data !== prevProps.data
        ) {
            this.setState({
                data: this.injected.data
            })
        }
    }

    render() {
        return (
            <>
                <td className={"th-price text-center"}>

                    <Link to={{
                        pathname: getRoutes("Modifier Client", this.state.data?.id),
                        aboutProps: {
                            data: this.state.data
                        }
                    }}>
                        <Button className="mr-4" color="primary" type="button" href={"#"}
                                id={"UncontrolledTooltipExamplePencil" + this.state.data?.id}>
                            <i className="fa fa-pencil" aria-hidden="true"/>
                            <UncontrolledTooltip placement="top"
                                                 target={"UncontrolledTooltipExamplePencil" + this.state.data?.id}>
                                Editer
                            </UncontrolledTooltip>
                        </Button></Link>

                    <Button color="secondary" type="button" onClick={e => this.onUserSelection(e, this.state.data)}
                            id={"UncontrolledTooltipExampleSelection" + this.state.data?.id}>
                        <i className="fa fa-hand-rock-o" aria-hidden="true"/>
                        <UncontrolledTooltip placement="top"
                                             target={"UncontrolledTooltipExampleSelection" + this.state.data?.id}>
                            Sélectionner
                        </UncontrolledTooltip>
                    </Button>
                </td>
            </>
        );
    }

}));

export default ActionUserRow;
