import React from "react";
import moment from "moment";

function DateRow({title}) {
    return <td className="text-center">
                        <span className="mb-0 text-sm">
                            {moment(title).format("lll")}
                        </span>
    </td>
}

export default DateRow;
