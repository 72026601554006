/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import {inject, observer} from "mobx-react";
import styles from "../../../views/admin/Customers/Customers.module.css";
import ChoiceSearch from "../Search/ChoiceSearch";

const GenericStaticCard = (inject("rootStore") (observer( class GenericStaticCard extends React.Component {

    category;

    toggle(e) {
        e.preventDefault();

        this.setState({ collapse: !this.state.collapse });
    }

    constructor(props, context) {
        super(props, context);

        //default : not showing Card
        this.state = {
            search: this.injected.search || false,
            collapse: this.injected.collapse || false,
            fadeIn: true,
            timeout: 300,
            category: this.injected.category
        };

        this.toggle = this.toggle.bind(this);

    }

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Card>
                        <CardHeader className="bg-transparent"  style={{display: "flex", color: "#20a8d8"}}>
                            <i  className={[styles.hideButton]} />
                            {this.injected.element}
                        </CardHeader>
                        {this.props.children}
                </Card>
            </>
        );
    }
})));

export default GenericStaticCard;
