import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Col} from "reactstrap";
import PictureHidden from "../PictureHidden";
import ModalAkitatek from "../Modal/Modal";
import PickAsset from "../../../views/admin/Asset/PickAsset";
import WidgetSearch from "./Search/WidgetSearch";

const ChoisirImageInput = inject("rootStore")(observer(class ChoisirImageInput extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            pickAsset: false
        }
        this.pickAsset = this.pickAsset.bind(this)
        this.onDelete = this.onDelete.bind(this)
    }

    onDelete() {
        this.injected.onPickAssetFilename("delete")
    }

    pickAsset(e, value) {
        if (e) {
            e.preventDefault()
        }

        this.setState({
            pickAsset: e ? e.target.value : value
        })
    }


    onCloseAsset = () => {
        this.pickAsset(null, false)
    }

    onPickAsset = () => {
        if (this.injected.rootStore.imageStore.pickAsset) {
            this.pickAsset(null, false)
            this.injected.onPickAssetFilename("add")
        } else {
            this.pickAsset(null, false)
        }
    }

    render() {
        const {assetName} = this.props
        return (
            <>
                <Col md={6} className={'m-auto'}
                     style={{
                         display: "flex",
                         justifyContent: "space-around",
                         alignItems: "center"
                     }}>

                    <Button className={"btn btn-primary"}
                            value={true}
                            onClick={this.pickAsset}
                    > Choisir une image dans la gallerie</Button>
                    <PictureHidden assetName={assetName ?? ""}/>

                    {assetName && (
                        <Button className={"btn btn-danger"}
                                value={true}
                                onClick={this.onDelete}
                        > Supprimer l'image</Button>
                    )}
                </Col>


                <ModalAkitatek show={this.state.pickAsset}
                               title={<WidgetSearch icon={"fa fa-search bg-primary p-1 font-10xl mr-3 float-left"}
                                                    col={"col-12"} category={"images"}/>}
                               children={<PickAsset onCallback={this.onPickAsset}/>}
                               onCallback={this.onCloseAsset}/>
            </>
        );
    }

}));

export default ChoisirImageInput;
