import React from "react";
import {inject, observer} from "mobx-react";
import {Link, withRouter} from "react-router-dom";
import moment from "moment";
import {CardHeader, UncontrolledTooltip} from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import getRoutes from "../../../components/Akitatek/getRoutes";
import ActionsPDF from "./ActionsPDF";


const ActionRow = inject("rootStore")(observer(class ActionRow extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        moment.locale('fr');
        this.onCopy = this.onCopy.bind(this)
    }


    async onCopy(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setQuestion("Voulez vous copier la facture " + this.injected.data.intitule + " ?", "Copier #" + this.injected.data.id, e => this.onChange())

    }

    async onChange() {
        let datas = {
            id: this.injected.data.id,
            status: this.injected.data.statusEnum
        }
        let authResponse = await this.injected.rootStore.invoiceStore.copyInvoice(datas)
        if (authResponse?.status === "success") {
            this.injected.history.push(getRoutes("Gérer une facture", authResponse.datas.id, "élaboration"))
        }
    }


    render() {
        let {data} = this.props
        let path =  getRoutes("Gérer une facture", data.id, data.status)
        let pathPayer =  getRoutes("Payer une facture", data.id)

        return (
            <>
                    <td className={"th-actions"}>
                        <div style={{display: "flex"}}>
                            <Link to={{
                                pathname: path,
                                aboutProps: {
                                    isCommandeValid: true,
                                    data: data
                                }
                            }}>
                                <Button className={"ml-2 mr-2"} color="primary" type="button"
                                        id={"UncontrolledTooltipExampleVoir" + data.id}>
                                    <i className="fa fa-eye" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top"
                                                         target={"UncontrolledTooltipExampleVoir" + data.id}>
                                        Voir
                                    </UncontrolledTooltip>
                                </Button>
                            </Link>

                            <ActionsPDF data={data} api={this.injected.rootStore.invoiceStore} filename={"facture" + data?.id + "_" + "nom" + "_" + data?.created_at + ".pdf"}/>

                            {data.statusEnum !== "DRAFT" && data.statusEnum !== "PAID" &&
                            <Link to=
                                      {{
                                          pathname: pathPayer

                                      }}>
                                <Button className="ml-2"
                                        variant="danger"
                                        type="button"
                                        tooltip="Encaisser"
                                        id={"UncontrolledTooltipExampleMoney" + data.id}>
                                    <i className="fa fa-money" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top"
                                                         target={"UncontrolledTooltipExampleMoney" + data.id}>
                                        Encaisser
                                    </UncontrolledTooltip>
                                </Button>
                            </Link>
                            }


                            <Button onClick={this.onCopy} className={"ml-2"} variant="warning" type="button"
                                    id={"UncontrolledTooltipExampleCopy" + data.id}>
                                <i className="fa fa-copy" aria-hidden="true"/>
                                <UncontrolledTooltip placement="top"
                                                     target={"UncontrolledTooltipExampleCopy" + data.id}>
                                    Copier
                                </UncontrolledTooltip>
                            </Button>
                        </div>
                    </td>
            </>
        );
    }

}));

export default withRouter(ActionRow);
