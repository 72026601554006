import React from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

const ActionsPDF = inject("rootStore")(observer(class ActionsPDF extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }

    render() {
        let {data, api, filename, type, name  = "pdf.facture"} = this.props
        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle variant="info">
                        <i className="fa fa-file-pdf-o" aria-hidden="true"/>
                            <span className={"ml-2"}>{type}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        { name === "pdf.facture" && (
                        < Dropdown.Item
                            disabled={data.statusEnum === "DRAFT"}
                            onClick={e => api.togglePdf(e, data?.id, data?.user?.email, name)}>Mail
                            avec PDF</Dropdown.Item>
                        )}
                        <Dropdown.Item
                            onClick={e => api.openPdf(e, data?.id, data?.statusEnum, name)}>Ouvrir
                            le PDF</Dropdown.Item>
                        <Dropdown.Item
                            onClick={e => api.printPdf(e, data?.id, data?.statusEnum, name)}>Imprimer
                            le PDF</Dropdown.Item>
                        <Dropdown.Item
                            onClick={e => api.downloadPdf(e, data?.id, filename, data.statusEnum, name)}>Télécharger
                            le PDF</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }

}));

export default withRouter(ActionsPDF);
