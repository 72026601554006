import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";
import encodeDataToURL from "../components/Akitatek/encodeDatatoURL";

export default class CommandeAPI {

    async getCommandes(filter) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/commandes?` + encodeDataToURL(filter),
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getAllStatus() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/getAllStatus/commandes`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async find(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/commandes/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async createCommandeRapide(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/commandesrapide`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }


    async saveCommande(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/commandes`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async saveAllCommande(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/commandes/all`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async updateCommande(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/commandes`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async findCommandById(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/commandes/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async saveCommandeProducts(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/commandesProducts`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async updateCommandeProducts(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/commandesProducts`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async deleteCommandeProducts(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/commandesProducts`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }


    async saveMouvCommande(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/commandes/mouvementStock`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async delete(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/commandes/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async createPdf(mediaId, printerId) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/commandes/pdf/${mediaId}/${printerId}`,
                    headers: {
                        'content-type': '*/*'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }
}