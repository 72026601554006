import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";

export default class MouvAPI {
    async getMouv(limit, pageNumber, search) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/mouvementStock?page=${pageNumber}&limit=${limit}&search=${search}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve({data: response.data.data, lastPage :response.data.last_page, currentPage: response.data.current_page})
            } catch (error) {
                reject(error)
            }
        })
    }

    async saveMouv(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/mouvementStock`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async inventaire(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/inventaire`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }


    async updateMouv(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/mouvementStock`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }


    async deleteMouv(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/mouvementStock`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

}