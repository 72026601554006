import {decorate, observable} from "mobx";
import Api from "../util/api";

export default class ImageStore {

    search = ""
    images = observable([]);

    currentPage = 0;
    lastPage = 1;
    limit = 16
    tags = observable([]);

    pickAsset;


    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }


    async getImages(limit) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.images.getImage(this.search, limit ? limit : this.limit, this.currentPage);

            if (this.images.length > 0) {
                this.images.replace(authResponse.data)
            } else {
                this.images = authResponse.data;
            }

            this.currentPage = authResponse.current_page;
            this.lastPage = authResponse.last_page;
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.datas
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async findById(id) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.images.find(id);
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.datas;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async save(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.images.save(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Images ajouté(e)s", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async update(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.images.update(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Image #" + authResponse.datas.id + " modifiée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.images.delete(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Image # " + id + " supprimée", "success");
                this.getImages()
            }

            this.rootStore.savedDatasStore.setLoading(false)
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getAllTags() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.images.getTags();

            if (this.tags.length > 0) {
                this.tags.replace(authResponse)
            } else {
                this.tags = authResponse;
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async createTag(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.images.createTag(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Tag ajouté", "success");
                this.getAllTags()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(ImageStore, {
    images: observable,
    tags: observable,
    search: observable,
    limit: observable,
    currentPage: observable,
    lastPage: observable,
    pickAsset: observable
});

