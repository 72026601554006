
/* Custom import */
import MouvStore from "./mouvStore";
import ProductStore from "./productStore";
import Api from "../../util/api";


export default class StockStore{
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.mouvStore = new MouvStore(this.rootStore, this);
        this.productStore = new ProductStore(this.rootStore, this);
        this.api = new Api();
    }

    async openPdf(e, idLot) {
        e.preventDefault();

        if (idLot) {
            const file = await this.getPdf(idLot);

            if (file) {
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }
        }
    }

    async getPdf(idLot) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let pdf =  await this.api.stocks.getPdf(idLot)
            this.rootStore.savedDatasStore.setLoading(false)
            return pdf;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async downloadPdf(e, id, idLot, printerId) {
        e.preventDefault();
        try {
            if (id) {
                this.rootStore.savedDatasStore.setLoading(true)

                let authResponse = await this.api.stocks.createPdf(id, printerId, idLot);

                if(authResponse?.status === "success") {
                    this.rootStore.notificationStore.setNotification( "Impression en cours", "success");
                }
                this.rootStore.savedDatasStore.setLoading(false)
            }
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
}