import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Container, Form, FormGroup, Input, Row, Table} from "reactstrap";
import i18n from "i18next";
import {Col} from "react-bootstrap";
import Address from "../../../components/Akitatek/Address";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import AutoCompleteSearch from "../../../components/Akitatek/Search/AutoCompleteSearch";
import CreateDevis from "./CreateDevis";
import {withRouter} from "react-router-dom";
import AutoCompleteInvoices from "../../../components/Akitatek/AutoCompleteInvoices/AutoCompleteInvoices";
import ActionsPDF from "../Invoices/ActionsPDF";

const AddDevis = inject("rootStore")(observer(class AddDevis extends React.Component {

    devisStore = this.injected.rootStore.devisStore.devisCreate;
    errorStore = this.injected.rootStore.errorStore;
    devis

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);


        this.state = {
            isCommandeValid: this.injected.location.aboutProps?.isCommandeValid ?? false,
            devis: this.injected.location.aboutProps?.devis ?? {
                ligne: [],
                titre: "",
                user: this.injected.location.aboutProps?.user,
                status: "DRAFT",
                prise_en_charge_id: ""
            }
        };
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des devis", "Ajouter / modifier / convertir un devis", "devis.jpg", this.injected.location.aboutProps?.subCategory ?? "", this.injected.location.aboutProps?.user)

        this.onSubmit.bind(this);
    }

    async componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            await this.onRouteChanged();
        }

        if (this.state.devis === "" && this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }

    //check if the values are up to date
    componentDidMount() {
        if (this.injected.match.params.id) {
            this.onRouteChanged()
        }
    }


    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (id) {
            let devis = await this.devisStore.getDevis(id);

            this.setState({
                devis: devis,
                isCommandeValid: true
            })
        } else if (this.props.match.params.id === "add" && !this.injected.location.aboutProps?.devis && !this.injected.location.aboutProps?.user) {
            this.setState({
                isCommandeValid: false,
                devis: {
                    ligne: [],
                    titre: "",
                    status: "DRAFT",
                    prise_en_charge_id: ""
                }
            })
        }
    }

    createRow(data, index) {
        return <CreateDevis
            callbackRefreshLignes={this.callbackRefreshLignes}
            devis={this.state.devis}
            line={data}
            callbackRefreshTotal={this.callbackRefreshTotal}
            key={index}
            id={data.id}
            position={index}
        />;
    }


    createButtonSave(title, color, onclickFunc) {
        return (
            <Col>
                <Button block color={color}
                        type="submit"
                        disabled={this.disabled()}
                        onClick={onclickFunc}>{title}</Button>
            </Col>)
    }


    createButton(title, color, onclickFunc) {
        return (
            <Col>
                <Button block color={color}
                        type="submit"
                        disabled={this.state.devis?.status !== "WAIT"}
                        onClick={onclickFunc}>{title}</Button>
            </Col>)
    }


    async toggleValidate(e) {
        e.preventDefault()
        let htmlWarningSave = '<p><strong>Toute validation est définitive !<br /></strong></p>' +
            '<ul>' +
            '   <li>Il n\'est plus possible de modifier le devis</li>' +
            '   <li>Le devis prend le status "EN ATTENTE"</li>' +
            '   <li>La validation rend le devis disponible au client</li>' +
            '   <li>Permet la validation du devis par le client</li>' +
            '</ul>';
        await this.injected.rootStore.notificationStore.validateWarning(htmlWarningSave, event => this.onValidate(this.state.devis))
    }


    async onValidate(myDevis) {
        let devis = Object.assign({}, myDevis);
        devis.status = "WAIT"

        let authResponse = await this.injected.rootStore.devisStore.updateDevis(devis, "Devis #" + this.state.devis?.id + " validée. Ce devis est validé. Il ne pourra plus être modifié !")

        if (authResponse?.status === "success") {
            this.setState({
                devis: authResponse.datas
            })
        }
    }

    async toggleRefusedClient(e) {
        e.preventDefault()
        let htmlWarningSave = '<p><strong>Tout refus est définitif !<br /></strong></p>' +
            '<ul>' +
            '   <li>En appuyant sur ce bouton, vous refusez le devis à la place du client !</li>' +
            '   <li>Vous vous engagez et attestez sur l\'honneur que le client a refusé le devis en cours</li>' +
            '   <li>Ce devis ne devra pas être réalisé. </li>' +
            '</ul>';
        await this.injected.rootStore.notificationStore.validateWarning(htmlWarningSave, event => this.onRefusedClient(this.state.devis), false)
    }

    async onRefusedClient(myDevis) {
        let devis = Object.assign({}, myDevis);
        devis.status = "REFUSED"
        let authResponse = await this.injected.rootStore.devisStore.updateDevis(devis, "Devis #" + this.state.devis?.id + " est refusé par le client. Ce devis ne sera pas réalisé!")

        if (authResponse?.status === "success") {
            this.setState({
                devis: authResponse.datas
            })

            this.injected.rootStore.savedDatasStore.setUser(this.state.devis?.user)
        }
    }


    async toggleDelete(e) {
        e.preventDefault()

        await this.injected.rootStore.notificationStore.setWarning("Devis #" + this.state.devis?.id, event => this.onDelete(this.state.devis?.id), false)
    }

    async onDelete(id) {
        let authResponse = await this.injected.rootStore.devisStore.delete(id)

        if (authResponse?.status === "success") {
            this.injected.rootStore.savedDatasStore.setUser(this.state.devis?.user)
            this.injected.history.push("/devis");

        }
    }


    async toggleValidateClient(e) {
        e.preventDefault()
        let htmlWarningSave = '<p><strong>Toute validation est définitive !<br /></strong></p>' +
            '<ul>' +
            '   <li>En appuyant sur ce bouton, vous validez à la place du client !</li>' +
            '   <li>Vous vous engagez et attestez sur l\'honneur que le client a accepté le devis en cours</li>' +
            '   <li>Ce devis devra être réalisé. </li>' +
            '</ul>';
        await this.injected.rootStore.notificationStore.validateWarning(htmlWarningSave, event => this.onValidateClient(this.state.devis))
    }


    async onValidateClient(myDevis) {
        let devis = Object.assign({}, myDevis);
        devis.status = "VALID"
        let authResponse = await this.injected.rootStore.devisStore.updateDevis(devis, "Devis #" + this.state.devis?.id + " est validé par le client. Ce devis sera réalisé !")

        if (authResponse?.status === "success") {
            this.setState({
                devis: authResponse.datas
            })

            this.injected.rootStore.savedDatasStore.setUser(this.state.devis?.user)
        }
    }

    async onSubmit(e) {
        e.preventDefault();

        let authResponse = await this.injected.rootStore.devisStore.updateDevis(this.state.devis)

        if (authResponse?.status === "success") {
            this.setState({
                devis: authResponse.datas
            })

            this.injected.rootStore.savedDatasStore.setUser(this.state.devis?.user)
        }
    }

    //Refresh commande
    callbackUser = (childData) => {
        let devis = Object.assign({}, this.state.devis);
        devis.user = childData
        devis.user_id = childData?.id
        this.setState({devis: devis})
    };

    disabled() {
        return this.state.devis.status !== "DRAFT" && this.state.devis.status !== "WAIT"
    }

    //Refresh commande
    callbackRefreshLignes = (childData) => {
        let devis = Object.assign({}, this.state.devis);
        devis.ligne = childData
        this.setState({devis: devis})

        this.calculateTotal()
    };

    //Refresh commande
    callbackRefreshTotal = () => {
        this.calculateTotal()
    };

    calculateTotal() {
        let totalht = this.state.devis.ligne.reduce((a, {totalht}) => parseFloat(a) + parseFloat(totalht), 0);
        let totalttc = this.state.devis.ligne.reduce((a, {totalttc}) => parseFloat(a) + parseFloat(totalttc), 0);
        let totalht2 = totalht;

        if (this.state.devis.cout_deplacement != null) {
            totalht2 = parseFloat(totalht) + parseFloat(this.state.devis.cout_deplacement)
            totalttc = parseFloat(totalttc) + parseFloat(this.state.devis.cout_deplacement)
        }

        let devis = this.state.devis
        devis.totalht2 = totalht2.toFixed(2)
        devis.totalttc = totalttc.toFixed(2)
        devis.totalht = totalht.toFixed(2)
        this.setState({
            devis: devis
        })
    }


    async onSubmitFirstStep(e) {
        e.preventDefault();

        let data = {
            user_id: this.state.devis?.user?.id ?? this.injected.rootStore.savedDatasStore.user?.id,
            titre: this.state.devis.titre,
            status: this.state.devis.status,
            prise_en_charge_id: this.state.devis?.prise_en_charge_id
        }

        let authResponse = await this.injected.rootStore.devisStore.saveDevis(data)

        if (authResponse?.status === "success") {
            this.setState({
                devis: authResponse.datas,
                isCommandeValid: true
            })

            this.injected.rootStore.savedDatasStore.setUser(this.state.devis?.user)
            this.injected.history.replace(authResponse.datas.id.toString())
        }
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Row className="mb-3">
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader
                                    className={this.injected.rootStore.savedDatasStore.setColor(this.state.devis?.status)}
                                    style={{display: "flex", justifyContent: "space-between"}}>
                                    <h4 className="mb-0">

                                        {
                                            this.state.devis?.id && (
                                                "Devis #" + this.state.devis?.id + " " + this.state.devis?.valider.toUpperCase()
                                            ) || (
                                                i18n.t('page.admin.devis.add')
                                            )
                                        }

                                        {this.state.devis?.priseencharge?.length > 0 && (
                                            " pour la prise en charge " + "n° " + this.state.devis?.priseencharge[0].id
                                        )}

                                        {this.state.devis?.prise_en_charge_id && (
                                            " pour la prise en charge " + "n° " + this.state.devis?.prise_en_charge_id
                                        )}
                                    </h4>

                                    {(this.state.isCommandeValid && (
                                        <ActionsPDF data={this.state.devis} api={this.injected.rootStore.devisStore}
                                                    filename={"devis" + this.state.devis?.id + "_" + this.state.devis?.user?.nom + "_" + this.state.devis?.created_at + ".pdf"}/>
                                    ))}

                                </CardHeader>
                                <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                    <h6 className="mb-0">

                                        {
                                            !this.state.devis?.id && (
                                                "Renseigner un titre et un utilisateur pour votre devis."
                                            )
                                        }
                                    </h6>
                                </CardHeader>
                                <Form
                                    className="mb-4"
                                    id="form-add-devis"
                                    name="form-add-devis"
                                    style={{textAlign: "center"}}
                                >
                                    <CardBody>
                                        <Col>
                                            <Input

                                                style={{textAlign: "center"}}
                                                className={"mb-3"}
                                                placeholder="Titre du devis"
                                                id="input-title"
                                                type="text"
                                                disabled={this.disabled()}
                                                value={this.state.devis?.titre}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    let devis = this.state.devis
                                                    devis.titre = e.target.value
                                                    this.setState({devis: devis})
                                                }}
                                            />

                                            <Row className="ml-2 mr-2" style={{justifyContent: "space-between"}}>
                                                <Address/>

                                                <AutoCompleteSearch
                                                    type={"devis"}
                                                    callbackUser={this.callbackUser}
                                                    myStatus={this.state.devis?.status}
                                                    user={this.state.devis?.user ?? this.injected.rootStore.savedDatasStore.user}
                                                    status={this.disabled() ? this.state.devis?.status : "VALID"}
                                                    status2="VALID"/>
                                            </Row>

                                            {(this.state.isCommandeValid && !this.disabled()) && (
                                                <AutoCompleteInvoices
                                                    type={"devis"}
                                                    id={this.state.devis?.id}
                                                    lignes={this.state.devis.ligne}
                                                    callbackRefreshLignes={this.callbackRefreshLignes}
                                                />
                                            )}

                                            {(this.state.isCommandeValid && (
                                                <TableGeneric
                                                    key={"devis" + this.state.devis?.id}
                                                    titles={[
                                                        "Pos.",
                                                        i18n.t('page.admin.devis.facturation.ref'),
                                                        i18n.t('page.admin.devis.facturation.designation'),
                                                        i18n.t('page.admin.devis.facturation.quantity'),
                                                        i18n.t('page.admin.invoices.facturation.tarifu'),
                                                        "Tarif U HT",
                                                        i18n.t('page.admin.devis.facturation.remise'),
                                                        i18n.t('page.admin.devis.facturation.totalHT'),
                                                        i18n.t('page.admin.devis.facturation.tva'),
                                                        "Montant TVA",
                                                        i18n.t('page.admin.devis.facturation.totalTTC'),
                                                        "Actions"
                                                    ]}>

                                                    {this.state.devis?.ligne.map((devis, index) => {
                                                        return this.createRow(devis, index);
                                                    })}

                                                </TableGeneric>))}

                                            {(this.state.isCommandeValid && (
                                                <Row>
                                                    <Col md="6">
                                                        <Card>
                                                            <CardHeader>
                                                                Obligation légale
                                                            </CardHeader>
                                                            <CardBody>
                                                                <FormGroup>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-ob-2"
                                                                        type="text"
                                                                        placeholder="Obligation légale"
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-ob-1"
                                                                        type="text"
                                                                        placeholder="Obligation légale"
                                                                    />
                                                                </FormGroup>
                                                            </CardBody>

                                                        </Card>
                                                    </Col>

                                                    <Col md="6">
                                                        <Card>
                                                            <CardHeader>
                                                                Total dû
                                                            </CardHeader>
                                                            <CardBody>

                                                                <Table>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <label className="form-control-label">
                                                                                Total HT hors frais de port ou frais
                                                                                déplacement
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            {this.state.devis?.totalht} €
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            <label className="form-control-label">
                                                                                Montant TVA
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            {this.state.devis?.montant_tva} €
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            <label
                                                                                className="form-control-label"
                                                                                htmlFor="input-deplacement"
                                                                            >
                                                                                Frais déplacement ou port
                                                                            </label>

                                                                        </td>
                                                                        <td>
                                                                            <FormGroup style={{display: "flex"}}>
                                                                                <Input
                                                                                    className="form-control-alternative"
                                                                                    id="input-deplacement"
                                                                                    type="number"
                                                                                    value={this.state.devis?.cout_deplacement}
                                                                                    min={0}
                                                                                    disabled={this.disabled()}
                                                                                    onChange={async (e) => {
                                                                                        e.preventDefault();
                                                                                        let devis = this.state.devis
                                                                                        devis.cout_deplacement = e.target.value
                                                                                        await this.setState({devis: devis})

                                                                                        this.calculateTotal()
                                                                                    }}
                                                                                />
                                                                                <span style={{margin: "auto"}}>€ </span>
                                                                            </FormGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label className="form-control-label">
                                                                                Total HT 2
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            {this.state.devis?.totalht2} €
                                                                        </td>
                                                                    </tr>


                                                                    {this.injected.rootStore.authStore.isMicro() &&
                                                                    <tr>
                                                                        <td colSpan="2" style={{
                                                                            fontWeight: "bold",
                                                                            textAlign: "center"
                                                                        }}>
                                                                            TVA non applicable, art. 293 B du CGI
                                                                        </td>
                                                                    </tr>
                                                                    }
                                                                    {!this.injected.rootStore.authStore.isMicro() &&
                                                                    <tr>
                                                                        <td>
                                                                            <label className="form-control-label">
                                                                                Total TTC
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            {this.state.devis?.totalttc} €

                                                                        </td>
                                                                    </tr>
                                                                    }
                                                                    </tbody>
                                                                </Table>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>))}
                                        </Col>

                                        {(this.state.isCommandeValid && (
                                            <Row>
                                                {this.createButtonSave("Sauvegarder", "success", e => this.onSubmit(e))}

                                                {this.state.devis?.status === "DRAFT" && (
                                                    <Col>
                                                        <Button block color={"success"}
                                                                type="submit"
                                                                disabled={this.disabled()}
                                                                onClick={e => this.toggleValidate(e)}>Valider le
                                                            devis</Button>
                                                    </Col>)
                                                }
                                                {this.state.devis?.status === "WAIT" && (this.createButton("Valider le devis pour le client", "success", e => this.toggleValidateClient(e)))}
                                                {this.state.devis?.status === "WAIT" && (this.createButton("Refuser le devis pour le client", "danger", e => this.toggleRefusedClient(e)))}

                                                {this.state.devis?.status === "DRAFT" && (
                                                    <Col>
                                                        <Button block color={"danger"}
                                                                type="submit"
                                                                disabled={this.disabled()}
                                                                onClick={e => this.toggleDelete(e)}>Supprimer le
                                                            devis</Button>
                                                    </Col>)
                                                }

                                                <Col>
                                                    <Button block color={"primary"}
                                                            type="submit"
                                                            disabled={this.state.devis?.status === "DRAFT"}
                                                            onClick={e => this.injected.rootStore.devisStore.togglePdf(e, this.state.devis?.id, this.state.devis?.user?.email)}>Mail
                                                        avec PDF</Button>
                                                </Col>
                                            </Row>))}
                                        {(!this.state.isCommandeValid && (
                                            this.createButtonSave("Sauvegarder", "success", e => this.onSubmitFirstStep(e))
                                        ))}
                                    </CardBody>
                                </Form>
                            </Card>
                        </div>
                    </Row>


                </Container>


            </>
        );
    }
}));

export default withRouter(AddDevis);
