import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardHeader, Table} from "reactstrap";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import AutoCompleteLine from "./AutoCompleteLine";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import Badge from "reactstrap/lib/Badge";
import ModalAkitatek from "../Modal/Modal";
import AddStock from "../../../views/admin/Stock/AddStock";
import CardBody from "reactstrap/lib/CardBody";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";


const AutoCompleteInvoices = inject("rootStore")(observer(class AutoCompleteInvoices extends React.Component {


    lignes
    type
    id
    titles = ["Ref", "Designation", "Quantité", "Tarif U TTC", "Tarif U HT", "Remise %", "TVA", "Actions"];

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            isProv: this.injected.isProv,
            choiceType: "ligne",
            id: this.injected.id,
            type: this.injected.type ?? "invoice",
            lignes: this.injected.lignes,
            suggestion: "",
            total_unit_ht: 0,
            prix_unitaire: 0,
            tva: !this.injected.rootStore.authStore.isMicro() || this.injected.type === "commande" ? 20 : 0,
            remise: 0,
            quantity: 1,
            reference_produit: "mo",
            pieces: [],
            showProduct: false,
            pid: ""
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.lignes !== prevProps.lignes ||
            this.injected.id !== prevProps.id ||
            this.injected.type !== prevProps.type
        ) {
            this.setState({
                lignes: this.injected.lignes,
                id: this.injected.id,
                type: this.injected.type ?? "invoice",
                tva: !this.injected.rootStore.authStore.isMicro() || this.injected.type === "commande" ? 20 : 0
            })
        }
    }

    modeCallback = () => {
        this.setState({
            modeSearch: !this.state.modeSearch
        })
    };

    async onChangeChoiceScan() {
        await this.resetMode("scan")
    }

    async resetMode(type) {
        await this.setState({
            lot: "",
            choiceType: type,
            suggestion: "",
            designation: "",
            reference_produit: "",
            total_unit_ht: 0,
            prix_unitaire: 0,
            pieces: [],
            pid: ""
        })
    }

    async onChangeChoiceProduct() {
        if (this.state.type === "commande") {
            await this.injected.rootStore.commandeStore.getStocksAvailable(true)
        } else {
            await this.injected.rootStore.commandeStore.getStocksAvailable()
        }

        await this.injected.rootStore.commandeStore.getLots()
        await this.resetMode("product")
    }

    async onChangeChoiceLigne() {
        await this.resetMode("ligne")
    }

    async onChangePrestaType(type) {
        await this.injected.rootStore.annuaireStore.prestationStore.getAllPrestations()
        await this.resetMode("presta")
    }

    setTitle() {
        return (
            <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
                <div className="mr-4 ml-2"><h5>Ajouter </h5></div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <FormGroup check className="mr-4">
                        <Label check style={{alignItems: "center", display: "flex"}}><Radio type="radio"
                                                                                            onChange={e => this.onChangeChoiceLigne()}
                                                                                            checked={this.state.choiceType === "ligne"}
                                                                                            name="radio2"/>
                            <h4><Badge color="danger">Ligne</Badge></h4>
                        </Label>
                    </FormGroup>
                    {this.state.type !== "commande" && (
                        <FormGroup check className="mr-4">
                            <Label check style={{alignItems: "center", display: "flex"}}><Radio type="radio"
                                                                                                name="radio2"
                                                                                                onChange={e => this.onChangePrestaType()}
                                                                                                checked={this.state.choiceType === "presta"}/>
                                <h4><Badge color="success">Prestation</Badge></h4>
                            </Label>
                        </FormGroup>)}
                    <FormGroup check className="mr-4">
                        <Label check style={{alignItems: "center", display: "flex"}}><Radio type="radio" name="radio3"
                                                                                            onChange={e => this.onChangeChoiceProduct()}
                                                                                            checked={this.state.choiceType === "product"}/>
                            <h4><Badge color="primary">Produit</Badge></h4>
                        </Label>
                    </FormGroup>

                    {this.state.type !== "commande" && (
                        <FormGroup check className="mr-4">
                            <Label check style={{alignItems: "center", display: "flex"}}><Radio type="radio"
                                                                                                name="radio4"
                                                                                                onChange={e => this.onChangeChoiceScan()}
                                                                                                checked={this.state.choiceType === "scan"}/>
                                <h4><Badge color="warning">Scan</Badge></h4>
                            </Label>
                        </FormGroup>)}
                </div>

                {this.state.choiceType === "product" && (
                    <Button style={{marginLeft: "auto"}} color="warning"
                            onClick={e => this.setState({showProduct: true})}>
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </Button>)}
            </div>
        )
    }


    setStock(product) {
        this.setState({
            suggestion: product,
            designation: product?.nom ?? "",
            reference_produit: product?.ref ?? "produit",
            prix_unitaire: product?.prix_vente ?? 0,
        })
    };


    setCallbackPieces = (pieces) => {
        this.setState({
            pieces: pieces
        })
    };


    disabled() {
        return (this.state.choiceType !== "ligne" && !this.state.suggestion)
    }


    setMode() {
        return (
            <Table style={{"marginBottom": "0em"}}>
                <thead>
                <tr className="text-center">
                    {
                        this.titles.map(title => {
                            return <th scope="col" key={title}>{title}</th>

                        })
                    }
                </tr>
                </thead>
                <tbody key={this.state.id}>
                <AutoCompleteLine
                    isProv={this.state.isProv}
                    lignes={this.state.lignes}
                    type={this.state.type}
                    id={this.state.id}
                    disabled={false}
                    lot={this.state.lot}
                    prix_unitaire={this.state.prix_unitaire}
                    reference_produit={this.state.reference_produit}
                    choiceType={this.state.choiceType}
                    suggestion={this.state.suggestion}
                    total_unit_ht={this.state.total_unit_ht}
                    designation={this.state.designation}
                    setCallbackPieces={this.setCallbackPieces}
                    isPiece={false}
                    position={1}
                    quantity={this.state.quantity}
                    callbackRefreshLignes={this.injected.callbackRefreshLignes}/>

                {this.state.pieces?.map((piece, id) => {
                    return (<AutoCompleteLine
                        isProv={this.state.isProv}
                        lignes={this.state.lignes}
                        type={this.state.type}
                        disabled={true}
                        lot={this.state.lot}
                        key={id}
                        index={id + 1}
                        suggestion={piece}
                        designation={piece?.nom}
                        id={this.state.id}
                        quantity={piece.pivot.quantity}
                        total_unit_ht={this.state.total_unit_ht}
                        prix_unitaire={piece.pivot.prix_unitaire}
                        reference_produit={piece.ref}
                        choiceType={"product"}
                        isPiece={true}
                        position={id}
                        callbackRefreshLignes={this.injected.callbackRefreshLignes}/>)
                })}
                </tbody>
            </Table>
        )
    }

    selectionLot(suggestion) {
        this.setState({
            lot: suggestion,
            prix_unitaire: suggestion?.mouv?.commande?.prix_unitaire ?? suggestion?.product?.prix_vente,

        })
    }


    selection(suggestion) {
        this.setState({
            reference_produit: suggestion?.ref ?? "produit",
            prix_unitaire: suggestion?.prix_vente ?? 0,
            suggestion: suggestion ?? "",
            designation: suggestion?.nom ?? ""
        })
    }


    selectionPresta(suggestion) {
        this.setState({
            reference_produit: "mo",
            prix_unitaire: suggestion?.prix_unitaire ?? 0,
            suggestion: suggestion ?? "",
            pieces: suggestion?.pieces ?? [],
            designation: suggestion?.fullname ?? " "
        })
    }


    onClose = (childData) => {
        this.setState({
            showProduct: false
        })

        if (childData) {
            this.setStock(childData)
        }
    };


    async saveWithPID(value) {
        let authResponse = await this.injected.rootStore.stockStore.productStore.createWithPID({
            pid: value,
            type: this.state.type,
            id: this.state.id
        })

        if (authResponse?.status === "success") {
            this.setState({pid: ""})
            this.injected.callbackRefreshLignes(authResponse?.datas)
        }
    }

    enter(e) {
        if (e.key === 'Tab' || e.key === 'Enter') {
            e.preventDefault()
            this.saveWithPID(this.state.pid)
        }
    }

    render() {
        return (
            <Card>
                <CardHeader style={{padding: "0.2em"}}
                            className={this.injected.rootStore.savedDatasStore.setColorType(this.state.choiceType)}>
                    {this.setTitle()}
                </CardHeader>
                <div className={"m-auto"}>

                    {this.state.choiceType === "product" && (
                        <div style={{display: "flex"}}>
                            <Autocomplete
                                key={"stocks"}
                                value={this.state.suggestion}
                                options={this.injected.rootStore.commandeStore.stocksAvailable}
                                getOptionLabel={(option) => {
                                    if (option?.id) {
                                        return "#" + option?.id + " " + option?.nom
                                    } else {
                                        return ""
                                    }
                                }}
                                style={{width: 300}}
                                onChange={async (event, newValue) => {
                                    await this.selection(newValue);
                                    await this.selectionLot(null);
                                    await this.injected.rootStore.commandeStore.getLots(this.state.suggestion?.id)

                                }}
                                selectOnFocus
                                renderInput={(params) => <TextField {...params}
                                                                    margin="normal"
                                                                    label="Rechercher un produit"
                                                                    variant="outlined"/>}
                            />
                            {this.state.type !== "commande" && (
                                <Autocomplete
                                    key={"lots"}
                                    value={this.state.lot}
                                    options={this.injected.rootStore.commandeStore.lots}
                                    getOptionLabel={(option) => {
                                        var prix = option?.mouv?.commande?.prix_unitaire ?? option?.product?.prix_vente;
                                        if (option?.id) {
                                            return option?.pid + " " + prix + " " + "€";
                                        } else {
                                            return ""
                                        }
                                    }}
                                    style={{width: 300}}
                                    onChange={(event, newValue) => {
                                        this.selection(newValue?.product);
                                        this.selectionLot(newValue);
                                    }}
                                    selectOnFocus
                                    renderInput={(params) => {
                                        return (<TextField {...params}
                                                           margin="normal"
                                                           label="Saisir un lot"
                                                           variant="outlined"/>)
                                    }}
                                />)}

                        </div>
                    )}

                    {this.state.choiceType === "presta" && (
                        <div style={{display: "flex"}}>
                            <Autocomplete
                                key={"presta"}
                                value={this.state.suggestion}
                                options={this.injected.rootStore.annuaireStore.prestationStore.allPrestations}
                                getOptionLabel={(option) => {
                                    if (option?.id) {
                                        return "#" + option?.id + " " + option?.fullname
                                    } else {
                                        return ""
                                    }
                                }}
                                style={{width: 300}}
                                onChange={(event, newValue) => {
                                    this.selectionPresta(newValue);
                                }}
                                selectOnFocus
                                renderInput={(params) => {
                                    return (<TextField {...params}
                                                       margin="normal"
                                                       label="Rechercher une prestation"
                                                       variant="outlined"/>)
                                }}
                            />
                        </div>
                    )}
                </div>

                {this.state.choiceType !== "scan" ? (
                    this.setMode()
                ) : <CardBody>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <InputGroup
                            style={{width: "auto"}}
                        >
                            <Input
                                value={this.state.pid}
                                placeholder={"Scan moi"}
                                onChange={e => this.setState({pid: e.target.value})}
                                onKeyDown={e => this.enter(e)}
                                onKeyPress={e => this.enter(e)}
                            >
                            </Input>

                            <InputGroupAddon addonType="append">
                                <Button onClick={e => this.saveWithPID(this.state.pid)} color="warning"><i
                                    className="fa fa-barcode" aria-hidden="true"></i></Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </CardBody>}

                <ModalAkitatek title={"Ajouter un produit"} show={this.state.showProduct} update={true}
                               children={<AddStock showProduct={false} onCallback={this.onClose}/>}
                               onCallback={e => this.onClose()}/>
            </Card>
        )
    }
}));

export default AutoCompleteInvoices;
