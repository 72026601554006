import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Container, Form, FormGroup, Input, Row, Table} from "reactstrap";
import i18n from "i18next";
import {Col} from "react-bootstrap";
import Address from "../../../components/Akitatek/Address";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import TableCreateInvoice from "./TableCreateInvoice";
import AutoCompleteSearch from "../../../components/Akitatek/Search/AutoCompleteSearch";
import {withRouter} from "react-router-dom";
import AutoCompleteInvoices from "../../../components/Akitatek/AutoCompleteInvoices/AutoCompleteInvoices";
import getRoutes from "../../../components/Akitatek/getRoutes";
import ActionsPDF from "./ActionsPDF";
import moment from "moment";

const AddInvoice = inject("rootStore")(observer(class AddInvoice extends React.Component {

    invoiceStore = this.injected.rootStore.invoiceStore.createInvoiceStore;
    errorStore = this.injected.rootStore.errorStore;
    invoice

    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des factures",
            "Créer une facture / gérer les encaissements",
            "facture.jpg",
            this.injected.location.aboutProps?.subCategory ?? "",
            this.injected.location.aboutProps?.user)

        this.state = {
            isCommandeValid: this.injected.location.aboutProps?.isCommandeValid ?? false,
            isLoading: false,
            invoice: this.injected.location.aboutProps?.invoice ?? {
                facture_ligne: [],
                facture_reglement: [],
                intitule: "",
                user: this.injected.location.aboutProps?.user,
                statusEnum: "DRAFT",
                prise_en_charge_id: ""
            }
        };

        this.toggleArchive = this.toggleArchive.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
        this.toggleValidate = this.toggleValidate.bind(this)
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitFirstStep = this.onSubmitFirstStep.bind(this);
    }

    //update component if the id change, or if there is a refresh
    async componentDidUpdate(prevProps) {
        if (this.injected.location !== prevProps.location) {
            await this.onRouteChanged()
        }
        if (this.state.invoice === "" && this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }

    //check if the values are up to date
    async componentDidMount() {
        if (this.injected.match.params.id) {
            await this.onRouteChanged()
        }
    }

    //get the values of commande by ID
    async onRouteChanged() {
        let id = this.props.match && parseInt(this.props.match.params.id, 10);
        let type = this.props.match && this.props.match.params.type;
        if (id) {
            let invoice = await this.invoiceStore.getInvoice(id, type === "élaboration" ? "DRAFT" : "ALL");
            this.setState({
                isLoading: false,
                invoice: invoice,
                isCommandeValid: true
            })
        } else if (this.props.match.params.id === "add" && !this.injected.location.aboutProps?.invoice && !this.injected.location.aboutProps?.user) {
            this.setState({
                isLoading: false,
                isCommandeValid: false,
                invoice: {
                    facture_ligne: [],
                    intitule: "",
                    statusEnum: "DRAFT",
                    prise_en_charge_id: ""
                }
            })
        }
    }

    createButton(title, color, onclickFunc, func, href = "#") {
        return (
            <Col>
                <Button block
                        href={href}
                        color={color}
                        type="submit"
                        disabled={func}
                        onClick={onclickFunc}>{title}</Button>
            </Col>)
    }


    async toggleValidate(e) {
        e.preventDefault()
        let htmlWarningSave = '<p><strong>Toute validation est définitive !<br /></strong></p>' +
            '<ul>' +
            '   <li>La validation déclenche le mouvement de stock</li>' +
            '   <li>Il n\'est plus possible de modifier une facture</li>' +
            '   <li>Permet l\'encaissement de la facture</li>' +
            '</ul>';
        await this.injected.rootStore.notificationStore.validateWarning(htmlWarningSave, event => this.onValidate(this.state.invoice))
    }


    async onValidate(myInvoice) {
        this.setState({isLoading: true})

        let data = Object.assign({}, myInvoice);
        data.statusEnum = "TO_PAID"

        let authResponse = await this.injected.rootStore.invoiceStore.updateInvoice(data, "Facture #" + this.state.invoice?.id + " validée. Cette facture est validée. Elle ne pourra plus être modifiée !")

        if (authResponse?.status === "success") {
            let data = authResponse.datas
            this.injected.rootStore.savedDatasStore.setUser(data?.user)
            this.injected.history.push(getRoutes("Gérer une facture", data.id, data.status))
        } else {
            this.setState({isLoading: false})
        }

    }

    async onSubmit(e) {
        e.preventDefault();
        let authResponse = await this.injected.rootStore.invoiceStore.updateInvoice(this.state.invoice)
        if (authResponse?.status === "success") {
            let data = authResponse.datas
            this.injected.rootStore.savedDatasStore.setUser(data?.user)
            this.injected.history.push(getRoutes("Gérer une facture", data.id, data.status))
        }
    }

    async onSubmitFirstStep(e) {
        e.preventDefault();
        let data = {
            user_id: this.state.invoice?.user?.id ?? this.injected.rootStore.savedDatasStore.user?.id,
            intitule: this.state.invoice.intitule,
            statusEnum: this.state.invoice.statusEnum,
            prise_en_charge_id: this.state.invoice?.prise_en_charge_id

        }
        let authResponse = await this.injected.rootStore.invoiceStore.saveInvoice(data)
        if (authResponse?.status === "success") {
            let data = authResponse.datas
            this.injected.rootStore.savedDatasStore.setUser(data?.user)
            this.injected.history.push(getRoutes("Gérer une facture", data.id, data.status))
        }
    }

    createRow(data, index) {
        return <TableCreateInvoice
            callbackRefreshLignes={this.callbackRefreshLignes}
            invoice={this.state.invoice}
            line={data}
            callbackRefreshTotal={this.callbackRefreshTotal}
            key={index}
            id={data.id}
            position={index}
        />;
    }


    //Refresh commande
    callbackUser = (childData) => {
        let invoice = Object.assign({}, this.state.invoice);
        console.log(childData)
        invoice.user = childData
        invoice.user_id = childData?.id
        this.setState({invoice: invoice})
    };


    //Refresh commande
    callbackRefreshLignes = (childData) => {
        let invoice = Object.assign({}, this.state.invoice);
        invoice.facture_ligne = childData
        this.setState({invoice: invoice})
        this.calculateTotal()
    };

    //Refresh commande
    callbackRefreshTotal = async (childData) => {
        await this.setState({invoice: childData})
        this.calculateTotal()
    };


    disabled() {
        return this.state.invoice.statusEnum === "PAID" || this.state.invoice.statusEnum === "TO_PAID" || this.state.invoice.statusEnum === "ARCHIVED"
    }

    calculateTotal() {
        let totalht = this.state.invoice.facture_ligne.reduce((a, {totalht}) => parseFloat(a) + parseFloat(totalht), 0);
        let totalttc = this.state.invoice.facture_ligne.reduce((a, {totalttc}) => parseFloat(a) + parseFloat(totalttc), 0);
        let totalht2 = totalht;

        if (this.state.invoice.deplacement != null) {
            totalht2 = parseFloat(totalht) + parseFloat(this.state.invoice.deplacement)
            totalttc = parseFloat(totalttc) + parseFloat(this.state.invoice.deplacement)
        }

        let invoice = Object.assign({}, this.state.invoice);
        invoice.totalht2 = totalht2.toFixed(2)
        invoice.totalttc = totalttc.toFixed(2)
        invoice.totalht = totalht.toFixed(2)
        this.setState({
            invoice: invoice
        })
    }

    async toggleArchive(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setQuestion("Mettre en archive?", "Facture #" + this.state.invoice?.id, event => this.onArchive(e))
    }

    async onArchive(e) {
        e.preventDefault();

        let datas = {
            id: this.state.invoice?.id,
            statusEnum: "ARCHIVED"
        }

        let authResponse = await this.injected.rootStore.invoiceStore.archived(datas)
        if (authResponse?.status === "success") {
            this.setState({
                invoice: authResponse.datas
            })

            this.injected.rootStore.savedDatasStore.setUser(this.state.invoice?.user)
        }
    }

    async toggleDelete(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setWarning("Facture #" + this.state.invoice?.id, event => this.onDelete(this.state.invoice?.id), false)
    }

    async onDelete(id) {
        let authResponse;

        if (this.state.invoice?.statusEnum === "DRAFT") {
            authResponse = await this.injected.rootStore.invoiceStore.delete(id)
        }

        if (this.state.invoice?.statusEnum === "PAID") {
            authResponse = await this.injected.rootStore.invoiceStore.cancelInvoice(id)
        }

        if (this.state.invoice?.statusEnum === "TO_PAID") {
            authResponse = await this.injected.rootStore.invoiceStore.replaceInvoice(id)
        }

        if (authResponse?.status === "success") {
            let data = authResponse.datas
            this.injected.history.push(getRoutes("Factures"));
        }
    }


    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Row className="mb-3">
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader
                                    className={this.injected.rootStore.savedDatasStore.setColor(this.state.invoice?.statusEnum)}
                                    style={{display: "flex", justifyContent: "space-between"}}>
                                    <h4 className="mb-0">
                                        {this.state.invoice?.id && this.props.match.params.type !== "élaboration" && (
                                            "Facture #" + this.state.invoice?.id + " " + this.state.invoice?.statusname.toUpperCase()
                                        )}

                                        {this.state.invoice?.id && this.props.match.params.type === "élaboration" && (
                                            "Facture Provisoire #" + this.state.invoice?.id + " " + this.state.invoice?.statusname.toUpperCase()
                                        )}
                                        {!this.state.invoice?.id && (
                                            i18n.t('page.admin.invoices.add')
                                        )}


                                        {this.state.invoice?.priseencharge?.length > 0 && (
                                            " pour la prise en charge " + "n° " + this.state.invoice?.priseencharge[0].id
                                        )}

                                        {this.state.invoice?.prise_en_charge_id && (
                                            " pour la prise en charge" + " n° " + this.state.invoice?.prise_en_charge_id
                                        )}
                                    </h4>


                                    {(this.state.isCommandeValid && (
                                        <ActionsPDF data={this.state.invoice} api={this.injected.rootStore.invoiceStore}
                                                    filename={"facture" + this.state.invoice?.id + "_" + "nom" + "_" + this.state.invoice?.created_at + ".pdf"}/>
                                    ))}

                                </CardHeader>
                                <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                    <h6 className="mb-0">

                                        {
                                            !this.state.invoice?.id && (
                                                "Renseigner un titre et un utilisateur pour votre facture. Celle-ci sera dans un état provisoire dans un premier temps."
                                            )
                                        }
                                    </h6>

                                    {moment(this.state.invoice.created_at).format("lll")}
                                </CardHeader>
                                <Form
                                    className="mb-4"
                                    id="form-add-invoice"
                                    name="form-add-invoice"
                                    style={{textAlign: "center"}}
                                >
                                    <CardBody>
                                        <Col>
                                            <Input

                                                style={{textAlign: "center"}}
                                                className={"mb-3"}
                                                placeholder="Titre de la facture"
                                                id="input-title"
                                                type="text"
                                                disabled={
                                                    this.disabled()
                                                }
                                                value={this.state.invoice?.intitule}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    let invoice = Object.assign({}, this.state.invoice);
                                                    invoice.intitule = e.target.value
                                                    this.setState({invoice: invoice})
                                                }}
                                            />


                                            <Row className="ml-2 mr-2" style={{justifyContent: "space-between"}}>
                                                <Address/>
                                                <AutoCompleteSearch
                                                    callbackUser={this.callbackUser}
                                                    myStatus={this.state.invoice?.statusEnum}
                                                    adresse_livraison={this.state.invoice?.adresse_livraison}
                                                    adresse={this.state.invoice?.adresse}
                                                    user={this.state.invoice?.user ?? this.injected.rootStore.savedDatasStore.user}
                                                    store={this.invoiceStore} status="PAID" status2="TO_PAID"/>
                                            </Row>

                                            {(this.state.isCommandeValid && !this.disabled()) && (
                                                <AutoCompleteInvoices
                                                    isProv={this.props.match && this.props.match.params.type}
                                                    id={this.state.invoice?.id}
                                                    lignes={this.state.invoice.facture_ligne}
                                                    callbackRefreshLignes={this.callbackRefreshLignes}
                                                />
                                            )}


                                            {(this.state.isCommandeValid && (

                                                <TableGeneric
                                                    key={"invoice" + this.state.invoice?.id}
                                                    titles={[
                                                        "Pos.",
                                                        i18n.t('page.admin.invoices.facturation.ref'),
                                                        i18n.t('page.admin.invoices.facturation.designation'),
                                                        i18n.t('page.admin.invoices.facturation.quantity'),
                                                        i18n.t('page.admin.invoices.facturation.tarifu'),
                                                        "Tarif U HT",
                                                        i18n.t('page.admin.invoices.facturation.remise'),
                                                        i18n.t('page.admin.invoices.facturation.totalHT'),
                                                        i18n.t('page.admin.invoices.facturation.tva'),
                                                        "Montant TVA",
                                                        i18n.t('page.admin.invoices.facturation.totalTTC'),
                                                        "Actions"
                                                    ]}>

                                                    {this.state.invoice?.facture_ligne?.map((invoice, index) => {
                                                        return this.createRow(invoice, index);
                                                    })}

                                                </TableGeneric>
                                            ))}

                                            {(this.state.isCommandeValid && (
                                                <Row>
                                                    <Col md="6">
                                                    </Col>

                                                    <Col md="6">
                                                        <Card>
                                                            <CardHeader>
                                                                Total dû
                                                            </CardHeader>
                                                            <CardBody>

                                                                <Table>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <label className="form-control-label">
                                                                                Total HT hors frais déplacement ou port
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            {this.state.invoice?.totalht} €
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            <label className="form-control-label">
                                                                                Montant TVA
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            {this.state.invoice?.montant_tva} €
                                                                        </td>
                                                                    </tr>


                                                                    <tr>
                                                                        <td>
                                                                            <label className="form-control-label">
                                                                                Total TTC hors frais déplacement ou port
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            {this.state.invoice?.totalttc - this.state.invoice?.deplacement} €
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            <label
                                                                                className="form-control-label"
                                                                                htmlFor="input-deplacement"
                                                                            >
                                                                                Frais déplacement ou port
                                                                            </label>

                                                                        </td>
                                                                        <td>
                                                                            <FormGroup style={{display: "flex"}}>
                                                                                <Input
                                                                                    className="form-control-alternative"
                                                                                    id="input-deplacement"
                                                                                    type="number"
                                                                                    value={this.state.invoice?.deplacement}
                                                                                    min={0}
                                                                                    disabled={
                                                                                        this.disabled()

                                                                                    }
                                                                                    onChange={async (e) => {
                                                                                        e.preventDefault();
                                                                                        let invoice = Object.assign({}, this.state.invoice);
                                                                                        invoice.deplacement = e.target.value
                                                                                        await this.setState({invoice: invoice})

                                                                                        this.calculateTotal()
                                                                                    }}
                                                                                />
                                                                                <span style={{margin: "auto"}}>€ </span>
                                                                            </FormGroup>
                                                                        </td>
                                                                    </tr>

                                                                    {this.state.invoice.type === "micro" &&
                                                                    <tr>
                                                                        <td colSpan="2" style={{
                                                                            fontWeight: "bold",
                                                                            textAlign: "center"
                                                                        }}>
                                                                            TVA non applicable, art. 293 B du CGI
                                                                        </td>
                                                                    </tr>
                                                                    }
                                                                    {!this.injected.rootStore.authStore.isMicro() &&
                                                                    <tr>
                                                                        <td>
                                                                            <label className="form-control-label">
                                                                                Total TTC
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            {this.state.invoice?.totalttc} €
                                                                        </td>
                                                                    </tr>
                                                                    }
                                                                    </tbody>
                                                                </Table>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>

                                        {(this.state.isCommandeValid && !this.state.isLoading && (
                                            <Row>
                                                {this.createButton("Sauvegarder", "success", this.onSubmit, this.state.invoice?.statusEnum === "PAID" || this.state.invoice?.statusEnum === "TO_PAID")}
                                                {this.createButton("Valider la facture", "success", e => this.toggleValidate(e), this.state.invoice?.statusEnum !== "DRAFT")}
                                                {this.state.invoice?.statusEnum === "DRAFT" && (this.createButton("Supprimer la facture", "danger", this.toggleDelete, this.state.invoice?.statusEnum !== "DRAFT"))}
                                                {this.state.invoice?.statusEnum === "PAID" && (this.createButton("Annuler", "danger", this.toggleDelete, this.state.invoice?.statusEnum !== "PAID"))}
                                                {this.state.invoice?.statusEnum === "TO_PAID" && (this.createButton("Remplacer", "danger", this.toggleDelete, this.state.invoice?.statusEnum !== "TO_PAID"))}
                                                {this.state.invoice?.statusEnum === "TO_PAID" && (this.createButton("Archive pour impayée", "danger", this.toggleArchive))}
                                                {this.createButton("Mail avec PDF", "primary", e => this.injected.rootStore.invoiceStore.togglePdf(e, this.state.invoice?.id, this.state.invoice?.user?.email), this.state.invoice?.statusEnum === " " || this.state.invoice?.statusEnum === "DRAFT"
                                                )}
                                                {this.createButton("Reglement", "warning", () => {
                                                }, this.state.invoice?.statusEnum === "DRAFT", "/#" + getRoutes("Payer une facture", this.state.invoice?.id))}
                                            </Row>
                                        ))}
                                        {(!this.state.isCommandeValid && (
                                            this.createButton("Sauvegarder", "success", this.onSubmitFirstStep, this.state.invoice?.statusEnum === "PAID" || this.state.invoice?.statusEnum === "TO_PAID")
                                        ))}
                                    </CardBody>
                                </Form>
                            </Card>
                        </div>
                    </Row>
                </Container>


            </>
        );
    }
}));

export default withRouter(AddInvoice);
