import React from "react";
import routes from "../../routes";

function getRoutes(name, id = "", type = "") {
    let path = ""
    routes.map((route) => {
        if (route.name.toLowerCase() === name?.toLowerCase()) {
            path = route.path


            if (id) {
                path = path.replace(":id", id);
            }
            if (type) {
                path = path.replace(":type", type);
            }

        }

    })

    return path
}

export default getRoutes;
