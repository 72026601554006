import React from "react";
import {Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";


const Customers = (inject("rootStore")(observer(class Customers extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Clients", "Gestion des clients et des documents liés", "clients.jpg", this.injected.rootStore.userStore)
    }

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericTableAffichage api={this.injected.rootStore.userStore}
                                           user={false}
                                           icon={<i className="fa fa-user-circle mr-2" aria-hidden="true"/>}
                                           name={"Mes clients"}
                                           type="customer"/>
                </Container>
            </>
        );
    }
})));

export default Customers;
