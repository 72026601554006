import React from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

const ActionsCmdClient = inject("rootStore")(observer(class ActionsCmdClient extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            data: this.injected.data
        }
        this.togglePreparation = this.togglePreparation.bind(this)
        this.expediate = this.expediate.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
    }


    async expediate(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.data.id, e => this.onExpedition(this.state.data.id, this.state.data.suivi), this.state.data.id, "Je valide l'expédition de la commande #" + this.state.data.id)
    }

    async validate(id) {
        let data = {
            commande_id: id
        }

        let authResponse = await this.injected.rootStore.commandeClientStore.validate(data)
        this.onReload(authResponse, authResponse?.datas)
    }

    onReload(authResponse, data) {
        if (authResponse?.status === "success") {
            this.injected.onCallback(data)
            this.setState({
                data: data
            })
        }
    }

    async toggleDelete(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setWarning("Commande #" + this.state.data?.id, event => this.onDelete(this.state.data?.id), false)
    }

    async onDelete(id) {
        let authResponse = await this.injected.rootStore.commandeClientStore.cancelCommande(id)
        this.onReload(authResponse, authResponse?.datas)
    }


    async togglePreparation(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setQuestion("Etes vous sûr(e) d'avoir terminé la préparation de la commande n° " + this.state.data.id, " Valider", e => {
            this.validate(this.state.data.id)
        })
    }

    async onExpedition(id, suivi) {
        var datas = {
            suivi: suivi,
            id: id
        }
        let authResponse = await this.injected.rootStore.commandeClientStore.expediate(datas)
        this.onReload(authResponse, authResponse?.datas)
    }

    render() {
        return (
            <>
                    <Dropdown className={"mr-2"}>
                        <Dropdown.Toggle variant="warning">
                            <i className="fa fa-wrench" aria-hidden="true"/>
                            Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {this.state.data.status === "ORDERED" && (
                                <Dropdown.Item color={"warning"}
                                               className={"mr-2"}
                                               onClick={this.togglePreparation}>Valider
                                    la préparation de
                                    la commande</Dropdown.Item>)}
                            {this.state.data.status === "ORDERED" &&
                            (<Dropdown.Item color={"danger"}
                                            className={"mr-2"}
                                            onClick={this.toggleDelete}>Annuler</Dropdown.Item>)}
                            {this.state.data.status === "PREPARATION" && (
                                <Dropdown.Item color={"success"}
                                               className={"mr-2"}
                                               onClick={this.expediate}>Marquer comme
                                    expédier ou
                                    retirer</Dropdown.Item>)}


                        </Dropdown.Menu>
                    </Dropdown>
            </>
        );
    }

}));

export default withRouter(ActionsCmdClient);
