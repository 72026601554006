import React from "react";
import i18n from "i18next";

function TypeTable(type, api) {
    let titles;
    let onFilter;
    if (type === "invoice") {

        titles = [{
            "title": "id",
            "action": "thLink",
            "href": "Gérer une Facture",
            "key": "id",
            "filter": "id"
        },
            {
                "title": "titre",
                "action": "tr",
                "key": "intitule",
                "filter": "intitule"
            }, {
                "title": i18n.t('page.admin.invoices.client'),
                "action": "user",
                "key": "user",
                "filter": ""
            }, {
                "title": "status",
                "action": "status",
                "key": "statusname"
            }, {
                "title": "Modifiée le",
                "action": "date",
                "key": "created_at",
                "filter": "created_at"

            }, {
                "title": "Créee le",
                "action": "date",
                "key": "updated_at",
                "filter": "updated_at"
            }, {
                "title": "total",
                "action": "tr",
                "key": "total_facture",
            }, {
                "title": "Actions",
                "action": "Actions",
                "key": "",
                "filter": ""

            }]
    }

    if (type === "devis") {
        titles = [{
            "title": "id",
            "action": "thLink",
            "href": "Gérer un Devis",
            "key": "id",
            "filter": "id"
        },
            {
                "title": "titre",
                "action": "tr",
                "key": "titre",
                "filter": "titre"
            }, {
                "title": "status",
                "action": "status",
                "key": "valider",
                "filter": "valider"
            },
            {
                "title": i18n.t('page.admin.invoices.client'),
                "action": "user",
                "key": "user",
                "filter": ""
            },
            {
                "title": "Modifiée le",
                "action": "date",
                "key": "created_at",
                "filter": "created_at"

            }, {
                "title": "Créee le",
                "action": "date",
                "key": "updated_at",
                "filter": "updated_at"
            }, {
                "title": "total",
                "action": "tr",
                "key": "total_facture",
            }, {
                "title": "Actions",
                "action": "Actions",
                "key": "",
                "filter": ""
            }
        ]
    }

    if (type === "customer") {
        titles = [{
            "title": "id",
            "action": "thLink",
            "href": "Modifier Client",
            "key": "id",
            "filter": "id"
        },
            {
                "title": "Type",
                "action": "tr",
                "key": "account",
                "filter": "typeAccount"
            },
            {
                "title": "Prénom",
                "action": "tr",
                "key": "prenom",
                "filter": "prenom"
            },
            {
                "title": "Nom",
                "action": "tr",
                "key": "nom",
                "filter": "nom"
            },
            {
                "title": "Entreprise",
                "action": "tr",
                "key": "entreprise",
                "filter": "entreprise"
            },
            {
                "title": "Email",
                "action": "email",
                "key": "email",
                "filter": "email"
            },
            {
                "title": "Portable",
                "action": "phone",
                "sub": "profile",
                "key": "telephone_portable",
            },
            {
                "title": "Ville",
                "action": "tr",
                "sub": "profile",
                "key": "ville",
            },
            {
                "title": "Code postal",
                "action": "tr",
                "sub": "profile",
                "key": "codepostal",
            },

            {
                "title": "Date",
                "action": "date",
                "key": "created_at",
                "filter": "created_at"
            }
        ]
    }
    if (type === "spoolers") {
        titles = [
            {
                "title": "Nom du fichier",
                "action": "tr",
                "key": "filename",
            },
            {
                "title": "Imprimante",
                "action": "tr",
                "key": "name",
                "sub": "printer",
            },
            {
                "title": "Date de création",
                "action": "date",
                "key": "created_at",
                "filter": "created_at"
            },
            {
                "title": "Actions",
                "action": "Actions",
                "key": "",
                "filter": ""
            }
        ]
    }

    if (type === "printers") {
        titles = [
            {
                "title": "Titre",
                "action": "tr",
                "key": "title",
            },
            {
                "title": "Nom officiel",
                "action": "tr",
                "key": "name",
            },
            {
                "title": "Par défaut",
                "action": "map",
                "key": "printer",
                "type": "printer"
            },
            {
                "title": "Actions",
                "action": "Actions",
                "key": "",
                "filter": ""
            }
        ]
    }


    if (type === "stock") {
        titles = [{
            "title": "id",
            "action": "thLink",
            "href": "Modifier stock",
            "key": "id",
            "filter": "id"
        },
            {
                "title": "Nom",
                "action": "tr",
                "key": "nom",
                "filter": "nom",
            },
            {
                "title": "Path",
                "action": "tr",
                "key": "path",
                "sub": "category",
            },
            {
                "title": "Casier",
                "action": "tr",
                "key": "name",
                "sub": "casier",
            },
            {
                "title": "Stockage",
                "action": "tr",
                "key": "name",
                "last":"stockage",
                "sub": "casier",
            },
            {
                "title": "Quantité",
                "action": "tr",
                "key": "stock",
                "alerte": "alerte"
            },
        ]
    }


    if (type === "cmdClient") {
        titles = [{
            "title": "id",
            "action": "thLink",
            "href": "Voir une commande",
            "key": "id",
            "filter": "id"
        },
            {
                "title": "total",
                "action": "tr",
                "key": "total_facture",
            },
            {
                "title": i18n.t('page.admin.invoices.client'),
                "action": "user",
                "key": "user",
                "filter": ""
            },
            {
                "title": "Date de création",
                "action": "date",
                "key": "created_at",
                "filter": "created_at"
            },
            {
                "title": "status",
                "action": "status",
                "key": "statusname",
                "filter": "status"
            },
            {
                "title": "Actions",
                "action": "actionpencillink",
                "href": "Voir une commande",
                "key": "id",
                "filter": "id"
            }
        ]
    }

    if (type === "pec" || type === "pecinternet") {
        titles = [{
            "title": "id",
            "action": "thLink",
            "href": "Gérer une Prise En Charge",
            "key": "id",
            "filter": "id"},
            {
                "title": "titre",
                "action": "tr",
                "key": "title",
                "filter": "title"
            },
            {
                "title": "Appareil",
                "action": "map",
                "href": "Afficher un produit",
                "key": "appareils",
                "type": "appareil"
            },
            {
                "title": "Prestations",
                "action": "map",
                "href": "Modifier une prestation",
                "key": "prestations",
                "type": "prestations"
            },
            {
                "title": i18n.t('page.admin.invoices.client'),
                "action": "user",
                "key": "user",
                "filter": ""
            },
            {
                "title": "status",
                "action": "status",
                "key": "statusname",
                "filter": "status"
            },
            {
                "title": "Date d'arrivée",
                "action": "date",
                "key": "date_arrived",
                "filter": "date_arrived"
            },
            {
                "title": "signature",
                "action": "tr",
                "key": "suivi"
            },
            {
                "title": "Type",
                "action": "magasinouinternet",
                "key": "online"
            },
            {
                "title": "Actions",
                "action": "Actions",
                "key": "",
                "filter": ""
            }
        ]
    }

    if (type === "pecinternet") {
        titles = [{
            "title": "id",
            "action": "thLink",
            "href": "Gérer une Prise En Charge",
            "key": "id",
            "filter": "id"},
            {
                "title": "titre",
                "action": "tr",
                "key": "title",
                "filter": "title"
            },
            {
                "title": "Appareil",
                "action": "map",
                "href": "Afficher un produit",
                "key": "appareils",
                "type": "appareil"
            },
            {
                "title": "Prestations",
                "action": "map",
                "href": "Modifier une prestation",
                "key": "prestations",
                "type": "prestations"
            },
            {
                "title": i18n.t('page.admin.invoices.client'),
                "action": "user",
                "key": "user",
                "filter": ""
            },
            {
                "title": "status",
                "action": "status",
                "key": "statusname",
                "filter": "status"
            },
            {
                "title": "Date de création",
                "action": "date",
                "key": "created_at",
                "filter": "created_at"
            },
            {
                "title": "Date d'arrivée",
                "action": "date",
                "key": "date_arrived",
                "filter": "date_arrived"
            },
            {
                "title": "signature",
                "action": "tr",
                "key": "suivi"
            },
            {
                "title": "Type",
                "action": "magasinouinternet",
                "key": "online"
            },
            {
                "title": "Actions",
                "action": "Actions",
                "key": "",
                "filter": ""
            }
        ]
    }

    if (type === "cmd") {
        titles = [{
            "title": "id",
            "action": "thLink",
            "href": "Gérer une commande",
            "key": "id",
            "filter": "id"},
            {
                "title": "titre",
                "action": "tr",
                "key": "title",
                "filter": "title"
            },
            {
                "title": "status",
                "action": "status",
                "key": "statusname",
                "filter": "status"
            },
            {
                "title": "Référence",
                "action": "tr",
                "key": "ref",
                "filter": "ref"
            },
            {
                "title": "Fournisseur",
                "action": "tr",
                "sub": "fournisseur",
                "key": "nom",
            },
            {
                "title": "Date de création",
                "action": "date",
                "key": "created_at",
                "filter": "created_at"
            },
            {
                "title": "Modifiée",
                "action": "date",
                "key": "updated_at",
                "filter": "updated_at"
            },
            {
                "title": "Date d'envoi",
                "action": "date",
                "key": "date_envoi",
                "sub": "suivi",

            },
            {
                "title": "total",
                "action": "tr",
                "key": "totalttc",
            },
            {
                "title": "Actions",
                "action": "Actions",
                "key": "",
                "filter": ""
            },
        ]
    }

    onFilter = function (name, order) {
        api.filter.name = name;
        api.filter.order = order;

        api.getDatas();
    }

    return [titles, onFilter]
}

export default TypeTable;
