import {action, decorate, observable} from "mobx";
import Api from "../util/api";

export default class PrinterStore {

    datas = observable([]);
    filter = {
        status : "",
        search: "",
        page: "",
        last_page: "",
        user_id: ""

    }
    defaultFilter = {
        status: "",
        search: "",
        page: "",
        user_id: "",
        last_page: "",
    }
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    statusname = ""


    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            this.filter.user_id = this.rootStore.authStore.user.id
            let authResponse = await this.api.printer.getPrinters(this.filter)

            if (authResponse?.status === "success") {
                this.rootStore.savedDatasStore.setLoading(false)
                this.datas = authResponse.datas;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async makeDefault(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.printer.save(datas);

            if(authResponse?.status  === "success") {
                this.rootStore.notificationStore.setNotification( message,   "success");
                this.getDatas()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
}

decorate(PrinterStore, {
    datas: observable,
    getPrinters: action,
    status: observable,
    statusname: observable
});

