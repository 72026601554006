import React from "react";
import {inject, observer} from "mobx-react";
import {FormGroup, Input} from "reactstrap";
import Label from "reactstrap/lib/Label";
import CATEGORY from "./Category";
import AnnuaireCategory from "../../../views/admin/Annuaire/Category/Category";
import ModalAkitatek from "../Modal/Modal";


const ChoiceCategory = inject("rootStore")(observer(class ChoiceCategory extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            showCategory: false
        }
    }

    onClose = () => {
        this.choiceCategory(null, false)
        this.injected.onChangeCategory()
    }


    onCloseWithoutSuccess = () => {
        this.choiceCategory(null, false)
    }

    choiceCategory(e, value) {
        if (e) {
            e.preventDefault()
        }
        this.setState({
            showCategory: value
        })
    }

    render() {
        const {marqueId, defaultExpanded, path, type, disabled, name} = this.props
        return (
            <>
                <FormGroup>
                    <Label for="exampleRef">{name}</Label>
                    <Input
                        type="text"
                        name="exampleRef"
                        value={path}
                        disabled={disabled}
                        onClick={e => this.choiceCategory(e, true)}
                    />
                </FormGroup>


                {type === CATEGORY.ANNUAIRE && (
                    <ModalAkitatek show={this.state.showCategory}
                                   children={<AnnuaireCategory
                                       api={this.injected.rootStore.annuaireStore.annuaireCategoryStore}
                                       looking={false}
                                       marqueId={marqueId}
                                       defaultExpanded={defaultExpanded}
                                       update={true} onSelection={false}
                                       onClose={this.onClose}/>}
                                   onCallback={this.onCloseWithoutSuccess}/>
                )}


                {type === CATEGORY.NORMAL && (
                    <ModalAkitatek show={this.state.showCategory}
                                   children={<AnnuaireCategory
                                       api={this.injected.rootStore.annuaireStore.categoryStore}
                                       looking={true}
                                       defaultExpanded={defaultExpanded}
                                       update={true} onSelection={false}
                                       onClose={this.onClose}/>

                                   }
                                   onCallback={this.onCloseWithoutSuccess}/>
                )}

                {type === CATEGORY.GLOBAL && (
                    <ModalAkitatek show={this.state.showCategory}
                                   children={<AnnuaireCategory
                                       api={this.injected.rootStore.annuaireGlobalStore}
                                       looking={false}
                                       marqueId={marqueId}
                                       defaultExpanded={defaultExpanded}
                                       update={true} onSelection={false}
                                       onClose={this.onClose}/>

                                   }
                                   onCallback={this.onCloseWithoutSuccess}/>
                )}
            </>
        );
    }

}));

export default ChoiceCategory;
