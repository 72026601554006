/* Custom import */
import Api from '../../util/api'
import {decorate, observable} from "mobx";

export default class CreateInvoiceStore {


    status
    allStatus = {
        'PAID':'solde',
        'TO_PAID': 'validé',
        'DRAFT': 'élaboration'
    };

    rootStore
    invoiceStore


    constructor(rootStore, invoiceStore) {
        this.rootStore = rootStore
        this.invoiceStore = invoiceStore;
        this.api = new Api();
    }

    async getInvoice(id, status) {
       this.rootStore.savedDatasStore.setLoading(true)
       let invoice =  await this.api.invoices.findInvoiceById(id, status);
       this.rootStore.savedDatasStore.setLoading(false)
        return invoice
    }

    async getMoney(id) {
        this.rootStore.savedDatasStore.setLoading(true)
        let money = await this.api.invoices.getMoney(id);
        this.rootStore.savedDatasStore.setLoading(false)
        return money;
    }

    async giveMoney(id, type, valeur) {
        this.rootStore.savedDatasStore.setLoading(true)
        let status = await this.api.invoices.giveMeMoney(id, type, valeur)

        if(status === "success") {
            this.rootStore.notificationStore.setNotification("Paiement de " + valeur + "€ en " + type + " enregistré !", "success");
        }
        this.invoiceStore.getDatas();
        this.rootStore.savedDatasStore.setLoading(false)
        return status;
    }
}

decorate(CreateInvoiceStore, {
    error: observable,
    user: observable,
});

