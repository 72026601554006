import React from "react";
import {Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";


const PriseEnCharge = (inject("rootStore")(observer(class PriseEnCharge extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Prises En Charge", "Gérer les Prises En Charge", "tools.jpg", this.injected.rootStore.prisenchargeStore)
    }

    get injected() {
        return this.props;
    }


    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericTableAffichage api={this.injected.rootStore.prisenchargeStore}
                                           user={false}
                                           status={false}
                                           statusHeader={true}
                                           priseencharge={true}
                                           icon={<i className="fa fa-briefcase mr-2" aria-hidden="true"/>}
                                           name={"Prises en Charge"}
                                           type="pec"/>
                </Container>
            </>
        );
    }
})));

export default PriseEnCharge;
