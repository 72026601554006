/* Global import */
import {decorate, observable} from 'mobx';
import Api from "../util/api";
/* Custom import */

export default class AnnuaireGlobalCategory {


    treesCategories = observable([])
    treeCategory = 1;
    treesCategoriesId = observable([])
    selectionCategory;

    constructor(rootStore, annuaireStore) {
        this.rootStore = rootStore
        this.annuaireStore = annuaireStore;
        this.api = new Api();
    }



    async getCategoriesByTree() {
        let authResponse = await this.api.annuaireglobalcategories.getCategoriesByTree()

        if (authResponse?.status === "success") {
            this.treesCategories = authResponse.datas
            this.treesCategoriesId = authResponse.data.flat()
        }
    }


    async createCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.annuaireglobalcategories.createCategory(myData);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Categorie " + authResponse.data.id + " sauvegardée", "success");
                this.setCategories(authResponse.datas)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.annuaireglobalcategories.updateCategory(myData);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Categorie #" + authResponse.data.id + " modifiée", "success");
                this.setCategories(authResponse.datas)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteCategory(myData) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.annuaireglobalcategories.deleteCategory(myData);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Categorie" + myData.id + " supprimée", "success");
                this.setCategories(authResponse.datas)
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    setCategories(annuaireglobalcategories) {
        this.treesCategories.replace(annuaireglobalcategories)
    }

}

decorate(AnnuaireGlobalCategory, {
    treesCategories: observable,
    treesCategoriesId: observable,
    treeCategory: observable,
    selectionCategory: observable
});