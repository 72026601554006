import {action, decorate, observable} from "mobx";
/* Custom import */
import Api from '../util/api'

export default class BlogStore {
    blogs = observable([{}]);
    currentPage = 0;
    lastPage = 1;
    limit = 15;

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }


    async getBlogs() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.blogs.getBlogs(this.limit, this.currentPage);

            if (this.blogs.length > 0) {
                this.setBlogs(authResponse.blogs)
            } else {
                this.blogs = authResponse.blogs;
            }


            this.currentPage = authResponse.currentPage;
            this.lastPage = authResponse.lastPage;


        } catch (e) {
        }

        this.rootStore.savedDatasStore.setLoading(false)

    }


    async createBlog(data, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.blogs.createBlog(data);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ??"Blog # " + authResponse.datas.id + " sauvegardé", "success");
            }


            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async updateBlog(data, message, id) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.blogs.update(data, id);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Blog # " + authResponse.datas.id + " modifié", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async findBlogById(id) {
        this.rootStore.savedDatasStore.setLoading(true)
        let myBlog;
        try {
            myBlog = await this.api.blogs.findBlogById(id);
        } catch (e) {
        }
        this.rootStore.savedDatasStore.setLoading(false)
        return myBlog;
    }

    async delete(id) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            let authResponse = await this.api.blogs.delete(id);


            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Blog # " + id + " supprimé", "success");
                this.getBlogs(this.currentPage)
            }

            this.rootStore.savedDatasStore.setLoading(false)
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    setBlogs(datas) {
        this.blogs.replace(datas)
    }
}

decorate(BlogStore, {
    getBlogs: action,
    blogs: observable,
    lastPage: observable,
    currentPage: observable,
    createBlog: action,
    findBlogById: action,
    status: observable,
    limit: observable
});