/* Global import */
import {decorate, observable} from 'mobx';
import Api from "../util/api";

export default class BoutiqueStore {

    stocks = observable([]);

    currentPage = 0;
    lastPage = 1;
    search = ""
    selectionProduct;
    selectionCategory;
    limit = 25
    mode = "ADD"

    constructor(rootStore, stockStore) {
        this.rootStore = rootStore
        this.stockStore = stockStore;
        this.api = new Api();
    }


    setSelection(item) {
        this.selectionProduct = item
    }
}


decorate(BoutiqueStore, {
    stocks: observable,
    selectionProduct: observable,
    selectionCategory: observable,
});