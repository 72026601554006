import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";
import styles from "../Annuaire/Prestation/Prestation.module.css";
import i18n from "i18next";
import PlaceAutoComplete from "../../../components/Akitatek/PlaceAutoCompletes";
import React from "react";
import {inject, observer} from "mobx-react";

const CustomerAdresse = inject("rootStore")(observer(class CustomerAdresse extends React.Component {

    get injected() {
        return this.props;
    }

    async onUpdateCustomer(e) {
        e.preventDefault();

        if (this.state.userId) {
            let authResponse = await this.injected.rootStore.userStore.updateUserProfile(this.state);

            if (authResponse?.status === "success") {
                this.setUser(authResponse.user)
            }
        }
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            id:   this.injected.adresse?.id,
            name: this.injected.adresse?.nom,
            firstname: this.injected.adresse?.prenom,
            displayPrenom: this.injected.displayPrenom,
            userId: this.injected.userId,
            typeAccount: this.injected.typeAccount,
            entreprise: this.injected.adresse?.entreprise,
            address: this.injected.adresse?.adresse_rue,
            address2: this.injected.adresse?.adresse_ruesuite,
            phone: this.injected.adresse?.telephone_portable,
            phoneHome: this.injected.adresse?.telephone_fixe,
            postalCode: this.injected.adresse?.codepostal,
            city: this.injected.adresse?.ville,
        };

        this.onUpdateCustomer.bind(this);
    }


    setUser(adresse, typeAccount) {
        if (adresse) {
            this.setState({
                name: adresse?.nom,
                firstname: adresse?.prenom,
                typeAccount: typeAccount,
                entreprise: adresse?.entreprise,
                address: adresse?.adresse_rue,
                address2: adresse?.adresse_ruesuite,
                phone: adresse?.telephone_portable,
                phoneHome: adresse?.telephone_fixe,
                postalCode: adresse?.codepostal,
                city: adresse?.ville,
            });
        }
    }

    handleChange = (address, city, postcode) => {
        this.setState({
            address: address,
            city: city ?? this.state.city, postalCode: postcode ?? this.state.postalCode
        });
    };

    setForm() {
        return (
            <Form style={{width: "100%"}}
                  role="form"
                  id="myFormUpdate"
            >
                <div className="pl-lg-4">
                    <h4> Adresse de facturation principale </h4>
                    <Row>
                        {!this.state.displayPrenom && (
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-first-name"
                                    >
                                        Nom de l'entreprise
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-first-name"
                                        type="text"
                                        value={this.state.entreprise || ''}
                                        onChange={e => this.setState({entreprise: e.target.value})}
                                        required
                                    />
                                </FormGroup></Col>)}
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-last-name"
                                >
                                    {i18n.t('page.admin.customers.name')}
                                </label>
                                <Input
                                    autocomplete="chrome-off"
                                    className="form-control-alternative"
                                    id="input-last-name"
                                    type="text"
                                    value={this.state.name || ''}
                                    onChange={e => this.setState({name: e.target.value})}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-first-name"
                                >
                                    {i18n.t('page.admin.customers.firstname')}
                                </label>
                                <Input
                                    autocomplete="chrome-off"
                                    className="form-control-alternative"
                                    id="input-first-name"
                                    type="text"
                                    value={this.state.firstname || ''}
                                    onChange={e => this.setState({firstname: e.target.value})}
                                />
                            </FormGroup></Col>
                    </Row>
                </div>
                {/* Address */}
                <div className="pl-lg-4">
                    <Row>
                        <Col md="6">
                            <PlaceAutoComplete adresse={this.state.address} handleChange={this.handleChange}/>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-address2"
                                >
                                    {i18n.t('page.admin.customers.address2')}
                                </label>
                                <Input
                                    autocomplete="chrome-off"
                                    className="form-control-alternative"
                                    id="input-address2"
                                    type="text"
                                    value={this.state.address2 || ''}
                                    onChange={e => this.setState({address2: e.target.value})}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-city"
                                >
                                    {i18n.t('page.admin.customers.city')}

                                </label>
                                <Input
                                    autocomplete="chrome-off"
                                    className="form-control-alternative"
                                    id="input-city"
                                    type="text"
                                    value={this.state.city || ''}
                                    onChange={e => this.setState({city: e.target.value})}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-country"
                                >
                                    {i18n.t('page.admin.customers.postal')}
                                </label>
                                <Input
                                    autocomplete="chrome-off"
                                    className="form-control-alternative"
                                    id="input-postal-code"
                                    type="number"
                                    value={this.state.postalCode || ''}
                                    onChange={e => this.setState({postalCode: e.target.value})}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-phone"
                                >
                                    {i18n.t('page.admin.customers.phone')}
                                </label>

                                <Input
                                    type={"tel"}
                                    autocomplete="chrome-off"
                                    value={this.state.phone}
                                    onChange={phone => this.setState({phone: phone.target.value})}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label
                                    className={styles.typeField}
                                    htmlFor="input-phone-home"
                                >
                                    {i18n.t('page.admin.customers.phoneHome')}
                                </label>


                                <Input
                                    type={"text"}
                                    autocomplete="chrome-off"
                                    value={this.state.phoneHome}
                                    onChange={phoneHome => this.setState({phoneHome: phoneHome.target.value})}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                </div>

                <div className="pl-lg-4" style={{textAlign: "center"}}>
                    <Row>
                        <Col>
                            <Button color="success" onClick={e => this.onUpdateCustomer(e)} type="submit">
                                {i18n.t('button.update')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        )
    }

    render() {
        return this.setForm()
    }
}))

export default CustomerAdresse