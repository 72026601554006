import React from 'react';
import {inject, observer} from "mobx-react";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import {withRouter} from "react-router-dom";
import SetCategories from "../Categories";

const Cat = (inject("rootStore")(observer(class Cat extends React.Component {
    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
    }


    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Card>
                        <CardHeader>
                            <h3 className="mb-0"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    color: "#20a8d8"
                                }}>
                                Catégories pour tous les produits
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <SetCategories
                                api={this.injected.rootStore.annuaireGlobalStore}
                            />
                        </CardBody>
                    </Card>
                </Container>


            </>
        );
    }
})));

export default withRouter(Cat);
