import React from "react";
import {Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import Category from "./Category";

const Casier = (inject("rootStore")(observer(class Casier extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.injected.rootStore.stockageCasierStore.getStockageCasier()
        this.injected.rootStore.stockageCasierStore.findStockage(this.injected.rootStore.stockageCasierStore.stockage?.id)

    }

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Category onSelection={this.injected.onSelection} onCallback={this.injected.onCallback}></Category>
                </Container>
            </>
        );
    }
})));

export default Casier;
