import React from "react";
import {inject, observer} from "mobx-react";
import {Card, CardBody, CardHeader} from "reactstrap";
import Form from "reactstrap/lib/Form";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import moment from "moment";

const SuiviCommande = inject("rootStore") (observer( class SuiviCommande extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            commande: this.injected.commande
        }
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.commande !== prevProps.commande
        ) {
            this.setState({
                commande: this.injected.commande
            })
        }
    }



    render() {
        return (
            <div className="bg-transparent mt-1">

                <Card style={{minWidth: "20em"}}>
                    <CardHeader  style={{display: "flex", justifyContent: "center", alignItems:"center", color: "#20a8d8"}}>
                        <h5> Suivi </h5>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Label for="status">Status</Label>
                            <FormGroup>

                                    <Input
                                    className={this.injected.rootStore.savedDatasStore.setColor(this.state.commande?.status)}
                                    type="select" name="status"
                                       value={this.state.commande.status}
                                        disabled={this.state.commande.status === "VALID"}
                                       onChange={ async e => {
                                           let commande = this.state.commande;
                                           commande.status = e.target.value
                                           this.injected.callbackRefresh()
                                           await this.setState({commande: commande})
                                       }}>
                                    <option className={this.injected.rootStore.savedDatasStore.setColor("DRAFT")}
                                            value={"DRAFT"}> Brouillon </option>
                                    <option
                                        className={this.injected.rootStore.savedDatasStore.setColor("ORDERED")}
                                        value={"ORDERED"}> Commandé </option>
                                    <option
                                        className={this.injected.rootStore.savedDatasStore.setColor("SEND")}
                                        value={"SEND"}> Expédié </option>
                                    <option
                                        className={this.injected.rootStore.savedDatasStore.setColor("RECEPTION")}
                                        value={"RECEPTION"}> Réceptionné </option>
                                    <option
                                        className={this.injected.rootStore.savedDatasStore.setColor("VALID")}
                                        value={"VALID"}> Validé </option>

                                </Input>
                            </FormGroup>

                            {(this.state.commande.status === "SEND" || this.state.commande.status === "RECEPTION" || this.state.commande.status === "VALID") && (
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="refSuivi">Numéro de Suivi</Label>
                                            <Input type="text"  name="refSuivi"
                                                   value={ this.state.commande?.suivi?.ref ||'' }
                                                   onChange={  e => {
                                                       let commande = this.state.commande;
                                                       if(commande.suivi === null  || commande.suivi === undefined) {
                                                           commande.suivi = {
                                                               commande_id: this.state.commande?.id,
                                                               ref: e.target.value,
                                                               date_envoi:  moment().format("YYYY-MM-DD")
                                                           }
                                                       } else {
                                                           commande.suivi.ref = e.target.value
                                                       }

                                                       this.setState({commande: commande})
                                                   }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="dateSend">Date de d'envoi</Label>
                                            <Input
                                                type="date"
                                                name="dateSend"
                                                value={ moment(this.state.commande?.suivi?.date_envoi).format("YYYY-MM-DD") ||moment().format("YYYY-MM-DD") }
                                                onChange={  e => {
                                                    let commande = this.state.commande;

                                                    if(commande.suivi === null || commande.suivi === undefined) {
                                                        commande.suivi = {
                                                            commande_id: this.state.commande?.id,
                                                            ref: "",
                                                            date_envoi:  e.target.value}
                                                    } else {
                                                        commande.suivi.date_envoi = e.target.value
                                                    }

                                                    this.setState({commande: commande})
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>)}


                            {(this.state.commande.status === "RECEPTION" || this.state.commande.status === "VALID") && (

                                <FormGroup>
                                    <Label for="dateReception">Date de réception</Label>
                                    <Input
                                        type="date"
                                        name="dateReception"
                                        value={ this.state.commande?.suivi?.date_reception  ?
                                            moment(this.state.commande?.suivi?.date_reception).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD") }
                                        onChange={  e => {

                                            let commande = this.state.commande;

                                            if(commande.suivi === null  || commande.suivi === undefined) {
                                                commande.suivi = {
                                                    date_reception:  moment().format("YYYY-MM-DD"),
                                                    commande_id: this.state.commande?.id,
                                                    ref: "",
                                                    date_envoi: e.target.value}
                                            } else {
                                                commande.suivi.date_reception = e.target.value
                                            }

                                            this.setState({commande: commande})
                                        }}
                                    />
                                </FormGroup>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            </div>

        )
    }
}));

export default SuiviCommande;
