import React from "react";
import PhoneRow from "./Table/PhoneRow";
import {Link} from "react-router-dom";
import getRoutes from "./getRoutes";
import CardHeader from "reactstrap/lib/CardHeader";
import Card from "reactstrap/lib/Card";
import {CardBody} from "reactstrap";

function UserInformation({user, adresse, adresseLivraison, hasAdresse = true}) {


    function dataAdresse(adresse, title) {
        if (adresse) {
            return (<div style={{fontStyle: "italic", flex: 1}}>
                <div style={{fontWeight: "bold"}}>{title}</div>
                <div> {adresse.fullname} </div>
                <div> {adresse.adresse_rue} </div>
                <div> {adresse.adresse_ruesuite} </div>
                <div> {adresse.codepostal} {adresse.ville}  </div>
                <p><PhoneRow title={adresse.telephone ?? adresse.telephone_portable}/></p>
            </div>)
        } else {
            return (<div style={{fontStyle: "italic", flex: 1}}>
                <div style={{fontWeight: "bold"}}>{title}</div>
                <div> {user?.profile?.fullname}</div>
                <div> {user?.profile?.adresse_rue} </div>
                <div> {user?.profile?.adresse_ruesuite} </div>
                <div> {user?.profile?.codepostal} {user?.profile?.ville}  </div>
                <p><PhoneRow title={user?.profile?.telephone_portable}/></p>
                <p><PhoneRow title={user?.profile?.telephone_fixe}/></p>
            </div>)
        }
    }


    return (
        <Card>
            <CardHeader><h4><Link to={{pathname: getRoutes("Modifier Client", user?.id)}}>
                {user?.fullname}
            </Link></h4></CardHeader>
            <div className={"mt-2"}> {user?.email} </div>
            <CardBody>
                <div style={{display: "flex", justifyContent: "space-around"}}>
                    {dataAdresse(adresse ?? user?.facturation, "Adresse de facturation")}
                    <br/>
                    {hasAdresse && dataAdresse(adresseLivraison ?? user?.expedition, "Adresse de livraison")}
                </div>
            </CardBody>
        </Card>);
}

export default UserInformation;
