import {inject, observer} from "mobx-react";
import React from "react";
import {Container, Tab} from "react-bootstrap";
import {CardBody, CardHeader} from "reactstrap";
import Card from "react-bootstrap/Card";
import Button from "reactstrap/lib/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Input from "@material-ui/core/Input";
import Row from "react-bootstrap/Row";
import ModalAkitatek from "../../../components/Akitatek/Modal/Modal";
import AddCustomer from "../Customers/AddCustomer";
import AnnuaireCategory from "../Annuaire/Category/Category";
import CasierContent from "./CasierContent";

const Category = (inject("rootStore")(observer(class Category extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            looking: this.injected.looking ?? true,
            update: this.injected.update ?? true,
            selectionCategory: null,
            selectionCategoryName: "",
            selectionCategoryForEditable: null,
            selectionCategoryForDelete: null,
            selectionCategoryNameForDelete: "",
            selectionCategoryForReset: null,
            selectionCategoryForUpdate: null,
            onAdd: false,
            expanded: this.injected.expanded,
            defaultExpanded: this.injected.defaultExpanded,
            onSelection: this.injected.onSelection ?? true,
            onAddCategory: false
        }
    }


    async componentDidUpdate(prevProps) {
        if (
            this.injected.update !== prevProps.update ||
            this.injected.looking !== prevProps.looking ||
            this.injected.expanded !== prevProps.expanded ||
            this.injected.defaultExpanded !== prevProps.defaultExpanded
        ) {
            await this.setState({
                update: this.injected.update,
                defaultExpanded: this.injected.defaultExpanded,
                expanded: this.injected.expanded,
            })
        }
    }


    onClear(event) {
        event.preventDefault()
        this.setState({
            selectionCategory: null,
            selectionCategoryName: "",
            selectionCategoryForEditable: null,
            selectionCategoryForDelete: null,
            selectionCategoryNameForDelete: "",
            selectionCategoryForReset: null,
            selectionCategoryForUpdate: null,
            onAdd: false
        })

        var elems = document.querySelectorAll(".my-li.active");
        [].forEach.call(elems, function (el) {
            el.classList.remove("active");
        });
        this.injected.rootStore.stockageCasierStore.stockage = ""
    }

    getSelection = (childData, nameData) => {
        this.setState({
            selectionCategory: childData,
            selectionCategoryName: nameData
        })

        this.injected.rootStore.stockageCasierStore.treeCategory = childData
    };

    getSuccessEditableCallback = (childData) => {
        this.setState({
            selectionCategoryForEditable: childData
        })
    };

    getSuccessUpdateCallback = (childData) => {
        this.setState({
            selectionCategoryForUpdate: childData
        })
    };


    onAdd(event) {
        event.preventDefault()

        if (this.state.selectionCategory) {
            this.setState({
                    selectionCategoryForEditable: this.state.selectionCategory
                }
            )
        } else {
            this.setState({
                    onAdd: !this.state.onAdd
                }
            )
        }

    }

    onUpdate(event) {
        event.preventDefault()

        this.setState({
                selectionCategoryForUpdate: this.state.selectionCategory,
                newValueForUpdate: this.state.selectionCategory.name
            }
        )
    }

    toggleAddCasier(event, category) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setQuantity("Entrer un nom pour votre casier", "Ajouter", category ? category[0].stockage_row_id : "", this.state.selectionCategory?.id)
    }


    toggleDelete(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning(this.state.selectionCategoryName, event => this.onDelete(this.state.selectionCategory))
    }

    async onDelete(deleteId) {
        let authResponse = await this.injected.rootStore.stockageCasierStore.deleteStockage(deleteId.id)

        if (authResponse?.status === "success") {
            this.setState({
                onAdd: false
            })

            this.injected.rootStore.stockageCasierStore.stockage = ""
        }
    }


    toggleDeleteCasier(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning(this.state.selectCasier.name, event => this.onDeleteCasier(this.state.selectCasier))
    }

    async onDeleteCasier(deleteId) {
        let authResponse = await this.injected.rootStore.stockageCasierStore.deleteCategory(deleteId.id)

        if (authResponse?.status === "success") {
            this.setState({
                selectionCategoryForUpdate: '',
                newValueForUpdate: ''
            })

        }
    }


    async updateEnter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            let data = {
                id: this.state.selectionCategoryForUpdate?.id,
                name: this.state.newValueForUpdate
            }
            let authResponse = await this.injected.rootStore.stockageCasierStore.updateStockage(data)

            if (authResponse?.status === "success") {
                this.setState({
                    selectionCategoryForUpdate: '',
                    newValueForUpdate: ''
                })
            }
        }

    }

    async sendCategory(e) {
        e.preventDefault()
        let data = {
            name: this.state.newValueForEditable
        }
        let authResponse = await this.injected.rootStore.stockageCasierStore.createStockage(data)

        if (authResponse?.status === "success") {
            this.setState({
                onAdd: !this.state.onAdd
            })
        }
    }

    async enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            let data = {
                name: this.state.newValueForEditable
            }
            let authResponse = await this.injected.rootStore.stockageCasierStore.createStockage(data)

            if (authResponse?.status === "success") {
                this.setState({
                    onAdd: !this.state.onAdd
                })
            }
        }
    }

    async setCasier(e, stockage) {
        e.preventDefault()
        this.setState({
            selectionCategory: stockage
        })
        this.injected.rootStore.stockageCasierStore.stockage = stockage;
        await this.injected.rootStore.stockageCasierStore.findStockage(stockage.id)
    }

    onAddStockage(event) {
        event.preventDefault()

        if (this.state.selectionCategory) {
            this.setState({
                    selectionCategoryForEditable: this.state.selectionCategory
                }
            )
        } else {
            this.setState({
                    onAdd: !this.state.onAdd
                }
            )
        }

    }


    setCard() {
        return (<div>
                {this.state.update && this.injected.rootStore.stockageCasierStore.stockage && (
                    <CardHeader className="bg-transparent" style={{display: "flex", color: "#20a8d8"}}>
                        <Button className="mr-2" color="warning"
                                onClick={event => this.onUpdate(event)}>Renommer le stockage</Button>
                        <Button className="mr-2" color="danger"
                                onClick={event => this.setState(this.toggleDelete(event))}>Supprimer le
                            stockage</Button>
                        <Button className="mr-2" color="primary" onClick={event => this.onClear(event)}>Clear</Button>
                        {this.injected.rootStore.authStore.modules['print'] === 1 && (
                            <Button className="mr-2" color="info"
                                    onClick={e => this.injected.rootStore.annuaireStore.annuaireCategoryStore.createPdfStockage(e,this.injected.rootStore.stockageCasierStore.stockage.id )}>Imprimer tous les casiers</Button>
                        )}
                    </CardHeader>)}


                {this.state.update && !this.injected.rootStore.stockageCasierStore.stockage && (
                    <CardHeader className="bg-transparent" style={{display: "flex", color: "#20a8d8"}}>
                        <Button className="mr-2" color="success"
                                onClick={event => this.onAddStockage(event)}>Ajouter</Button>
                        <Button className="mr-2" color="primary" onClick={event => this.onClear(event)}>Clear</Button>
                    </CardHeader>)}
                {this.injected.rootStore.stockageCasierStore.stockage && (
                    <CardHeader className="bg-transparent" style={{display: "flex", color: "#20a8d8"}}>
                        {this.injected.rootStore.stockageCasierStore.stockage?.name}
                    </CardHeader>)}
                <CardBody>


                    <Col className="mr-4">
                        <Tab.Container id="list-group-tabs-example">
                            <ListGroup as="ul" style={{flexDirection: "row", flexWrap: "wrap"}}>
                                {this.injected.rootStore.stockageCasierStore.stockages.map((stockage) => {
                                    return (<ListGroup.Item
                                        className={"my-li"}
                                        action as="li" href={"link" + stockage.id}
                                        onClick={e => this.setCasier(e, stockage)}
                                        style={{
                                            cursor: "pointer",
                                            flex: "0 1 25%"
                                        }}>
                                        {this.state.selectionCategoryForUpdate?.id === stockage.id ? (
                                            <Input
                                                onChange={(e) => this.setState({newValueForUpdate: e.target.value})}
                                                onKeyPress={(e) => {
                                                    this.updateEnter(e)
                                                }}
                                                value={this.state.newValueForUpdate}
                                            />
                                        ) : (<div>{stockage.name}</div>)}
                                    </ListGroup.Item>)
                                })}
                                {this.state.onAdd && (
                                    <div>
                                        <ListGroup.Item action as="li"
                                                        style={{cursor: "pointer", flex: "0 1 25%"}}>
                                            <Input
                                                onChange={(e) => this.setState({newValueForEditable: e.target.value})}
                                                onKeyPress={(e) => {
                                                    this.enter(e)
                                                }}
                                                value={this.state.newValueForEditable}
                                            />
                                            <Button className={"btn btn-success"}
                                                    onClick={e => this.sendCategory(e)}
                                                    type="button">
                                                <i className="fa fa-plus" aria-hidden="true"/>
                                            </Button>
                                        </ListGroup.Item>

                                    </div>
                                )}
                            </ListGroup>
                        </Tab.Container>
                    </Col>
                </CardBody>
                <ModalAkitatek show={this.state.showCasierContent}
                               title={this.state.dataCasier?.name}
                               children={<CasierContent data={this.state.dataCasier} />}
                               onCallback={e => this.setState({
                                   showCasierContent: false,
                                   dataCasier: ""
                               })}/>
            </div>
        )
    }

    onUpdateCasier(event) {
        event.preventDefault()

        this.setState({
            onAddCategory: true,
            selectionCasierForUpdate: this.state.selectCasier,
            newCasierForUpdate: this.state.selectCasier.name
        })
    }

    onAddCasier(event) {
        event.preventDefault()

        this.setState({
            onAddCategory: true
        })
    }

    selectCasier(e, casier) {
        this.injected.rootStore.stockageCasierStore.casier = casier
        this.setState({
            selectCasier: casier
        })
    }

    async updateCasier(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            let data = {
                name: this.state.newCasierForUpdate,
                id: this.state.selectionCasierForUpdate.id
            }
            let authResponse = await this.injected.rootStore.stockageCasierStore.updateCategory(data)

            if (authResponse?.status === "success") {
                this.setState({
                    newCasierForUpdate: "",
                    selectionCasierForUpdate: "",

                })
            }
        }
    }

    onClearCasier(e, casier) {
        this.setState({
            selectCasier: "",
            newCasierForUpdate: "",
            selectionCasierForUpdate: "",
        })

        var elems = document.querySelectorAll(".my-li-casier.active");
        [].forEach.call(elems, function (el) {
            el.classList.remove("active");
        });
    }

    render() {
        return (
            <>
                {this.state.update ? (
                    <Container fluid>
                        <Card>
                            {this.setCard()}</Card>

                        {this.injected.rootStore.stockageCasierStore.stockage && (
                            <Card>
                                {this.state.update && (
                                    <CardHeader className="bg-transparent" style={{display: "flex", color: "#20a8d8"}}>
                                        <Button className="mr-2" color="success"
                                                onClick={event => this.onAddCasier(event)}>Ajouter</Button>

                                        {this.state.selectCasier && (
                                            <div>
                                                <Button className="mr-2" color="warning"
                                                        onClick={event => this.onUpdateCasier(event)}>Renommer</Button>
                                                <Button className="mr-2" color="danger"
                                                        onClick={event => this.setState(this.toggleDeleteCasier(event))}>Supprimer</Button>
                                                <Button className="mr-2" color="info"
                                                        onClick={e => this.injected.rootStore.annuaireStore.annuaireCategoryStore.openPdf(e, this.state.selectCasier?.id)}>Visualiser le PDF</Button>
                                                {this.injected.rootStore.authStore.modules['print'] === 1 && (
                                                    <Button className="mr-2" color="info"
                                                        onClick={e => this.injected.rootStore.annuaireStore.annuaireCategoryStore.createPdf(e, this.state.selectCasier?.id)}>Imprimer</Button>
                                                )}
                                                <Button className="mr-2" color="primary"
                                                        onClick={event => this.onClearCasier(event)}>Clear la selection
                                                    du casier</Button>
                                            </div>

                                        )}

                                    </CardHeader>)}

                                {this.injected.rootStore.stockageCasierStore.casier && (
                                    <CardHeader className="bg-transparent" style={{display: "flex", color: "#20a8d8"}}>
                                        {this.injected.rootStore.stockageCasierStore.casier?.name}
                                    </CardHeader>)}
                                <div className={"m-4"}>
                                    <ListGroup as="ul">
                                        {this.injected.rootStore.stockageCasierStore.treesCategories?.map((category) => {
                                                return (
                                                    <Row style={{flexDirection: "row", flexWrap: "nowrap"}}>
                                                        {category.map((col) => {
                                                            return (
                                                                <ListGroup.Item
                                                                    className={"my-li-casier"}
                                                                    action as="li" href={"link" + col.id}
                                                                    onClick={e => this.selectCasier(e, col)}
                                                                    onDoubleClick={e => this.setState({
                                                                        dataCasier: col,
                                                                        showCasierContent: true
                                                                    })}
                                                                    style={{cursor: "pointer", textAlign: "center"}}>

                                                                    {this.state.selectionCasierForUpdate?.id === col.id ? (
                                                                            <Input
                                                                                onChange={(e) => this.setState({newCasierForUpdate: e.target.value})}
                                                                                onKeyPress={(e) => {
                                                                                    this.updateCasier(e)
                                                                                }}
                                                                                value={this.state.newCasierForUpdate}
                                                                            />
                                                                        ) :
                                                                        (<div>
                                                                            {col.name}

                                                                            {col.total > 0 ? (
                                                                                    <h5 style={{color: "red"}}>{col.total} </h5>) :
                                                                                (
                                                                                    <h5 style={{color: "green"}}> {col.total} </h5>)}

                                                                        </div>)}
                                                                </ListGroup.Item>
                                                            )
                                                        })}

                                                        <ListGroup.Item
                                                            style={{cursor: "pointer"}}>
                                                            <Button className={"btn btn-success"}
                                                                    onClick={e => this.toggleAddCasier(e, category)}
                                                                    type="button">
                                                                <i className="fa fa-plus" aria-hidden="true"/>
                                                            </Button>
                                                        </ListGroup.Item>
                                                    </Row>
                                                )
                                            }
                                        )}

                                        {this.state.onAddCategory && (
                                            <ListGroup.Item
                                                style={{cursor: "pointer"}}>
                                                <Button className={"btn btn-success"}
                                                        onClick={e => this.toggleAddCasier(e)} type="button">
                                                    <i className="fa fa-plus" aria-hidden="true"/>
                                                </Button>
                                            </ListGroup.Item>
                                        )} </ListGroup>
                                </div>
                            </Card>)}

                        {!this.state.onSelection && (
                            <Button color={"success"}
                                    onClick={e => this.injected.onCallback(this.state.selectCasier)}>Valider</Button>
                        )}
                    </Container>

                ) : (this.setCard())
                }
            </>
        );
    }

})));

export default Category;
