import axios from "axios";
import configuration from "../configuration/configuration";
import {default as i18n} from "i18next";
import encodeDataToURL from "../components/Akitatek/encodeDatatoURL";

export default class InvoiceAPI {


    async getInvoices(filter) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/invoices?` + encodeDataToURL(filter),
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getAllStatus() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/invoices/getAllStatus`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async findInvoiceById(id, status) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/show/invoices/` + id + `/` + status,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async copyInvoice(id, status) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/copy/invoices/${id}/${status}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve( response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async cancelInvoice(id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/cancel/invoices/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve( response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async replaceInvoice(id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/replace/invoices/${id}`,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve( response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getMoney(id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/invoices/encaisser/` + id,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    }

    async giveMeMoney(id, type, valeur) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/invoices/givememoney/` + id,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data : {
                        type,
                        valeur
                    }
                });

                resolve(response.data.status)
            } catch (error) {
                reject(error)
            }
        })
    }

    async sendPdf(mediaId) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${configuration.API}/api/invoices/sendPdfByMail/` + mediaId,
                    headers: {
                        'content-type': 'application/json'
                    }
                });

                resolve(response.data.status)
            } catch (error) {
                reject(error)
            }
        })
    }

    async getPdf(mediaId, status, print, type) {
        const response = await axios({
            method: 'get',
            url: `${configuration.API}/api/invoices/pdf/${mediaId}/${status}?print=${print}&name=${type}`,
            headers: {
                'content-type': '*/*'
            },
            responseType: 'blob'
        });

        const file = response.data;
        file.name =  response.data.filename;
        file.lastModified = new Date();

        return file;
    }



    async saveInvoice(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/invoices`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async archived(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/changeStatus/invoices`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async updateInvoice(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/invoices`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }


    async saveFactureLigne(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'post',
                    url: `${configuration.API}/api/factureLigne`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async updateFactureLigne(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'put',
                    url: `${configuration.API}/api/factureLigne`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async deleteFactureLigne(data) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/factureLigne`,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: data
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }

    async delete(id) {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await axios({
                    method: 'delete',
                    url: `${configuration.API}/api/invoices/` + id ,
                    headers: {
                        'content-type': 'application/json'
                    }
                });
                resolve(response.data)
            } catch (error) {
                reject(i18n.t('errors.login-fail'))
            }
        })
    }
}