import React from "react";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import TableGeneric from "../../../../components/Akitatek/Headers/TableGeneric";
import PageRow from "./PageRow";


const IndexPage = (inject("rootStore")(observer(class IndexPage extends React.Component {

     componentDidMount() {
         this.injected.rootStore.pageStore.getPages();
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des pages", "Ajouter / modifier une page", "devis.jpg")
    }

    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Card className="shadow">
                        <CardHeader
                            style={{display: "flex", justifyContent: "space-between"}}>
                            <h4 className="mb-0">
                                <i className="fa fa-plus-circle mr-2" aria-hidden="true"/>
                                Gérer les pages
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <TableGeneric key="card1"
                                          titles={["id", "Nom", "Menu", "Système", "Actions"]}>
                                {this.injected.rootStore.pageStore.pages
                                    .map(data => {
                                        return <PageRow data={data} key={data.id}/>
                                    })
                                }
                            </TableGeneric>
                        </CardBody>

                    </Card>
                </Container>
            </>
        );
    }
})));

export default IndexPage;
