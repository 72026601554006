import React from "react";
import {inject, observer} from "mobx-react";
import i18n from "i18next";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from "../Annuaire/Prestation/Prestation.module.css";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import htmlToDraft from 'html-to-draftjs';
import ChoiceImageInput from "../../../components/Akitatek/Form/ChoiceImageInput";
import EditorWord from "../../../components/Akitatek/EditorWord";

const AddBlog = inject("rootStore")(observer(class AddBlog extends React.Component {

    id;

    get injected() {
        return this.props;
    }


    async componentDidMount() {
        // do something async and call the callback:
        this.id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (this.id) {
            let blog = await this.injected.rootStore.blogStore.findBlogById(this.id);
            await this.onReload(blog)
        } else {
            await this.injected.rootStore.authStore.findAdmin();
            this.setState({
                authorId: this.injected.rootStore.authStore.user.id,
                author: this.injected.rootStore.authStore.username,
            });
        }
    }

    async onReload(blog) {
        let editorState = EditorState.createEmpty();
        if (blog?.text) {
            let blocksFromHTML = htmlToDraft(blog.text);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            editorState = EditorState.createWithContent(contentState);
        }

        this.setState({
            title: blog.title,
            text: editorState,
            id: blog.id,
            tags: blog.tags,
            description: blog.description,
            author: blog.author?.fullname,
            authorId: blog.author?.id,
            isVisible: blog.isVisible,
            assetName: blog?.asset?.filename,
            asset_id: blog?.asset?.id,
        });

    }

    constructor(props, context) {
        super(props, context);
        this.id = this.props.match && parseInt(this.props.match.params.id, 10);

        this.state = {
            title: '',
            id: this.id,
            authorId: "",
            author: "",
            description: '',
            tags: [],
            assetName: "",
            asset_id: "",
            text: EditorState.createEmpty(),
            isVisible: 0
        };

        this.onSubmitBlog.bind(this);
        this.injected.rootStore.savedDatasStore.setHeader("Blogs", "Gestion des blogs", "default.jpg")
        this.injected.rootStore.imageStore.getAllTags()
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            text:
            editorState,
        });
    };


    async onSubmitBlog(e) {
        e.preventDefault();
        if (this.state.title && this.state.text && this.state.authorId && this.state.description) {
            let authResponse;

            let data = {
                title: this.state.title,
                isVisible: this.state.isVisible,
                author_id: this.state.authorId,
                tags: this.state.tags,
                description: this.state.description,
                asset_id: this.state.asset_id,
                text: draftToHtml(convertToRaw(this.state.text.getCurrentContent()))
            };
            if (this.state.id) {
                authResponse = await this.injected.rootStore.blogStore.updateBlog(data, "Article modifié avec succès", this.state.id);
            } else {
                authResponse = await this.injected.rootStore.blogStore.createBlog(data, "Article ajouté avec succès");
            }

            if (authResponse?.status === "success") {
                await this.onReload(authResponse?.datas)
            }
        }
    }

    async handleInputChange(inputValue) {
        let tags = [];

        if (inputValue) {
            for (const element of inputValue) {
                if (element.__isNew__ === true) {
                    var tag = await this.injected.rootStore.imageStore.createTag({
                        name: element.label
                    })
                    if (tags.indexOf(element) === -1) tags.push(tag);
                } else {
                    if (tags.indexOf(element) === -1) tags.push({
                        nom: element.label,
                        id: element.value
                    });
                }
            }
        }

        this.setState({tags: tags});
    };

    onPickAssetFilename = (action) => {
        if (action === "add") {
            this.setState({
                assetName: this.injected.rootStore.imageStore.pickAsset?.filename,
                asset_id: this.injected.rootStore.imageStore.pickAsset?.id,
            })
        } else {
            this.setState({
                assetName: "",
                asset_id: "",
            })
        }
    }

    render() {
        return (
            <>

                <Container className="mt-2" fluid>
                    <Row className="mb-3">
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                    <h4 className="mb-0">{i18n.t('page.admin.blogs.add')}</h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Form style={{width: "100%"}}
                                              role="form"
                                              id="myForm"
                                              onSubmit={e => this.onSubmitBlog(e)}
                                        >
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className={styles.typeField}
                                                            htmlFor="input-first-title"
                                                        >
                                                            {i18n.t('page.admin.blogs.titleBlog')}
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-first-title"
                                                            type="text"
                                                            value={this.state.title || ''}
                                                            onChange={e => this.setState({title: e.target.value})}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className={styles.typeField}
                                                            htmlFor="input-last-author"
                                                        >
                                                            {i18n.t('page.admin.blogs.author')}
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-last-author"
                                                            type="text"
                                                            value={this.state.author || ''}
                                                            onChange={e => this.setState({author: e.target.value})}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <label
                                                            className={styles.typeField}
                                                            htmlFor="input-description"
                                                        >
                                                            {i18n.t('page.admin.blogs.description')}
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-description"
                                                            type="textarea"
                                                            value={this.state.description || ''}
                                                            onChange={e => {
                                                                this.setState({description: e.target.value});
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <ChoiceImageInput assetName={this.state.assetName}
                                                                  onPickAssetFilename={this.onPickAssetFilename}/>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <EditorWord editorState={this.state.text}
                                                                onEditorStateChange={this.onEditorStateChange}/>
                                                </Col>
                                            </Row>

                                            <hr className="my-4"/>

                                            {this.state.id ? (
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <label
                                                                className={styles.typeField}
                                                            >
                                                                Tags
                                                            </label>
                                                            <CreatableSelect
                                                                isClearable
                                                                isMulti
                                                                value={this.state.tags?.map(opt => ({
                                                                    label: opt.nom,
                                                                    value: opt.id
                                                                }))}
                                                                options={this.injected.rootStore.imageStore.tags.map(opt => ({
                                                                    label: opt.nom,
                                                                    value: opt.id
                                                                }))}
                                                                onChange={e => this.handleInputChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            ) : " "}

                                            <div className="pl-lg-4" style={{textAlign: "center"}}>

                                                <Row>
                                                    <Col style={{display: "flex", justifyContent: "center"}}>
                                                        <FormGroup className={"mr-2"}>
                                                            <label>
                                                                <input type="radio"
                                                                       checked={this.state.isVisible === 1}
                                                                       onClick={e => this.setState({isVisible: 1})}
                                                                />
                                                                <span
                                                                    className="badge badge-pill badge-success">PUBLIER</span>
                                                            </label>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <label>
                                                                <input type="radio"
                                                                       checked={this.state.isVisible === 0}
                                                                       onClick={e => this.setState({isVisible: 0})}
                                                                />
                                                                <span className="badge badge-pill badge-warning">NON PUBLIER</span>
                                                            </label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>


                                                <Row>
                                                    <Col>
                                                        <Button color="success" type="submit"
                                                                disabled={this.state.text === "" || this.state.description === "" || this.state.author === "" || this.state.title === ""
                                                                || this.state.authorId === ""}>

                                                            {
                                                                i18n.t('button.submit')
                                                            }

                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>


            </>
        );
    }
}));

export default AddBlog;
