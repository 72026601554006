import React from "react";
import {inject, observer} from "mobx-react";
import {Container,} from "reactstrap";
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SeePrestation from "./SeePrestation";
import {ContentState, EditorState} from "draft-js";
import AddOrEditPrestation from "./AddOrEditPrestation";
import htmlToDraft from 'html-to-draftjs';


const AddPrestation = inject("rootStore")(observer(class AddPrestation extends React.Component {


    get injected() {
        return this.props;
    }

    async componentDidMount() {
        this.injected.rootStore.annuaireStore.prestationStore.getOptionsPrestations()

        let id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (id) {
            let authResponse = await this.injected.rootStore.annuaireStore.prestationStore.findPrestationById(id);

            if (authResponse?.status === "success") {

                let editorState;
                if (authResponse.datas?.description != null) {
                    let blocksFromHTML = htmlToDraft(authResponse.datas?.description);
                    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

                    editorState = EditorState.createWithContent(contentState);
                } else {
                    editorState = EditorState.createEmpty()
                }

                this.setState({presta: authResponse.datas, editorState: editorState});
            }
        }
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            presta: this.injected.location.aboutProps?.data ?? {id: ""},
            editorState: ""
        };

        this.injected.rootStore.savedDatasStore.setHeader("Gestion des marques", "Gérer vos marques", "annuaire_marque.jpg")
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    {this.state.presta?.id !== "" && (
                        <SeePrestation presta={this.state.presta} editorState={this.state.editorState}/>)}
                    {this.state.presta?.id === "" && (<AddOrEditPrestation/>)}
                </Container>
            </>
        );
    }
}));

export default AddPrestation;
