import React from "react";
import {inject, observer} from "mobx-react";
import i18n from "i18next";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import 'react-phone-input-2/lib/style.css'
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import styles from "../Annuaire/Prestation/Prestation.module.css";
import PlaceAutoComplete from "../../../components/Akitatek/PlaceAutoCompletes";
import getRoutes from "../../../components/Akitatek/getRoutes";

const AddCustomer = inject("rootStore")(observer(class AddCustomer extends React.Component {


    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            name: '',
            firstname: '',
            email: '',
            address: '',
            address2: '',
            phone: '',
            phoneHome: '',
            postalCode: '',
            city: '',
            password: '',
            card: this.injected.card ?? true,
            typeAccount: this.injected.rootStore.authStore.modules['espace_client'] ? 'clientaccount' : 'clientnoaccount',
            displayPassword: !!this.injected.rootStore.authStore.modules['espace_client'],
            displayPrenom: true,

        };

        this.onSubmitCustomer.bind(this);

        if (this.injected.card) {
            this.injected.rootStore.savedDatasStore.setHeader("Clients", "Gestion des clients et des documents liés", "clients.jpg", this.injected.rootStore.userStore)
        }
    }

    async onSubmitCustomer(e) {
        e.preventDefault();
        let authResponse = await this.injected.rootStore.userStore.createUser(this.state);

        if (authResponse?.status === "success") {
            if (this.state.card) {
                this.injected.history.push(getRoutes("Clients"));
            } else {
                this.injected.onCallback(authResponse.datas)
            }
        }
    }

    handleChange = (address, city, postcode) => {
        this.setState({
            address: address,
            city: city ?? this.state.city, postalCode: postcode ?? this.state.postalCode
        });
    };


    addUser() {
        return (
            <Row>
                <Form style={{width: "100%"}}
                      role="form"
                      id="myForm"
                      onSubmit={e => this.onSubmitCustomer(e)}
                >
                    <div className="pl-lg-4">
                        <Row>
                            <Col style={{textAlign: "center"}}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Type de compte</FormLabel>
                                    <RadioGroup row aria-label="position" defaultValue={this.state.typeAccount}
                                                value={this.state.typeAccount}>

                                        {this.injected.rootStore.authStore.modules['espace_client'] && (
                                            <div>
                                                <FormControlLabel value="clientaccount" onClick={e => this.setState({
                                                    displayPassword: true,
                                                    displayPrenom: true,
                                                    typeAccount: "clientaccount"
                                                })} control={<Radio/>} label="Client"/>
                                                <FormControlLabel value="companyaccount" onClick={e => this.setState({
                                                    displayPassword: true,
                                                    displayPrenom: false,
                                                    typeAccount: "companyaccount",
                                                    firstname: ""
                                                })} control={<Radio/>} label="Entreprise"/>
                                            </div>
                                        )}
                                        <FormControlLabel value="clientnoaccount" onClick={e => this.setState({
                                            displayPassword: false,
                                            displayPrenom: true,
                                            typeAccount: "clientnoaccount",
                                            email: ""
                                        })} control={<Radio/>} label="Client sans Compte"/>
                                        <FormControlLabel value="companynoaccount" onClick={e => this.setState({
                                            displayPassword: false,
                                            displayPrenom: false,
                                            typeAccount: "companynoaccount",
                                            email: "",
                                            firstname: ""
                                        })} control={<Radio/>} label="Entreprise sans Compte"/>
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                        </Row>
                        {!this.state.displayPrenom && (
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className={styles.typeField}
                                            htmlFor="input-first-name"
                                        >
                                            Entreprise
                                        </label>
                                        <Input
                                            autocomplete="chrome-off"
                                            className="form-control-alternative"
                                            id="input-first-name"
                                            type="text"
                                            value={this.state.entreprise || ''}
                                            required
                                            onChange={e => this.setState({entreprise: e.target.value})}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-last-name"
                                    >
                                        {i18n.t('page.admin.customers.name')}
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-last-name"
                                        type="text"
                                        value={this.state.name || ''}
                                        onChange={e => this.setState({name: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-first-name"
                                    >
                                        {i18n.t('page.admin.customers.firstname')}
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-first-name"
                                        type="text"
                                        value={this.state.firstname || ''}
                                        onChange={e => this.setState({firstname: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.state.displayPassword && (
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label
                                            className={styles.typeField}
                                            htmlFor="input-email"
                                        >
                                            Email
                                        </label>
                                        <Input
                                            autocomplete="chrome-off"
                                            className="form-control-alternative"
                                            id="input-email"
                                            type="email"
                                            value={this.state.email || ''}
                                            onChange={e => this.setState({email: e.target.value})}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>)}
                    </div>
                    {/* Address */}
                    <div className="pl-lg-4">
                        <Row>
                            <Col md="6">
                                <PlaceAutoComplete adresse={this.state.address} handleChange={this.handleChange}/>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-address2"
                                    >
                                        {i18n.t('page.admin.customers.address2')}
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-address2"
                                        type="text"
                                        value={this.state.address2 || ''}
                                        onChange={e => this.setState({address2: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-city"
                                    >
                                        {i18n.t('page.admin.customers.city')}

                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-city"
                                        type="text"
                                        value={this.state.city || ''}
                                        onChange={e => this.setState({city: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-country"
                                    >
                                        {i18n.t('page.admin.customers.postal')}
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        className="form-control-alternative"
                                        id="input-postal-code"
                                        type="number"
                                        value={this.state.postalCode || ''}
                                        onChange={e => this.setState({postalCode: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-phone"
                                    >
                                        {i18n.t('page.admin.customers.phone')}
                                    </label>
                                    <Input
                                        type={"tel"}
                                        autocomplete="chrome-off"
                                        value={this.state.phone}
                                        onChange={phone => this.setState({phone: phone.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                        htmlFor="input-phone-home"
                                    >
                                        {i18n.t('page.admin.customers.phoneHome')}
                                    </label>
                                    <Input
                                        autocomplete="chrome-off"
                                        type={"tel"}
                                        value={this.state.phoneHome}
                                        onChange={phoneHome => this.setState({phoneHome: phoneHome.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    {this.state.displayPassword && (
                        <div className="pl-lg-4">
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label
                                            className={styles.typeField}
                                            htmlFor="input-password"
                                        >
                                            {i18n.t('page.auth.password')}

                                        </label>
                                        <Input
                                            autocomplete="chrome-off"
                                            className="form-control-alternative"
                                            id="input-password"
                                            type="text"
                                            default={'123456'}
                                            value={this.state.password || ''}
                                            onChange={e => this.setState({password: e.target.value})}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>)}
                    <div className="pl-lg-4" style={{textAlign: "center"}}>
                        <Row>
                            <Col>
                                <Button color="success" type="submit">
                                    {i18n.t('button.submit')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Row>)
    }

    render() {
        return (
            <>

                {this.state.card && (
                    <div>
                        <Container className="mt-2" fluid>
                            <Row className="mb-3">
                                <div className="col">
                                    <Card className="shadow">
                                        <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                            <h4 className="mb-0">{i18n.t('page.admin.customers.add')}</h4>
                                        </CardHeader>
                                        <CardBody>
                                            {this.addUser()}
                                        </CardBody>
                                    </Card>
                                </div>
                            </Row>
                        </Container></div>)}

                {!this.state.card && (
                    <Container className="mt-2">
                        <Row className="mb-3">
                            <div className="col">
                                {this.addUser()}
                            </div>
                        </Row>
                    </Container>
                )}


            </>
        );
    }
}));

export default AddCustomer;
