

import {action, decorate, observable} from "mobx";
import Api from "../util/api";

export default class UserNotificationStore {

    search = ""
    allNotifications = observable([]);
    notifications = observable([]);
    currentPage = 0;
    lastPage = 1;
    status = ""
    limit = 1000
    isLoading= false;

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    async getNotifications() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.notifications.getNotifications(this.limit, this.currentPage, "is_read", this.search);
            if (this.notifications.length > 0) {
                this.notifications.replace(authResponse.data)
            } else {
                this.notifications = authResponse.data;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

        this.rootStore.savedDatasStore.setLoading(false)
    }

    async getAllNotifications() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.notifications.getAllNotifications();
            if (this.allNotifications.length > 0) {
                this.allNotifications.replace(authResponse)
            } else {
                this.allNotifications = authResponse;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

        this.rootStore.savedDatasStore.setLoading(false)
    }



    async saveNotification(data, isAll = false) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.notifications.saveNotification(data);

            if(authResponse.status === "success") {
                    this.getAllNotifications()
                    this.getNotifications()

            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch(e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
}

decorate(UserNotificationStore, {
    notifications: observable,
    allNotifications: observable,
    getAllNotifications: observable,
    getNotifications: action,
    saveNotification: action,
    currentPage: observable,
    lastPage: observable,
    status: observable,
    limit:observable,
    isLoading: observable
});

