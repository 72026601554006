import React from 'react'
import Select from 'react-select'
import CardHeader from "reactstrap/lib/CardHeader";
import {inject, observer} from "mobx-react";
import ErrorBoundary from "../Error/ErrorBounday";

const SelectStatus = inject("rootStore")(observer(class SelectStatus extends React.Component {


    async componentDidMount() {
        await this.injected.api.getAllStatus();
    }


    get injected() {
        return this.props;
    }

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this)
    }

    onChange(value, { action, removedValue }) {
        let datas = [];
        switch (action) {

            case 'clear':
                value = []
                break;
        }


        if(value) {
            value.forEach(element => datas.push(element.value));
        }

        this.injected.api.filter.page = "1";
        this.injected.api.filter.status = datas;
        this.injected.load()
        this.forceUpdate()
    }



    render() {
        let {api} = this.props

        return (
            <ErrorBoundary>
                <CardHeader style={{ fontSize: "2em"}}>
                    <Select
                        placeholder={"Choisir un status"}
                        value={api?.allStatus.filter(({value}) =>  api?.filter.status.includes(value))}
                        isMulti
                        onChange={this.onChange}
                        options={api?.allStatus}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </CardHeader>
            </ErrorBoundary>
        )
    }
}));
export default SelectStatus;
