import React from "react";

function StatusRow({title, enumStatus, rootStore}) {
    return <td className="text-center">
                            <span
                                className={"badge badge-pill badge" + rootStore.savedDatasStore.setColor(enumStatus).replace("bg", "")}>
                                {title}
                            </span>
    </td>
}

export default StatusRow;
