import React from "react";
import {inject, observer} from "mobx-react";
import {Link, withRouter} from "react-router-dom";
import {Button, UncontrolledTooltip} from "reactstrap";
import configuration from "../../../../configuration/configuration";


const PageRow = inject("rootStore")(observer(class PageRow extends React.Component {

    data;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.data = this.injected.data;

        this.state = {
            data: this.injected.data,
        }

        this.toggleDanger = this.toggleDanger.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.data !== prevProps.data
        ) {
            this.setState({
                data: this.injected.data
            })
        }
    }

    toggleDanger(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning("Page #" + this.state.data.id, event => this.onDelete(this.state.data.id))
    }

    async onDelete(deleteId) {
        await this.injected.rootStore.pageStore.delete(deleteId);
    }

    async copyToClipboard(event, copy) {
        event.preventDefault()
        await navigator.clipboard.writeText(copy)
        await this.injected.rootStore.notificationStore.setNotification(copy + " copié ! ", "success")

    }


    render() {
        return (
            <>
                <tr>
                    <th scope="row" className="text-center">
                        <span className="mb-0 text-sm">
                                <Link to={{
                                    pathname: "/page/" + this.state.data.url,
                                    aboutProps: {
                                        data: this.state.data
                                    }
                                }}>
                                #{this.state.data?.id}
                                 </Link>
                        </span>
                    </th>

                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {this.state.data?.name}
                        </span>
                    </td>


                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {this.state.data?.menu ? "Oui" : "Non"}
                        </span>
                    </td>


                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {this.state.data?.system ? "Oui" : "Non"}
                        </span>
                    </td>

                    <td className={"th-actions"}>
                        <div style={{display: "flex", justifyContent: "flex-start"}}>

                            <Link to={{
                                pathname: "/page/" + this.state.data.url,
                                aboutProps: {
                                    data: this.state.data
                                }
                            }}>
                                <Button className={"ml-2 mr-2"} color="primary" type="button"
                                        id={"UncontrolledTooltipExampleVoir" + this.state.data.id}>
                                    <i className="fa fa-eye" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top"
                                                         target={"UncontrolledTooltipExampleVoir" + this.state.data.id}>
                                        Voir
                                    </UncontrolledTooltip>
                                </Button>
                            </Link>

                            <Button className={"bg-warning ml-2 mr-2"}
                                    id={"UncontrolledCopier"}
                                    onClick={e => this.copyToClipboard(e,configuration.API + '/page/' + this.state.data.url)}
                            > <i className="fa fa-copy"></i>
                                <UncontrolledTooltip placement="top"
                                                     target={"UncontrolledCopier"}>
                                    Copier l'URL
                                </UncontrolledTooltip>
                            </Button>

                            {!this.state.data?.system && (
                                <Button className={"ml-2 mr-2"} color="danger" type="button"
                                        onClick={this.toggleDanger}
                                        id={"UncontrolledTooltipExampleSelection" + this.state.data.id}>
                                    <i className="fa fa-minus" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top"
                                                         target={"UncontrolledTooltipExampleSelection" + this.state.data.id}>
                                        Supprimer
                                    </UncontrolledTooltip>
                                </Button>
                            )}

                        </div>
                    </td>
                </tr>
            </>
        );
    }

}));

export default withRouter(PageRow);
