import React from "react";
import Container from "react-bootstrap/Container";

class ErrorBoundary extends React.Component {

    constructor(props) {
        super();
        this.state = {error: false}
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo)
    }

    static getDerivedStateFromError(error) {
        return {error: true}
    }

    render() {
        if (this.state.error) {
            return <Container className="mt-2" fluid>
                <div className="alert alert-danger"> Il y a eu un problème. Merci de contacter votre administrateur :)
                </div>
            </Container>
        }
        return this.props.children
    }
}

export default ErrorBoundary;