/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Container} from "reactstrap";
import i18n from "i18next";
import {inject, observer} from "mobx-react";
import Pagination from "../../../components/Akitatek/Pagination";
import TableGeneric from "../../../components/Akitatek/Headers/TableGeneric";
import TableBlogRow from "./TableBlogRow";
import GenericStaticCard from "../../../components/Akitatek/Card/GenericStaticCard";
import {SRLWrapper} from "simple-react-lightbox";


const Blogs = (inject("rootStore")(observer(class Blogs extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.injected.rootStore.blogStore.getBlogs();
        this.injected.rootStore.savedDatasStore.setHeader("Blogs", "Gestion des blogs", "default.jpg")

    }

    parentCallbackPage = (childData) => {
        this.injected.rootStore.blogStore.currentPage = childData
        this.injected.rootStore.blogStore.getBlogs();
    };


    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>

                    <GenericStaticCard collapse={true}
                                       element={<h4 className="mb-0">{i18n.t('page.admin.blogs.title')}</h4>}>
                        <SRLWrapper>
                            <TableGeneric titles={[
                                "ID",
                                "Image",
                                i18n.t('page.admin.blogs.titleBlog'),
                                i18n.t('page.admin.blogs.author'),
                                i18n.t('page.admin.blogs.description'),
                                "Date",
                                "Status",
                                "Actions"
                            ]}>

                                {this.injected.rootStore.blogStore.blogs
                                    .map(blog => {
                                        return <TableBlogRow blog={blog} key={"blog" + blog.id}/>
                                    })
                                }
                            </TableGeneric>
                        </SRLWrapper>
                        <Pagination
                            currentPage={this.injected.rootStore.blogStore.currentPage}
                            lastPage={this.injected.rootStore.blogStore.lastPage}
                            parentCallback={this.parentCallbackPage}/>
                    </GenericStaticCard>
                </Container>
            </>
        );
    }
})));

export default Blogs;
