import React from 'react';
import {inject, observer} from "mobx-react";
import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";
import i18n from "i18next";
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {withRouter} from "react-router-dom";
import styles from "./Prestation/Prestation.module.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import ChoiceImageInput from "../../../components/Akitatek/Form/ChoiceImageInput";
import EditorWord from "../../../components/Akitatek/EditorWord";
import AnnuaireCategory from "./Category/Category";


const SetCategories = (inject("rootStore")(observer(class SetCategories extends React.Component {
    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            id: this.injected.id,
            assetCategory: "",
            api: this.injected.api
        };

        this.injected.rootStore.savedDatasStore.setHeader("Gestion des marques", "Gérer vos marques", "annuaire_marque.jpg")
        this.onSubmitDetailsCategory = this.onSubmitDetailsCategory.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const name = e.target.name
        const type = e.target.type
        const value = type === "radio" ? parseInt(e.target.value) : e.target.value
        this.setState({[name]: value});
    }


    onPickAssetFilenameCategory = (action) => {
        if (action === "add") {
            this.setState({
                assetCategory: this.injected.rootStore.imageStore.pickAsset,
            })
        } else {
            this.setState({
                assetCategory: "",
            })
        }
    }

    onBack(e) {
        e.preventDefault()
        this.injected.history.goBack()
    }


    onClose = (child) => {
        let editorState
        if (child?.details?.description != null) {
            let blocksFromHTML = htmlToDraft(child?.details?.description);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            editorState = EditorState.createWithContent(contentState);
        } else {
            editorState = EditorState.createEmpty()
        }


        this.setState({
            category: child,
            tri: child.details?.tri ?? "",
            categoryURL: child.details?.url ?? "",
            descriptionURL: editorState,
            assetCategory: child.details?.asset ?? "",
            parent_id: child.parent_id ?? null,
            metadescriptionCat: child.details?.metadescription ?? "",
            titleCat: child.details?.title ?? "" ,
            catUrlButton: child?.details?.url_button ?? ""

        })
    }

    async onSubmitDetailsCategory(e) {
        e.preventDefault();
        let authResponse;

        let data = {
            id: this.state.category?.id,
            url: this.state.categoryURL,
            description: draftToHtml(convertToRaw(this.state.descriptionURL.getCurrentContent())),
            asset_id: this.state.assetCategory?.id,
            tri: this.state.tri,
            metadescription: this.state.metadescriptionCat,
            title: this.state.titleCat,
            url_button: this.state.catUrlButton

        }
        if (this.state.category) {
            authResponse = await this.state.api.updateCategory(data);
        }

        if (authResponse?.status === "success") {
            if (this.state.card) {
                this.setInitialData(authResponse?.datas)
            } else {
                this.injected.onCallback()
            }
        }
    }

    onEditorStateChangeCategory = (editorState) => {
        this.setState({
            descriptionURL:
            editorState,
        });
    };


    render() {
        return (
            <>
                <div>
                    <h6 style={{fontStyle: "italic"}}>
                        {!this.state.id ? (" Ajouter, supprimer et modifier des modèles pour cette marque") : "Ajouter, supprimer et modifier des catégories"}
                    </h6>
                    <div style={{display: "flex"}}>
                        <AnnuaireCategory looking={false}
                                          api={this.state.api}
                                          marqueId={this.state.id}
                                          card={true}
                                          onClose={this.onClose}
                                          update={true} onSelection={true}
                        />

                        {this.state.api.selectionCategory && (
                            <Form
                                role="form"
                                onSubmit={this.onSubmitDetailsCategory}
                            >

                                <div className="pl-lg-4">
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <label
                                                    className={styles.typeField}
                                                    htmlFor="input-first-name"
                                                >
                                                    Modèle
                                                </label>
                                                {this.state.category?.nom}

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <label
                                                    className={styles.typeField}
                                                    htmlFor="input-first-name"
                                                >
                                                    URL
                                                </label>

                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-first-url"
                                                    type="text"
                                                    value={this.state.categoryURL}
                                                    name={'categoryURL'}
                                                    onChange={this.onChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <ChoiceImageInput assetName={this.state.assetCategory?.filename}
                                                          onPickAssetFilename={this.onPickAssetFilenameCategory}/>
                                    </Row>

                                    {this.injected.rootStore.authStore.options.metadata === 1 && this.state.parent_id === null && (
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <label
                                                        className={styles.typeField}
                                                    >
                                                        Titre de la page (Si vide généré auto)
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        name={'titleCat'}
                                                        onChange={this.onChange}
                                                        value={this.state.titleCat || ''}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <label
                                                        className={styles.typeField}
                                                    >
                                                        Métadescription (si vide : généré auto)
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        name={'metadescriptionCat'}
                                                        onChange={this.onChange}
                                                        value={this.state.metadescriptionCat || ''}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}

                                    {this.state.parent_id === null && (
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <label
                                                        className={styles.typeField}
                                                    >
                                                        Type de TRI
                                                    </label>

                                                    <Input type="select"
                                                           name={'tri'}
                                                           onChange={this.onChange}
                                                           value={this.state.tri}
                                                    >
                                                        <option value={"soros"}> Regrouper par catégories et en Listes
                                                            (SOROS)
                                                        </option>
                                                        <option value={"akitatek"}>Affichage de tout par images
                                                            (AKITATEK)
                                                        </option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <label
                                                        className={styles.typeField}
                                                    >
                                                        Url bouton Identifie ton
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        name={'catUrlButton'}
                                                        onChange={this.onChange}
                                                        value={this.state.catUrlButton || ''}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}

                                    <EditorWord editorState={this.state.descriptionURL}
                                                onEditorStateChange={this.onEditorStateChangeCategory}/>
                                </div>
                                <div className="pl-lg-4 mt-3" style={{textAlign: "center"}}>
                                    <Button color="success" className="mr-1" type="submit">
                                        {i18n.t('button.submit')}
                                    </Button>
                                </div>
                            </Form>)}
                    </div>
                </div>
            </>
        );
    }
})));

export default withRouter(SetCategories);
