import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Card, Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown, Alert} from 'reactstrap';

import {AppAsideToggler, AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import {inject, observer} from "mobx-react";
import GlobalSearch from "../../../../components/Akitatek/Search/GlobalSearch";
import Button from "reactstrap/lib/Button";
import ModalAkitatek from "../../../../components/Akitatek/Modal/Modal";
import moment from "moment";
import configuration from "../../../../configuration/configuration";
import SetLoading from "../../../../components/Akitatek/setLoading";
import {Animated} from "react-animated-css";

const Mousetrap = require("mousetrap");

const DefaultHeader = inject("rootStore")(observer(class DefaultHeader extends Component {
    get injected() {
        return this.props
    }

    setLoading() {
        if (this.injected.rootStore.savedDatasStore.loadingDatas) {
            return <SetLoading/>
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            showScan: false,
            play: false,
            pause: true

        };

        this.url = configuration.API + "/img/NyanNyanNyanNyanNyan.mp3";
        this.audio = new Audio(this.url);
    }

    play() {
        localStorage.setItem('play', "true");

        this.setState({
            play: true,
            pause: false
        });

        this.audio.currentTime = 0;
        this.audio.play();
    }


    pause() {
        localStorage.setItem('play', "false");
        this.setState({play: false, pause: true});
        this.audio.pause();
    }

    componentWillUnmount() {
        this.audio.removeEventListener('ended', () => this.setState({play: false}));
    }

    //check if the values are up to date
    async componentDidMount() {
        this.injected.rootStore.getEcho()
            .channel(`refresh`)
            .listen('RefreshEvent', ev => {
                console.log('yrdy')
                this.injected.rootStore.refresh(ev)
            })

        localStorage.setItem('play', "false");

        this.audio.addEventListener('ended', () => this.setState({play: false}));

        this.injected.rootStore.getEcho()
            .channel(`notif`)
            .listen('NotifEvent', ev => {
                this.pause();

                const play = localStorage.getItem('play') === 'true';

                if (ev.notification) {
                    if (!play && ev.notification.type !== "NEW_USER" && ev.notification.type !== "RELOAD") {
                        this.url = configuration.API + "/img/" + ev.notification.musique;
                        this.audio.src = this.url

                        this.play()
                        setTimeout(() => {
                            this.pause();
                        }, 25000);
                    }
                    this.injected.rootStore.notificationStore.setNotification(ev.notification.message, "warning", 8000)
                }

                this.injected.rootStore.userNotificationStore.getNotifications()
            })

        this.injected.rootStore.getEcho().connector.socket.on('connect', () => {
            this.injected.rootStore.authStore.isLoggedEcho = true;
            console.log("connect")
        });

        this.injected.rootStore.getEcho().connector.socket.on('disconnect', () => {
            this.injected.rootStore.authStore.isLoggedEcho = false;
            console.log("disconnect")
        });

        Mousetrap.bind("ctrl+shift+l", e => this.onFire(!this.state.showScan));

    }

    onFire(value) {
        this.setState({
            showScan: value
        })
    }

    onClose() {
        this.setState({
            showScan: false
        })
    }

    onBack(e) {
        e.preventDefault()
        this.injected.history.goBack()
    }

    moveTo(e, link, id) {
        e.stopPropagation()
        e.preventDefault()
        if (id) {
            this.injected.rootStore.userNotificationStore.saveNotification({
                id: id,
                is_read: true
            })
        }
        this.injected.history.push(link)
    }

    onStar(e, isStar, id) {
        e.stopPropagation()
        e.preventDefault()
        this.injected.rootStore.userNotificationStore.saveNotification({
            id: id,
            is_star: !isStar
        })
    }


    render() {
        return (
            <React.Fragment>
                <AppSidebarToggler className="d-lg-none" display="md" mobile/>
                <i style={{cursor: "pointer"}} onClick={e => this.onBack(e)} className="fa fa-arrow-left mr-2 ml-4"
                   aria-hidden="true"/>
                <AppSidebarToggler className="d-md-down-none ml-2" display="lg"/>
                {this.setLoading()}
                <AppNavbarBrand/>

                <Animated animationIn="fadeIn" animationOut="fadeOut"
                          isVisible={true}>
                    <Alert
                        className="p-1 m-auto"
                        color={this.injected.rootStore.authStore.isLoggedEcho ? "success" : "danger"}>
                        {this.injected.rootStore.authStore.isLoggedEcho ? (
                            "Connecté au serveur d'Event"
                        ) : (
                            "Déconnecté du serveur d'Event"
                        )}
                    </Alert> </Animated>
                <Nav className="ml-auto" navbar>

                    <div className="card-block p-1 clearfix">
                        <Button color={"outline-primary"} onClick={e => this.setState({showScan: true})}><i
                            className="fa fa-barcode" aria-hidden="true"></i></Button>
                        <ModalAkitatek title={"Scannez"} show={this.state.showScan}
                                       children={<GlobalSearch onCallback={e => this.onClose()}/>}
                                       onCallback={e => this.onClose()}/>
                    </div>

                    <UncontrolledDropdown nav direction="down" onClick={e => this.pause()}>
                        <DropdownToggle nav>
                            <div>
                                <i className="icon-bell"></i><Badge pill
                                                                    color="danger">{this.injected.rootStore.userNotificationStore.notifications.filter(notif => notif.is_read === 0 && notif.is_star === 0).length}</Badge>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu right
                                      style={{
                                          textAlign: "center",
                                          width: "50em",
                                          transform: "translate3d(-50em, 21px, 0px)"
                                      }}
                        >
                            <DropdownItem header tag="div"
                                          className="text-center"><strong>Notifications</strong></DropdownItem>
                            {this.injected.rootStore.userNotificationStore.notifications.length === 0 && (
                                <DropdownItem><i className="text-success"></i> Pas de nouvelles, bonne nouvelle
                                    ?</DropdownItem>
                            )}

                            {this.injected.rootStore.userNotificationStore.notifications.filter(notif => notif.is_star === 1).slice(0, 5).map((notif, id) =>
                                <div
                                    key={id}
                                >
                                    <DropdownItem
                                        key={id}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between"

                                        }}
                                        className={"alert alert-warning"}
                                    >

                                        <Link to={""} onClick={e => this.moveTo(e, notif.link)}>
                                            <i className={notif.icon}></i>
                                        </Link>
                                        <Link to={""} onClick={e => this.moveTo(e, notif.link)}>
                                            <div>
                                                {notif.message}
                                                <span style={{
                                                    fontStyle: "italic"
                                                }}>  {moment(notif.created_at).format("lll")}</span>
                                            </div>
                                        </Link>

                                        <div>
                                            <div onClick={e => this.onStar(e, notif.is_star, notif.id)} style={{
                                                fontStyle: "italic",
                                                cursor: "pointer"
                                            }}>
                                                {notif.is_star ? (
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                ) : (
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </DropdownItem>
                                </div>
                            )}

                            {this.injected.rootStore.userNotificationStore.notifications.filter(notif => notif.is_read === 0 && notif.is_star === 0).slice(0, 5).map((notif, id) =>
                                <div key={notif.id}>
                                    <DropdownItem divider/>
                                    <DropdownItem
                                        onClick={e => e.stopPropagation()}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between"

                                        }} key={id}>
                                        <Link to={""} onClick={e => this.moveTo(e, notif.link, notif.id)}>
                                            <i className={notif.icon}></i>
                                        </Link>

                                        <Link onClick={e => this.moveTo(e, notif.link, notif.id)} to={""}>
                                            {notif.message}
                                            <span style={{
                                                fontStyle: "italic"
                                            }}>  {moment(notif.created_at).format("lll")}</span>
                                        </Link>

                                        <div>
                                            <div onClick={e => this.onStar(e, notif.is_star, notif.id)} style={{
                                                fontStyle: "italic",
                                                cursor: "pointer"
                                            }}>
                                                {notif.is_star ? (
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                ) : (
                                                    <i className="fa fa-star-o" aria-hidden="true"></i>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </DropdownItem>
                                </div>
                            )}
                            <Link to={"/notifications"}>
                                <DropdownItem className="text-center"><strong> Voir plus</strong></DropdownItem>
                            </Link>

                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav direction="down">
                        <DropdownToggle nav>
                            <img src={configuration.API + "/magasin/svg/" + this.injected.rootStore.authStore.logo}
                                 className="img-avatar" alt={this.injected.rootStore.authStore.emailCompany}/>
                        </DropdownToggle>
                        <DropdownMenu right>
                            {/*<DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>*/}
                            {/*<DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>*/}
                            {/*<DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>*/}
                            {/*<DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>*/}
                            {/*<DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem>*/}
                            <DropdownItem header tag="div"
                                          className="text-center"><strong>Paramètres</strong></DropdownItem>
                            <Link to={`/settings`}> <DropdownItem><i className="fa fa-wrench"></i> Paramètres Entreprise</DropdownItem></Link>
                            {/*<DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem>*/}
                            {/*<DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem>*/}
                            {/*<DropdownItem divider />*/}
                            {/*<DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem>*/}
                            <DropdownItem onClick={e => this.injected.onLogout(e)}><i className="fa fa-lock"></i> Se
                                déconnecter</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
                <AppAsideToggler className="d-md-down-none"/>
                {/*<AppAsideToggler className="d-lg-none" mobile />*/}
            </React.Fragment>
        );
    }
}));


export default withRouter(DefaultHeader);
