import React from "react";
import Spinner from "react-bootstrap/Spinner";

function SetLoading({}) {
    return ([
        <Spinner key={1} className="ml-1" size="sm" animation="grow" variant="info"/>,
        <Spinner key={2} size="sm" animation="grow" variant="info"/>,
        <Spinner key={3} size="sm" animation="grow" variant="info"/>,
    ])
}

export default SetLoading;
