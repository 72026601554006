import React from "react";
import FormGroup from "reactstrap/lib/FormGroup";

function MagasinOuInternet({data}) {
    return <td className="text-center">
                        <span className="mb-0 text-sm">
                                 <FormGroup check>
                                     {data === 1 ? (
                                             <span className="badge badge-pill badge-danger">Internet</span>
                                         ) :
                                         (
                                             <span className="badge badge-pill badge-warning">Magasin</span>
                                         )
                                     }
                                </FormGroup>
                        </span></td>
}

export default MagasinOuInternet;
