import Api from "../util/api";
import {action, decorate, observable} from "mobx";


export default class CommandStore {
    lots = observable([]);
    stocks = observable([]);
    stocksAvailable = observable([]);
    fournisseurs = observable([]);
    datas = observable([]);
    filter = {
        name: "created_at",
        order: false,
        user_id: "",
        status: ["ORDERED"],
        limit: "15",
        search: "",
        page: "1",
        last_page: "1",
    }
    statusname = "";

    allStatus = observable([]);

    defaultFilter = {
        limit: "15",
        search: "",
        page: "1",
        last_page: "1",
        name: "created_at",
        order: false,
        status: ["ORDERED"],
    }

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    async getDatas() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.commandes.getCommandes(this.filter);

            if (this.datas.length > 0) {
                this.setCommandes(authResponse.data)
            } else {
                this.datas = authResponse.data;
            }
            this.filter.last_page = authResponse.last_page;
            this.filter.page = authResponse.current_page;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }

    getStatusname() {
        this.statusname = ""
        this.allStatus.forEach(element => {
                if (element.value === this.filter.status[0]) {
                    this.statusname = element.label
                }
            }
        );
    }

    async getAllStatus() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            this.allStatus = await this.api.commandes.getAllStatus();

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

        this.rootStore.savedDatasStore.setLoading(false)
    }

    async getCommandeForId(id) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.commandes.find(id);
            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse.datas;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    setCommandes(datas) {
        this.datas.replace(datas)
    }

    setFournisseurs(datas) {
        this.fournisseurs.replace(datas)
    }


    setStatus(status) {
        if (this.filter.status !== status) {
            this.filter.status = status

        }
    }

    async getFournisseurs() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.fournisseurs.getFournisseurs();

            if (this.fournisseurs.length > 0) {
                this.setFournisseurs(authResponse)
            } else {
                this.fournisseurs = authResponse;
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
        this.rootStore.savedDatasStore.setLoading(false)
    }

    async createCommande(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.commandes.createCommandeRapide(data);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Commande " + authResponse.datas.id + " ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveCommande(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.commandes.saveCommande(data);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Commande " + authResponse.datas.id + " ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateCommande(data, notHide = false, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.commandes.updateCommande(data);

            if (authResponse?.status === "success") {
                if (notHide) {
                    this.rootStore.notificationStore.setNotification(message ?? "Commande " + authResponse.datas.id + " sauvegardée", "success");
                }
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveFournisseur(data) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.fournisseurs.saveFournisseur(data);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Fournisseur " + authResponse.datas.id + " ajouté", "success");
                await this.getFournisseurs()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async findCommandById(id) {
        return await this.api.commandes.findCommandById(id);
    }

    async getStocks() {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stocks.getStocks(this.filter);

            if (this.stocks.length > 0) {
                this.stocks.replace(authResponse.data)
            } else {
                this.stocks = authResponse.data;
            }

            this.currentPage = authResponse.currentPage;
            this.lastPage = authResponse.lastPage;
            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse.data;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

    }

    async getStocksAvailable(isEmpty = '') {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stocks.getStocksAvailable(isEmpty);
            if (this.stocksAvailable.length > 0) {
                this.stocksAvailable.replace(authResponse.datas)
            } else {
                this.stocksAvailable = authResponse.datas;
            }

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

        this.rootStore.savedDatasStore.setLoading(false)
    }

    async getLots(search = "") {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.stocks.getLots(search);

            if (this.lots.length > 0) {
                this.lots.replace(authResponse.datas)
            } else {
                this.lots = authResponse.datas;
            }

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }

        this.rootStore.savedDatasStore.setLoading(false)
    }


    async saveCommandeProducts(data, message) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.commandes.saveCommandeProducts(data);

            if (authResponse?.status === "success") {
                let index = data.index + 1
                this.rootStore.notificationStore.setNotification(message ?? "Ligne #" + index + " ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateCommandeProducts(data, notHide) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.commandes.updateCommandeProducts(data);

            if (authResponse?.status === "success") {
                if (notHide) {
                    let index = data.index + 1
                    this.rootStore.notificationStore.setNotification("Ligne #" + index + " sauvegardée", "success");
                }
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveAllCommande(data) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.commandes.saveAllCommande(data);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Commande #" + data.id + "validée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async deleteCommandeProducts(data, index = "") {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.commandes.deleteCommandeProducts(data);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Ligne " + index + " supprimée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse.status
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async saveMouvCommande(data) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.commandes.saveMouvCommande(data)

            if (authResponse?.status === "success") {
                this.rootStore.savedDatasStore.setLoading(false)
                this.rootStore.notificationStore.setNotification(data.quantite + " quantité(s) reçue(s)", "success");

            }

            return authResponse.data

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async delete(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.commandes.delete(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Commmande # " + id + " supprimée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)

            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async downloadPdf(e, id, printerId) {
        e.preventDefault();
        try {
            if (id) {
                this.rootStore.savedDatasStore.setLoading(true)

                let authResponse = await this.api.commandes.createPdf(id, printerId);

                if (authResponse?.status === "success") {
                    this.rootStore.notificationStore.setNotification("Impression en cours", "success");
                }
                this.rootStore.savedDatasStore.setLoading(false)
            }
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }
}

decorate(CommandStore, {
    datas: observable,
    getDatas: action,
    fournisseurs: observable,
    stocks: observable,
    stocksAvailable: observable,
    allStatus: observable,
    lots: observable,
    filter: observable,
    statusname: observable
});