import React from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {Button} from "react-bootstrap";
import {UncontrolledTooltip} from "reactstrap";
import moment from "moment";
import ActionPencilLink from "../../../components/Akitatek/Table/ActionsPencilLink";


const ActionPriseEnChargeRow = inject("rootStore")(observer(class PriseEnChargeRow extends React.Component {

    data;
    online;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        moment.locale('fr');

        this.data = this.injected.data;

        this.state = {
            online: this.injected.online,
            data: this.injected.data,
        }

        this.toggleDanger = this.toggleDanger.bind(this);
    }


    async onReload(id) {
        let data = await this.injected.rootStore.prisenchargeStore.getPriseEnChargeForId(id);

        if (data) {
            this.setState({
                data: data
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.data !== prevProps.data
        ) {
            this.setState({
                data: this.injected.data
            })
        }
    }

    check(title) {
        return (<span
            className={"badge badge-pill badge" + this.injected.rootStore.savedDatasStore.setColor(this.state.data.status).replace("bg", "")}>{title}</span>)
    }

    toggleDanger(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning("la prise en charge #" + this.state.data?.id, event => this.onDelete(this.state.data?.id), this.state.data?.status === "DRAFT")
    }


    async onDelete(deleteId) {
        await this.injected.rootStore.prisenchargeStore.delete(deleteId);
    }


    async acceptedPec(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.data.id, e => this.onValidate(this.state.data.id), this.state.data.title, "Je valide la demande #" + this.state.data.id, "#4dbd74")
    }

    async onValidate(id) {
        var datas = {
            status: "VALIDATE",
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.changePriseEnCharge(datas)
        await this.onReload(id)

    }


    async refusedPec(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.data.id, e => this.onRemove(this.state.data.id), this.state.data.title, "Je refuse la demande #" + this.state.data.id)
    }

    async onRemove(id) {
        var datas = {
            status: "REFUSED",
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.changePriseEnCharge(datas)
        await this.onReload(id)
    }

    async arrivedPec(e) {
        e.preventDefault()
        this.injected.rootStore.notificationStore.remove(this.state.data.id, e => this.onArrived(this.state.data.id), this.state.data.title, "Je valide la réception de la pec #" + this.state.data.id)
    }


    async onArrived(id) {
        var datas = {
            status: "ARRIVED",
            id: id
        }
        await this.injected.rootStore.prisenchargeStore.changePriseEnCharge(datas)
        await this.onReload(id)
    }

    render() {
        return (
            <>
                <td className={"th-actions"}>
                    <div style={{display: "flex"}}>

                        <ActionPencilLink title={{
                            "title": "Actions",
                            "action": "actionpencillink",
                            "href": "Gérer une Prise En Charge",
                            "key": "id",
                            "filter": "id"
                        }} data={this.state.data} href={"Gérer une Prise En Charge"}/>

                        {this.state.data?.online === 1 && this.state.data?.status === "SUBMIT" && (
                            <div><Button variant="success" type="button" className="mr-1"
                                         onClick={e => this.acceptedPec(e)}
                                         id={"UncontrolledTooltipExampleSelection" + this.state.data.id}>
                                V
                            </Button>
                                <Button variant="danger" type="button" className="mr-1"
                                        onClick={e => this.refusedPec(e)}
                                        id={"UncontrolledTooltipExampleSelection" + this.state.data.id}>
                                    X
                                </Button></div>
                        )}


                        {this.state.data?.online === 1 && this.state.data?.status === "VALIDATE" && (
                            <div>
                                <Button variant="primary" type="button" className="mr-1"
                                        onClick={e => this.arrivedPec(e)}
                                        id={"UncontrolledTooltipExampleSelection" + this.state.data.id}>
                                    Arrivé
                                </Button>
                            </div>
                        )}

                        {this.state.data?.status !== "SUBMIT" && (
                            <Button variant="danger" type="button" className="mr-1" onClick={this.toggleDanger}
                                    id={"UncontrolledTooltipExampleSelection" + this.state.data.id}>
                                <i className="fa fa-close" aria-hidden="true"/>
                                <UncontrolledTooltip placement="top"
                                                     target={"UncontrolledTooltipExampleSelection" + this.state.data.id}>
                                    {this.state.data?.status === "DRAFT" ? (
                                        "Supprimée") : ("Cloturée")}
                                </UncontrolledTooltip>
                            </Button>
                        )}
                    </div>
                </td>
            </>
        );
    }

}));

export default withRouter(ActionPriseEnChargeRow);
