import React, {Component} from 'react';
import {Nav, NavItem, NavLink, TabContent} from 'reactstrap';
import classNames from 'classnames';
import {inject, observer} from "mobx-react";
import FirstAsidePageUser from "./FirstAsidePageUser";
import SecondAsidePage from "./SecondAsidePage";
import ThreeAsidePage from "./ThreeAsidePage";

const DefaultAside = inject("rootStore")(observer(class DefaultAside extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }
      get injected() {
        return this.props
      }


  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '1' })}
                     onClick={() => {
                       this.toggle('1');
                     }}>
              <i className="icon-list"></i>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '2' })}
                     onClick={() => {
                       this.toggle('2');
                     }}>
              <i className="icon-settings"></i>
            </NavLink>
          </NavItem>
            <NavItem>
                <NavLink className={classNames({ active: this.state.activeTab === '3' })}
                         onClick={() => {
                             this.toggle('3');
                         }}>
                    <i className="fa fa-print"></i>
                </NavLink>
            </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
            <FirstAsidePageUser/>
            <SecondAsidePage/>
            <ThreeAsidePage/>
        </TabContent>
      </React.Fragment>
    );
  }
}
));

export default DefaultAside;
