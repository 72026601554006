import React from "react";
import {Link} from "react-router-dom";
import getRoutes from "../getRoutes";

function UserRow({data}) {
    return   <th className="text-center">
                        <span className="mb-0 text-sm">
                        <Link to={{pathname: getRoutes("Modifier Client", data?.id)}}>
                             {data?.fullname}
                        </Link>
                        </span>
    </th>
}

export default UserRow;
