import Api from "../util/api";
import {action, decorate, observable} from "mobx";

export default class SavedDataStore {
    user = observable(
        {
            // observable properties:
            id: "",
            nom: "Sélectionner un client",
            prenom: "",
            facture: [],
            devis: [],
            ticket: []
        });

    isHeader = false
    subtitle
    title
    image
    subCategory
    userHeader
    allUsers = observable([])
    loadingDatas = false;
    type = ["ligne", "presta", "product"]
    searchs = observable([])
    typeSearch;
    myApi;

    setLoading(value) {
        this.loadingDatas = value
    }

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    async getSearch(search) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.searchs.getSearch(search);
            this.searchs = authResponse.datas;
            this.typeSearch = authResponse.type;
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    setUser(user) {
        this.user = user;
        this.findUserById(this.user.id)
    }


    clearUser() {
        this.user = {
            id: "",
            nom: "",
            prenom: "",
            facture: [],
            devis: [],
            ticket: []
        };
    }

    getUsername() {
        return this.user.fullname;
    }

    async findUserById(id) {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            this.user = await this.api.users.findUserById(id);
        } catch (e) {
        }
        this.rootStore.savedDatasStore.setLoading(false)

    }

    async getAllUsers() {
        try {
            this.allUsers = await this.api.users.getAllUsers();
        } catch (e) {
        }
    }

    setHeader(title, subtitle, image, api, subCategory = "", user = "") {
        if (title) {
            this.isHeader = true
            this.title = title;
            this.subtitle = subtitle;
            this.image = image;
            this.subCategory = subCategory;
            this.userHeader = user
            this.myApi = api;

        }
    }

    removeHeader() {
        this.isHeader = false
    }

    setColor(status) {
        let color = "bg-transparent"
        switch (status) {
            case 'DRAFT':
                color = "bg-light"
                break
            case 'DIAGNOSTIC':
            case 'REPARATION':
            case 'ARRIVED':
                color = "bg-primary"
                break
            case 'OPEN':
            case 'EN COURS':
            case 'TO_GET_BACK':
            case 'WAITING_CLIENT':
            case 'WAITING_PIECES':
            case 'WAITING_DEVIS':
            case 'WAITING_PAID':
            case 'TESTS' :
            case 'ARCHIVED' :
            case 'PREPARATION':
            case 'BROUILLON':
            case 'EN_COURS':
                color = "bg-warning"
                break
            case 'REFUSED':
            case 'TO_PAID':
            case 'CLOT':
            case 'TAKE_IN':
            case 'SUBMIT':
            case 'TO_SEND_BACK':
            case 'WAITING_REPAIR':
            case 'CANCEL':
                color = "bg-danger"
                break
            case 'RECEPTION':
            case 'DEVIS_SEND':
                color = "bg-secondary"
                break
            case 'PAID' :
            case 'VALID' :
            case 'OVER':
            case 'SEND':
            case 'TO_GET_BACK_SUCESSFULLY':
            case 'DEVIS_ACCEPTED':
            case 'PUBLIE':
            case 'VALIDATE':
                color = "bg-success"
                break
            case 'WAIT':
            case 'ORDERED':
            case 'ALL':
                color = 'bg-info'
                break;
            default:
                color = "bg-primary"
                break
        }
        return color;
    }

    setColorItemDivider(status) {
        let color = "bg-transparent"
        switch (status) {
            case 'OPEN':
                color = "list-group-item-accent-light"
                break
            case 'WAIT':
            case 'ORDERED':
                color = "list-group-item-accent-primary"
                break
            case 'DRAFT':
                color = "list-group-item-accent-warning"
                break
            case 'REFUSED':
            case 'TO_PAID':
            case 'SEND':
                color = "list-group-item-accent-danger"
                break
            case 'RECEPTION':
                color = "list-group-item-accent-secondary"
                break
            case 'PAID' :
            case 'VALID' :
                color = "list-group-item-accent-success"
                break
            default:
                color = "list-group-item-accent-transparent"
                break
        }
        return color;
    }

    setColorBadge(status) {
        let color = "badge-info"
        switch (status) {
            case 'success':
                color = "badge-primary"
                break
            case 'cancel':
                color = "badge-danger"
                break
            case 'élaboration':
                color = "badge-light"
                break
            case 'send':
                color = "badge-success"
                break
            default:
                color = "badge-info"
                break
        }
        return color;
    }

    setColorType(type) {
        let color = "bg-transparent"
        switch (type) {
            case 'product':
                color = "bg-primary"
                break
            case 'ligne':
                color = "bg-danger"
                break
            case 'presta':
                color = "bg-success"
                break
            case 'scan':
                color = "bg-warning"
                break
            default:
                color = "bg-transparent"
                break
        }
        return color;
    }
}

decorate(SavedDataStore, {
    setUser: action,
    clearUser: action,
    user: observable,
    getUsers: action,
    findUserById: action,
    allUsers: observable,
    loadingDatas: observable,
    title: observable,
    subtitle: observable,
    image: observable,
    myApi: observable,
    isHeader: observable,
    subCategory: observable,
    userHeader: observable,
    searchs: observable,
    typeSearch: observable
});