import React from "react";
import {inject, observer} from "mobx-react";
import {Button, UncontrolledTooltip} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import moment from "moment";
import getRoutes from "../../../components/Akitatek/getRoutes";


const ActionCommande = inject("rootStore")(observer(class ActionCommande extends React.Component {

    data;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        moment.locale('fr');

        this.state = {
            data: this.injected.data
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.data !== prevProps.data
        ) {
            this.setState({
                data: this.injected.data
            })
        }
    }

    async onCopy(e) {
        e.preventDefault()
        await this.injected.rootStore.notificationStore.setQuestion("Voulez vous copier la commande " + this.state.data.title + "?", "Copier #" + this.state.data.id, e => this.onChange())
    }




    async onChange() {
        let commandeCopy = Object.assign({}, this.state.data);
        commandeCopy.id = ""
        commandeCopy.status = "DRAFT"
        commandeCopy.suivi = null
        commandeCopy.suivi_id = null
        commandeCopy.created_at = moment().format("YYYY-MM-DD")
        commandeCopy.ref = ""


        let authResponse = await this.injected.rootStore.commandeStore.saveCommande(commandeCopy)
        this.injected.history.replace(getRoutes("Gérer une commande", authResponse.datas.id));
    }


    render() {
        return (
            <>
                <td className="th-actions text-center">
                    <Link to={{
                        pathname: getRoutes("Gérer une commande", this.state.data.id),
                        aboutProps: {
                            isCommandeValid: true,
                            data: this.state.data
                        }
                    }}>

                        <Button className={"mr-4"} color="primary" type="button"
                                id={"UncontrolledTooltipExampleEditer" + this.state.data.id}>
                            <i className="fa fa-pencil" aria-hidden="true"/>
                            <UncontrolledTooltip placement="top"
                                                 target={"UncontrolledTooltipExampleEditer" + this.state.data.id}>
                                Editer
                            </UncontrolledTooltip>
                        </Button>
                    </Link>


                    <Button onClick={e => this.onCopy(e)} className={"mr-4"} color="warning" type="button"
                            id={"UncontrolledTooltipExampleCopy" + this.state.data.id}>
                        <i className="fa fa-copy" aria-hidden="true"/>
                        <UncontrolledTooltip placement="top"
                                             target={"UncontrolledTooltipExampleCopy" + this.state.data.id}>
                            Copier
                        </UncontrolledTooltip>
                    </Button>

                </td>
            </>
        );
    }

}));

export default withRouter(ActionCommande);
