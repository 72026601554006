import React from "react";
import {inject, observer} from "mobx-react";
import {Card, CardBody, CardTitle, CardHeader, Button} from "reactstrap";
import AddFournisseurs from "./AddFournisseurs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const AutoCompleteCommande = inject("rootStore") (observer( class Search extends React.Component {

    commande

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            search: "",
            commande: this.injected.commande,
            fournisseur: this.injected.commande?.fournisseur ?? "",
            modeSearch: true
        }
        this.injected.rootStore.commandeStore.getFournisseurs();
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.commande !== prevProps.commande
        ) {
            this.setState({
                commande: this.injected.commande,
                fournisseur: this.injected.commande?.fournisseur ?? ""})
        }
    }

    setSuggestions() {

        return(
            <Autocomplete
                value={this.state.fournisseur}
                options={this.injected.rootStore.commandeStore.fournisseurs}
                getOptionLabel={(option) => {
                    if(option?.id) {
                        return "#" + option?.id + " " + option?.nom
                    } else {
                        return ""
                    }
                }}
                style={{ width: 250}}
                onChange={(event, newValue) => {
                    this.selection(newValue);
                }}
                selectOnFocus
                renderInput={(params) => <TextField {...params}
                                                    margin="normal"
                                                    label="Choisir un fournisseur"
                                                    variant="outlined" />}
            />)}




    modeCallback = (childData) => {
        let commande = this.state.commande
        commande.fournisseur = childData
        commande.fournisseur_id = childData.id
        this.setState({
            modeSearch: !this.state.modeSearch,
            fournisseur:childData,
            search: childData?.nom
        })
    };


    setInformation() {
        if(this.state.fournisseur !== "" || this.state.fournisseur !== undefined ) {
            return (<div style={{ fontStyle: "italic"}}>
                <div>  </div>
                <div> {this.state.fournisseur?.nom} </div>
                <div> {this.state.fournisseur?.first_name} {this.state.fournisseur?.last_name} </div>
                <div> {this.state.fournisseur?.address} </div>
                <div> {this.state.fournisseur?.postal_code} {this.state.fournisseur?.state}  {this.state.fournisseur?.city} </div>
                <div> {this.state.fournisseur?.email} </div>
                <div> {this.state.fournisseur?.phone} </div>
                <div> {this.state.fournisseur?.website} </div>
            </div>);
        }

    }

    selection(fournisseur) {

        let commande = this.state.commande
        commande.fournisseur = fournisseur ?? ""
        commande.fournisseur_id = fournisseur?.id ?? ""
        this.setState( {
            search: "",
            commande: commande,
            fournisseur: fournisseur,
        })
    }


    setTitle() {
        if(this.state.modeSearch) {
            return (
                <div  style={{display: "flex", justifyContent: "center", alignItems:"center", color: "#20a8d8"}}>
                    <h5  className="mr-2">  Chercher un fournisseur</h5>
                    <Button style={{marginLeft: "auto"}} color="warning" onClick={e => this.setState({ modeSearch: !this.state.modeSearch})}>
                        <i className="fa fa-plus-circle" aria-hidden="true"/>
                    </Button>
                </div>
            )
        } else {
            return (
                <div  style={{display: "flex", justifyContent: "center", alignItems:"center"}}>
                    <h5  className="mr-2"> Créer un fournisseur</h5>
                    <Button style={{marginLeft: "auto"}} color="warning" onClick={e => this.setState({ modeSearch: !this.state.modeSearch})}>
                        <i className="fa fa-search" aria-hidden="true"/>
                    </Button>
                </div>)
        }
    }


    setMode() {
        if(this.state.modeSearch) {
           return(
               <CardBody>
               <CardTitle style={{fontWeight: "bold"}}>
                {this.setSuggestions()}
            </CardTitle>
             {this.setInformation()}
          </CardBody>

           )
        } else {
            return (<CardBody>
                <AddFournisseurs
                    modeCallback={this.modeCallback}
                />
            </CardBody>)
        }
    }

    render() {
        return (
            <div className="bg-transparent mt-1">

                <Card style={{minWidth: "20em"}}>
                    <CardHeader>
                        {this.setTitle()}
                    </CardHeader>
                        {this.setMode()}
                </Card>
            </div>

        )
    }
}));

export default AutoCompleteCommande;
