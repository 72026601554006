import React from "react";
import {inject, observer} from "mobx-react";
import {Card, CardHeader, Button, Container} from "reactstrap";
import Input from "reactstrap/lib/Input";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import styles from "./CardUser.module.css"
const AddMachineUser = inject("rootStore") (observer( class AddMachineUser extends React.Component {


    id
    lignes
    titles = ["Marque", "Produit", "Code", "Accessoires",  "Defauts", "Actions"];
    machineClient

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            id: this.injected.id,
            serial: "",
            appareil: "",
            defauts:  "",
            marque:  "",
            lignes: this.injected.lignes?? [],
            code:  "",
            card: this.injected.card ?? true
        }

        this.search = this.search.bind(this);
    }

     componentDidMount() {
         this.injected.rootStore.annuaireStore.marqueStore.getAllMarques()
         this.injected.rootStore.annuaireStore.productStore.getProducts(this.state.marque?.id, "")
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.lignes !== prevProps.lignes ||
            this.injected.id !== prevProps.id||
            this.injected.card !== prevProps.card
        ) {
            this.setState({
                id: this.injected.id,
                lignes: this.injected.lignes?? [],
                card: this.injected.card ?? true
            })
        }
    }

    search(event) {
        event.preventDefault();
        this.injected.rootStore.annuaireStore.productStore.getProducts(this.state.marque?.id,  event.target.value)
    }


    selection(appareil) {
        this.setState( {
            marque: appareil?.marque ?? "",
            appareil: appareil ?? "",
        })
    }

    selectionMarque(marque) {
        this.setState( {
            marque: marque ?? ""
        })
    }


    setTitle() {
        return (
            <div  style={{display: "flex", alignItems:"center", fontSize: "1.2em"}}>
                <div  className="mr-4 ml-2">  Ajouter une Machine Client </div>
            </div>
        )
    }


    async onSubmit(event) {
        event.preventDefault()

        let authResponse

        let data = {
            defauts: this.state.defauts ?? "",
            serial: this.state.serial ?? "",
            annuaire_appareil_id :  this.state.appareil?.id ?? "",
            user_id: this.state.id,
            code: this.state.code
        }

        authResponse = await this.injected.rootStore.userStore.saveCustomersMachine(data)

        if(authResponse?.status  === "success") {
            this.setState( {
                marque: "",
                appareil:  "",
                serial: "",
                defauts: "",
                code: ""
            })

            if(this.state.card) {
                this.injected.callbackRefreshLignes(authResponse.datas)
            } else {
                this.injected.onCallback(authResponse.datas)
            }
        }

    }



    setCallbackStock = (product) => {
        this.setState({
            appareil: product,
            modeSearch: !this.state.modeSearch,
            marque:product?.marque?.nom
        })
    };


    async enter(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            await this.onSubmit(e)
        }
    }


    disabled() {
        return  !this.state.appareil
    }

    setMode() {
        return(
            <div className={this.state.card ? styles.cardFlex : ""}>
                <div className={"item"}>
                    <Input
                        placeholder={"Numéro de série"}
                        onKeyPress={e=> this.enter(e)}
                        value={this.state.serial}
                        className={ this.state.card ? "mr-2" : "mb-2"}
                        type="text" onChange={e => this.setState({ serial:  e.target.value})}>
                    </Input>
                </div>

                    <div className={"item"}>
                        <Input
                            placeholder={"Code"}
                            onKeyPress={e=> this.enter(e)}
                            value={this.state.code}
                            className={ this.state.card ? "mr-2" : "mb-2"}
                            type="text" onChange={e => this.setState({ code:  e.target.value})}>
                        </Input>
                    </div>
                    <div className={"item"}>
                        <Input
                            placeholder={"Defauts"}
                            onKeyPress={e=> this.enter(e)}
                            value={this.state.defauts}
                            className={ this.state.card ? "mr-2" : "mb-2"}
                            type="textarea" onChange={e => this.setState({ defauts:  e.target.value})}>
                        </Input>
                    </div>

                {  !this.state.card && (
                    <div className={styles.cardFlex}>
                        <Button
                            disabled={this.disabled()}
                            color={"success"}
                            onClick={e=> this.onSubmit(e)}> Ajouter <i className={"fa fa-plus"} />
                        </Button>
                    </div>)}

                {this.state.card && (
                    <div>
                        <Button
                            disabled={this.disabled()}
                            color={"success"}
                            onClick={e=> this.onSubmit(e)}>
                            <i className={"fa fa-plus"} /> </Button>
                    </div>)}
            </div>)
    }

    addMachineUser() {
        return (<div style={{ display: "flex", justifyContent: "center"}}>
            <Autocomplete
                className={"mr-2"}
                value={this.state.marque}
                options={this.injected.rootStore.annuaireStore.marqueStore.allMarques}
                getOptionLabel={(option) => {
                    if(option?.id) {
                        return "#" + option?.id + " " + option?.nom
                    } else {
                        return ""
                    }
                } }
                style={{ width: 300}}
                onChange={async (event, newValue) => {
                    await this.selectionMarque(newValue);
                    this.injected.rootStore.annuaireStore.productStore.getProducts(this.state.marque?.id, "")
                }}
                onInputChange={async (event, newInputValue, reason) => {
                    if (reason === "clear") {
                        await this.setState({
                            appareil: ""
                        })
                    }
                }}
                selectOnFocus
                renderInput={(params) => <TextField {...params}
                                                    margin="normal"
                                                    label="Rechercher une marque"
                                                    variant="outlined" />}
            />


            <Autocomplete
                value={this.state.appareil}
                options={this.injected.rootStore.annuaireStore.productStore.products}
                getOptionLabel={(option) => {
                    if(option?.id) {
                        return "#" + option?.id + " " + option?.nom
                    } else {
                        return ""
                    }
                }}
                style={{ width: 300}}
                onChange={async (event, newValue) => {
                    await this.selection(newValue);
                }}
                selectOnFocus
                onInputChange={(event, newInputValue, reason) => {
                    if(reason === "input") {
                        this.search(event, newInputValue);
                    }
                }}
                renderInput={(params) => <TextField {...params}
                                                    margin="normal"
                                                    label="Rechercher un produit"
                                                    variant="outlined" />}
            />
        </div>)
    }

    render() {
        return (
            <>

                {this.state.card &&(
                    <Card>
                        <CardHeader style={{padding: "0.2em"}} className={"bg-primary"} >
                            {this.setTitle()}
                        </CardHeader>
                        {this.addMachineUser()}
                        {this.setMode()}
                    </Card>
                    )}

                {!this.state.card && (
                    <Container className="mt-2">
                                {this.addMachineUser()}
                                {this.setMode()}
                    </Container>
                )}


            </>)
    }
}));

export default AddMachineUser;
