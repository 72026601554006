import {inject, observer} from "mobx-react";
import React from "react";
import {Container} from "react-bootstrap";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";


const Commande = (inject("rootStore")(observer(class Commande extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Commandes", "Gestion des commandes", "commande.jpg", this.injected.rootStore.commandeStore)
        this.injected.rootStore.commandeStore.filter.status = ""
    }

    onChange(event, status) {
        event.preventDefault()
        this.injected.rootStore.commandeStore.currentPage = 1;
        this.injected.rootStore.commandeStore.setStatus(status);
    }


    get injected() {
        return this.props;
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <GenericTableAffichage api={this.injected.rootStore.commandeStore}
                                           user={false}
                                           status={false}
                                           statusHeader={true}
                                           icon={<i className="fa fa-truck-o mr-2" aria-hidden="true"/>}
                                           name={"Commandes"}
                                           type="cmd"/>

                </Container>
            </>
        )
    }
})));

export default Commande;
