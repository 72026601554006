import React from "react";
import {inject, observer} from "mobx-react";
import i18n from "i18next";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import 'react-phone-input-2/lib/style.css'
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import styles from "../../Annuaire/Prestation/Prestation.module.css";
import configuration from "../../../../configuration/configuration";
import htmlToDraft from 'html-to-draftjs';
import EditorWord from "../../../../components/Akitatek/EditorWord";

const UpdatePage = inject("rootStore")(observer(class UpdatePage extends React.Component {

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            title: "",
            metadescription: "",
            system: 0,
            menu: 0,
            id: "",
            url: "",
            name: "",
            page: this.injected.match.params.page,
            editorState: EditorState.createEmpty(),
        };

        this.copyToClipboard = this.copyToClipboard.bind(this)
        this.onSubmit.bind(this);
        this.injected.rootStore.savedDatasStore.setHeader("Gestion des pages", "Ajouter / modifier une page", "devis.jpg")
    }

    //update component if the id change, or if there is a refresh
    async componentDidUpdate(prevProps) {

        if (this.injected.match.params.page && this.state.page !== this.injected.match.params.page && this.injected.match.params.page !== "add") {
            await this.onRouteChanged()
        }
        if (this.injected.match.params.page && this.state.page !== this.injected.match.params.page && this.injected.match.params.page === "add") {
            await this.setState({
                title: "",
                url: "",
                metadescription: "",
                system: 0,
                menu: 0,
                id: "",
                name: "",
                page: this.injected.match.params.page,
                editorState: EditorState.createEmpty(),
            })
        }
    }

    async onRouteChanged() {
        let authResponse = await this.injected.rootStore.pageStore.find(this.injected.match.params.page)
        await this.onChange(authResponse)
    }

    componentDidMount() {
        if (this.injected.match.params.page && this.injected.match.params.page !== "add") {
            this.onRouteChanged()
        }
    }

    async onChange(data) {
        let editorState;
        if (data.content) {
            let blocksFromHTML = htmlToDraft(data.content);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            editorState = EditorState.createWithContent(contentState);
        } else {
            editorState = EditorState.createEmpty()
        }
        await this.setState({
            id: data.id,
            name: data.name,
            editorState: editorState,
            title: data.title,
            system: data.system,
            menu: data.menu,
            url: data.url,
            metadescription: data.metadescription,
        });
    }

    async onSubmit(e) {
        e.preventDefault()


        let data = {
            id: this.state.id,
            title: this.state.title,
            metadescription: this.state.metadescription,
            name: this.state.name,
            system: this.state.system,
            menu: this.state.menu,
            content: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        }
        let authResponse;
        if (this.state.id) {
            authResponse = await this.injected.rootStore.pageStore.update(data)
        } else {
            authResponse = await this.injected.rootStore.pageStore.create(data)
        }

        if (authResponse?.status === "success") {
            this.onChange(authResponse.data)
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState:
            editorState,
        });
    };

    async copyToClipboard(e, copy) {
        e.preventDefault()
        await navigator.clipboard.writeText(copy)
        await this.injected.rootStore.notificationStore.setNotification(copy + " copié ! ", "success")

    }

    addEntreprise() {
        return (
            <Row>
                <Form style={{width: "100%"}}
                      role="form"
                      id="myForm"
                      onSubmit={e => this.onSubmit(e)}
                >

                    <div>
                        <Row className="pl-3 pr-3">
                            <Col>
                                <FormGroup>
                                    <label
                                        className={styles.typeField}
                                    >
                                        Nom de la page
                                    </label>
                                    <Input
                                        disabled={this.state.system}
                                        className="form-control-alternative"
                                        type="text"
                                        value={this.state.name || ''}
                                        onChange={e => this.setState({name: e.target.value})}
                                    />
                                </FormGroup>
                            </Col>
                            {this.injected.rootStore.authStore.options.metadata === 1 && (
                                <Col>
                                    <FormGroup>
                                        <label
                                            className={styles.typeField}
                                        >
                                            Titre de la page (Si vide généré auto)
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            type="text"
                                            value={this.state.title || ''}
                                            onChange={e => this.setState({title: e.target.value})}
                                        />
                                    </FormGroup>
                                </Col>
                            )}
                            {this.injected.rootStore.authStore.options.metadata === 1 && (
                                <Col>
                                    <FormGroup>
                                        <label
                                            className={styles.typeField}
                                        >
                                            Métadescription (si vide : généré auto)
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            type="text"
                                            value={this.state.metadescription || ''}
                                            onChange={e => this.setState({metadescription: e.target.value})}
                                        />
                                    </FormGroup>
                                </Col>
                            )}
                        </Row>

                        <Col>
                            <EditorWord editorState={this.state.editorState}
                                        onEditorStateChange={this.onEditorStateChange}/>
                        </Col>

                        <Row>
                            <Col style={{display: "flex", justifyContent: "center"}}>
                                <FormGroup className={"mr-2"}>
                                    <label>
                                        <input type="radio"
                                               checked={this.state.menu === 1}
                                               onClick={e => this.setState({menu: 1})}
                                        />
                                        <span className="badge badge-pill badge-success">MENU</span>
                                    </label>
                                </FormGroup>
                                <FormGroup>
                                    <label>
                                        <input type="radio"
                                               checked={this.state.menu === 0}
                                               onClick={e => this.setState({menu: 0})}
                                        />
                                        <span className="badge badge-pill badge-warning">HORS DU MENU</span>
                                    </label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="pl-lg-4" style={{textAlign: "center"}}>
                        <Row>
                            <Col>
                                {this.state.id && (
                                    <Button className={"bg-warning ml-2 mr-2"}
                                            onClick={e => this.copyToClipboard(e, configuration.API + '/page/' + this.state.url)}
                                    >
                                        Copier l'URL
                                    </Button>)}
                                <Button color="success" type="submit">
                                    {i18n.t('button.submit')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Row>)
    }

    render() {
        return (
            <>
                <Container className="mt-2" fluid>
                    <Card className="shadow">
                        <CardHeader className="bg-transparent" style={{display: "flex", color: "#20a8d8"}}>
                            <h4 className="mb-0"> Modifier la page {this.state.name}</h4>
                        </CardHeader>
                        <CardBody>
                            {this.addEntreprise()}
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}));

export default UpdatePage;
