import React from "react";
import {inject, observer} from "mobx-react";
import Input from "reactstrap/lib/Input";
import moment from "moment";
import {Link} from "react-router-dom";
import getRoutes from "../../../components/Akitatek/getRoutes";


const TableMouvRow = inject("rootStore") (observer( class TableMouvRow extends React.Component {

    item;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            disabled: true,
            lot: this.injected.item.lot ?? "",
            commande: this.injected.item.commande ?? "",
            commande_produit: this.injected.item.commande_produit ?? "",
            facture: this.injected.item.facture ?? "",
            idProduct: this.injected.item.product?.id ?? "",
            name: this.injected.item.product?.nom ?? "",
            type: this.injected.item.type,
            quantite: this.injected.item.quantite ?? 0,
            created_at: this.injected.item.created_at ?? "",
            updated_at: this.injected.item.updated_at ?? "",
            id: this.injected.item.id ?? "",
            reason: this.injected.item.reason ?? "",
            };

        this.toggleDanger = this.toggleDanger.bind(this);
    }

    toggleDanger() {
        this.injected.rootStore.notificationStore.setWarning("Mouvement stock #" + this.state.id + " de " + this.state.name, event => this.onDelete( this.state.id,  this.state.name))
    }

    async onDelete(idDelete, name) {
        await this.injected.rootStore.stockStore.mouvStore.deleteMouv({
            id: idDelete
        }, "Mouvement stock #" + idDelete + " de " + name)
    }

    componentDidUpdate(prevProps) {
        if (this.injected.item !== prevProps.item ) {
            this.setState({
                idProduct: this.injected.item.product?.id ?? "",
                name: this.injected.item.product?.nom ?? "",
                type: this.injected.item.type,
                quantite: this.injected.item.quantite ?? 0,
                created_at: this.injected.item.created_at ?? "",
                updated_at: this.injected.item.updated_at ?? "",
                id: this.injected.item.id ?? "",
                lot: this.injected.item.lot ?? "",
                commande_produit: this.injected.item.commande_produit ?? "",
                facture: this.injected.item.facture ?? "",
            })
        }
    }

    change() {
        this.setState({disabled: !this.state.disabled})
    }

    render() {
        return (
            <>
                <tr style={{ textAlign: "center"}}>
                    <th scope="row">
                        <span className="mb-0 text-sm">
                            { this.state.lot ? (
                                this.state.lot?.pid
                            ): ("Pas de lot associé")
                            }
                        </span>
                    </th>

                        <th scope="row">
                        <span className="mb-0 text-sm">
                           { this.state.facture ? (

                               <Link to={{
                                   pathname: getRoutes("Gérer une facture", this.state.facture.id),
                                   aboutProps: {
                                       isCommandeValid: true,
                                       invoice: this.state.facture
                                   }
                               }}>
                                   #{this.state.facture?.id}  - {this.state.facture?.intitule}
                               </Link>
                           ): ("Pas de facture associée")
                           }
                        </span>
                        </th>

                        <th scope="row">
                            <span className="mb-0 text-sm">
                                { this.state.commande_produit ? (
                                    <Link to={{
                                        pathname: "/command/update/" + this.state.commande_produit.commande.id,
                                        aboutProps: {
                                            isCommandeValid: true,
                                            commande: this.state.commande_produit.commande
                                        }
                                    }}>
                                        #{this.state.commande_produit.commande.id} - {this.state.commande_produit.commande.title}
                                    </Link>
                                ): ("Pas de commande magasin associée")
                                }
                            </span>
                        </th>

                    <th scope="row">
                            <span className="mb-0 text-sm">
                                { this.state.commande ? (
                                    <Link to={{
                                        pathname: "/commandes/modifier/" + this.state.commande.id,
                                        aboutProps: {
                                            commande: this.state.commande
                                        }
                                    }}>
                                        #{this.state.commande.id}
                                    </Link>
                                ): ("Pas de commande client associée")
                                }
                            </span>
                    </th>

                    <td>
                            <Input type="select" name="select" id="exampleSelect"
                                   disabled={this.state.disabled}
                                   value={this.state.type}
                                   onChange={ e => this.setState({type:  e.target.value})}

                            >
                                <option value={"REMOVE"}> REMOVE </option>
                                <option value={"ADD_MANUALLY"}> ENTREE </option>
                                <option value={"INVENTAIRE"}> INVENTAIRE </option>
                                <option value={"REMOVE_MANUALLY"}> SORTIE </option>
                                <option value={"BUY"}> ACHAT </option>
                                <option value={"SELL"}> VENTE </option>

                            </Input>
                       Raison : {this.state.reason}
                    </td>

                    <td className="th-price td-start" style={{ textAlign: "center"}}>
                        {this.state.quantite}
                    </td>

                    <td  style={{ textAlign: "center"}}>
                        <span className="text-sm m-auto">
                            {moment(this.state.created_at).format("LLLL")}
                        </span>
                    </td>
                </tr>
            </>
        );
    }

}));

export default TableMouvRow;
