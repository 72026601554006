import React from "react";
import {inject, observer} from "mobx-react";
import {Link, withRouter} from "react-router-dom";
import {Button, UncontrolledTooltip} from "reactstrap";
import PictureHidden from "../../../../components/Akitatek/PictureHidden";


const ServicesRow = inject("rootStore")(observer(class ServicesRow extends React.Component {

    data;

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);
        this.data = this.injected.data;

        this.state = {
            data: this.injected.data,
        }

        this.onDragOver = this.onDragOver.bind(this)
        this.onDragStart = this.onDragStart.bind(this)
        this.onDrop = this.onDrop.bind(this)
        this.toggleDanger = this.toggleDanger.bind(this)
    }


    componentDidUpdate(prevProps) {
        if (
            this.injected.data !== prevProps.data
        ) {
            this.setState({
                data: this.injected.data
            })
        }
    }

    toggleDanger(event) {
        event.preventDefault()
        this.injected.rootStore.notificationStore.setWarning("Service #" + this.state.data.id, event => this.onDelete(this.state.data.id))
    }

    async onDelete(deleteId) {
        await this.injected.rootStore.homeStore.deleteService(deleteId);
    }


    async onDrop(event) {
        event.preventDefault()
        event.stopPropagation()
        if (event.dataTransfer.getData("id")) {
            //all data from line
            let id = event.dataTransfer.getData("id")
            let data = {
                id: this.state.data.id,
                otherId: id
            }
            await this.injected.rootStore.homeStore.moveService(data)
        }

    }

    onDragStart(event) {
        event.dataTransfer.setData("id", this.state.data.id);
        event.stopPropagation()
    }

    onDragOver(event) {
        event.preventDefault()
    }


    render() {
        return (
            <>
                <tr>
                    {!this.state.data?.system ? (
                        <th scope="row"
                            draggable={true}
                            onDragOver={this.onDragOver}
                            onDrop={this.onDrop}
                            onDragStart={this.onDragStart}
                        >

                        <span className="mb-0 text-sm">
                            <i className="fa fa-th-list" aria-hidden="true"></i>
                            {" "} {this.state.data.order}
                        </span>
                        </th>) : (
                        <th scope="row"
                        >
                            Système : Première position
                        </th>
                    )}


                    <td className="text-center">
                        <PictureHidden assetName={this.state.data?.asset?.filename ?? ""} height={"4em"} width={"4em"}/>
                    </td>

                    <td className="text-center">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.state.data?.title
                            }}>
                        </div>
                    </td>


                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {this.state.data?.position}
                        </span>
                    </td>

                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {this.state.data?.system ? "Oui" : "Non"}
                        </span>
                    </td>

                    <td className="text-center">
                        <span className="mb-0 text-sm">
                            {this.state.data?.isVisible ? <span className="badge badge-pill badge-success">Publié</span>
                                : <span className="badge badge-pill badge-warning">Non publié</span>
                            }
                        </span>
                    </td>


                    <td className={"th-actions"}>
                        <div style={{display: "flex", justifyContent: "flex-start"}}>

                            <Link to={{
                                pathname: "/accueil/service/ajouter/" + this.state.data.id,
                                aboutProps: {
                                    isCommandeValid: true,
                                    data: this.state.data
                                }
                            }}>
                                <Button className={"ml-2 mr-2"} color="primary" type="button"
                                        id={"UncontrolledTooltipExampleVoir" + this.state.data.id}>
                                    <i className="fa fa-eye" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top"
                                                         target={"UncontrolledTooltipExampleVoir" + this.state.data.id}>
                                        Voir
                                    </UncontrolledTooltip>
                                </Button>
                            </Link>
                            {!this.state.data?.system && (
                                <Button className={"ml-2 mr-2"} color="danger" type="button" onClick={this.toggleDanger}
                                        id={"UncontrolledTooltipExampleSelection" + this.state.data.id}>
                                    <i className="fa fa-minus" aria-hidden="true"/>
                                    <UncontrolledTooltip placement="top"
                                                         target={"UncontrolledTooltipExampleSelection" + this.state.data.id}>
                                        Supprimer
                                    </UncontrolledTooltip>
                                </Button>)}
                        </div>
                    </td>
                </tr>
            </>
        );
    }

}));

export default withRouter(ServicesRow);
