import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
import i18n from "i18next";
import styles from "../../Annuaire/Prestation/Prestation.module.css";
import {Editor} from "react-draft-wysiwyg";
import {ContentState, EditorState} from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import ChoiceImageInput from "../../../../components/Akitatek/Form/ChoiceImageInput";
import EditorWord from "../../../../components/Akitatek/EditorWord";


const AddService = (inject("rootStore")(observer(class AddService extends React.Component {
    get injected() {
        return this.props;
    }


    constructor(props, context) {
        super(props, context);

        this.state = {
            file: "",
            img: null,
            id: null,
            url: '',
            isVisible: 0,
            title: '',
            description: '',
            assetName: "",
            asset_id: "",
            card: this.injected.card ?? true,
            right: 1,
            button: "En savoir plus"
        }

        this.onBack = this.onBack.bind(this);
        this.toggleDanger = this.toggleDanger.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        if (this.state.card) {
            this.injected.rootStore.savedDatasStore.setHeader("Services", "Modifier les services sur la page d'accueil", "business.jpg")
        }
    }


    UNSAFE_componentWillMount() {
        if (this.injected.location?.aboutProps?.service) {
            this.setInitialData(this.injected.location.aboutProps?.service)
        }
    }

    async componentDidMount() {
        this.id = this.props.match && parseInt(this.props.match.params.id, 10);

        if (this.id) {
            let service = await this.injected.rootStore.homeStore.findServiceById(this.id);
            if (service) {
                this.setInitialData(service)
            }
        }
    }

    onEditorStateTitleChange = (editorState) => {
        this.setState({
            title:
            editorState,
        });
    };


    onEditorStateChange = (editorState) => {
        this.setState({
            description:
            editorState,
        });
    };

    setInitialData(service) {
        let editorState;
        let title;
        if (service?.description != null) {
            let blocksFromHTML = htmlToDraft(service.description);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

            editorState = EditorState.createWithContent(contentState);
        } else {
            editorState = EditorState.createEmpty()
        }

        if (service?.title != null) {
            let blocksFromHTML = htmlToDraft(service.title);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

            title = EditorState.createWithContent(contentState);
        } else {
            title = EditorState.createEmpty()
        }

        this.setState({
            id: service.id,
            url: service?.url,
            assetName: service?.asset?.filename,
            asset_id: service?.asset?.id,
            isVisible: service.isVisible,
            title: title,
            description: editorState,
            right: service?.right,
            button: service?.button
        });
    }


    async onSubmit(e) {
        e.preventDefault();
        let authResponse;

        if (this.state.id) {
            authResponse = await this.injected.rootStore.homeStore.updateService(this.state);
        } else {
            authResponse = await this.injected.rootStore.homeStore.saveService(this.state);
        }

        if (authResponse?.status === "success") {
            if (this.state.card) {
                this.setInitialData(authResponse?.data)
            } else {
                this.injected.onCallback()
            }
        }
    }

    styles;


    toggleDanger() {
        this.injected.rootStore.notificationStore.setWarning("Service #" + this.state.id, event => this.onDelete(this.state.id))
    }

    async onDelete(deleteId) {
        let status = await this.injected.rootStore.homeStore.deleteService(deleteId);
        if (status === "success") {
            this.injected.history.goBack()
        }
    }

    onChange(e) {
        const name = e.target.name
        const type = e.target.type
        const value = type === "radio" ? parseInt(e.target.value) : e.target.value
        this.setState({[name]: value});
    }

    onPickAssetFilename = (action) => {
        if (action === "add") {
            this.setState({
                assetName: this.injected.rootStore.imageStore.pickAsset?.filename,
                asset_id: this.injected.rootStore.imageStore.pickAsset?.id,
            })
        } else {
            this.setState({
                assetName: "",
                asset_id: "",
            })
        }
    }


    setForm() {
        return (<Form style={{width: "100%"}}
                      role="form"
                      id="myFormUpdate"
                      onSubmit={this.onSubmit}
        >

            <div className="pl-lg-4">
                <Row>
                    <Col>
                        <FormGroup>
                            <label
                                className={styles.typeField}
                                htmlFor="input-description"
                            >
                                Titre
                            </label>
                            <Editor
                                editorState={this.state.title}
                                onEditorStateChange={this.onEditorStateTitleChange}
                                wrapperClassName="wrapper-class"
                                localization={{
                                    locale: 'fr',
                                }}
                                stripPastedStyles={true}
                                toolbar={{
                                    options: ['inline', 'link', 'emoji', 'history', 'remove', 'colorPicker'],
                                    image: {
                                        urlEnabled: false,
                                        uploadEnabled: false,
                                        alignmentEnabled: true, // Whether to display the arrange button is equivalent to text-align
                                        previewImage: false,
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <ChoiceImageInput assetName={this.state.assetName}
                                      onPickAssetFilename={this.onPickAssetFilename}/>
                </Row>


                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <label
                                className={styles.typeField}
                                htmlFor="input-first-name"
                            >
                                Titre du bouton
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="input-first-name"
                                type="textarea"
                                value={this.state.button || ''}
                                name="button"
                                onChange={this.onChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <label
                                className={styles.typeField}
                                htmlFor="input-first-name"
                            >
                                URL
                                {this.injected.rootStore.authStore.options.homeOrganisation === "soros" && (
                                    '/ Si rien : affichera Modal entreprise "Présentez nous"')}
                            </label>

                            <Input
                                className="form-control-alternative"
                                id="input-first-url"
                                type="text"
                                value={this.state.url || ''}
                                name="url"
                                onChange={this.onChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <EditorWord editorState={this.state.description} onEditorStateChange={this.onEditorStateChange}/>
                {this.injected.rootStore.authStore.options.homeOrganisation === "soros" && (
                    <Row>
                        <Col style={{display: "flex", justifyContent: "center"}}>
                            <FormGroup className={"mr-2"}>
                                <label>
                                    <input type="radio"
                                           name="right"
                                           value={1}
                                           onChange={this.onChange}
                                           checked={this.state.right === 1}
                                    />
                                    <span className="badge badge-pill badge-success">Droite</span>
                                </label>
                            </FormGroup>
                            <FormGroup className={"mr-2"}>
                                <label>
                                    <input type="radio"
                                           name="right"
                                           value={2}
                                           onChange={this.onChange}
                                           checked={this.state.right === 2}
                                    />
                                    <span className="badge badge-pill badge-info">Centre</span>
                                </label>
                            </FormGroup>
                            <FormGroup>
                                <label>
                                    <input type="radio"
                                           name="right"
                                           value={0}
                                           onChange={this.onChange}
                                           checked={this.state.right === 0}
                                    />
                                    <span className="badge badge-pill badge-warning">Gauche</span>
                                </label>
                            </FormGroup>
                        </Col>
                    </Row>)}

                <Row>
                    <Col style={{display: "flex", justifyContent: "center"}}>
                        <FormGroup className={"mr-2"}>
                            <label>
                                <input type="radio"
                                       name="isVisible"
                                       value={1}
                                       onChange={this.onChange}
                                       checked={this.state.isVisible === 1}
                                />
                                <span className="badge badge-pill badge-success">PUBLIER</span>
                            </label>
                        </FormGroup>
                        <FormGroup>
                            <label>
                                <input type="radio"
                                       name="isVisible"
                                       value={0}
                                       onChange={this.onChange}
                                       checked={this.state.isVisible === 0}
                                />
                                <span className="badge badge-pill badge-warning">NON PUBLIER</span>
                            </label>
                        </FormGroup>
                    </Col>
                </Row>

                <div className="pl-lg-4 mt-3" style={{textAlign: "center"}}>
                    <Button color="success" className="mr-1" type="submit">
                        {i18n.t('button.submit')}
                    </Button>

                    {this.state.id &&
                    <Button color="danger" type="button" className="mr-1"
                            onClick={this.toggleDanger}>
                        Supprimer
                    </Button>
                    }
                </div>
            </div>
        </Form>)
    }


    onBack(e) {
        e.preventDefault()
        this.injected.history.goBack()
    }

    render() {
        return (
            <>
                {this.state.card && (
                    <Container className="mt-2" fluid>
                        <Row className="mb-3">
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="bg-transparent" style={{display: "flex"}}>
                                        <h3 className="mb-0" style={{color: "#20a8d8"}}>  {
                                            this.state.id ? (
                                                <div>
                                                    <i onClick={this.onBack} style={{cursor: "pointer"}}
                                                       className="fa fa-arrow-left mr-2" aria-hidden="true"/>
                                                    Service #{this.state.id}
                                                </div>
                                            ) : (
                                                "Service"
                                            )
                                        }</h3>
                                    </CardHeader>
                                    <CardBody> <Row>{this.setForm()} </Row></CardBody>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                )}


                {!this.state.card && (
                    this.setForm()
                )}
            </>
        );
    }
})));

export default AddService;
