import React from "react";

function PhoneRow({title, action}) {

   function FrTel(tel){
        if(tel) {
            return tel.replace(/(\d\d(?!$))/g,"$1-");
        }
        return "";
    }

    function copy() {
        /* Copy the text original */
        navigator.clipboard.writeText(title);
    }



    return <span className="mb-0 text-sm">
                            <a onClick={copy}
                                onCopy={copy} href={"tel:" + title}>
                              { title ? FrTel(title) : ""}
                            </a>
                        </span>
}

export default PhoneRow;
