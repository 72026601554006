import {inject, observer} from "mobx-react";
import React from "react";
import {Container} from "react-bootstrap";
import styles from "./Stock.module.css";
import {CardHeader} from "reactstrap";
import Card from "reactstrap/lib/Card";
import GenericStaticCard from "../../../components/Akitatek/Card/GenericStaticCard";
import GenericTableAffichage from "../../../components/Akitatek/Table/GenericTableAffichage";
import AnnuaireCategory from "../Annuaire/Category/Category";


const Stock = (inject("rootStore")(observer(class Stock extends React.Component {


    stock;

    get injected() {
        return this.props;
    }

    refresh(e, shop, alerte) {
        e.preventDefault()
        this.injected.rootStore.stockStore.productStore.filter.search = ""
        this.injected.rootStore.stockStore.productStore.filter.page = "1"
        this.injected.rootStore.stockStore.productStore.filter.shop = shop;
        this.injected.rootStore.stockStore.productStore.filter.category = "";
        this.injected.rootStore.stockStore.productStore.filter.alerte = alerte;
        this.injected.rootStore.stockStore.productStore.selectionCategory = null;
        this.injected.rootStore.stockStore.productStore.getDatas();
    }

    componentDidMount() {
        this.injected.rootStore.annuaireStore.categoryStore.getCategoriesByTree()
    }

    constructor(props, context) {
        super(props, context);
        this.injected.rootStore.savedDatasStore.setHeader("Stock", "Gestion des stocks", "motherboard.jpg", this.injected.rootStore.stockStore.productStore)
    }

    setProductName() {
        return (
            <Card className={styles.item}>
                <GenericTableAffichage api={this.injected.rootStore.stockStore.productStore}
                                       user={false}
                                       status={false}
                                       statusHeader={false}
                                       icon={<i className="fa fa-file-pdf-o mr-2" aria-hidden="true"/>}
                                       name={"Stocks"}
                                       type="stock"/>

            </Card>)
    }

    render() {
        return (
            <>
                <Container className={"mt-2"} fluid>
                    <div style={{display: "flex", justifyContent: "space-between", alignContent: "center"}}>
                        <GenericStaticCard search={false} category="stocks" key="card1"
                                           element={<h4 className="mb-0">Chercher les pièces par catégorie</h4>}>
                            <Card style={{margin: 0, cursor: "pointer"}}>
                                <CardHeader style={{justifyContent: "center", textAlign: "center"}}>
                                    <button className={"btn-lg btn-primary 2x"}
                                            onClick={e => this.refresh(e, "", "")}>Tout
                                    </button>

                                    {this.injected.rootStore.authStore.modules['boutique'] === 1 && (
                                        <button className={"btn-lg btn-secondary"}
                                                onClick={e => this.refresh(e, "1", "")}>Magasin</button>
                                    )}

                                    <button className={"btn-lg btn-danger 2x"}
                                            onClick={e => this.refresh(e, "", "1")}>Alerte
                                    </button>
                                </CardHeader>
                            </Card>

                            <div style={{display: "block"}}>

                                <AnnuaireCategory
                                    api={this.injected.rootStore.annuaireStore.categoryStore}
                                    update={true}
                                />
                            </div>
                        </GenericStaticCard>
                        {this.setProductName()}
                    </div>
                </Container>
            </>
        );
    }

})));

export default Stock;
