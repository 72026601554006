import React from "react";
import {inject, observer} from "mobx-react";
import styles from "./Header.module.css"
import configuration from "../../../configuration/configuration";

const Header = inject("rootStore") (observer(class Header extends React.Component {

    title
    subtitle
    image

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            title : this.injected.title,
            subtitle: this.injected.subtitle,
            image: configuration.API +"/backend/img/h1background/" + this.injected.image
        }
    }


    componentDidUpdate(prevProps) {
        if (this.injected.title !== prevProps.title ||
            this.injected.subtitle !== prevProps.subtitle||
            this.injected.image !== prevProps.image
        ) {
            this.setState({
                title : this.injected.title,
                subtitle: this.injected.subtitle,
                image: configuration.API +"/backend/img/h1background/" + this.injected.image

            })
        }
    }






    render() {
        return (
            <>
                <section className={styles.subHeader} style={{ backgroundImage: `url(${this.state.image})` }}>
                        <div className={styles.header}>
                            <div  className={styles.title}>{this.state.title}</div>
                            <div  className={styles.subtitle}>{this.state.subtitle}</div>
                        </div>
                    </section>
            </>
        );
    }
}));

export default Header;
