import {decorate, observable} from "mobx";
import Api from "../util/api";

export default class HomeStore {

    presentations = observable([]);
    services = observable([]);

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.api = new Api();
    }

    async getPresentations() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.home.getPresentations();

            if (this.presentations.length > 0) {
                this.presentations.replace(authResponse)
            } else {
                this.presentations = authResponse;
            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.datas
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async getServices() {
        this.rootStore.savedDatasStore.setLoading(true)
        try {
            const authResponse = await this.api.home.getServices();

            if (this.services.length > 0) {
                this.services.replace(authResponse)
            } else {
                this.services = authResponse;
            }
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.datas
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async findServiceById(id) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.home.findServiceById(id);
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async findPresById(id) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            const authResponse = await this.api.home.findPresById(id);
            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse;

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async saveService(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.home.saveService(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Service ajouté", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async savePres(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.home.savePres(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Présentation ajoutée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.data

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updateService(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.home.updateService(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Service #" + authResponse.data.id + " modifiée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async moveService(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.home.moveService(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Succès", "success");
                this.getServices()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async movePres(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.home.movePres(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Succès", "success");
                this.getPresentations()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async updatePres(datas, message) {
        this.rootStore.savedDatasStore.setLoading(true)

        try {
            let authResponse = await this.api.home.updatePres(datas);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification(message ?? "Pres #" + authResponse.data.id + " modifiée", "success");
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse

        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }


    async deletePres(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.home.deletePres(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Présentation # " + id + " supprimée", "success");
                this.getPresentations()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.status
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

    async deleteService(id) {
        try {
            this.rootStore.savedDatasStore.setLoading(true)
            let authResponse = await this.api.home.deleteService(id);

            if (authResponse?.status === "success") {
                this.rootStore.notificationStore.setNotification("Service # " + id + " supprimé", "success");
                this.getServices()
            }

            this.rootStore.savedDatasStore.setLoading(false)
            return authResponse.status
        } catch (e) {
            this.rootStore.savedDatasStore.setLoading(false)
        }
    }

}

decorate(HomeStore, {
    presentations: observable,
    services: observable
});

