import React from "react";
import ErrorBoundary from "../Error/ErrorBounday";
import TableGeneric from "../Headers/TableGeneric";
import DataRow from "./DataRow";
import Pagination from "../Pagination";
import {inject, observer} from "mobx-react";
import TypeTable from "./getTypeTable";
import ActionType from "./ActionType";
import SelectStatus from "./SelectStatus";
import {Card, CardBody, CardHeader} from "reactstrap";
import SetLoading from "../setLoading";
import Label from "reactstrap/lib/Label";
import FormGroup from "reactstrap/lib/FormGroup";
import Badge from "reactstrap/lib/Badge";
import Radio from "@material-ui/core/Radio";
import StatusHeaderType from "../StatusHeaderType";

const GenericTableAffichage = inject("rootStore")(observer(class GenericTableAffichage extends React.Component {

    async componentDidMount() {
        await  this.reload();
    }

    async reload() {
        if(!this.injected.api.datas ||this.injected.api.datas.length <= 0 ) {
            this.setState({
                loading: true
            })

            if (this.props.statusHeader) {
                await this.injected.api.getAllStatus();
                await this.injected.api.getStatusname();
            }
            await this.loadDatas()
        } else {
            if(this.injected.api.filter?.user_id !== this.state.filters?.user_id || this.injected.api.filter?.prise_en_charge_id !== this.state.filters?.prise_en_charge_id
            ){
                await this.loadDatas()
            }
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.injected.filters !== prevProps.filters) {
            this.setState({
                filters: this.injected.filters ?  this.injected.filters : this.injected.api.defaultFilter
            })

            await this.reload()

        }
    }

    onChangeStatus = async (status) => {
        this.setState({
            loading: true
        })
        this.injected.api.filter = this.injected.api.defaultFilter
        this.injected.api.filter.status = status
        await this.injected.api.getStatusname()
        await this.injected.api.getDatas()
        this.setState({
            loading: false
        })
    }


    async loadDatas() {
            if (this.state.filters) {
                this.injected.api.filter = this.state.filters
            } else {
                this.injected.api.filter = Object.assign({}, this.injected.api.defaultFilter)
            }
            await this.load()


    }

    async load() {
        this.setState({
            loading: true
        })
        await this.injected.api.getDatas();
        this.setState({
            loading: false
        })
    }

    parentLoad = async () => {
        await this.load()
    };


    constructor(props, context) {
        super(props, context);
        this.state = {
            user_id: this.injected.user_id,
            loading: false,
            filters: this.injected.filters ?  this.injected.filters : this.injected.api.defaultFilter
        }
        this.onClickOnline = this.onClickOnline.bind(this)
    }

    get injected() {
        return this.props;
    }

    parentCallback = async (childData) => {
        this.injected.api.filter.page = childData
        await this.load()
    };

    async onClickOnline(value) {
        this.injected.api.filter.online = value
        await this.load()
    }

    render() {
        let {type, api, status, name, icon, priseencharge, statusHeader} = this.props
        const [titles, onFilter] = TypeTable(type, api)

        return (
            <>
                <ErrorBoundary>
                    <Card>

                        {name && (
                            <CardHeader
                                className={this.injected.rootStore.savedDatasStore.setColor(api.filter.status ? api.filter.status[0] : "")}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                <h4 className="mb-0">
                                    {icon}


                                    {api.filter.search !== "" ? (
                                        "Recherche " + api.filter.search + " "
                                    ) : (
                                        " " + name + " " + this.injected.api?.statusname + " "
                                    )
                                    }

                                    {type === "stock" && (
                                        this.injected.api?.filter?.shop === "1" ? "Magasin " : "Tout ")
                                    }

                                    {type === "stock" && (
                                        this.injected.api?.selectionCategory?.nom  ?? "")
                                    }





                                </h4>

                                {statusHeader && (<StatusHeaderType type={type} onChange={this.onChangeStatus}/>)}

                            </CardHeader>)}
                        {priseencharge && (
                            <CardHeader style={{display: "flex", padding: "0.1em"}}>
                                <FormGroup check>
                                    <Label check style={{display: "flex", alignItems: "center"}}>
                                        <Radio type="radio" name="myGroupName" onChange={e => this.onClickOnline("")}
                                               checked={this.injected.api.filter.online === ""}/>
                                        <h5><Badge color="primary">Tout</Badge></h5>
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check style={{display: "flex", alignItems: "center"}}>
                                        <Radio type="radio" name="myGroupName" onChange={e => this.onClickOnline("1")}
                                               checked={this.injected.api.filter.online === "1"}/>
                                        <h5><Badge color="danger">Internet</Badge></h5>
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check style={{display: "flex", alignItems: "center"}}>
                                        <Radio type="radio" name="myGroupName" onChange={e => this.onClickOnline("0")}
                                               checked={this.injected.api.filter.online === "0"}/>
                                        <h5><Badge color="warning">Magasin</Badge></h5>
                                    </Label>
                                </FormGroup>

                            </CardHeader>
                        )}
                        {status && (<SelectStatus api={api} load={this.parentLoad}/>)}
                        {!this.state.loading ? (
                                <CardBody>
                                    <TableGeneric key={type} onFilter={onFilter}
                                                  titles={titles}>
                                        {api?.datas?.map(data => {
                                            return <DataRow
                                                titles={titles ? titles : []}
                                                data={data}
                                                key={data.id}
                                                actions={<ActionType data={data} type={type}/>}
                                            />
                                        })
                                        }
                                        {(api?.datas?.length === 0) && (
                                            <tr>
                                                <td className={"no_td"}
                                                    colSpan={titles?.length}>Aucunes données
                                                </td>
                                            </tr>
                                        )}
                                    </TableGeneric>
                                    {api?.filter.page !== "" && api?.filter.last_page !== "1" && (
                                        <Pagination
                                            loading={this.state.loading}
                                            currentPage={api?.filter.page}
                                            lastPage={api?.filter.last_page}
                                            parentCallback={this.parentCallback}/>
                                    )}

                                </CardBody>) :
                            (
                                <CardBody><SetLoading/></CardBody>
                            )}
                    </Card>
                </ErrorBoundary>
            </>
        );
    }

}));
export default GenericTableAffichage;
