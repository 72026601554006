import React  from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import {inject, observer} from "mobx-react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import TabPane from "reactstrap/lib/TabPane";
import TabContent from "reactstrap/lib/TabContent";
import classnames from 'classnames';

const Tabs = inject("rootStore") (observer(  class Tabs extends React.Component {

    show
    titles

    component1 = <div> Bonjour, un problème avec l'affichage est survenu. Merci de contacter un administrateur. </div>

    constructor(props, context) {
        super(props, context);

        this.state={
            show: this.injected.show ?? false,
            titles: this.injected.titles ?? ["Infos"],
            activeTab: this.injected.activeTab ?? "0",
            children: this.injected.children ?? [this.component1]

        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.children !== prevProps.children ||
            this.injected.titles !== prevProps.titles ||
            this.injected.activeTab !== prevProps.activeTab
        ) {
            this.setState({
                children : this.injected.children,
                titles: this.injected.titles,
                activeTab: this.injected.activeTab ?? "0",
            })
        }
    }

    toggle(e, nb) {
        this.setState({
            activeTab: nb
        })

        this.injected.onCallback(nb)
    }

    get injected() {
        return this.props;
    }


    render() {
        return (
            <div>
                <Nav tabs className={"tab-color"}>
                        {   this.state.titles.map((title, index)=> {
                            return (
                                <NavItem key={index} ><NavLink
                                className={classnames({ active: this.state.activeTab === (index).toString() })}
                                onClick={ e => this.toggle(e, (index).toString())}
                            >
                                {title}
                            </NavLink></NavItem>
                            )})}

                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                                <Row className={"mt-2"}>
                                    <Col style={{ minHeight: '30em'}}  sm="12">
                                        {this.state.children[this.state.activeTab]}
                                    </Col>
                                </Row>
                </TabContent>
            </div>
        );
    }
}));

export default Tabs;

