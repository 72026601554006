import React from "react";
import ErrorBoundary from "../../../components/Akitatek/Error/ErrorBounday";
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";

function AnnuaireCard({data, url, route, rootStore, name}) {

    async function onLoad(id, name) {
        rootStore.annuaireStore.productStore.search = "";
        rootStore.annuaireStore.productStore.marqueId = id;
        rootStore.annuaireStore.productStore.marque = name;
        await rootStore.annuaireStore.productStore.getProducts();
        rootStore.annuaireStore.prestationStore.prestations = [];
        if (document.getElementById('productCard')) {
            document.getElementById('productCard').scrollIntoView()
        }
    }

    async function onLoadPresta(id, name) {
        rootStore.annuaireStore.productStore.search = "";
        rootStore.annuaireStore.prestationStore.appareilId = id;
        await rootStore.annuaireStore.prestationStore.getPrestation();
        rootStore.annuaireStore.prestationStore.appareil = "de " + name;
        if (document.getElementById('prestationCard')) {
            document.getElementById('prestationCard').scrollIntoView()
        }
    }

    function getLoad(e, name, data) {
        e.preventDefault();

        if(name === "presta") {
            onLoadPresta(data.id, data.nom)
        }

        if(name === "product") {
            onLoad( data.id, data.nom)
        }
    }

    return <ErrorBoundary>
        <Card onClick={e=> getLoad(e, name, data)} className={"mr-4"}
              style={{flex: "0 0 10%"}}>
            {(data.asset || data.photo || data.icon) &&
            <Card.Img style={{height: '10rem', objectFit: "contain"}}
                      variant="top"
                      src={url}/>
            }
            <Card.Body>
                <Card.Title>
                    <Link to={{
                        pathname: route,
                        aboutProps: {
                            data: data
                        }
                    }}>
                        {data.nom} {data.title} {data.isVisible ? (
                        <i className="fa fa-eye " aria-hidden="true"/>) : (
                        <i className="fa fa-eye-slash " aria-hidden="true"/>)}
                    </Link>
                </Card.Title>
            </Card.Body>
        </Card>
    </ErrorBoundary>
}

export default AnnuaireCard;
