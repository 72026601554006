import React from "react";
import {inject, observer} from "mobx-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/Button";
import {Card, CardHeader} from "reactstrap";

const SearchPresta = inject("rootStore")(observer(class SearchPresta extends React.Component {


    id
    machinesClient
    user

    get injected() {
        return this.props;
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            id: this.injected.id,
            activeTab: "3",
            presta: ""
        }

        this.injected.rootStore.annuaireStore.prestationStore.getAllPrestations()
    }

    componentDidUpdate(prevProps) {
        if (
            this.injected.machineClient !== prevProps.machineClient ||
            this.injected.id !== prevProps.id

        ) {
            this.setState({
                id: this.injected.id,
            })
        }
    }


    selection(appareil) {
        this.setState({
            presta: appareil ?? ""
        })
    }


    async onSubmit(e) {
        e.preventDefault()

        let data = {
            presta_id: this.state.presta.id,
            prise_en_charge_id: this.state.id,
        }

        let authResponse = await this.injected.rootStore.prisenchargeStore.savePresta(data)

        if (authResponse?.status === "success") {
            this.injected.callbackPresta(authResponse.datas, this.state.activeTab)
        }
    }


    onClose = (childData) => {
        this.setState({
            addMachineClient: false,
            activeTab: "2"
        })

        if (childData) {
            this.setState({
                machinesClient: childData,
                activeTab: "2"
            })
        }
    };


    render() {
        return (
            <Card>
                <CardHeader style={{padding: "0.2em"}} className={"bg-primary"}>
                    <div style={{display: "flex", justifyContent: "space-between", fontSize: "1.2em"}}>
                        <div className="mr-4 ml-2"> Ajouter des prestation</div>
                    </div>
                </CardHeader>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                    <Autocomplete
                        className={"mr-2 ml-2"}
                        value={this.state.presta}
                        options={this.injected.rootStore.annuaireStore.prestationStore.allPrestations}
                        getOptionLabel={(option) => {
                            if (option?.id) {
                                return "#" + option?.id + " " + option?.fullname
                            } else {
                                return ""
                            }
                        }}
                        style={{width: "50em"}}
                        onChange={async (event, newValue) => {
                            await this.selection(newValue);
                        }}
                        selectOnFocus
                        renderInput={(params) => <TextField {...params}
                                                            margin="normal"
                                                            label={"Rechercher des prestations "}
                                                            variant="outlined"/>}
                    />


                    <Button
                        onClick={(e) => {
                            this.onSubmit(e, true);
                        }} variant="success" size="sm" type="button" className="mr-1">
                        <i className="fa fa-check"/>
                    </Button>
                </div>
            </Card>


        )
    }
}));

export default SearchPresta;
